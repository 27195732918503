import { NOTIFICATION_TYPE } from "services/womsEnum";

export function getTypeCssClasses(type) {
    var type;
    switch(type) {
      case NOTIFICATION_TYPE.SYSTEM_ALERT:
        type="danger"
        break;
        case NOTIFICATION_TYPE.SYSTEM_NOTICE:
        type="success"
        break;
        case NOTIFICATION_TYPE.USER_MESSAGE:
        type="secondary"
        break;
        case NOTIFICATION_TYPE.ORG_NOTICE:
          type="primary"
          break;
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }
import firebase from 'firebase/compat/app';


// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";

export default function setupAxios(axios) {

  //let navigate = useNavigate();
  axios.interceptors.request.use(
    config => {
      var user = localStorage.getItem("authUser");
      if (user) {
        user = JSON.parse(user);
        if (user.stsTokenManager.accessToken) {
          // Token is valid
          config.headers.Authorization = `Bearer ${user.stsTokenManager.accessToken}`;

          // currentOrganization 
          var organization = JSON.parse(localStorage.getItem("currentOrganization"));
          if (organization) {
            config.headers['X-Organization'] = organization.id; // Adding X-Organization header
        }

          return config;
        } else {
          // Token has expired
          return config;
        }
      } else {
        return config;
      }

    },
    err => {
      Promise.reject(err);
    });
  // Add a response interceptor
  axios.interceptors.response.use(
    response => {
      // If the response was good, return it
      return response;
    },
    err => {
       const originalRequest = err.config;

      // Check if the error was 401 and we have not already retried this request
      if (err.response.status === 401 && !originalRequest._retry) {
        // Set this flag so we don't keep retrying the same request
        originalRequest._retry = true;
        const user = firebase.auth().currentUser;

        if (user) {
          // User is signed in, now you can get the ID token
          user.getIdToken(/* forceRefresh */ true).then(function(idToken) {
            console.log("token refresh successful");
            originalRequest.headers.Authorization = `Bearer ${idToken}`;
            return axios(originalRequest);
          }).catch(function(error) {
            console.log("token refresh failed");
            window.location.href="/logout";
            return Promise.resolve();
          });
        } else {
          console.log("getting authuser failed");
          window.location.href="/logout";
          return Promise.resolve();
        }

      } else if (err.response.status === 401) {
        //navigate("/logout");
        window.location.href="/logout";
        // Return a new resolved promise to prevent further error handling
        return Promise.resolve();
      } /* custom error handling here
    else {
      //forgotLastLocation(); // prevent keep go back to error location
      if (!process.env.REACT_APP_ENVIRONMENT.startsWith('DEV')) {
        if (err.response) {
          if (err.response.status === 404) {
            navigate("/error/error404");
          } if (err.response.status === 403) {
            navigate("/error/error404");
          } else if (err.response.status === 500) {
            navigate("/error/error500");
          } else if (err.response.status === 422) {
            //no action
          } else {
            navigate("/error/error500");
          }
        } else {
          history.push("/error/error500");
        }
      } else {
        if (err.response.status !== 422) {
          navigate("/error/errordev?err=" + err + "&stack=" + (err.response.data.error ? err.response.data.error.message : err.response.data.message ? err.response.data.message : err.response.data.stack ? err.response.data.stack : err.stack));
        }
      }
    } 
    */

      return Promise.reject(err);
    }
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import UserModal from "pages/User/UserModal";
import Avatar from "react-avatar";
import SubscriptionPlanModal from "pages/SubscriptionPlan/SubscriptionPlanModal";
import { SUBSCRIPTION_PLAN_TYPE } from "services/womsEnum";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");
  const [showModal, setShowModal] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const womsUser = useSelector(state => state.womsUser);
  const organization = useSelector(state => state.organization);

  const toggleModal = ()=> {
    setShowModal(!showModal);
  }
  const handlePlanModalClose = ()=> {
    setShowPlanModal(false);
  }
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.email);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);
  useEffect(() => {
    if (organization.id && (!organization.subscriptionPlan || organization.isNeedsUpgrade)) {
      setShowPlanModal(true);
    }
  }, [organization]);

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <Avatar name={womsUser?.firstName + " " + womsUser?.lastName} src={womsUser?.imageUrl} size="38" round="19px" />
          {/*<img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />*/}
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" onClick={() => toggleModal()}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("PROFILE")}{" "}
          </DropdownItem>
          {/*<DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem>*/}
          <DropdownItem tag="a" href="#" disabled>
            {/*<span className="badge bg-success float-end">11</span>*/}
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("PROFILE.SETTINGS")}
          </DropdownItem>
          <Link to="/billing" className="dropdown-item">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("PROFILE.BILLING")}
            </Link>
          {organization.subscriptionPlan != SUBSCRIPTION_PLAN_TYPE.INSIDER && <Badge className="ms-4 mt-2" role="button" color="primary" onClick={()=>setShowPlanModal(true)} pill>{props.t("UPGRADE_PLAN")}</Badge>}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("PROFILE.LOGOUT")}</span>
          </Link>
          
        </DropdownMenu>
      </Dropdown>
      <UserModal
        show={showModal}
        isEdit={true}
        id={womsUser?.id}
        onClose={toggleModal}
        isProfile={true}
        //errorMessage={errorMessage}
      />
      <SubscriptionPlanModal
        show={showPlanModal}
        onClose={handlePlanModalClose}
      />
      
    </>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);

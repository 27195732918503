import PropTypes from 'prop-types'
import React from "react"
import { Card, CardBody, Col, Spinner } from "reactstrap"
import { SUBSCRIPTION_PLAN_TYPE } from 'services/womsEnum';
import i18n from "i18next"

const CardPricing = props => {
  const { pricing, organization,isLoading } = props;

  const checkSelectable = () => {
    // new organization
    if (!organization.subscriptionPlan) {
      return true;
    }    

    if (organization.subscriptionPlan == SUBSCRIPTION_PLAN_TYPE.FREE && pricing.subscriptionPlan != SUBSCRIPTION_PLAN_TYPE.FREE) {
      return true;
    } else if (organization.subscriptionPlan == SUBSCRIPTION_PLAN_TYPE.BASIC && (pricing.subscriptionPlan == SUBSCRIPTION_PLAN_TYPE.PRO || pricing.subscriptionPlan == SUBSCRIPTION_PLAN_TYPE.ENTERPRISE))
    {
      return true;
    } else if (organization.subscriptionPlan == SUBSCRIPTION_PLAN_TYPE.PRO && pricing.subscriptionPlan == SUBSCRIPTION_PLAN_TYPE.ENTERPRISE) {
      return true;
    } 
    return false;

  }

  return (
    <React.Fragment>
      <Col xl="3" md="6">
        <Card className="plan-box">
          <CardBody className="p-4">
            <div className="d-flex plan-title">
              <div className="flex-grow-1">
                <h5>{pricing.title}</h5>
              </div>
              <div className="flex-shrink-0 ms-3">
                <i
                  className={"bx " + pricing.icon + " h1 text-primary"} style={{lineHeight:1}}
                />
              </div>
            </div>
            <div className="d-flex plan-desc">
                <p className="text-muted">{pricing.description}</p>
            </div>
            <div className="pb-2">
              {pricing.price ? <h3>
                <sup>
                  <small>$</small>
                </sup>{" "}
                {pricing.price}{" "}
                <span className="font-size-13"> / {pricing.duration}</span>
              </h3> : <h5>{pricing.duration}</h5>}
            </div>
            <div className="text-center plan-btn">
              <div
                //to={pricing.link}
                onClick={!checkSelectable() || isLoading ? ()=>{} :
                 pricing.callback ? pricing.callback : ()=>{window.location.href=pricing.link} }
                
                className={"btn btn-sm waves-effect waves-light " + (isLoading ? "btn-secondary" : (organization.subscriptionPlan && organization.subscriptionPlan == pricing.subscriptionPlan ? "btn-warning" :
                checkSelectable() ? "btn-primary" : "btn-secondary"))}
              >{isLoading ? <Spinner size='sm' animation="border" className="ms-3 me-3 mt-1"/> : (organization.subscriptionPlan && organization.subscriptionPlan == pricing.subscriptionPlan ? i18n.t("CURRENT") :
                pricing.price ? pricing.price === "0" ? i18n.t("TRY_FREE") : organization.subscriptionPlan ? i18n.t("UPGRADE") : i18n.t("SIGN_UP") : i18n.t("GET_QUOTE"))}
              </div>
            </div>

            <div className="plan-features mt-5">
              {pricing.features.map((feature, key) => (
                <p key={"_feature_" + key}>
                  <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                  {feature.title}
                </p>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardPricing.propTypes = {
  pricing: PropTypes.object
}

export default CardPricing

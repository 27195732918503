import { nanoid } from "nanoid";
import { CLIENT_SYNC_TYPE, ORDER_FEE_TYPE, PRICE_MOD_ROW_TYPE, PRICE_MOD_TYPE } from "services/womsEnum";
import { Button } from 'reactstrap';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import i18n from "i18next";
import { PriceModRow } from "./PriceModRow";
import { usePriceModConfig } from "./usePriceModConfig";
import { isNanoId } from "helpers/is_nano_id";
import { useEffect } from "react";

const ItemPriceModSectionsContainer = ({children}) => {
  return (
    <div className="d-flex flex-column gap-2 pt-4">
      {children}
    </div>
  );
};

const ItemPriceModMenu = ({
  item,
  
  addFeeItem,
  addDiscountItem,
  addTaxItem
}) => {
  const menuButtons = [
    {
      name: i18n.t("ITEM_FEE"),
      onClick: addFeeItem
    },
    {
      name: i18n.t("ITEM_DISCOUNT"),
      onClick: addDiscountItem
    },
    {
      name: i18n.t("ITEM_TAX"),
      onClick: addTaxItem
    }
  ];
  
  return (
    <div className="d-flex justify-content-end gap-2 " >
      {
      menuButtons.map(btn => (
      <Button
      disabled={item.syncType === CLIENT_SYNC_TYPE.DELETED}
      type="button"
      className="d-flex justify-content-center price-mod-add-button-oli gap-2 align-items-center  text-black"
      onClick={btn.onClick}
      key={btn.name}
      >
        <AddCircleOutlineIcon fontSize="inherit"/> {btn.name}
      </Button>
      ))
      }
    </div>
  );
};

const ItemPriceModSection = ({
  item,
  priceModType,
  order, 
  setOrder,
  currency,
}) => {

  const { priceModLineItemsKey, pModHeader } = usePriceModConfig(priceModType);

  const handleItemPriceModChange = (priceModItem, value, changeField) => {

    const updatedPriceModItems = item[priceModLineItemsKey].map(pmi => 
      pmi.id === priceModItem.id 
      ? { ...pmi, 
        [changeField]: value, 
        syncType: !isNanoId(priceModItem.id) ? CLIENT_SYNC_TYPE.UPDATED : CLIENT_SYNC_TYPE.CREATED 
      } 
      : pmi
    );
  
    const updatedOrderLineItems = order.orderLineItems.map(orderItem => 
      orderItem.id === item.id 
      ? { ...orderItem, [priceModLineItemsKey]: updatedPriceModItems, syncType: !isNanoId(orderItem.id) ? CLIENT_SYNC_TYPE.UPDATED : CLIENT_SYNC_TYPE.CREATED  } 
      : orderItem
    );

    setOrder(prev => ({...prev, orderLineItems: updatedOrderLineItems}));
  };

  const markPriceModAsDeleted = (id) => {
    const updatedPriceModItems = item[priceModLineItemsKey].map(pmi => {
      if (pmi.id === id) {
        return {
          ...pmi,
          syncType: pmi.syncType === CLIENT_SYNC_TYPE.DELETED && isNanoId(id)
            ? CLIENT_SYNC_TYPE.CREATED
            : pmi.syncType === CLIENT_SYNC_TYPE.DELETED
            ? CLIENT_SYNC_TYPE.UPDATED
            : CLIENT_SYNC_TYPE.DELETED
        };
      }
      return pmi;
    });

    const updatedOrderLineItems = order.orderLineItems.map(orderItem => 
      orderItem.id === item.id 
      ? { ...orderItem, [priceModLineItemsKey]: updatedPriceModItems, syncType: !isNanoId(orderItem.id) ?  CLIENT_SYNC_TYPE.UPDATED : CLIENT_SYNC_TYPE.CREATED } 
      : orderItem
    );

    setOrder(prev => ({...prev, orderLineItems: updatedOrderLineItems}));
  };

  
  const handleRowDeleted = (id) => {
    // Filter out the row with the specified ID from priceModLineItemsKey
    const updatedPriceModItems = item[priceModLineItemsKey].filter(pmi => pmi.id !== id);
  
    // Update the specific orderLineItem that corresponds to the item, if needed
    const updatedOrderLineItems = order.orderLineItems.map(orderItem => 
      orderItem.id === item.id 
      ? {
          ...orderItem, 
          [priceModLineItemsKey]: updatedPriceModItems, 
          syncType: !isNanoId(orderItem.id) ? CLIENT_SYNC_TYPE.UPDATED : CLIENT_SYNC_TYPE.CREATED
        } 
      : orderItem
    );
  
    // Update the overall order state
    setOrder(prev => ({...prev, orderLineItems: updatedOrderLineItems}));
  };

  return (
    <>
    {
      priceModLineItemsKey &&
      item[priceModLineItemsKey].length > 0 &&
      <div className="d-flex flex-column align-items-end w-100 gap-2">
        {
        item[priceModLineItemsKey].map(pmi => (
          <PriceModRow 
          type={PRICE_MOD_ROW_TYPE.ITEM}
          item={item}
          key={pmi.id}
          priceModItem={pmi}
          header={`${pModHeader}:`}
          currency={currency}
          handlePriceModChange={handleItemPriceModChange}
          markPriceModAsDeleted={markPriceModAsDeleted}
          handleRowDeleted={handleRowDeleted}
          />
        ))}
      </div>
    }
    </>
  )
};

export const ItemPriceMods = ({
  item,
  order,
  setOrder,
  
}) => {
   
  const addItem = (type, extraAttributes = {}) => {

    const defaultCorePriceModItem = {
      id: nanoid(),
      amount: 0.00,
      name: "",
      orderLineItemId: item.id,
    };

    const itemToAdd = {
      ...defaultCorePriceModItem,
      syncType: CLIENT_SYNC_TYPE.CREATED,
      ...extraAttributes,
    };

    if(type === PRICE_MOD_TYPE.FEE) {
      itemToAdd.type = ORDER_FEE_TYPE.OTHER
    };

    const keyMap = {
      [PRICE_MOD_TYPE.FEE]: 'feeLineItems',
      [PRICE_MOD_TYPE.DISCOUNT]: 'discountLineItems',
      [PRICE_MOD_TYPE.TAX]: 'taxLineItems',
    };

    const lineItemsKey = keyMap[type] || type;

    const updatedItems = [...(item[lineItemsKey] || []), itemToAdd];

    const updatedOrderLineItems = order.orderLineItems.map(oli => 
      oli.id === item.id 
        ? { ...oli, [lineItemsKey]: updatedItems, syncType: !isNanoId(oli.id) ? CLIENT_SYNC_TYPE.UPDATED : CLIENT_SYNC_TYPE.CREATED }
        : oli
    );

    setOrder(prev => ({ ...prev, orderLineItems: updatedOrderLineItems }));
  };

  const addFeeItem = () => addItem(PRICE_MOD_TYPE.FEE);
  const addDiscountItem = () => addItem(PRICE_MOD_TYPE.DISCOUNT);
  const addTaxItem = () => addItem(PRICE_MOD_TYPE.TAX, { isInclusive: 0 });

  return (
    <tr>
      {/* <td colSpan={1}></td> */}
      <td colSpan={8}>
        <div className={`
          w-100 d-flex flex-column align-items-end pt-2 pb-2
          ${item.syncType === CLIENT_SYNC_TYPE.DELETED ? "opacity-25" : "opacity-100"} 
        `}>

            <ItemPriceModMenu 
            item={item}
            addFeeItem={addFeeItem}
            addDiscountItem={addDiscountItem}
            addTaxItem={addTaxItem}
            />

            <ItemPriceModSectionsContainer>
              {
              Object.keys(PRICE_MOD_TYPE).map(pModType => (
              <ItemPriceModSection 
              key={pModType}
              item={item}
              priceModType={pModType}
              order={order}
              setOrder={setOrder}
              currency={order.currency}
              />
              ))
              }
            </ItemPriceModSectionsContainer>

              
        </div>
      </td>
      
    </tr>
  )
};
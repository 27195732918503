import { SYNC_STATUS } from "services/womsEnum";

export function getsSyncStatusCssClasses(status) {
  var type;
  switch(status) {
    case SYNC_STATUS.NONE:
    type="secondary"
    break;
    case SYNC_STATUS.REQUESTED:
    type="primary"
    break;
    case SYNC_STATUS.PROCESSING:
    type="warning"
    break;
    case SYNC_STATUS.COMPLETED_WITH_ISSUE:
    type="info"
    break;
    case SYNC_STATUS.COMPLETED:
    type="success"
    break;
    case SYNC_STATUS.FAILED:
    type="danger"
    break;
    case SYNC_STATUS.OTHER:
    type="secondary"
    break;
    default:
      type=""
  }

  return `badge badge-soft-${
    type
  }`;
}
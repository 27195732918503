import React, { useEffect, useState, useMemo, useContext } from "react"
import Moment from "react-moment"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import AdvancedTableContainer from "components/Common/AdvancedTableContainer"
import { getTypeCssClasses } from "./NotificationUIHelper"
import { WomsServiceContext } from "services/womsService"
import cf from "../../services/codeFormatter"
import i18n from "i18next"
import { withTranslation } from "react-i18next"
import NotificationModal from "./NotificationModal"
import { ENTITY_FILTER_TYPE, USER_ROLE } from "services/womsEnum"
import { updateUnreadCount } from "store/notification/actions"
import { useDispatch, useSelector } from "react-redux";
import { hasRole } from "helpers/woms_utils"
// Column
const Id = cell => {
  return cell.getValue() ? <>{"#NO" + cell.getValue()} {!cell.row.original.userNotifications[0].isRead && <span className="badge badge-soft-warning ms-2">New</span>}</>: ""
}
const Type = cell => {
  return (
    <span className={getTypeCssClasses(cell.getValue())}>
      {cell.getValue() ? cf.format("NotificationType", cell.getValue()) : ""}
    </span>
  )
}
const Content = cell => {
  return cell.getValue() ? cell.getValue() : ""
}
const SentBy = cell => {
  return cell.getValue() ? cell.getValue().firstName + " " + cell.getValue().lastName : ""
}
const Reference = cell => {
  return cell.getValue() ? <a href={cell.getValue()}>Link</a> : ""
}
const CreatedOn = cell => {
  return cell.getValue() ? <Moment format="ll">{cell.getValue()}</Moment> : ""
}
function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}) {
  const ref = React.useRef();

  React.useEffect(() => {
    if (indeterminate) {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
    />
  )
}
const NotificationList = props => {
  //meta title
  document.title = i18n.t("Notifications")
  const { womsSvc } = useContext(WomsServiceContext)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const dispatch = useDispatch();
  const womsUser = useSelector(state => state.womsUser);
  const organization = useSelector(state => state.organization);


  const handleEntityClick = arg => {}

  // Customber Column
  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <div className="text-center"><IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
              onClick: ()=> {
                table.toggleAllRowsSelected();
              }
            }}
          /></div>
        ),
        cell: ({ row }) => (
          <div className="text-center">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                //disabled: !row.getCanSelect(),
                //indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
                onClick: () => {
                  row.toggleSelected();
                },
              }}
            />
          </div>
        ),
      },
      {
        header: i18n.t("ID"),
        accessorKey: "id",
        filterable: false,
        enableSorting: true,
        cell: cellProps => {
          return <Id {...cellProps} />
        },
      },
      {
        header: i18n.t("TYPE"),
        accessorKey: "type",
        enableSorting: true,
        filterable: false,
        cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        header: i18n.t("CONTENT"),
        accessorKey: "content",
        enableSorting: false,
        filterable: false,
        cell: cellProps => {
          return <Content {...cellProps} />
        },
      },
      {
        header: i18n.t("SENT_BY"),
        accessorKey: "sentBy",
        enableSorting: false,
        filterable: false,
        cell: cellProps => {
          return <SentBy {...cellProps} />
        },
      },
      {
        header: i18n.t("REFERENCE"),
        accessorKey: "referenceRoute",
        enableSorting: false,
        filterable: false,
        cell: cellProps => {
          return <Reference {...cellProps} />
        },
      },
      {
        header: i18n.t("CREATED_ON"),
        accessorKey: "createdOn",
        enableSorting: true,
        cell: cellProps => {
          return <CreatedOn {...cellProps} />
        },
      },
      {
        header: i18n.t("ACTION"),
        enableSorting: false,
        cell: cellProps => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original
                    onMarkAsReadClick(entityData.id)
                  }}
                >
                  <i
                    className="mdi mdi-pencil font-size-16 text-success me-1"
                    id="edittooltip"
                  ></i>
                  {i18n.t("MARKASREAD")}
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    {i18n.t("MARKASREAD")}
                  </UncontrolledTooltip>
                </DropdownItem>

                {(hasRole(womsUser,organization,USER_ROLE.SYS_ADMIN) || cellProps.row.original.sentById == womsUser?.id) && <DropdownItem
                  href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original
                    onDeleteClick(entityData)
                  }}
                >
                  <i
                    className="mdi mdi-trash-can font-size-16 text-danger me-1"
                    id="deletetooltip"
                  ></i>
                  {i18n.t("DELETE_FOR_ALL")}
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {i18n.t("DELETE_FOR_ALL")}
                  </UncontrolledTooltip>
                </DropdownItem>}
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        },
      },
    ],
    [i18n.language,womsUser]
  )

  const selectedPopup = (selectedRows) => {
    return <>
    <Button className="btn btn-sm btn-light ms-4 me-4" onClick={()=> {

    if (selectedRows && selectedRows.length > 0) {
      
      let ids = [];
      for (var row of selectedRows) {
          if (row && row.original && row.original.userNotifications[0].isRead != true && row.original.id) {
              ids.push(row.original.id);
          }
      }
      womsSvc.markAsRead(ids).then(({ data }) => {
        womsSvc.getUnreadCount().then(({ data }) => {
          dispatch(updateUnreadCount(data));
          setRerender(rerender + 1);
        })
      });
    }
  }}>{i18n.t("MARK_AS_READ")}</Button>
    </>
};

  const [deleteModal, setDeleteModal] = useState(false)
  const [entityData, setEntityData] = useState({})
  const [errorMessage, setErrorMessage] = useState()
  const [rerender, setRerender] = useState(0)
  const onDeleteClick = entityData => {
    setEntityData(entityData)
    setDeleteModal(true)
  }

  const handleDeleteEntity = () => {
    if (entityData && entityData.id) {
      womsSvc.deleteNotification(entityData.id).then(({ data }) => {
        setRerender(rerender + 1);
        setDeleteModal(false)
      })
      
    }
  }

  const handleAddEntity = () => {

  }
  const onMarkAsReadClick = id => {
    womsSvc.markAsRead([id]).then(({ data }) => {
      womsSvc.getUnreadCount().then(({ data }) => {
        dispatch(updateUnreadCount(data));
        setRerender(rerender + 1);
      })
    });
    //setEntityData(entityData)
    //setErrorMessage("")
    //setIsEdit(true)
    //setModal(true)
  }

  const onAddClick = () => {
    setEntityData({})
    setErrorMessage("")
    setIsEdit(false)
    setModal(true)
  }

  const handleModalClose = isUpdated => {
    if (isUpdated) {
      setRerender(rerender + 1)
    }
    setModal(false)
  }

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEntity}
        errorMessage={errorMessage}
        onCloseClick={() => setDeleteModal(false)}
        entityName={i18n.t("NOTIFICATION")}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs altTitle={i18n.t("NOTIFICATION_LIST")} title={i18n.t("NOTIFICATION")} breadcrumbItem={i18n.t("LIST")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AdvancedTableContainer
                    columns={columns}
                    odataFetch={womsSvc.searchNotifications}
                    initialSortBy={"createdOn"}
                    isGlobalFilter={true}
                    addButtonLabel={i18n.t("NEW_NOTIFICATION")}
                    onAddClick={onAddClick}
                    customPageSize={10}
                    className="table-advanced"
                    rerender={rerender}
                    selectedPopup={selectedPopup}
                    isCustomFilter={false}
                    entityType={ENTITY_FILTER_TYPE.NOTIFICATION}
                    //fetchCondition={}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <NotificationModal
        show={modal}
        isEdit={isEdit}
        id={entityData?.id}
        onClose={handleModalClose}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default withTranslation()(NotificationList)

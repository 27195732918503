import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Col, Button, Input, FormFeedback, Label } from "reactstrap";
import Moment from 'react-moment';
import { useLocation } from "react-router-dom";
import Avatar from "react-avatar"
import { Formik, useFormik } from 'formik';
import HtmlReactParser from 'html-react-parser';
import { WomsServiceContext } from "services/womsService";
import { ACTIVITY_LOG_TYPE } from "services/womsEnum";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import i18n from 'i18next';

function ActivityLogEntry (props) {
    const { aLog, depth, isNewDate, refreshHandler, orderId,productId } = props;
    const { womsSvc } = useContext(WomsServiceContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isReply, setIsReply] = useState(false);
    let location = useLocation();
    const womsUser = useSelector(state => state.womsUser);
    
    function formatLog(str) {

        var newStr = str.replace(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi, function () {
                return '<a href="'+arguments[0]+'" target="_blank">' + arguments[0] + '</a>';
            }).replace(/(?:\r\n|\r|\n)/g, '<br />');
        return newStr;
    }

    function deleteActivityLog(id) {
        womsSvc.deleteActivityLog(id).then(({ data }) => {
            refreshHandler();
        }).finally(() => {
            //setSubmitting(false); 필요없음 리프레시 되면서 component ummount

        });
    }
    
    const formik  = useFormik({
        enableReinitialize: true,
    
        initialValues: {
            parentLogId: aLog.id,
            orderId: aLog.orderId,
            productId: aLog.productId,
            type: ACTIVITY_LOG_TYPE.USER_NOTE,
            note: "",
            referenceRoute: location.pathname
        },
        validationSchema: Yup.object({
            note: Yup.string().required("Please enter message")
        }),
    
        onSubmit : (values, { setSubmitting }) => {
            setSubmitting(true);
            if (aLog.type === ACTIVITY_LOG_TYPE.ADMIN_LOG) values.type = ACTIVITY_LOG_TYPE.ADMIN_LOG;
            womsSvc.createActivityLog(values).then(({ data }) => {
                refreshHandler();
                values.note = "";
                setIsReply(false);
            }).finally(() => {
                //setSubmitting(false); 필요없음 리프레시 되면서 component ummount

            });
        },
        });

    // inline style class로 전환할것

    return (<>
        {isNewDate ? <Row>
            <Col><hr className="divider" /></Col>
            <Col style={{ flex: "0 0 80px" }} className='pt-1'><Moment format="MMM Do">{aLog.createdOn}</Moment></Col>
            <Col><hr  className="divider" /></Col>
        </Row> : <Row><Col style={{ marginLeft: "50px" }}><br/></Col></Row>}
        <Row style={(aLog.type === ACTIVITY_LOG_TYPE.ADMIN_LOG) ? {backgroundColor:"#FFEEDE", paddingTop:"10px",paddingBottom:"5px"} : {}} >
            <Col style={{ flex: "0 0 80px", borderLeft: "1px solid lightGrey", borderLeftStyle: depth === 0 ? "hidden" : "solid" }}>
                {(aLog.type === ACTIVITY_LOG_TYPE.USER_NOTE || aLog.type === ACTIVITY_LOG_TYPE.ADMIN_LOG) && aLog.submittedBy && <Avatar name={aLog.submittedBy.firstName + " " + aLog.submittedBy.lastName} size="30" round="15px" />}
                {aLog.type === ACTIVITY_LOG_TYPE.SYSTEM_LOG && <i className="bx bx-code-block font-size-18 message-icon" />}
            </Col>
            <Col>
                <Row>
                    <Col>{aLog.submittedBy && <span><b>{aLog.submittedBy.firstName + " " + aLog.submittedBy.lastName}</b> - </span>} <Moment format="lll">{aLog.createdOn}</Moment> {aLog.type === ACTIVITY_LOG_TYPE.OPERATOR_LOG && <span className="label label-lg label-light-success label-inline">
                        {i18n.t("ADMIN_LOG")}</span>}
                    {aLog.type !== ACTIVITY_LOG_TYPE.SYSTEM_LOG && aLog.submittedById == womsUser.id && 
                    <Button
                    disabled={isSubmitting}
                    type="button"
                    className="btn btn-light btn-sm woms-edit-btn float-right"
                    onClick={() => {
                        deleteActivityLog(aLog.id);
                    }}
                >
                    <i className="bx bx-trash" />
                </Button>}
                    </Col>
                </Row>
                <Row><Col>{HtmlReactParser(formatLog(aLog.note))}</Col>
                </Row>

                {(depth <= 2 && (aLog.type === ACTIVITY_LOG_TYPE.USER_NOTE || aLog.type === ACTIVITY_LOG_TYPE.ADMIN_LOG)) && <Row>
                    <Col className='p-0'>
                    <Button
                        disabled={isSubmitting}
                        type="button"
                        className="btn btn-light btn-sm my-1"
                        onClick={() => setIsReply(!isReply)}
                    >
                        {i18n.t("REPLY")} <i className="bx bx-reply" />
                    </Button>
                        {isReply &&
                            <Row>
                                <Col>
                                    
                                <Form
                                    onSubmit={(e) => {
                                    e.preventDefault();
                                    formik.handleSubmit();
                                    return false;
                                    }}>
                                    <div className="mb-1">
                                    <Input
                                        name="note"
                                        type="textarea"
                                        placeholder={i18n.t("LOG_MESSAGE")}
                                        rows="3"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.note || ""}
                                        invalid={
                                        formik.touched.note && formik.errors.note ? true : false
                                        }
                                        disabled={isSubmitting}
                                    />
                                    {formik.touched.note && formik.errors.note ? (
                                        <FormFeedback type="invalid">{formik.errors.note}</FormFeedback>
                                    ) : null}
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button
                                        type="submit"
                                        className="btn btn-primary btn-sm mb-2 mt-1"
                                        disabled={isSubmitting || !formik.isValid}
                                    >
                                        {i18n.t("LOG_POST")}
                                    </button>
                                    </div>
                                    </Form>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>}
                <Row>

                    <Col>
                        {aLog.childLogs && aLog.childLogs.map((aLog, index) => (
                            <React.Fragment key={index}>
                                <ActivityLogEntry aLog={aLog} depth={depth + 1} isNewDate={false} refreshHandler={refreshHandler}></ActivityLogEntry>
                            </React.Fragment>
                        ))
                        }
                    </Col>
                </Row>
            </Col>
        </Row>

    </>);
};




function ActivityLogs(props) {

    const { orderId, productId, forceUpdate } = props;
    const { womsSvc } = useContext(WomsServiceContext);
    const [activityLogList, setActivityLogList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    var location = useLocation();
    const womsUser = useSelector(state => state.womsUser);
    const formik  = useFormik({
        enableReinitialize: true,
    
        initialValues: {
            orderId: orderId,
            productId: productId,
            type: ACTIVITY_LOG_TYPE.USER_NOTE,
            isAdmin: false,
            note: "",
            referenceRoute: location.pathname
        },
        validationSchema: Yup.object({
            note: Yup.string().required(i18n.t("VALIDATION.LOG_PLEASE_ENTER_MESSAGE"))
        }),
    
        onSubmit : (values, { setSubmitting }) => {
            setSubmitting(true);
            if (values.isAdmin) values.type = ACTIVITY_LOG_TYPE.ADMIN_LOG;
            womsSvc.createActivityLog(values).then(({ data }) => {
                refreshLogList();
                values.note = "";
                values.isAdmin = false;
            }).finally(() => {
                setSubmitting(false);
            });
            
        },
        });

    useEffect(() => {
        if (orderId || productId) {
            refreshLogList();
        }

    }, [orderId,productId,forceUpdate]);

    const refreshLogList = () => {
        setIsLoading(true);
        if (orderId) {
            womsSvc.getActivityLogsForOrder(orderId).then(({ data }) => {
            setActivityLogList(data);

        }).finally(() => {
            setIsLoading(false);
        });
        } else if (productId) {
            womsSvc.getActivityLogsForProduct(productId).then(({ data }) => {
                setActivityLogList(data);

            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    return (<>
        <br />
        <Row>
            <Col>
            <Form
            onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
            }}>
                                    <div className="mb-1">
                                    <Input
                                        name="note"
                                        type="textarea"
                                        placeholder={i18n.t("LOG_MESSAGE")}
                                        rows="3"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.note || ""}
                                        invalid={
                                        formik.touched.note && formik.errors.note ? true : false
                                        }
                                        disabled={isLoading}
                                        className="ps-5"
                                    />
                                    <span>
                                    <Avatar name={womsUser?.firstName + " " +  womsUser?.lastName} size="30" round="15px" className="woms-activitylog-avatar" />

                                    </span>
                                    {formik.touched.note && formik.errors.note ? (
                                        <FormFeedback type="invalid">{formik.errors.note}</FormFeedback>
                                    ) : null}
                                    </div>
                                    <div className="d-flex justify-content-end">
                {/*<Label className="form-labe mt-1">Admin only</Label>
                <div className="form-check form-switch ms-3 mt-1" >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isAdmin"
                            checked={formik.values.isAdmin ?? false}
                            onClick={e => {
                                formik.setFieldValue('isAdmin', !formik.values.isAdmin);
                            }}
                            onChange={e => {
                            }}
                          />
                        </div>*/}
                        <button
                            type="submit"
                            className="btn btn-sm btn-primary mb-2 mt-1"
                            disabled={isLoading || isSubmitting || !formik.isValid}
                        >
                            {i18n.t("MEMO_POST")}
                        </button>
                </div>
                                    
                                    </Form>
                
            </Col>
        </Row>
        {activityLogList && activityLogList.map((aLog, index) => (
            <React.Fragment key={index}>
                <ActivityLogEntry aLog={aLog} depth={0} isNewDate={index === 0 || (new Date(aLog.createdOn).toDateString() !== new Date(activityLogList[index - 1].createdOn).toDateString())} refreshHandler={refreshLogList} orderId={orderId}></ActivityLogEntry>
            </React.Fragment>
        ))
        }

    </>);
}


export default ActivityLogs;

import React, { useEffect, useState, useContext } from "react";
import Moment from "react-moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  Badge,
  Spinner
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import { WomsServiceContext } from "services/womsService";
import cf from "../../services/codeFormatter";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import { formatToCurrency } from "pages/Order/OrderUIHelper";
import { Link } from "react-router-dom";
import { getStripeStatusCssClasses } from "./BillingUIHelper";
const BillingHistory = props => {
  // Meta title
  document.title = i18n.t("BILLING_HISTORY");

  const { womsSvc } = useContext(WomsServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [billingList, setBillingList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    womsSvc.getBillingHistory().then(({ data }) => {
      setBillingList(data.invoices);
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
            <h3>{i18n.t("BILLING_HISTORY")}</h3>
            <h5 className="mt-3">{i18n.t("BILLING_HISTORY_DESC")}</h5>
          <Row>
            <Col md="12">
              {isLoading ? (
                <Spinner color="secondary" size={'md'} className='ms-2'/>
              ) : (
                billingList.length == 0 ? <h6 className="mt-3">{i18n.t("NO_BILLING_HISTORY")}</h6> : <div className="billing-table">
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                        <th>{i18n.t("INVOICE_NO")}</th>
                        <th>{i18n.t("STATUS")}</th>
                        <th>{i18n.t("AMOUNT")}</th>
                        <th>{i18n.t("INVOICE")}</th>
                        <th>{i18n.t("RECEIPT")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {billingList.map(invoice => (
                        <tr key={invoice.id}>
                          <td>{invoice.number}</td>
                          <td>
                            <span className={getStripeStatusCssClasses(invoice.status)}>{invoice.status}</span>
                            
                          </td>
                          <td>{formatToCurrency((invoice.amount_due / 100).toFixed(2),invoice.currency.toUpperCase())}</td>
                          <td>
                            <Link color="primary" to={invoice.invoice_pdf} target="_blank">
                            {i18n.t("DOWNLOAD")}
                            </Link>
                          </td>
                          <td>
                            {invoice.hosted_invoice_url &&
                            <Link color="secondary" to={invoice.hosted_invoice_url} target="_blank">
                              {i18n.t("VIEW")}
                            </Link>
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default withTranslation()(BillingHistory);
import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import UserList from "pages/User/UserList"
import OrderList from "pages/Order/OrderList"
import LocationList from "pages/Location/LocationList"
import StoreList from "pages/Store/StoreList"
import ProductList from "pages/Product/ProductList"
import ProductDetails from "pages/Product/ProductDetail/ProductDetails"
import OrganizationList from "pages/Organization/OrganizationList"
import OrderDetail from "pages/Order/Detail/OrderDetail"
import BundleList from "pages/Bundle/BundleList"
import NewRegistration from "pages/Organization/NewRegistration"
import StoreListingList from "pages/StoreListing/StoreListingList"
import Report from "pages/Report/Report"
import NewUser from "pages/Authentication/NewUser"
import NotificationList from "pages/Notification/NotificationList"
import EmailVerification from "pages/Authentication/EmailVerification"
import BillingHistory from "pages/Billing/BillingHistory"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard/> },

  // //profile
  { path: "/profile", component: <UserProfile/> },

  { path: "/salesOrder", component: <OrderList /> },
  { path: "/salesOrder/:id", component: <OrderDetail /> },
  { path: "/product", component: <ProductList /> },
  { path: "/product/:id", component: <ProductDetails />},
  { path: "/bundle", component: <BundleList /> },
  { path: "/store", component: <StoreList /> },
  { path: "/listing", component: <StoreListingList /> },
  { path: "/report", component: <Report /> },
  { path: "/integration", component: <Dashboard /> },
  { path: "/user", component: <UserList /> },
  { path: "/location", component: <LocationList /> },
  { path: "/organization", component: <OrganizationList /> },
  { path: "/newRegistration", component: <NewRegistration /> },
  { path: "/notification", component: <NotificationList /> },
  { path: "/billing", component: <BillingHistory /> },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
   {
    path: "/",
    exact: true,
    component: < Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/new", component: <NewUser /> },
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/emailVerification", component: <EmailVerification /> },
]

export { authProtectedRoutes, publicRoutes }


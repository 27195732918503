import { CLIENT_SYNC_TYPE } from "services/womsEnum";

export const calculateSubtotal = (order) => {
  let subTotal = 0;

  order.orderLineItems?.forEach(lineItem => {
      const lineItemTotal = lineItem.price * lineItem.quantity;
      const lineItemDiscount = (lineItem.discountLineItems || []).reduce((total, discount) => markedAsDelete(discount) ? total : (total + discount.amount), 0);

      const lineItemFee = (lineItem.feeLineItems || []).reduce((total, fee) => markedAsDelete(fee) ? total : (total + fee.amount), 0); 

      const lineItemTax = (lineItem.taxLineItems || []).reduce((total, tax) => markedAsDelete(tax) ? total : (total + tax.amount), 0);

      subTotal += ((lineItemTotal + lineItemFee + lineItemTax) - lineItemDiscount);
  });
  return subTotal;
};


export const calculateTotalAmount = (order) => {
  let total = 0;

  const totalDiscount = (order.discountLineItems || []).reduce((total, discount) => markedAsDelete(discount) ? total : (total + discount.amount), 0);
  const totalFees = (order.feeLineItems || []).reduce((total, fee) => markedAsDelete(fee) ? total : (total + fee.amount), 0);
  //const totalTaxes = (order.taxLineItems || []).reduce((total, tax) => total + tax.amount, 0);
  const totalTaxes = calculateTotalTax(order);
  total = calculateSubtotal(order) - totalDiscount + totalFees + totalTaxes;
  return total <= 0 ? 0 : total;
};


export const calculateTotalTax = (order) => {
  let taxTotal = 0;

  order.orderLineItems?.forEach(lineItem => {
    const lineItemTax = (lineItem.taxLineItems || []).reduce((total, tax) => markedAsDelete(tax) ? total : (total + tax.amount), 0);
    taxTotal += lineItemTax;
  });

  const orderTaxLineItemsTotal = (order.taxLineItems || []).reduce((total, tax) => {
    return markedAsDelete(tax) ? total : (total + tax.amount);
  },0);

  taxTotal += orderTaxLineItemsTotal;
  return taxTotal;
};

const markedAsDelete = (priceModItem) => priceModItem.syncType === CLIENT_SYNC_TYPE.DELETED ? true : false;
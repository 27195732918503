import { 
  Input, 
  Form, 
  FormGroup, 
  Label,
  ModalBody,
  ModalFooter,
  Modal,
  ModalHeader, 
} from 'reactstrap';

import * as Yup from 'yup';

import { WomsServiceContext } from "services/womsService";

import { ErrorMessage, Formik } from 'formik';

import i18n from "i18next"
import { EditCancelButton } from 'components/Common/EditModal/EditCancelButton';
import { EditSaveButton } from 'components/Common/EditModal/EditSaveButton';
import { EDIT_MODAL_TYPE } from 'services/womsEnum';
import { useContext, useEffect, useState } from 'react';

export const NotesModal = ({
  order,
  setOrder,
  activeModal,
  setActiveModal
}) => {
  const { womsSvc } = useContext(WomsServiceContext);

  const [modalIsOpen, setModalIsOpen] = useState(false);
 
  const toggle = () => {
    setModalIsOpen(!modalIsOpen);
    setActiveModal(``);
  };

  useEffect(()=> {
    if (activeModal && activeModal === EDIT_MODAL_TYPE.ORDER_NOTES) {
      setModalIsOpen(true); 
    }
  }, [activeModal]);

  const notesSchema = Yup.object().shape({
    referenceNo: Yup.string()
    .max(1024, 'Too Long! Please keep order number under 1024 characters.')
    .required('Required')
  });

  const handleNotesSubmit = async (values, setSubmitting) => {
    setSubmitting(true);
    let updateOrder = {
      ...order,
      referenceNo: values.referenceNo,
    };
    try {

      await womsSvc.updateOrder(updateOrder);
      const { data } = await womsSvc.getOrder(order.id)
      setOrder(data);
      setSubmitting(false);
      toggle();
      
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  return (
    <Modal isOpen={modalIsOpen} toggle={toggle}>
      <Formik 
      enableReinitialize
      initialValues={{
        referenceNo: order.referenceNo
      }}
      validationSchema={notesSchema}
      onSubmit={(values, {setSubmitting}) => handleNotesSubmit(values, setSubmitting)}
      >
        {({ 
          values, 
          handleChange, 
          handleBlur, 
          errors, 
          touched, 
          handleSubmit,
          isSubmitting 
        }) => (
          <>
            <ModalHeader className='text-base font-semibold !bg-gray-50 !rounded-t-3xl ' tag={"h3"} toggle={toggle}>
              Edit Notes
            </ModalHeader>

            <ModalBody>
              <Form 
              className='grid grid-cols-2'
              onSubmit={handleSubmit}
              > 
                <FormGroup className='col-span-2'>
                  <Label>
                    {i18n.t("PRODUCT_REFERENCE_NUMBER")}
                  </Label>
                  <Input
                    className='w-full border-gray-200'
                    type="text"
                    name="referenceNo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.referenceNo}
                  />
                  <ErrorMessage name="referenceNo" component="div" className="formik-error-message-color"/>
                </FormGroup>
                
              </Form>
            </ModalBody>
            <ModalFooter>
              <EditCancelButton onClick={toggle}/>
              <EditSaveButton isLoading={isSubmitting} onClick={handleSubmit}/>
            </ModalFooter>
          </>
      )}
      </Formik>
    </Modal>
  );
};
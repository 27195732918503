import React, { useContext, useEffect, useState } from 'react'
import {
    Button,
    Col,
    Row,
    Label,
    Card,
    CardTitle,
    CardHeader,
    CardImg,
    CardBody,
    Container,
} from 'reactstrap'
import { withTranslation } from "react-i18next";
import i18n from 'i18next';
import ProductImageModal from './ProductImageModal';
import ProductImageGrid from './ProductImageGrid'
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";
import EditButtonIcon from 'components/Common/EditButtonIcon';

const ProductImages = props => {
    const { images,isLoading }= props;
    const [show, setShow] = useState(false)
    const [isOpenLightbox, setIsOpenLightbox] = useState(false)
    const [imageIndex, setImageIndex] = useState(0)
    function addToggle() {
        setShow(!show)
    }
    function onClickItem(index) {
        setImageIndex(index)
        setIsOpenLightbox(true)
    }
    function onEditClick() {
        setShow(true)
    }
    
    return (
        <>
            <Card>
                <CardBody>
                    <CardTitle className="mb-2">
                        {i18n.t("IMAGES")}
                        <Button
                            //disabled={isLoading}
                            disabled
                            type="button"
                            className="btn btn-light btn-sm woms-edit-btn"
                            onClick={onEditClick}
                        >
                            <i className="bx bx-pencil" />
                        </Button>
                    </CardTitle>
                    {images && images.length > 0 && <ProductImageGrid data={images} onClick={onClickItem}/>}
                </CardBody>
                
                
            </Card>
            {images && images.length > 0 && isOpenLightbox ? 
            <Lightbox 
                mainSrc={images[imageIndex].url}
                nextSrc={images[(imageIndex + 1) % images.length].url}
                prevSrc={images[(imageIndex + images.length - 1) % images.length].url}
                onCloseRequest={() => {setIsOpenLightbox(false)}}
                // add caption or title. order of image
                imagePadding={100}
                onMovePrevRequest={() => {setImageIndex((imageIndex + images.length -1) % images.length)}}
                onMoveNextRequest={() => {setImageIndex((imageIndex + 1) % images.length)}}
             /> : null}
             <ProductImageModal
                show={show}
                setShow={addToggle}
                />
        </>
    )
}

export default withTranslation()(ProductImages)
import React, { useEffect, useState, useMemo, useContext } from "react";
import Moment from 'react-moment';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import ControlledTableContainerV8 from "components/Common/ControlledTableContainerV8";
import { formatToCurrency, getOrderStatusCssClasses } from "./OrderUIHelper";
import { WomsServiceContext } from "services/womsService";
import cf from "../../services/codeFormatter";
import i18n from 'i18next';
import { withTranslation } from "react-i18next";
import AdvancedTableContainer from "components/Common/AdvancedTableContainer";
import EntityFilterModal from "../../components/Common/EntityFilterModal";
import { ENTITY_FILTER_TYPE } from "services/womsEnum";
import { useNavigate } from "react-router";

// Column
const Id = (cell) => {
  return cell.getValue() ? "#SO" + cell.getValue() : '';
};
const OrderNo = (cell) => {
    return cell.getValue() ? cell.getValue() : '';
};
const Store = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};
const CustomerName = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};
const Amount = (cell) => { // shopify를 보니 discount나 tax는 합산 안함
  return <div className="text-end" style={{marginRight:"15px"}}>{cell.getValue() ? cell.getValue() : ''}</div>
};
const Quantity = (cell) => { 
  return cell.getValue() ? (cell.getValue() > 1 ? cell.getValue() + " items" : cell.getValue() + " item") : '';
};
const Status = (cell) => {
  return cell.getValue() ? <span className={getOrderStatusCssClasses(cell.getValue())}>{cf.format("OrderStatus",cell.getValue())}</span> : '';
};
const OrderedOn = (cell) => {
    return cell.getValue() ? <Moment format='ll'>{cell.getValue()}</Moment>: '';
};

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}) {
  const ref = React.useRef();

  React.useEffect(() => {
    if (indeterminate) {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
    />
  )
}

const OrderList = (props) => {

  //meta title
  document.title = i18n.t("SALES_ORDERS");
  const { womsSvc } = useContext(WomsServiceContext);
  let navigate = useNavigate();

  const handleEntityClick = arg => {

  };

  const onEditClick = (entityData) => {
    navigate(`/salesOrder/${entityData.id}`);
  };

  // Customber Column
  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <div className="text-center"><IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
              onClick: ()=> {
                table.toggleAllRowsSelected();
              }
            }}
          /></div>
        ),
        cell: ({ row }) => (
          <div className="text-center">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                //disabled: !row.getCanSelect(),
                //indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
                onClick: () => {
                  row.toggleSelected();
                },
              }}
            />
          </div>
        ),
      },
      {
        header: i18n.t('ID'),
        accessorKey: 'id',
        filterable: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Id {...cellProps} />;
        }
      },
      {
        header: i18n.t('ORDER_NO'),
        accessorKey: 'orderNo',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <OrderNo {...cellProps} />;
          ;
        }
      },
      {
        header: i18n.t('STORE'),
        accessorKey: 'store.name',
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <Store {...cellProps} />;
        }
      },
      {
        header: i18n.t('CUSTOMER'),
        accessorFn: data => { return (data.shippingAddress.firstName || "" )+ (data.shippingAddress.lastName ? " " + data.shippingAddress.lastName : "") },
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <CustomerName {...cellProps} />;
        }
      },
      {
        id:'amount', // id needed if there is accessorFn and header is not text
        header: <div className="text-end" style={{marginRight:"15px"}}>{i18n.t('AMOUNT')}</div>,
        accessorFn: data => { return formatToCurrency(data.totalAmount,data.currency); },
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <Amount {...cellProps} />;
        }
      },
      {
        header: i18n.t('QUANTITY'),
        accessorKey: 'totalItems',
        //accessorFn: data => { return data.orderLineItems.reduce((acc, val) => {
        //  return acc + val.quantity; }, 0); },
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <Quantity {...cellProps} />;
        }
      },
      {
        header: i18n.t('STATUS'),
        accessorKey: 'status',
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <Status {...cellProps}/>;
        }
      },
      {
        header: i18n.t('ORDERED_ON'),
        accessorKey: 'orderedOn',
        enableSorting: true,
        cell: (cellProps) => {
          return <OrderedOn {...cellProps} />;
        }
      },
      {
        header: i18n.t('ACTION'),
        enableSorting: false,
        cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onEditClick(entityData);
                  }
                  }
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                  {i18n.t("EDIT")}
                  <UncontrolledTooltip placement="top" target="edittooltip">
                  {i18n.t("EDIT")}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onDeleteClick(entityData);
                  }}>
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                  {i18n.t("DELETE")}
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                  {i18n.t("DELETE")}
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }
      },
    ],
    [i18n.language]
  );
  const selectedPopup = (selectedRows) => {
      return <>
      <Button className="btn btn-sm btn-light" onClick={()=> {
    }}>{i18n.t("ORDER_MARK_AS_FULFILLED")}</Button>&nbsp;&nbsp;
    <Button className="btn btn-sm btn-light">{i18n.t("ORDER_CREATE_SHIPPING_LABEL")}</Button>&nbsp;&nbsp;
    <Button className="btn btn-sm btn-light">{i18n.t("ORDER_CANCEL_ORDERS")}</Button>
      </>
  };

  //delete 
  const [deleteModal, setDeleteModal] = useState(false);
  const [entityData, setEntityData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [rerender, setRerender] = useState(0);
  const [storeId,setStoreId] = useState('');
  const [storeList, setStoreList] = useState([]);

  const onDeleteClick = (entityData) => {
    setEntityData(entityData);
    setDeleteModal(true);
  };

  const handleDeleteEntity = () => {
    if (entityData && entityData.id) {
      // intzSvc.deleteConfiguration(configuration.id);
      setDeleteModal(false);
    }
  };

  const handleAddEntity = () => {
    // navigate to add integration
  };

  useEffect(() => {
    womsSvc.searchStore(100,0,'createdOn','desc').then(({data}) => {
      setStoreList(data.value);
    }).finally(() => {
    });
  }, []);
  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEntity}
        errorMessage={errorMessage}
        entityName={i18n.t("SALES_ORDER")}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs title={i18n.t("SALES_ORDER")} breadcrumbItem={i18n.t("LIST")}
          altTitle={i18n.t("ORDER_LIST") + " : "} button={
            <select
            className="form-select form-select-sm mt-1"
            value={storeId}
            onChange={(e) => {
              setStoreId(e.target.value);
              setRerender(rerender +  1);
            }}
          >
            <option key={''} value={''}>
            {i18n.t("ORDER_ALL_STORES")}
              </option>
            {storeList.map(store => (
              <option key={store.id} value={store.id}>
                {store.name}
              </option>
            ))}
          </select>
          } />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="pt-2">
                  <AdvancedTableContainer
                    columns={columns}
                    odataFetch={womsSvc.searchOrder}
                    odataExport={womsSvc.exportOrder}
                    odataLineExport={womsSvc.exportOrderLine}
                    entityNameForExport={"Order"}
                    fetchCondition={storeId ? "storeId eq "+ storeId : ""}
                    initialSortBy={"id"}
                    isGlobalFilter={true}
                    filterColumns={["orderNo","referenceNo","shippingAddress/firstName","shippingAddress/lastName"]}
                    //addButtonLabel={i18n.t("ADD_NEW_ORDER")}
                    addButtonLabel={''}
                    isExportButton={true}
                    isLineItemExportCheckbox={true}
                    onAddClick={handleAddEntity}
                    customPageSize={20}
                    isCustomFilter={true}
                    canAddFilter={true}
                    entityType={ENTITY_FILTER_TYPE.SALES_ORDER}
                    className="table-advanced"
                    rerender={rerender}
                    selectedPopup={selectedPopup} 
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </>
  );
};

export default withTranslation()(OrderList);
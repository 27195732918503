import React, { useEffect, useState, useMemo, useContext } from "react"

import i18n from "i18next"
import { withTranslation } from "react-i18next"
import OrganizationModal from "./OrganizationModal"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { WomsServiceContext } from "services/womsService"
import { refreshWomsUser } from "store/actions"
import { useNavigate } from "react-router-dom"

const NewRegistration = props => {
  //meta title
  document.title = "New Registration"
  const { womsSvc } = useContext(WomsServiceContext);
  const [modal, setModal] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const womsUser = useSelector(state => state.womsUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (womsUser && womsUser.organizations?.length > 0) {
        navigate("/dashboard");
    }

  },[womsUser]);


  const handleModalClose = isUpdated => {
    
    setModal(false);
    if (isUpdated) {
        womsSvc.getCurrentUser().then(({ data }) => {
            dispatch(refreshWomsUser(data));
        });

    }
  }


  return (
    <>
      <div className="page-content">
        <Container fluid>
        </Container>
      </div>
      <OrganizationModal
        show={modal}
        isEdit={false}
        onClose={handleModalClose}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default withTranslation()(NewRegistration)

import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
    Button,
    Col,
    Row,
    Label,
    Card,
    CardTitle,
    CardHeader,
    CardImg,
    CardBody,
    Container,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledTooltip,
} from 'reactstrap'
import { withTranslation } from "react-i18next";
import TableContainerV8 from 'components/Common/TableContainerV8';
import Moment from 'react-moment';
import i18n from 'i18next';
import { WomsServiceContext } from 'services/womsService';
import { generateTrackingUrl, getFulfillmentStatusCssClasses } from 'pages/Fulfillment/FulfillmentUIHelper';
import cf from "../../../services/codeFormatter";
import FulfillmentDetailModal from 'pages/Fulfillment/FulfillmentDetailModal';

const Id = (cell) => {
    return cell.getValue() ? "#SL" + cell.getValue() : '';
  };
  const Status = (cell) => {
    return cell.getValue() ? <span className={getFulfillmentStatusCssClasses(cell.getValue())}>{cf.format("FulfillmentStatus",cell.getValue())}</span> : '';
  };
  const Location = (cell) => {
    return cell.getValue() ? cell.getValue() : '';
  };
  const TrackingNo = (cell) => {
    return cell.getValue() ? <a href={generateTrackingUrl(cell.getValue())} target="_blank" rel="noopener noreferrer">{cell.getValue()}</a> : '';
  };
  const CreatedOn = (cell) => {
    return cell.getValue() ? <Moment format='ll'>{cell.getValue()}</Moment> : '';
  };

const OrderFulfillments = (props) => {
    const columns = useMemo(
        () => [
          {
            header: i18n.t("ID"),
            accessorKey: 'id',
            filterable: false,
            enableSorting: true,
            cell: (cellProps) => {
              return <Id {...cellProps} />;
            }
          },
          {
            header: i18n.t("STATUS"),
            accessorKey: 'status',
            enableSorting: true,
            filterable: false,
            cell: (cellProps) => {
              return <Status {...cellProps} />;
              ;
            }
          },
          {
            header: i18n.t('LOCATION'),
            accessorKey: 'location.name',
            enableSorting: true,
            filterable: false,
            cell: (cellProps) => {
              return <Location {...cellProps} />;
              ;
            }
          },
          {
            header: i18n.t("TRACKING_NO"),
            accessorKey: 'trackingNo',
            enableSorting: true,
            filterable: false,
            cell: (cellProps) => {
              return <TrackingNo {...cellProps} />;
            }
          },
          {
            header: i18n.t('CREATED_ON'),
            accessorKey: 'createdOn',
            enableSorting: true,
            cell: (cellProps) => {
              return <CreatedOn {...cellProps} />;
            }
          },
          ,
      {
        header: i18n.t('ACTION'),
        enableSorting: false,
        cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    setFulfillmentId(entityData.id);
                    setShowDetailModal(true)
                  }
                  }
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                  {i18n.t("DETAIL")}
                  <UncontrolledTooltip placement="top" target="edittooltip">
                  {i18n.t("DETAIL")}
                  </UncontrolledTooltip>
                </DropdownItem>

              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }
      },
        ],
        [i18n.language]
      );

      const {orderId} = props;
      const { womsSvc } = useContext(WomsServiceContext);
      const [isLoading, setIsLoading] = useState(false);
      const [fulfillments, setFulfillments] = useState([]);
      const [fulfillmentId, setFulfillmentId] = useState('');
      const [showDetailModal, setShowDetailModal] = useState(false);
      

      useEffect(() => {

        if (orderId) {
            setIsLoading(true);
            womsSvc.getOrderFulfillments(orderId).then(({ data }) => {
                setFulfillments(data.value);
                setIsLoading(false);
            });
        }

    }, [orderId]);

    const handleModalClose = () => {
      setShowDetailModal(false);
    }
    return (
        <>{fulfillments.length > 0 && <Card>
            <CardBody>
                <CardTitle>{i18n.t("FULFILLMENTS")}</CardTitle>
                <TableContainerV8
                    columns={columns}
                    data={fulfillments}
                    initialSortBy={"createdOn"}
                    customPageSize={5}
                    className="custom-header-css"
                    hidePageSizeOption={true}
                />
            </CardBody>
        </Card>}
        <FulfillmentDetailModal
        id={fulfillmentId}
        show={showDetailModal}
        onClose={handleModalClose}
        />
          </>
    )
}

export default withTranslation()(OrderFulfillments)
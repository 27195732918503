import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Form
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup";
import i18n from 'i18next';
import { WomsServiceContext } from "services/womsService";
import BrandModal from "pages/Brand/BrandModal";

const BrandSelectedModal = props => {
  const { show, infos, onClose } = props;
  const { womsSvc } = useContext(WomsServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [brandModal, setBrandModal] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      brandId: '',
    },
    validationSchema: Yup.object({
      // brandId: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
    }),

    onSubmit: (values) => {
      womsSvc.updateStoreListingBrand(infos.map(el => el.id), values.brandId ? values.brandId : 0).then(({ data }) => {
        formik.resetForm();
        onClose(true);
      });
    },
  });

  useEffect(() => {
    if (show) {
      refreshBrandList();
      formik.resetForm();
    }
  }, [show]);

  const refreshBrandList = () => {
    setIsLoading(true);
    womsSvc.getBrands().then(({ data }) => {
      setBrandList(data);
      setIsLoading(false);
    });
  }

  const handleBrandModalClose = (isUpdated) => {
    if (isUpdated) {
      refreshBrandList();
    }
    setBrandModal(false);
  }

  return (
    <>
      <Modal isOpen={show} size="md">
        <ModalHeader tag="h4">
          {i18n.t("ASSIGN_BRAND_SELECTED")}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label className="form-label">{i18n.t('BRAND')}</Label>
                  <div className="d-flex flex-row">
                    <Input
                      name="brandId"
                      type="select"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.brandId || ""}
                      invalid={formik.touched.brandId && formik.errors.brandId ? true : false}
                      disabled={isLoading}
                    >
                      <option key={''} value={''}>
                        {i18n.t('NOT_SPECIFIED')}
                      </option>
                      {brandList.map(el => (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      ))}
                      
                    </Input>
                    &nbsp;
                    <Button outline onClick={() => { setBrandModal(true) }}>
                      {formik.values.brandId ? <i className="mdi mdi-pencil" /> : <i className="mdi mdi-plus" />}
                    </Button>
                  </div>
                  {formik.touched.brandId && formik.errors.brandId ? (
                    <FormFeedback type="invalid">{formik.errors.brandId}</FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col md="12">
                <div className="text-end mt-2">
                  <button type="button" className="btn btn-secondary" onClick={() => onClose()} disabled={formik.isSubmitting}>
                    {i18n.t("CANCEL")}
                  </button>
                  &nbsp;&nbsp;
                  <button type="submit" className="btn btn-success" disabled={formik.isSubmitting}>
                    {i18n.t("SUBMIT")}
                  </button>
                </div>
              </Col>
            </Row>
          </ModalFooter>
        </Form>
      </Modal>
      <BrandModal
        show={brandModal}
        id={formik.values.brandId}
        onClose={handleBrandModalClose}
      />
    </>
  )
}

export default BrandSelectedModal;
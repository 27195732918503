import React, { useEffect, useState, useMemo, useContext } from "react"
import Moment from "react-moment"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import AdvancedTableContainer from "components/Common/AdvancedTableContainer"
import { getIsActiveCssClasses } from "./LocationUIHelper"
import { WomsServiceContext } from "services/womsService"
import cf from "../../services/codeFormatter"
import i18n from "i18next"
import { withTranslation } from "react-i18next"
import LocationModal from "./LocationModal"
import { ENTITY_FILTER_TYPE } from "services/womsEnum"
import { useSelector } from "react-redux"
import { getCountryName } from "helpers/woms_utils"

// Column
const Id = cell => {
  return cell.getValue() ? "#LO" + cell.getValue() : ""
}
const Text = cell => {
  return cell.getValue() ? cell.getValue() : ""
}
const IsActive = cell => {
  return (
    <span className={getIsActiveCssClasses(cell.getValue())}>
      {cell.getValue() ? i18n.t("ACTIVE") : i18n.t("INACTIVE")}
    </span>
  )
}
const CreatedOn = cell => {
  return cell.getValue() ? <Moment format="ll">{cell.getValue()}</Moment> : ""
}
const Country = cell => {
  return cell.getValue() ? getCountryName(cell.getValue(),i18n.language) : ""
}
const LocationList = props => {
  //meta title
  document.title = i18n.t("LOCATIONS")
  const { womsSvc } = useContext(WomsServiceContext)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const handleEntityClick = arg => {}

  // Customber Column
  const columns = useMemo(
    () => [
      {
        header: i18n.t("ID"),
        accessorKey: "id",
        filterable: false,
        enableSorting: true,
        cell: cellProps => {
          return <Id {...cellProps} />
        },
      },
      {
        header: i18n.t("NAME"),
        accessorKey: "name",
        enableSorting: true,
        filterable: false,
        cell: cellProps => {
          return <Text {...cellProps} />
        },
      },
      {
        header: i18n.t("CITY"),
        accessorKey: "city",
        enableSorting: true,
        filterable: false,
        cell: cellProps => {
          return <Text {...cellProps} />
        },
      },
      {
        header: i18n.t("STATE"),
        accessorKey: "province",
        enableSorting: false,
        filterable: false,
        cell: cellProps => {
          return <Text {...cellProps} />
        },
      },
      {
        header: i18n.t("COUNTRY"),
        accessorKey: "country",
        enableSorting: false,
        filterable: false,
        cell: cellProps => {
          return <Country {...cellProps} />
        },
      },
      {
        header: i18n.t("PHONE_NO"),
        accessorKey: "phone",
        enableSorting: false,
        filterable: false,
        cell: cellProps => {
          return <Text {...cellProps} />
        },
      },
      {
        header: i18n.t("IS_ACTIVE"),
        accessorKey: "isActive",
        enableSorting: false,
        filterable: false,
        cell: cellProps => {
          return <IsActive {...cellProps} />
        },
      },
      {
        header: i18n.t("CREATED_ON"),
        accessorKey: "createdOn",
        enableSorting: true,
        cell: cellProps => {
          return <CreatedOn {...cellProps} />
        },
      },
      {
        header: i18n.t("ACTION"),
        enableSorting: false,
        cell: cellProps => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original
                    onEditClick(entityData)
                  }}
                >
                  <i
                    className="mdi mdi-pencil font-size-16 text-success me-1"
                    id="edittooltip"
                  ></i>
                  {i18n.t("EDIT")}
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    {i18n.t("EDIT")}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original
                    onDeleteClick(entityData)
                  }}
                >
                  <i
                    className="mdi mdi-trash-can font-size-16 text-danger me-1"
                    id="deletetooltip"
                  ></i>
                  {i18n.t("DELETE")}
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {i18n.t("DELETE")}
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        },
      },
    ],
    [i18n.language]
  )

  const selectedPopup = () => {}

  const [deleteModal, setDeleteModal] = useState(false)
  const [entityData, setEntityData] = useState({})
  const [errorMessage, setErrorMessage] = useState()
  const [rerender, setRerender] = useState(0)
  const onDeleteClick = entityData => {
    setEntityData(entityData)
    setDeleteModal(true)
  }

  const handleDeleteEntity = () => {
    if (entityData && entityData.id) {
      womsSvc.deleteLocation(entityData.id).then(({ data }) => {
        setRerender(rerender + 1);
        setDeleteModal(false)
      })
      
    }
  }

  const handleAddEntity = () => {
    // navigate to add integration
  }
  const onEditClick = entityData => {
    setEntityData(entityData)
    setErrorMessage("")
    setIsEdit(true)
    setModal(true)
  }

  const onAddClick = () => {
    setEntityData({})
    setErrorMessage("")
    setIsEdit(false)
    setModal(true)
  }

  const handleModalClose = isUpdated => {
    if (isUpdated) {
      setRerender(rerender + 1)
    }
    setModal(false)
  }

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEntity}
        errorMessage={errorMessage}
        onCloseClick={() => setDeleteModal(false)}
        entityName={i18n.t("LOCATION")}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs altTitle={i18n.t("LOCATION_LIST")} title={i18n.t("LOCATIONS")} breadcrumbItem={i18n.t("LIST")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AdvancedTableContainer
                    columns={columns}
                    odataFetch={womsSvc.searchLocation}
                    initialSortBy={"createdOn"}
                    isGlobalFilter={true}
                    addButtonLabel={i18n.t("ADD_NEW_LOCATION")}
                    onAddClick={onAddClick}
                    customPageSize={10}
                    className="table-advanced"
                    rerender={rerender}
                    filterColumns={["name", "city", "firstName", "lastName"]}
                    selectedPopup={selectedPopup}
                    isCustomFilter={true}
                    entityType={ENTITY_FILTER_TYPE.LOCATION}
                    fetchCondition={"isActive eq true"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <LocationModal
        show={modal}
        isEdit={isEdit}
        id={entityData?.id}
        onClose={handleModalClose}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default withTranslation()(LocationList)

import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
    Button,
    Col,
    Row,
    Label,
    Card,
    CardTitle,
    CardHeader,
    CardImg,
    CardBody,
    Container,
} from 'reactstrap'
import { withTranslation } from "react-i18next";
import TableContainerV8 from 'components/Common/TableContainerV8';
import Moment from 'react-moment';
import i18n from 'i18next';
import { WomsServiceContext } from 'services/womsService';

const Id = (cell) => {
    return cell.getValue() ? "#SL" + cell.getValue() : '';
  };
  const StoreSKU = (cell) => {
    return cell.getValue() ? cell.getValue() : '';
  };
  const Name = (cell) => {
    return cell.getValue() ? cell.getValue() : '';
  };
  const Product = (cell) => {
    return cell.getValue() ? <a href={"/product/"+cell.getValue().id}>{cell.getValue().sku}</a> : <span className="badge badge-soft-danger">New</span>;
  };
  const Store = (cell) => {
    return cell.getValue() ? cell.getValue() : '';
  };
  const CreatedOn = (cell) => {
    return cell.getValue() ? <Moment format='ll'>{cell.getValue()}</Moment> : '';
  };

const ProductStoreListings = (props) => {
    const columns = useMemo(
        () => [
          {
            header: i18n.t("ID"),
            accessorKey: 'id',
            filterable: false,
            enableSorting: true,
            cell: (cellProps) => {
              return <Id {...cellProps} />;
            }
          },
          {
            header: i18n.t("STORE_SKU"),
            accessorKey: 'storeSku',
            enableSorting: true,
            filterable: false,
            cell: (cellProps) => {
              return <StoreSKU {...cellProps} />;
              ;
            }
          },
          {
            header: i18n.t('NAME'),
            accessorKey: 'name',
            enableSorting: true,
            filterable: false,
            cell: (cellProps) => {
              return <Name {...cellProps} />;
              ;
            }
          },
          {
            header: i18n.t("STORE"),
            accessorKey: 'store.name',
            enableSorting: true,
            filterable: false,
            cell: (cellProps) => {
              return <Store {...cellProps} />;
            }
          },
          {
            header: i18n.t('CREATED_ON'),
            accessorKey: 'createdOn',
            enableSorting: true,
            cell: (cellProps) => {
              return <CreatedOn {...cellProps} />;
            }
          },
        ],
        [i18n.language]
      );

      const {productId} = props;
      const { womsSvc } = useContext(WomsServiceContext);
      const [isLoading, setIsLoading] = useState(false);
      const [storeListings, setStoreListings] = useState({});

      useEffect(() => {

        if (productId) {
            setIsLoading(true);
            womsSvc.getProductStoreListings(productId).then(({ data }) => {
                setStoreListings(data.value);
                setIsLoading(false);
            });
        }

    }, [productId]);
    return (
        <Card>
            <CardBody>
                <CardTitle>{i18n.t("STORE_LISTINGS")}</CardTitle>
                <TableContainerV8
                    columns={columns}
                    data={storeListings}
                    initialSortBy={"createdOn"}
                    isGlobalFilter={true}
                    customPageSize={10}
                    className="custom-header-css"
                />
            </CardBody>
        </Card>
    )
}

export default withTranslation()(ProductStoreListings)
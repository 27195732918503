import React, { useContext, useEffect, useState } from "react";

import { Row, Col, Card, CardBody, CardTitle, Input, FormFeedback, Label, InputGroup, Form, Spinner } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "components/Common/ChartsDynamicColor";
import { WomsServiceContext } from "services/womsService";
import { useDispatch, useSelector } from "react-redux";
import { updateOrderStatusWidget } from "store/woms-dashboard/order-status/actions";
import { REPORT_PERIOD_TYPE, REPORT_STATUS, REPORT_TYPE, ORDER_STATUS, REPORT_SKU_TYPE } from "services/womsEnum";
import cf from "../../../services/codeFormatter";
import i18n from 'i18next';
import { useFormik } from "formik";
import Flatpickr from 'react-flatpickr';
import * as Yup from "yup";
import { updateSalesByStoreWidget } from "store/report/sales-by-store/actions";
import { downloadCSV } from "../ReportUIHelper";
import moment from "moment";
import { updateSkuTrendWidget } from "store/report/sku-trend-by-store/actions";
import { formatToCurrency } from "../../Order/OrderUIHelper";
import ControlledTypehead from "components/Common/ControlledTypehead";

const SkuTrendWidget = ({storeList}) => {
  const organization = useSelector(state => state.organization);
  const user = useSelector(state => state.womsUser);
    const dataColors ='["--bs-primary", "--bs-warning", "--bs-success", "--bs-danger",  "--bs-secondary"]' ;
    const stackedColumnChartColors = getChartColorsArray(dataColors);
    const options = {
      chart: {
        stacked: false,
        toolbar: {
          show: 1
        },
        zoom: {
          enabled: !0
        }
      },
      stroke: {
        curve: 'smooth', // Sets the curve of the line chart to be smooth
        width: 2        // Sets the width of the line to 1
      },
      plotOptions: {
        bar: {
          horizontal: !1,
          columnWidth: "15%"
          // endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: !1
      },
      xaxis: {
        show: true,
        categories: [
        ],
        labels: {
          show: true
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return formatToCurrency(value,user.organizations?.find(o => o.id == organization.id).baseCurrency);
          }
        }
      },
      colors: stackedColumnChartColors,
      legend: {
        position: "bottom"
      },
      fill: {
        opacity: 1
      }
    }

    const { womsSvc } = useContext(WomsServiceContext)
    const [data, setData] = useState([]);
    const [category, setCategory] = useState([]);
    const [status, setStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    const dispatch = useDispatch();
    const widget = useSelector(state => state.skuTrendWidget);
    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            "periodType": REPORT_PERIOD_TYPE.LAST_MONTH,
            "fromDate": '',
            'toDate': '',
            'storeId' : '',
            'skuType' : REPORT_SKU_TYPE.TOP_3,
            'skus': []
        },
        validationSchema: Yup.object({
            periodType: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
            fromDate: Yup.date().when('periodType', {
                is: periodType => periodType == REPORT_PERIOD_TYPE.RANGE,
                then: Yup.date()
                    .required(i18n.t("VALIDATION.FROM_DATE_REQUIRED")),
                otherwise: Yup.date()
            }),
            toDate: Yup.date().when('periodType', {
                is: periodType => periodType == REPORT_PERIOD_TYPE.RANGE,
                then: Yup.date()
                    .required(i18n.t("VALIDATION.TO_DATE_REQUIRED")),
                otherwise: Yup.date()
            })
        }),

        onSubmit: (values) => {
            setIsLoading(true);
            setErrorMessage("");
            womsSvc.requestReport( REPORT_TYPE.SKU_TREND_BY_STORE,values,false).then(({data}) => {
                dispatch(updateSkuTrendWidget(data));
                setIsLoading(false);
            });
        },
    });
    useEffect(() => {
        if (widget && widget.data && widget.data.id) {
            setStatus(widget.data.status)
            if (widget.data.payload) {
              var data = JSON.parse(widget.data.payload)
              // mod data here
              
                setData(data.skuTrends);
                setCategory(data.category ? formik.values.periodType == REPORT_PERIOD_TYPE.LAST_DAY ? data.category.map(entry => moment(entry).startOf('hour').fromNow()) : data.category.map(entry => moment(entry).format('ll')):[]);
            } else {
              setData([]);
            }
      }
    }, [widget]);
    return (
        <>
            <Card>
                <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            formik.handleSubmit();
                            return false;
                        }}
                    >
                <CardBody>
                    <CardTitle className="mb-4">{i18n.t("REPORT.SALES_TREND_BY_SKU")}{(status && status == REPORT_STATUS.FAILED) && <i className="bx bx-error-circle text-danger font-size-13"></i>}
                    {((status && status == REPORT_STATUS.PROCESSING) || isLoading) && <Spinner size='sm' animation="border" className="ms-2"/>}
                    {errorMessage && <span className="text-danger ms-2"><small>{errorMessage}</small></span>}
                        <button
                            type="submit"
                            className="btn btn-success save-integration"
                            style={{ "float": "right" }}
                            disabled={((status && status == REPORT_STATUS.PROCESSING) || isLoading)}
                        >
                            {i18n.t("REPORT.GENERATE")}
                        </button>

                    </CardTitle>
                    
                        <Row>
                            <Col md="4">
                                <div className="mb-3">
                                    <Row>
                                        <Col>
                                            <Label className="form-label"> {i18n.t("REPORT.PERIOD_TYPE")}</Label>

                                        </Col>
                                    </Row>
                                    <Input
                                        name="periodType"
                                        type="select"
                                        className="form-select"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={
                                            formik.values.periodType || ""
                                        }
                                        invalid={
                                            formik.touched.periodType && formik.errors.periodType ? true : false
                                        }
                                    >
                                        {
                                            Object.values(REPORT_PERIOD_TYPE).map(val =>
                                                <option value={val} key={val}>{cf.format("ReportPeriodType", val)}</option>
                                            )
                                        }
                                    </Input>
                                    {formik.touched.periodType && formik.errors.periodType ? (
                                        <FormFeedback type="invalid">{formik.errors.periodType}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="mb-3">
                                    <Row>
                                        <Col>
                                            <Label className="form-label"> {i18n.t("STORE")}</Label>

                                        </Col>
                                    </Row>
                                    <Input
                                        name="storeId"
                                        type="select"
                                        className="form-select"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={
                                            formik.values.storeId || ""
                                        }
                                        invalid={
                                            formik.touched.storeId && formik.errors.storeId ? true : false
                                        }
                                    >
                                        <option key={''} value={''}>
                                            {i18n.t("ALL_STORES")}
                                          </option>
                                        {storeList.map(store => (
                                          <option key={store.id} value={store.id}>
                                            {store.name}
                                          </option>))}
                                    </Input>
                                    {formik.touched.storeId && formik.errors.storeId ? (
                                        <FormFeedback type="invalid">{formik.errors.storeId}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="mb-3">
                                    <Row>
                                        <Col>
                                            <Label className="form-label"> {i18n.t("REPORT.SKU_TYPE")}</Label>

                                        </Col>
                                    </Row>
                                    <Input
                                        name="skuType"
                                        type="select"
                                        className="form-select"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={
                                            formik.values.skuType || ""
                                        }
                                        invalid={
                                            formik.touched.skuType && formik.errors.skuType ? true : false
                                        }
                                    >
                                        {
                                            Object.values(REPORT_SKU_TYPE).map(val =>
                                                <option value={val} key={val}>{cf.format("ReportSkuType", val)}</option>
                                            )
                                        }
                                    </Input>
                                    {formik.touched.skuType && formik.errors.skuType ? (
                                        <FormFeedback type="invalid">{formik.errors.skuType}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                        {formik.values.periodType == REPORT_PERIOD_TYPE.RANGE &&   <Col md="4">
                                <div className="mb-3">
                                    <Label>{i18n.t("REPORT.DATE_RANGE")}</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            className="form-control d-block"
                                            placeholder={i18n.t("REPORT.SELECT_DATE_RANGE")}
                                            options={{
                                                mode: "range",
                                                dateFormat: "Y-m-d"
                                            }}
                                            value={[formik.values.fromDate, formik.values.toDate]}
                                            onChange={([from, to]) => {
                                                formik.setFieldValue('fromDate', from);
                                                formik.setFieldValue('toDate', to);
                                            }}
                                            onBlur={() => {
                                                formik.setFieldTouched('fromDate');
                                                formik.setFieldTouched('toDate');
                                            }}
                                        />
                                    </InputGroup>
                                    {formik.errors.fromDate ? (
                                        <div className="text-danger">
                                            <small>{formik.errors.fromDate}</small>
                                        </div>
                                    ) : null}
                                    {formik.errors.toDate ? (
                                        <div className="text-danger">
                                            <small>{formik.errors.toDate}</small>
                                        </div>
                                    ) : null}

                                </div>

                            </Col>}

                            {formik.values.skuType == REPORT_SKU_TYPE.SELECT && <Col>
            <Label className="form-label">{i18n.t("SKU")}</Label> <small>{i18n.t("REPORT.SKU_DESC")}</small>
            <ControlledTypehead
                odataFetch = {womsSvc.searchStoreListing}
                multiple = {true}
                //value={formik.values.sku} not working if multiple
                filterColumn = {["storeSku","name"]}
                labelKey= {(option) => `${option["storeSku"]} : ${option["name"]}`}
                valueSelected = {(selected) => {
                    formik.setFieldValue('skus',selected.map(el=> el.storeSku));
                }}
                invalid={
                    formik.errors.skus ? true : false
                }
                />
                {formik.touched.skus && formik.errors.skus ? (
                  <FormFeedback type="invalid">
                    {formik.errors.skus}
                  </FormFeedback>
                ) : null}
            </Col>}

                        </Row>
                        <Row>
                            <Col md="12">
                            <ReactApexChart
                                options={{
                                ...options,
                                xaxis: {
                                    show: true,
                                    categories: category,
                                }}}
                                series={[...data]}
                                type="line"
                                height="359"
                                className="apex-charts"
                            />
                            </Col>
                        </Row>
                    
                </CardBody>
                </Form>
            </Card>
        </>
    );
};

export default SkuTrendWidget;

import React, { useEffect, useState, useMemo, useContext } from "react"
import Moment from 'react-moment'
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    UncontrolledTooltip,
    Input,
    FormFeedback,
    Label,
    Form,
    Dropdown,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import ControlledTableContainerV8 from "components/Common/ControlledTableContainerV8";
import { formatToCurrency, getStatusCssClasses } from "./ProductUIHelper";
import ProductAddModal from "./ProductAddModal"
import { WomsServiceContext } from "services/womsService";
import cf from "../../services/codeFormatter";
import i18n from 'i18next';
import { withTranslation } from "react-i18next";
import AdvancedTableContainer from "components/Common/AdvancedTableContainer";
import { FLOWBASEANNOTATION_TYPES } from "@babel/types";
import { useNavigate } from "react-router-dom";

//Column
const Id = (cell) => {
    return cell.getValue() ? "#PR" + cell.getValue() : ''
}
const Thumbnail = (cell) => {
    return cell.getValue() ? <img src={cell.getValue()} /> : <i className="bx bx-image-alt woms-product-placeholder" />;
}
const Name = (cell) => {
    return cell.getValue() ? cell.getValue() : ''
}
const SKU = (cell) => {
    return cell.getValue() ? cell.getValue() : ''
}
const Status = (cell) => {
    return cell.getValue() ? <span className={getStatusCssClasses(cell.getValue())}>{cell.getValue()}</span> : ''
}
const CreatedOn = (cell) => {
    return cell.getValue() ? <Moment format='ll'>{cell.getValue()}</Moment> : ''
}


const ProductList = (props) => {
    document.title = i18n.t("PRODUCTS")
    const  { womsSvc } = useContext(WomsServiceContext);
    const navigate = useNavigate();

    const handleEntityClick = arg => {
    }

    const columns = useMemo(
        () => [
            {
                header: i18n.t("ID"),
                accessorKey: 'id',
                filterable: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return <Id {...cellProps} />
                }
            },
            {
                header: i18n.t('IMAGE'),
                accessorKey: 'thumbnailUrl',
                filterable: false,
                enableSorting: false,
                cell: (cellProps) => {
                    return <Thumbnail {...cellProps} />
                }
            },
            {
                header: i18n.t('NAME'),
                accessorKey: 'name',
                filterable: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return <Name {...cellProps} />
                }
            },
            {
                header: i18n.t('SKU'),// translate?
                accessorKey: 'sku',
                filterable: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return <SKU {...cellProps} />
                }
            },
            {
                header: i18n.t('STATUS'),
                accessorKey: 'status',
                enableSorting: true,
                filterable: false,
                cell: (cellProps) => {
                    return <Status {...cellProps}/>
                }

            },
            {
                header:i18n.t('CREATED_ON'),
                accessorKey: 'createdOn',
                enableSorting: true,
                cell: (cellProps) => {
                    return <CreatedOn {...cellProps}/>
                }
            },
            {
                header: i18n.t('ACTION'),
                enableSorting: false,
                cell: (cellProps) => {
                    return (
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" href="#" className="card-drop">
                                <i className="mdi mdi-dots-horizontal font-size-18"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem 
                                    href="#"
                                    onClick={() => {
                                        const entityData = cellProps.row.original
                                        onEditClick(entityData)
                                    }}
                                >
                                    <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                                    {i18n.t("DETAILS")}
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                    {i18n.t("DETAILS")}
                                    </UncontrolledTooltip>
                                </DropdownItem>

                            </DropdownMenu>
                        </UncontrolledDropdown>
                    )
                }
            }

        ],
        [i18n.language]
    )

    const [entityData, setEntityData] = useState({})
    const [errorMessage, setErrorMessage] = useState({})
    const [rerender, setRerender] = useState(0)
    const [productList, setProductList] = useState([])
    const [modal, setModal] = useState(false)
    
    const handleAddEntity = () => {
        // navigate to add integration
        //setModal(true)
        navigate("/products")
    }
    
    const handleModalClose = (isUpdated) => {
        if(isUpdated) {
            setRerender(rerender + 1)
        }
    }

    function addToggle(){
        setModal(!modal)
    }
    
    const onEditClick = (entityData) => {
        navigate(`/product/${entityData.id}`);
      };
    

    useEffect(() => {
        
    },[])

    const selectedPopup = () => {
        return <></>
    }
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs altTitle={i18n.t("PRODUCT_LIST")} title={i18n.t("PRODUCTS")} breadcrumbItem={i18n.t("LIST")} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <AdvancedTableContainer
                                        columns={columns}
                                        odataFetch={womsSvc.searchProduct}
                                        fetchCondition={"bundleType eq 'NONE'"}
                                        initialSortBy={"createdOn"}
                                        isGlobalFilter={true}
                                        filterColumns={["name","sku"]}
                                        //addButtonLabel={i18n.t("ADD_NEW_PRODUCT")}
                                        addButtonLabel={''}
                                        onAddClick={handleAddEntity}
                                        customPageSize={10}
                                        className="table-advanced"
                                        rerender={rerender}
                                        selectedPopup={selectedPopup}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ProductAddModal
                show={modal}
                setShow={addToggle}
                isEdit={false}
                id={0}
                onClose={handleModalClose}
            />
        </>
    )
}

export default withTranslation()(ProductList)



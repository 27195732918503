import { Button, Spinner } from "reactstrap";
import i18n from 'i18next';

export const EditSaveButton = ({ onClick, isLoading }) => {
  return (
    <Button color="success" onClick={onClick}>
      {
        isLoading ? 
        <Spinner size={"sm"} />
        :
        <span>{i18n.t("SAVE")}</span>
      }
    </Button>
  );
};
  
  
 


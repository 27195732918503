
import { useState, useEffect, useContext } from 'react';
import { Input, Form, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Modal } from 'reactstrap';
import { WomsServiceContext } from 'services/womsService';
import { ErrorMessage, Formik } from 'formik';
import { CURRENCY_CODE, EDIT_MODAL_TYPE } from "services/womsEnum";
import i18n from 'i18next';
import { FormikDatePickerField } from "components/Common/FormikDatePicker";
import { EditCancelButton } from 'components/Common/EditModal/EditCancelButton';
import { EditSaveButton } from 'components/Common/EditModal/EditSaveButton';
import * as Yup from 'yup';

export const DetailsModal = ({
  order,
  setOrder,
  activeModal,
  setActiveModal
}) => {

  const { womsSvc } = useContext(WomsServiceContext);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggle = () => {
    setModalIsOpen(!modalIsOpen);
    setActiveModal(``);
  };

  useEffect(()=> {
    if(activeModal && activeModal === EDIT_MODAL_TYPE.ORDER_DETAILS) {
      setModalIsOpen(true); 
    }
  },[activeModal]);

  const detailsSchema = Yup.object().shape({
    orderNo: Yup.string()
      .max(1024, 'Too Long! Please keep order number under 1024 characters.')
      .required('Required'),
    orderedOn: Yup.date()
      .required('Required')
      .typeError('Invalid date format. Please enter a valid date.')
  });

  return (
    <Modal isOpen={modalIsOpen} toggle={toggle}>

      <Formik
      initialValues={{
        orderNo: order.orderNo,
        currency: order.currency,
        store: order.store,
        orderedOn: order.orderedOn 
        ? new Date(order.orderedOn).toISOString() 
        : new Date().toISOString(),
      }}
      validationSchema={detailsSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        let updateOrder = {
          ...order,
          orderNo: values.orderNo,
          orderedOn: values.orderedOn,
          currency: values.currency,
        };

        try {
          await womsSvc.updateOrder(updateOrder);
          const { data } = await womsSvc.getOrder(order.id)
          setOrder(data);
          setSubmitting(false);
          toggle();

        } catch (error) {
          setSubmitting(false);
          console.error(error);
        }
      }}
      >
        {({ 
          values, 
          handleChange, 
          handleBlur, 
          errors, 
          touched, 
          handleSubmit,
          isSubmitting 
        }) => (
          <>
            <ModalHeader className='text-base font-semibold !bg-gray-50 !rounded-t-3xl ' tag={"h4"} toggle={toggle}>
              {i18n.t("EDIT_DETAILS")}
            </ModalHeader>
            <ModalBody>
              <Form
              className='grid grid-cols-2 gap-4'
              onSubmit={handleSubmit}
              > 
                <FormGroup>
                  <Label>
                    {i18n.t("ORDER_NO")}
                  </Label>
                  <Input
                    className='col-span-1 border-gray-200'
                    type="text"
                    name="orderNo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.orderNo}
                  />
                  <ErrorMessage name="orderNo" component="div" className="formik-error-message-color"/>
                </FormGroup>

                {/* STORENAME-STORENAME-STORENAME-STORENAME-STORENAME-STORENAME-STORENAME-STORENAME-STORENAME-STORENAME */}
                <FormGroup>
                  <Label>
                    {i18n.t("STORE_NAME")}
                  </Label>
                  <Input
                    className='col-span-1 border-none border-white disabled:bg-white'
                    readOnly
                    
                    name="store"
                    // onChange={(e)=> handleStoreNameChange(e)}
                    onBlur={handleBlur}
                    value={values.store.name}
                  />
                </FormGroup>

                {/* CURRENCY-CURRENCY-CURRENCY-CURRENCY-CURRENCY-CURRENCY-CURRENCY-CURRENCY-CURRENCY-CURRENCY */}
                <FormGroup >
                  <Label>
                    {i18n.t("ORDER_CURRENCY")}
                  </Label>
                  <Input
                    className='col-span-1 border-white disabled:bg-white'
                    //type="select"
                    name="currency"
                    readOnly
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.currency}
                  >
                    {/*
                      Object.values(CURRENCY_CODE).map(value => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))
                      */}
                  </Input>
                  {errors.currency && touched.currency && errors.currency}

                </FormGroup>
                
                {/* ORDERED-ON-DATE-ORDER-ON-DATE-ORDERED-ON-DATE-ORDER-ON-DATE-ORDERED-ON-DATE-ORDER-ON-DATE-ORDERED-ON-DATE-ORDER-ON-DATE-ORDERED*/}
                <FormGroup >
                  <Label>
                    {i18n.t("ORDERED_ON")}
                  </Label>
                  <FormikDatePickerField name="orderedOn" className={`border-gray-200`}/>
                  <ErrorMessage name="orderedOn" component="div" className="formik-error-message-color"/>

                </FormGroup>
                
              </Form>
              
            </ModalBody>
            <ModalFooter>
              <EditCancelButton onClick={toggle}/>
              <EditSaveButton isLoading={isSubmitting} onClick={handleSubmit}/>
            </ModalFooter>
          </>
        )}
      </Formik> 
    </Modal>
  );
};
import { useEffect, useState } from "react";

/* set search text when typing ends. */
export function useDebounceSearch(delay = 350) {
    const [search, setSearch] = useState('');
    const [query, setQuery] = useState('');

    useEffect(() => {
        const delayFunction = setTimeout(() => setSearch(query), delay)
        return () => clearTimeout(delayFunction)
    },[query, delay])
    
    return [search, setQuery];
}

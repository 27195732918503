import { nanoid } from "nanoid";
import { Button } from "reactstrap";
import { CLIENT_SYNC_TYPE, ORDER_FEE_TYPE, PRICE_MOD_TYPE } from "services/womsEnum";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import i18n from 'i18next';

export const AddPriceMod = ({
  order, 
  setOrder,
  priceModType,
  orderFeeType
}) => {

  const defaultCorePriceModItem = {
    id: nanoid(),
    amount: 0.00,
    name: "",
    orderId: order.orderId,
  };

  const handleAddFeeItem = (type) => {
    const feeItem = {
      ...defaultCorePriceModItem, 
      type,
      syncType: CLIENT_SYNC_TYPE.CREATED
    };

    const feeLineItems = [...order.feeLineItems, feeItem];
    setOrder(prev => ({...prev, feeLineItems}));
  };

  const handleAddDiscountItem = () => {
    const discountItem = {
      ...defaultCorePriceModItem, 
      syncType: CLIENT_SYNC_TYPE.CREATED
    };

    const discountLineItems = [...order.discountLineItems, discountItem];
    setOrder(prev => ({...prev, discountLineItems}));
  };
  
  const handleAddTaxItem = () => {
    const taxItem = {
      ...defaultCorePriceModItem, 
      isInclusive: 0,
      syncType: CLIENT_SYNC_TYPE.CREATED
    };

    const taxLineItems = [...order.taxLineItems, taxItem];
    setOrder(prev => ({...prev, taxLineItems}));
  };

  const buttonProps = {
    [PRICE_MOD_TYPE.FEE + ORDER_FEE_TYPE.SHIPPING] : {
      name: i18n.t("SHIPPING"),
      onClick: ()=> handleAddFeeItem(ORDER_FEE_TYPE.SHIPPING)
    },
    [PRICE_MOD_TYPE.FEE + ORDER_FEE_TYPE.OTHER] : {
      name: i18n.t("FEE"),
      onClick: ()=> handleAddFeeItem(ORDER_FEE_TYPE.OTHER)
    },
    [PRICE_MOD_TYPE.DISCOUNT] : {
      name: i18n.t("DISCOUNT"),
      onClick: handleAddDiscountItem
    },
    [PRICE_MOD_TYPE.TAX] : {
      name: i18n.t("TAX"),
      onClick: handleAddTaxItem
    },
  };
  return (
    <div className="d-flex gap-2 justify-content-end p-2">
      <Button
      type="button"  
      className="price-mod-add-button w-auto bg-black d-flex align-items-center gap-2 text-white hover:bg-black/70 rounded-1 "
      onClick={()=> 
        orderFeeType 
        ? buttonProps[priceModType + orderFeeType].onClick()
        : buttonProps[priceModType].onClick()
      }
      >
        <AddCircleOutlineIcon fontSize="inherit"/> {i18n.t("ADD")}
      </Button>
    </div>
  );
};
import React, { useEffect, useState, useMemo, useContext } from "react";
import Moment from 'react-moment';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import AdvancedTableContainer from "components/Common/AdvancedTableContainer";
import { getIsActiveCssClasses } from "./StoreUIHelper";
import { WomsServiceContext } from "services/womsService";
import cf from "../../services/codeFormatter";
import i18n from 'i18next';
import { withTranslation } from "react-i18next";
import StoreModal from "./StoreModal";
import EntityFilterModal from "../../components/Common/EntityFilterModal";
import { ENTITY_FILTER_TYPE, SUBSCRIPTION_PLAN_TYPE } from "services/womsEnum";
import { useSelector } from "react-redux";
import SubscriptionPlanModal from "pages/SubscriptionPlan/SubscriptionPlanModal";
// Column
const Id = (cell) => {
  return cell.getValue() ? "#ST" + cell.getValue() : '';
};
const Name = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};
const URL = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};
const Type = (cell) => {
  return cell.getValue() ? cf.format("StoreType", cell.getValue()) : '';
};
const SyncType = (cell) => {
  return cell.getValue() ? cf.format("StoreSyncType", cell.getValue()) : '';
};
const IsActive = (cell) => {
  return <span className={getIsActiveCssClasses(cell.getValue())}>{cf.format("IsActive", cell.getValue())}</span>;
};

const CreatedOn = (cell) => {
  return cell.getValue() ? <Moment format='ll'>{cell.getValue()}</Moment> : '';
};

const StoreList = (props) => {

  //meta title
  document.title = "Stores";
  const { womsSvc } = useContext(WomsServiceContext);
  const organization = useSelector(state => state.organization);

  const handleEntityClick = arg => {

  };

  // Customber Column
  const columns = useMemo(
    () => [

      {
        header: i18n.t('ID'),
        accessorKey: 'id',
        filterable: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Id {...cellProps} />;
        }
      },
      {
        header: i18n.t('NAME'),
        accessorKey: 'name',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <Name {...cellProps} />;
          ;
        }
      },
      {
        header: 'URL',
        accessorKey: 'url',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <URL {...cellProps} />;
        }
      },
      {
        header: i18n.t('STORE_TYPE'),
        accessorKey: 'type',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <Type {...cellProps} />;
        }
      },
      {
        header: i18n.t('SYNC_TYPE'),
        accessorKey: 'syncType',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <SyncType {...cellProps} />;
        }
      },
      {
        header: i18n.t('IS_ACTIVE'),
        accessorKey: 'isActive',
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <IsActive {...cellProps} />;
        }
      },
      {
        header: i18n.t('CREATED_ON'),
        accessorKey: 'createdOn',
        enableSorting: true,
        cell: (cellProps) => {
          return <CreatedOn {...cellProps} />;
        }
      },
      {
        header: i18n.t('ACTION'),
        enableSorting: false,
        cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onEditClick(entityData);
                  }
                  }
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                  {i18n.t('EDIT')}
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    {i18n.t('EDIT')}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onDeleteClick(entityData);
                  }}
                  disabled>
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                  {i18n.t('DELETE')}
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {i18n.t('DELETE')}
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }
      },
    ],
    [i18n.language]
  );
  //delete 
  const [deleteModal, setDeleteModal] = useState(false);
  const [entityData, setEntityData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [rerender, setRerender] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [planBlock, setPlanBlock] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const handlePlanModalClose = ()=> {
    setShowPlanModal(false);
  }
  const onDeleteClick = (entityData) => {
    setEntityData(entityData);
    setDeleteModal(true);
  };

  const onEditClick = (entityData) => {
    setEntityData(entityData);
    setErrorMessage("");
    setIsEdit(true);
    setModal(true);
  };

  const handleDeleteEntity = () => {
    if (entityData && entityData.id) {
      womsSvc.deleteStore(entityData.id).then((data) => {
        setRerender(rerender + 1);
        setDeleteModal(false);
      });
    }
  };

  const handleAddEntity = () => {
    if (planBlock) {
      setShowPlanModal(true);
    } else {
      setEntityData(null);
      setErrorMessage("");
      setIsEdit(false);
      setModal(true);
    }

  };

  const handleModalClose = (isUpdated) => {
    if (isUpdated) {
      setRerender(rerender + 1);
    }
    setModal(false);

  };

  useEffect(() => {
    if (organization && organization.id) {
    womsSvc.searchStore(0,0,"id","desc","isActive eq true").then(({data}) => {
      if (organization.subscriptionPlan) {
        switch (organization.subscriptionPlan) {
          case SUBSCRIPTION_PLAN_TYPE.FREE:
            if (data['@odata.count'] > 5) { setPlanBlock(true); } else { setPlanBlock(false); }
            break;
          case SUBSCRIPTION_PLAN_TYPE.BASIC:
            if (data['@odata.count'] > 10) { setPlanBlock(true); } else { setPlanBlock(false); }
            break;
          case SUBSCRIPTION_PLAN_TYPE.PRO:
            if (data['@odata.count'] > 50) { setPlanBlock(true); } else { setPlanBlock(false); }
            break;
          case SUBSCRIPTION_PLAN_TYPE.ENTERPRISE:
            setPlanBlock(false);
            break;
          default:
            break;
        }
      } else {
        // before free subscription
        setPlanBlock(true);
      }
    })
  }
  }, [organization,rerender]);

  const selectedPopup = () => {
    return <></>
  }

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEntity}
        errorMessage={errorMessage}
        entityName={i18n.t("STORE")}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs altTitle={i18n.t("STORE_LIST")} title={i18n.t("STORES")} breadcrumbItem={i18n.t("LIST")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AdvancedTableContainer
                    columns={columns}
                    odataFetch={womsSvc.searchStore}
                    //odataExport={womsSvc.exportOrder}
                    //fetchCondition={storeId ? "storeId eq "+ storeId : ""}
                    initialSortBy={"createdOn"}
                    isGlobalFilter={true}
                    filterColumns={["name","url"]}
                    addButtonLabel={i18n.t("ADD_NEW_STORE")}
                    //isExportButton={true}
                    onAddClick={handleAddEntity}
                    customPageSize={20}
                    isCustomFilter={true}
                    entityType={ENTITY_FILTER_TYPE.STORE}
                    className="table-advanced"
                    rerender={rerender}
                    selectedPopup={selectedPopup}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <StoreModal
        show={modal}
        isEdit={isEdit}
        id={entityData?.id}
        onClose={handleModalClose}
        errorMessage={errorMessage}
      />
      <SubscriptionPlanModal
        show={showPlanModal}
        onClose={handlePlanModalClose}
      />
    </>
  );
};

export default withTranslation()(StoreList);
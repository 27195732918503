import { PRODUCT_STATUS } from "services/womsEnum";

export function getStatusCssClasses(status) {
    var type;
    switch(status) {
      case PRODUCT_STATUS.ACTIVE:
        type="success"
        break;
      case PRODUCT_STATUS.INACTIVE:
        type="warning"
        break;
      case PRODUCT_STATUS.DELETED:
        type="danger"
        break;
    default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }
import { REFRESH_WOMSUSER } from "./actionTypes";

const initialState = {
  
};

const womsUser = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_WOMSUSER:
      //state = { ...state, ...action.payload};
      state = { ...action.payload};
      break;
    
    default:
      break;
  }
  return state;
};

export default womsUser;

import React, { useEffect, useRef, useState } from 'react';
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import i18n from "i18next"
import { TYPEAHEAD_VALUE_RETURN_TYPE } from 'services/womsEnum';

function ControlledTypehead({
  odataFetch,
  labelKey,
  filterColumn,  // can be a string or an array of strings
  valueSelected,
  disabled,
  value,
  rerender,
  invalid,
  multiple,
  additionalFilter = "", // ex. and StoreId eq 123 and Id ne 444
  valueType = "", // enum ID or OBJECT
  className = "",
  renderMenu = null // manually create renderMenu for customization
}) {
  
  const [isLoading, setIsLoading] = useState(false);
  const [entityOptions, setEntityOptions] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState();
  const [open, setOpen] = useState(false);
  const typeHeadRef = useRef(null);
  const [
    entitySearchCount,
    setEntitySearchCount,
  ] = React.useState(0);
  const [
    entitySearchString,
    setEntitySearchString,
  ] = React.useState("");
  const PER_PAGE = 5;
// Function to compose filter query based on filterColumn type
  const composeFilterQuery = (search) => {
    if (Array.isArray(filterColumn)) {
      return "(" + filterColumn.map(col => `contains(${col}, '${search}')`).join(' or ') + ")";
    } else {
      return `contains(${filterColumn}, '${search}')`;
    }
  };

  useEffect(() => {
    if (typeHeadRef.current) typeHeadRef.current.setState({text: ''});
    if (value) {
      if (value != selectedValue) {
        setIsLoading(true);
        var filter = 'id eq ' + value;
        odataFetch(PER_PAGE, 0, filterColumn, "asc", filter).then(({ data }) => {
          if (data.value.length > 0) {
            // setSelectedValue(data.value[0].id);

            if(!valueType || valueType === TYPEAHEAD_VALUE_RETURN_TYPE.ID) {
              setSelectedValue(data.value[0].id);
            } else if (valueType === TYPEAHEAD_VALUE_RETURN_TYPE.OBJECT) {
              setSelectedValue(data.value[0]);
            }
            
            if (typeHeadRef.current) typeHeadRef.current.setState({text: labelKey(data.value[0])});
          }
          setIsLoading(false);
        });
      }
    } 
  }, [value]);

  function handleSearch(search) {
    if (!search || search.length > 2) {
      setIsLoading(true);
      setEntitySearchString(search);
      // var filter = search ? composeFilterQuery(search) : "";
      var filter = search 
      ? composeFilterQuery(search) + additionalFilter 
      : additionalFilter.split("and")[1];

      odataFetch(PER_PAGE, 0, filterColumn, "asc", filter).then(({ data }) => {
        setEntityOptions(data.value);
        setEntitySearchCount(data["@odata.count"]);
        setIsLoading(false);
      });
    }

  }  


  //contains(name, 'ska') or contains(sku, 'ska')
  // function handleSearch(search) {
  //   if (!search || search.length > 2) {
  //     setIsLoading(true);
  //     setEntitySearchString(search);
  //     var filter = search ? composeFilterQuery(search) : "";

  //     console.log(filter)
  //     odataFetch(PER_PAGE, 0, filterColumn, "asc", filter).then(({ data }) => {
  //       setEntityOptions(data.value);
  //       setEntitySearchCount(data["@odata.count"]);
  //       setIsLoading(false);
  //     });
  //   }

  // }
  function filterCallback(option, props) {
    return true;
  }
  function handlePagination(e, shownResults) {
    if (
      entityOptions.length > shownResults ||
      entityOptions.length === entitySearchCount
    ) {
      return;
    }
    setIsLoading(true);
    let filter = entitySearchString ? composeFilterQuery(entitySearchString) + additionalFilter : additionalFilter.split("and")[1];
    
    odataFetch(PER_PAGE, entityOptions.length, filterColumn, "asc", filter).then(({ data }) => {

      setEntityOptions(entityOptions.concat(data.value));
      setEntitySearchCount(data["@odata.count"]);
      setIsLoading(false);
    });
  }

  // function handlePagination(e, shownResults) {
    // if (
      // entityOptions.length > shownResults ||
      // entityOptions.length === entitySearchCount
    // ) {
      // return;
    // }
    // setIsLoading(true);
    // var filter = entitySearchString ? `contains(${filterColumn}, '${entitySearchString}')` : "";
    // odataFetch(PER_PAGE, entityOptions.length, filterColumn, "asc", filter).then(({ data }) => {
// 
      // setEntityOptions(entityOptions.concat(data.value));
      // setEntitySearchCount(data["@odata.count"]);
      // setIsLoading(false);
    // });
  // }
  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }
  const onInputChange = React.useCallback(debounce(value => {
    handleSearch(value);
  }, 200), []); // needed usecallback to prevent rerendering    

  const handleBlur = () => {
    setOpen(false);
  };

  const handleFocus = () => {
    setOpen(true);
  };

  const handleChange = (selected) => {
    var value;
    if (multiple) {
      value = selected; // when multiple, edit mode not supported, returned row for properties than id needed.
    } else {
      // value = selected.length > 0 ? selected[0].id : '';
      if (selected.length > 0 && (!valueType || valueType === TYPEAHEAD_VALUE_RETURN_TYPE.ID)) {
        value = selected[0].id;
      } else if (selected.length > 0 && valueType === TYPEAHEAD_VALUE_RETURN_TYPE.OBJECT) {
        value = selected[0];
      } else {
        value = '';
      }
    }
    valueSelected(value);

    if (rerender) rerender(value);
  };

  const asyncTypeaheadProps = {
    className: `${className} w-[]`,
    id: "typehead",
    onChange: handleChange, // Use handleChange
    onInputChange: (text, event) => {
      if (!text) onInputChange("");
    },
    paginate: true,
    maxResults: PER_PAGE - 1,
    isLoading: isLoading,
    multiple: multiple,
    allowNew: false,
    options: entityOptions,
    labelKey: labelKey,
    minLength: 0,
    // open: true,
    // defaultOpen: true,
    open: open,
    defaultOpen: false,
    onSearch: handleSearch,
    filterBy: filterCallback,
    onPaginate: handlePagination,
    promptText: i18n.t("SEARCH_DEFAULT_OPTION"),
    placeholder: i18n.t("SEARCH_DEFAULT_OPTION"),
    disabled: disabled,
    paginationText: i18n.t("SEARCH_SHOW_MORE"),
    useCache: false,
    ref: typeHeadRef,
    isInvalid: invalid,
    onFocus: handleFocus,
    onBlur: handleBlur,
    inputProps: {
      className: "typehead-input",
      style: {
        border: "solid 1px rgb(243, 244, 246)",
        borderRadius: "0.5rem",
      },
    }

  };

  if (renderMenu) {
    asyncTypeaheadProps.renderMenu = renderMenu;
  }

  return (<AsyncTypeahead {...asyncTypeaheadProps} />);
  
}

export default ControlledTypehead;
import React, { useEffect, useState, useMemo, useContext } from "react"
import Moment from "react-moment"
import {
  Card,
  Button,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import SimpleNoticeModal from "components/Common/SimpleNoticeModal"
import RemoveFromOrganizationModal from "./RemoveFromOrganizationModal"
import AdvancedTableContainer from "components/Common/AdvancedTableContainer"
import { getStatusCssClasses } from "./UserUIHelper"
import { WomsServiceContext } from "services/womsService"
import cf from "../../services/codeFormatter"
import i18n from "i18next"
import { withTranslation } from "react-i18next"
import UserInviteModal from "./UserInviteModal"
import UserModal from "./UserModal"
import { useSelector, useDispatch } from "react-redux"
import { ENTITY_FILTER_TYPE, SUBSCRIPTION_PLAN_TYPE, USER_ROLE } from "services/womsEnum"
import { getRoles, hasRole } from "helpers/woms_utils"
import SubscriptionPlanModal from "pages/SubscriptionPlan/SubscriptionPlanModal"

// Column
const Id = cell => {
  return cell.getValue() ? "#UR" + cell.getValue() : ""
}
const Email = cell => {
  return cell.getValue() ? cell.getValue() : ""
}
const FirstName = cell => {
  return cell.getValue() ? cell.getValue() : ""
}
const LastName = cell => {
  return cell.getValue() ? cell.getValue() : ""
}
const Status = cell => {
  return cell.getValue() ? (
    <span className={getStatusCssClasses(cell.getValue())}>
      {cf.format("UserStatus", i18n.t(cell.getValue()))}
    </span>
  ) : (
    ""
  )
}
const CreatedOn = cell => {
  return cell.getValue() ? <Moment format="ll">{cell.getValue()}</Moment> : ""
}
const UserList = props => {
  //meta title
  document.title = i18n.t("USERS")
  const { womsSvc } = useContext(WomsServiceContext)
  const [userEditModal, setUserEditModal] = useState(false)
  const [userInviteModal, setUserInviteModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const womsUser = useSelector(state => state.womsUser);
  const currentOrg = useSelector(state => state.organization);
  const roles = getRoles(womsUser, currentOrg);

  const columns = useMemo(
    () => [
      {
        header: i18n.t("ID"),
        accessorKey: "id",
        filterable: false,
        enableSorting: true,
        cell: cellProps => {
          return <Id {...cellProps} />
        },
      },
      {
        header: i18n.t("EMAIL"),
        accessorKey: "email",
        enableSorting: true,
        filterable: false,
        cell: cellProps => {
          return <Email {...cellProps} />
        },
      },
      {
        header: i18n.t("FIRST_NAME"),
        accessorKey: "firstName",
        enableSorting: true,
        filterable: false,
        cell: cellProps => {
          return <FirstName {...cellProps} />
        },
      },
      {
        header: i18n.t("LAST_NAME"),
        accessorKey: "lastName",
        enableSorting: true,
        filterable: false,
        cell: cellProps => {
          return <LastName {...cellProps} />
        },
      },
      {
        header: i18n.t("STATUS"),
        accessorKey: "status",
        enableSorting: false,
        filterable: false,
        cell: cellProps => {
          return <Status {...cellProps} />
        },
      },
      {
        header: i18n.t("CREATED_ON"),
        accessorKey: "createdOn",
        enableSorting: true,
        cell: cellProps => {
          return <CreatedOn {...cellProps} />
        },
      },
      {
        header: i18n.t("ACTION"),
        enableSorting: false,
        cell: cellProps => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {(hasRole(womsUser,currentOrg,USER_ROLE.ORG_ADMIN) || hasRole(womsUser,currentOrg,USER_ROLE.SYS_ADMIN) ) && (
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      const entityData = cellProps.row.original
                      onEditClick(entityData,hasRole(womsUser,currentOrg.id,USER_ROLE.SYS_ADMIN) ? true : false)
                    }}
                  >
                    <i
                      className="mdi mdi-pencil font-size-16 text-success me-1"
                      id="edittooltip"
                    ></i>
                    {hasRole(womsUser,currentOrg,USER_ROLE.SYS_ADMIN) ? i18n.t("EDIT") : i18n.t("DETAILS")}
                    <UncontrolledTooltip placement="top" target="edittooltip">
                    {hasRole(womsUser,currentOrg,USER_ROLE.SYS_ADMIN) ? i18n.t("EDIT") : i18n.t("EDIT")}
                    </UncontrolledTooltip>
                  </DropdownItem>
                )}

                <DropdownItem
                  href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original
                    onRemoveClick(entityData)
                  }}
                >
                  <i
                    className="mdi mdi-trash-can font-size-16 text-danger me-1"
                    id="deletetooltip"
                  ></i>
                  {i18n.t("REMOVE_FROM_ORGANIZATION")}
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {i18n.t("REMOVE_FROM_ORGANIZATION")}
                  </UncontrolledTooltip>
                </DropdownItem>

              </DropdownMenu>
            </UncontrolledDropdown>
          )
        },
      },
    ],
    [i18n.language, roles]
  )

  const [removeModal, setRemoveModal] = useState(false);
  const [inviteResultModal, setInviteResultModal] = useState(false);
  const [entityData, setEntityData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [inviteResultMessage, setInviteResultMessage] = useState();
  const [rerender, setRerender] = useState(0);
  const [planBlock, setPlanBlock] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const handlePlanModalClose = ()=> {
    setShowPlanModal(false);
  }

  const onRemoveClick = entityData => {
    setErrorMessage("");
    setEntityData(entityData);
    setRemoveModal(true);
  }

  const handleRemoveFromOrganization = (userId) => {
      womsSvc.removeUserFromOrganization(userId).then(({ data }) => {
        setRerender(rerender + 1);
        setRemoveModal(false);
      })
  }


  const onEditClick = (entityData, isEdit) => {
    setEntityData(entityData);
    setErrorMessage("");
    setIsEdit(isEdit);
    setUserEditModal(true);
  }

  const onAddClick = () => {
    if (planBlock) {
      setShowPlanModal(true);

    } else {

      setEntityData({});
      setErrorMessage("");
      setIsEdit(false);
      setUserInviteModal(true);
    }
  }

  const handleModalClose = isUpdated => {
    if (isUpdated) {
      setRerender(rerender + 1)
    }
    setUserEditModal(false);
  }
  const handleInviteModalClose = (isSuccess,errorMessage,isUpdated) => {
    setUserInviteModal(false);
    if(isUpdated){
      if (isSuccess) {
        setRerender(rerender + 1);
        setInviteResultMessage(i18n.t("ADD_USER_TO_ORGANIZATION_SUCCESSFUL"));
        setInviteResultModal(true);
      } else {
        setInviteResultMessage(errorMessage);
        setInviteResultModal(true);
      }
    }
    
  }

  useEffect(() => {
    if (currentOrg && currentOrg.id) {
    womsSvc.searchUser(0,0,"id","desc","status eq 'ACTIVE'").then(({data}) => {
      if (currentOrg.subscriptionPlan) {
        switch (currentOrg.subscriptionPlan) {
          case SUBSCRIPTION_PLAN_TYPE.FREE:
            if (data['@odata.count'] > 1) { setPlanBlock(true); } else { setPlanBlock(false); }
            break;
          case SUBSCRIPTION_PLAN_TYPE.BASIC:
            if (data['@odata.count'] > 5) { setPlanBlock(true); } else { setPlanBlock(false); }
            break;
          case SUBSCRIPTION_PLAN_TYPE.PRO:
            if (data['@odata.count'] > 20) { setPlanBlock(true); } else { setPlanBlock(false); }
            break;
          case SUBSCRIPTION_PLAN_TYPE.ENTERPRISE:
            setPlanBlock(false);
            break;
          default:
            break;
        }
      } else {
        // before free subscription
        setPlanBlock(true);
      }
    })
    }
  }, [currentOrg,rerender]);

  return (
    <>
      <RemoveFromOrganizationModal
        show={removeModal}
        onRemoveClick={handleRemoveFromOrganization}
        errorMessage={errorMessage}
        id={entityData.id}
        onCloseClick={() => {
          setRemoveModal(false)
          setRerender(rerender + 1)
        }}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
          altTitle={i18n.t("USER_LIST")}
            title={i18n.t("USERS")}
            breadcrumbItem={i18n.t("LIST")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AdvancedTableContainer
                    columns={columns}
                    odataFetch={womsSvc.searchUser}
                    initialSortBy={"createdOn"}
                    isGlobalFilter={true}
                    addButtonLabel={i18n.t("INVITE_NEW_USER")}
                    onAddClick={onAddClick}
                    customPageSize={10}
                    className="table-advanced"
                    rerender={rerender}
                    filterColumns={["email", "lastName", "firstName"]}
                    isCustomFilter={true}
                    entityType={ENTITY_FILTER_TYPE.USER}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <UserInviteModal
        show={userInviteModal}
        userId={entityData?.id}
        onClose={handleInviteModalClose}
      />
      <UserModal
        show={userEditModal}
        isEdit={isEdit}
        id={entityData?.id}
        onClose={handleModalClose}
        errorMessage={errorMessage}
      />
      <SimpleNoticeModal show={inviteResultModal}
      onCloseClick={() => setInviteResultModal(false)}
      message={inviteResultMessage}/>    

      <SubscriptionPlanModal
        show={showPlanModal}
        onClose={handlePlanModalClose}
      />
      </>
      
  )
}

export default withTranslation()(UserList)

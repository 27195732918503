import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { WomsServiceContext } from "services/womsService"
import ControlledTypehead from "components/Common/ControlledTypehead"
import { CountryDropdown, RegionDropdown } from "react-country-region-selector"
import i18n from "i18next"
import { withTranslation } from "react-i18next"
import Moment from "react-moment"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/bootstrap.css"
import { useSelector } from "react-redux"
import { FEEDBACK_TYPE, NOTIFICATION_SEND_TO_TYPE, NOTIFICATION_TYPE, USER_ROLE } from "services/womsEnum"
import cf from "../../services/codeFormatter"
import { hasRole } from "helpers/woms_utils"
import { data } from "autoprefixer"
const BetaFeedbackModal = props => {
  const { show, isEdit, id, onClose, errorMessage } = props
  const { womsSvc } = useContext(WomsServiceContext)
  const [isLoading, setIsLoading] = useState(false)
  const womsUser = useSelector(state => state.womsUser);
  const organization = useSelector(state => state.organization);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      type : NOTIFICATION_TYPE.USER_MESSAGE,
      feedbackType : FEEDBACK_TYPE.UNSPECIFIED,
      content : "",
      sendToType : NOTIFICATION_SEND_TO_TYPE.SPECIFIC_USER,
      sendToId : "",
      sendToOrgId : "",
      
    },
    
    validationSchema: Yup.object({
      content: Yup.string().required(i18n.t("VALIDATION.REQUIRED"))
    }),

    onSubmit: values => {
      setIsLoading(true);
      values.content = "FEEDBACK TYPE : " + cf.format('FeedbackType',values.feedbackType) + "\n " + values.content;
        womsSvc.betaFeedback(values).then(({ data }) => {
          formik.resetForm()
          onClose(true)
          setIsLoading(false)
        })
      
    },
  })

  useEffect(() => {
      formik.resetForm();
  }, [show])

  return (
    <Modal isOpen={show} size="lg">
      <ModalHeader tag="h4">
        {i18n.t("BETA_FEEDBACK")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            formik.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col md="5">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("FEEDBACK_TYPE")}</Label>
                <Input
                  name="feedbackType"
                  type="select"
                  className="form-select"
                  onChange={formik.handleChange}
                  disabled={isEdit}
                  onBlur={formik.handleBlur}
                  value={formik.values.feedbackType || ""}
                  invalid={
                    formik.touched.feedbackType && formik.errors.feedbackType
                      ? true
                      : false
                  }
                >
                  <option value={FEEDBACK_TYPE.UNSPECIFIED}>{cf.format('FeedbackType',FEEDBACK_TYPE.UNSPECIFIED)}</option>
                  <option value={FEEDBACK_TYPE.BUG_REPORT}>{cf.format('FeedbackType',FEEDBACK_TYPE.BUG_REPORT)}</option>
                  <option value={FEEDBACK_TYPE.FEATURE_SUGGESTION}>{cf.format('FeedbackType',FEEDBACK_TYPE.FEATURE_SUGGESTION)}</option>
                  <option value={FEEDBACK_TYPE.INTERNALIZATION}>{cf.format('FeedbackType',FEEDBACK_TYPE.INTERNALIZATION)}</option>
                  <option value={FEEDBACK_TYPE.GENERAL_FEEDBACK}>{cf.format('FeedbackType',FEEDBACK_TYPE.GENERAL_FEEDBACK)}</option>
                  </Input>
                {formik.touched.feedbackType && formik.errors.feedbackType ? (
                  <FormFeedback type="invalid">
                    {formik.errors.feedbackType}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            
            </Row>
            
          <Row>
            <Col md="12">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("CONTENT")}</Label>
                <Input
                  name="content"
                  type="textarea"
                  row="3"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.content ? formik.values.content : ""}
                  invalid={
                    formik.touched.content && formik.errors.content
                      ? true
                      : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.content && formik.errors.content ? (
                  <FormFeedback type="invalid">
                    {formik.errors.content}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            </Row>
            
            
          <Row>
            <Col md="12">
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => onClose()}
                  disabled={isLoading}
                >
                  {i18n.t("CANCEL")}
                </button>
                &nbsp;&nbsp;
                <button
                  type="submit"
                  className="btn btn-success save-integration"
                  disabled={isLoading}
                >
                  {i18n.t("SUBMIT")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(BetaFeedbackModal)

import React, {useMemo, useEffect, useState } from 'react'

import "../../product.scss"
const ProductImageGrid = ({data, onClick}) => {

    const [maxNumberOfImages, setMaxNumberOfImages] = useState(6)
    
    useEffect(() => {
        const handleResize = () => {
            if(window.innerWidth > 1200 || window.innerWidth < 576) {
                setMaxNumberOfImages(6)
            } else {
                setMaxNumberOfImages(10)
            }
        }
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    },[])

    const gridItems = useMemo(() => data?.filter((item,index) =>index <= (maxNumberOfImages -1)).map((item, index) => {
        return <div key={index} className="woms-grid-item rounded" onClick={() =>onClick(index)}>
            <div className='woms-grid-item-content rounded'>
            <img className='woms-grid-img rounded' src={item.url}/>
            </div>
            {index === (maxNumberOfImages - 1)  ? <div className='woms-grid-item-overlay'><span className='woms-grid-item-overlay-text'>+{data.length - maxNumberOfImages}</span></div> : <></>}
            
        </div>
    }),[data, maxNumberOfImages])
    
    return (
        <div className="woms-grid-container">
            {gridItems}
        </div>
    )
}

export default ProductImageGrid
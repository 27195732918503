import { Button, Input } from "reactstrap";
import { formatToCurrency } from "../OrderUIHelper";
import { CLIENT_SYNC_TYPE, PRICE_MOD_ROW_TYPE } from "services/womsEnum";
import CurrencyInput from "react-currency-input-field";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import i18n from "i18next";
import { isNanoId } from "helpers/is_nano_id";

export const PriceModRow = ({
  type = PRICE_MOD_ROW_TYPE.DEFAULT,
  item = {},
  priceModItem, 
  handlePriceModChange,
  header = "",
  currency,
  markPriceModAsDeleted,
  handleRowDeleted,
}) => {

  return (
    
    <div key={priceModItem.id} className={` price-mod-row 
    d-flex rounded-2 justify-content-end gap-2`} >

      {
        header && 
        <span className={`${priceModItem.syncType === CLIENT_SYNC_TYPE.DELETED ? "opacity-50" : "opacity-100"} 
        price-mod-item-label d-flex align-items-center justify-content-end`}>{header}</span>
      }

      <div className={`${priceModItem.syncType === CLIENT_SYNC_TYPE.DELETED ? "opacity-50" : "opacity-100"} border border-light rounded-2 price-mod-input-container d-flex align`}>

        <Input className="w-100 input-border-color bg-white price-mod-name-input me-2" 
        value={priceModItem.name} 
        disabled={priceModItem.syncType === CLIENT_SYNC_TYPE.DELETED}
        onChange={(e)=> handlePriceModChange(priceModItem, e.target.value, "name")}
        placeholder={i18n.t("DESCRIPTION")}
        />

        <div className={`border-light w-auto border-1 border-start  `}></div>

        <CurrencyInput 
        className='p-2 overflow-x-auto text-end w-100 input-border-color border-0 bg-white rounded-1'
        //prefix={formatToCurrency(0, currency)[0]}
        disabled={priceModItem.syncType === CLIENT_SYNC_TYPE.DELETED}
        name="price" 
        defaultValue={priceModItem.amount.toFixed(2)}
        //value={priceModItem.amount.toFixed(2)}
        intlConfig={{ locale: i18n.language, currency: currency }}
        //decimalsLimit={2}
        //decimalScale={2}
        onValueChange={(_,__, values) => {
          handlePriceModChange(priceModItem, values && values.value ? values.float : 0.00, "amount");
        }} 
        />
              
      </div>

      <div className="d-flex w-auto gap-2 ">

        <Button
          type="button"
          className="btn btn-light btn-sm woms-edit-btn border h-auto w-auto rounded m-0 d-flex justify-content-center align-items-center hover-bg-light disabled-bg-white border-light edit-order-edit-button"
          onClick={() => {
            if (isNanoId(priceModItem.id)) {
              handleRowDeleted(priceModItem.id)
            } else {
              markPriceModAsDeleted(priceModItem.id)}
            }
          }
        > 
          {
            priceModItem.syncType === CLIENT_SYNC_TYPE.DELETED 
            ? <RestoreFromTrashIcon className="text-success" fontSize="inherit" />
            : <DeleteOutlineIcon fontSize="inherit" />
          }
        </Button>
      </div>
    </div>
  );
};

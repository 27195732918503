import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button } from "reactstrap";

import { Link, useNavigate } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import i18n from 'i18next';
import withRouter from "components/Common/withRouter";
import firebase from 'firebase/compat/app';
const EmailVerification = props => {

  //meta title
  document.title = i18n.t("AUTHENTICATION.EMAIL_VERIFICATION") + " | Worldshoppe - OMS";

  const [emailSent, setEmailSent] = useState(false);

  return (
    <>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{i18n.t("AUTHENTICATION.EMAIL_VERIFICATION")}</h5>
                        
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  
                  <div className="p-2">
                  <p>{i18n.t("AUTHENTICATION.EMAIL_VERIFICATION_NOTICE")}.</p>
                  </div>
                  Already verified? Please login <Link to="/login">Here</Link>
                  <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          onClick={() => {
                            firebase.auth().currentUser.sendEmailVerification();
                            setEmailSent(true);
                          }}
                          disabled={emailSent}
                        >
                          {i18n.t("AUTHENTICATION.RESEND_EMAIL_VERIFICATION")}
                        </button>
                      </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Worldshoppe. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Codiz Inc
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(EmailVerification);

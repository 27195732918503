import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form
} from "reactstrap"
import { FieldArray, useFormik } from "formik"
import * as Yup from "yup";
import { WomsServiceContext } from "services/womsService";
import { ENTITY_FILTER_TYPE, FILTER_OPERATOR_TYPE, FILTER_VALUE_TYPE, ORDER_STATUS, ENTITY_FILTER_USER_FIELD } from "services/womsEnum";
import cf from "../../services/codeFormatter";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import i18n from 'i18next';

const EntityFilterModal = props => {
    const { show, entityFilter, onClose, entityType } = props;
    const { womsSvc } = useContext(WomsServiceContext);
    const [isLoading, setIsLoading] = useState(false);
    
    const entityFilterModel =  {
        [ENTITY_FILTER_TYPE.SALES_ORDER]: [
            {name:ENTITY_FILTER_USER_FIELD.STATUS,
            field:'status',
            availableOperators: [FILTER_OPERATOR_TYPE.EQUAL,FILTER_OPERATOR_TYPE.NOT_EQUAL],
            valueType: FILTER_VALUE_TYPE.ENUM,
            enum : [{name:"Submitted",value:ORDER_STATUS.SUBMITTED},
            {name:"Cancelled",value:ORDER_STATUS.CANCELLED},
            {name:"Ready to fulfill",value:ORDER_STATUS.READY_TO_FULFILL},
            {name:"Pending billing",value:ORDER_STATUS.PENDING_BILLING},
            {name:"Completed",value:ORDER_STATUS.COMPLETED}]
            },
            {name:ENTITY_FILTER_USER_FIELD.ORDERED_ON,
            field:'orderedOn',
            availableOperators: [FILTER_OPERATOR_TYPE.GREATER_THAN,FILTER_OPERATOR_TYPE.LESS_THAN,FILTER_OPERATOR_TYPE.BETWEEN],
            valueType: FILTER_VALUE_TYPE.DATE
            }
            ,
            {name:ENTITY_FILTER_USER_FIELD.SKU,
            field:"orderLineItems/storeListing/storeSku",
            availableOperators: [FILTER_OPERATOR_TYPE.EQUAL,FILTER_OPERATOR_TYPE.CONTAINS],
            valueType: FILTER_VALUE_TYPE.TEXT
            },
            {name:ENTITY_FILTER_USER_FIELD.ITEM_NAME,
            field:'orderLineItems/storeListing/name',
            availableOperators: [FILTER_OPERATOR_TYPE.EQUAL,FILTER_OPERATOR_TYPE.CONTAINS],
            valueType: FILTER_VALUE_TYPE.TEXT
            },
            {name:ENTITY_FILTER_USER_FIELD.FIRST_NAME,
            field:'shippingAddress/firstName',
            availableOperators: [FILTER_OPERATOR_TYPE.EQUAL,FILTER_OPERATOR_TYPE.CONTAINS],
            valueType: FILTER_VALUE_TYPE.TEXT
            },
            {name:ENTITY_FILTER_USER_FIELD.LAST_NAME,
            field:'shippingAddress/lastName',
            availableOperators: [FILTER_OPERATOR_TYPE.EQUAL,FILTER_OPERATOR_TYPE.CONTAINS],
            valueType: FILTER_VALUE_TYPE.TEXT
            },
            {name:ENTITY_FILTER_USER_FIELD.TOTAL_AMOUNT,
            field:'totalAmount',
            availableOperators: [FILTER_OPERATOR_TYPE.EQUAL,FILTER_OPERATOR_TYPE.GREATER_THAN,FILTER_OPERATOR_TYPE.LESS_THAN],
            valueType: FILTER_VALUE_TYPE.NUMBER
            },
            {name:ENTITY_FILTER_USER_FIELD.TOTAL_ITEMS,
            field:'totalItems',
            availableOperators: [FILTER_OPERATOR_TYPE.EQUAL,FILTER_OPERATOR_TYPE.GREATER_THAN,FILTER_OPERATOR_TYPE.LESS_THAN],
            valueType: FILTER_VALUE_TYPE.NUMBER
            }
            
        ]
    }

    const buildFilterValues = (filter) => {

        var formattedValue = entityFilterModel[entityType].find(el=> el.field == filter.field)?.valueType == FILTER_VALUE_TYPE.DATE ? 
        moment(filter.value1).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : 
        entityFilterModel[entityType].find(el=> el.field == filter.field)?.valueType == FILTER_VALUE_TYPE.NUMBER ? filter.value1 :
        ("'" + filter.value1 + "'");

        if (filter.field == 'orderLineItems/storeListing/storeSku') {
            if(filter.operator == FILTER_OPERATOR_TYPE.CONTAINS) {
                return "orderLineItems/any(oli: contains(oli/storeListing/storeSku, "+formattedValue+"))";
            } else {
                return "orderLineItems/any(oli: oli/storeListing/storeSku eq "+formattedValue+")";
            }
        }
        if (filter.field == 'orderLineItems/storeListing/name') {
            if(filter.operator == FILTER_OPERATOR_TYPE.CONTAINS) {
                return "orderLineItems/any(oli: contains(oli/storeListing/name, "+formattedValue+"))";
            } else {
                return "orderLineItems/any(oli: oli/storeListing/name eq "+formattedValue+")";
            }
        }

        if (filter.operator == FILTER_OPERATOR_TYPE.CONTAINS || filter.operator == FILTER_OPERATOR_TYPE.NOT_CONTAINS) {
            return (filter.operator == FILTER_OPERATOR_TYPE.NOT_CONTAINS ? ' not ' : '') + 
            ' contains(' + filter.field + "," + formattedValue + ')';
        } else if (filter.operator == FILTER_OPERATOR_TYPE.BETWEEN) {
            var endDate = moment(filter.value2).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            return filter.field + ' ge ' + formattedValue + ' and ' + filter.field + ' lt ' + endDate;
        } else {
            return filter.field + ' ' + (filter.operator == FILTER_OPERATOR_TYPE.GREATER_THAN ? 'ge ' : 
            filter.operator == FILTER_OPERATOR_TYPE.LESS_THAN ? 'lt ' : filter.operator == FILTER_OPERATOR_TYPE.EQUAL ? 'eq ' :
            filter.operator == FILTER_OPERATOR_TYPE.NOT_EQUAL ? 'ne ' : '?? ') + formattedValue;
        }


    }


    const formik  = useFormik({
    enableReinitialize: true,

    initialValues: {
        id: '',
        description:  '',
        filters:[{field:'',operator:'',value1:'',value2:''}],
        filterValue: ''
    },
    validationSchema: Yup.object({
        description: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
        //filterValue: Yup.string().required("Please enter filter value"),
        filters: Yup.array()
        .of(
          Yup.object().shape({
            field: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
            operator: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
            value1: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
            
            })
        )
    }),

    onSubmit: (values) => {
        if (entityFilter) {
            womsSvc.updateEntityFilter(values).then(() => {
                onClose(values);
                formik.resetForm();
                
            });
        } else {
            values.filterValue = values.filters.map(buildFilterValues).join(' and ');
            values.entityType = entityType;
            womsSvc.createEntityFilter(values).then(({data}) => {
                formik.resetForm();
                onClose(data);
            });
        }

    },
    });

    useEffect(() => {
        if (show) {
            if (entityFilter) {   
                formik.setValues(entityFilter);
            } else {
                formik.resetForm();
            }
        }
    }, [show]);

    return (
    <Modal isOpen={show} size='lg'>
        <ModalHeader tag="h4">
        {entityFilter ? i18n.t("FILTER.EDIT_FILTER") : i18n.t("FILTER.ADD_NEW_FILTER")}
        </ModalHeader>
        <ModalBody>
        <Form
            onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
            }}
            
        >
            <Row>
            <Col className="col-12">

                <div className="mb-3">
                <Label className="form-label">{i18n.t("FILTER.DESCRIPTION")}</Label>
                <Input
                    name="description"
                    type="text"
                    placeholder={i18n.t("FILTER.ADD_DESCRIPTION")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description || ""}
                    invalid={
                    formik.touched.description && formik.errors.description ? true : false
                    }
                    disabled={isLoading}
                />
                {formik.touched.description && formik.errors.description ? (
                    <FormFeedback type="invalid">{formik.errors.description}</FormFeedback>
                ) : null}
                </div>

            </Col>
            </Row>
            {!entityFilter && <><Row>
            <Col className="col-4">
                <Label className="form-label">{i18n.t("FILTER.FIELD")}</Label>
                </Col>
                <Col className="col-3">
                <Label className="form-label">{i18n.t("FILTER.OPERATOR")}</Label>
                </Col>
                <Col className="col-5">
                <Label className="form-label">{i18n.t("FILTER.VALUE")}</Label>
                </Col>
            </Row>
            {formik.values.filters.map((filter, index) => ( 
                    <React.Fragment key={index}>
                        <Row className="mb-3">
                        <Col md="4">
                        <Input
                            id={`filters.${index}.field`}
                            name={`filters.${index}.field`}
                            type="select"
                            onChange={(e) => {
                                formik.setFieldValue(`filters.${index}.operator`,'');
                                formik.setFieldValue(`filters.${index}.value1`,'');
                                formik.setFieldValue(`filters.${index}.value2`,'');
                                formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.filters[index].field}
                            invalid={
                                formik.touched?.filters?.[index]?.field && formik.errors?.filters?.[index]?.field ? true : false
                            }
                            >
                            <option key={''} value={''}>
                            {i18n.t("FILTER.SELECT_FIELD")}
                            </option>
                            {entityFilterModel[entityType].map(el => (
                            <option key={el.name} value={el.field}>
                                {cf.format("EntityFilterUserField",el.name)}
                            </option>))}
                            </Input>
                            {formik.touched?.filters?.[index]?.field && formik.errors?.filters?.[index]?.field ? (
                                    <FormFeedback type="invalid">{formik.errors?.filters?.[index]?.field}</FormFeedback>
                                ) : null}
                        
                        </Col>
                        <Col md="3">
                        <Input
                            id={`filters.${index}.operator`}
                            name={`filters.${index}.operator`}
                            type="select"
                            onChange={(e) => {
                                
                                formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.filters[index].operator}
                            invalid={
                                formik.touched?.filters?.[index]?.operator && formik.errors?.filters?.[index]?.operator ? true : false
                            }
                            >
                            <option key={''} value={''}>
                                {""}
                            </option>
                            {entityFilterModel[entityType].find(el=> el.field == filter.field)?.availableOperators.map(el2 => (
                            <option key={el2} value={el2}>
                                {cf.format("FilterOperatorType",el2)}
                            </option>))}
                            </Input>
                            {formik.touched?.filters?.[index]?.operator && formik.errors?.filters?.[index]?.operator ? (
                                    <FormFeedback type="invalid">{formik.errors?.filters?.[index]?.operator}</FormFeedback>
                                ) : null}
                        
                        </Col>
                        <Col>
                        {entityFilterModel[entityType].find(el=> el.field == filter.field)?.valueType == FILTER_VALUE_TYPE.TEXT ? <>
                            <Input
                            id={`filters.${index}.value1`}
                            name={`filters.${index}.value1`}
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.filters[index].value1}
                            invalid={
                                formik.touched?.filters?.[index]?.value1 && formik.errors?.filters?.[index]?.value1 ? true : false
                            }
                            />
                            {formik.touched?.filters?.[index]?.value1 && formik.errors?.filters?.[index]?.value1 ? (
                                    <FormFeedback type="invalid">{formik.errors?.filters?.[index]?.value1}</FormFeedback>
                                ) : null}
                        </> : entityFilterModel[entityType].find(el=> el.field == filter.field)?.valueType == FILTER_VALUE_TYPE.NUMBER ? <>
                            <Input
                                id={`filters.${index}.value1`}
                                name={`filters.${index}.value1`}
                                type="number"
                                step=".1"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.filters[index].value1}
                                invalid={
                                    formik.touched?.filters?.[index]?.value1 && formik.errors?.filters?.[index]?.value1 ? true : false
                                }
                                />
                                {formik.touched?.filters?.[index]?.value1 && formik.errors?.filters?.[index]?.value1 ? (
                                    <FormFeedback type="invalid">{formik.errors?.filters?.[index]?.value1}</FormFeedback>
                                ) : null}
                        </> : entityFilterModel[entityType].find(el=> el.field == filter.field)?.valueType == FILTER_VALUE_TYPE.ENUM ? <>
                        <Input
                            id={`filters.${index}.value1`}
                            name={`filters.${index}.value1`}
                            type="select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.filters[index].value1}
                            invalid={
                                formik.touched?.filters?.[index]?.value1 && formik.errors?.filters?.[index]?.value1 ? true : false
                            }
                            >
                            <option key={''} value={''}>
                                {i18n.t("SELECT_DEFAULT_OPTION")}
                            </option>
                            {entityFilterModel[entityType].find(el=> el.field == filter.field)?.enum.map(el => (
                            <option key={el.name} value={el.value}>
                                {cf.format("OrderStatus",el.value)}
                            </option>))}
                            </Input>
                            {formik.touched?.filters?.[index]?.value1 && formik.errors?.filters?.[index]?.value1 ? (
                                    <FormFeedback type="invalid">{formik.errors?.filters?.[index]?.value1}</FormFeedback>
                                ) : null}
                        
                        </> : entityFilterModel[entityType].find(el=> el.field == filter.field)?.valueType == FILTER_VALUE_TYPE.DATE && formik.values.filters[index].operator != FILTER_OPERATOR_TYPE.BETWEEN ? <>
                        <Flatpickr
                        key="date"
                            id={`filters.${index}.value1`}
                            name={`filters.${index}.value1`}
                            onChange={([val]) => {
                                formik.setFieldValue(`filters.${index}.value1`,val);
                                formik.setFieldValue(`filters.${index}.value2`,'');
                            }}
                            className="form-control d-block"
                            options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d"
                            }
                        }
                        />
                        {formik.touched?.filters?.[index]?.value1 && formik.errors?.filters?.[index]?.value1 ? (
                                    <div className="small" style={{color:"red"}}>{formik.errors?.filters?.[index]?.value1}</div>
                                ) : null}
                        </> : entityFilterModel[entityType].find(el=> el.field == filter.field)?.valueType == FILTER_VALUE_TYPE.DATE && formik.values.filters[index].operator == FILTER_OPERATOR_TYPE.BETWEEN ? <>
                        <Flatpickr
                        key="range"
                            id={`filters.${index}.value1`}
                            name={`filters.${index}.value1`}
                            onChange={(vals) => {
                                formik.setFieldValue(`filters.${index}.value1`,vals[0]);
                                formik.setFieldValue(`filters.${index}.value2`,vals.length > 1 ? vals[1] : "");
                            }}
                            className="form-control d-block"
                            options={{
                                mode: "range",
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d"
                              }}
                        />
                        {formik.touched?.filters?.[index]?.value1 && formik.errors?.filters?.[index]?.value1 ? (
                                    <div className="small" style={{color:"red"}}>{formik.errors?.filters?.[index]?.value1}</div>
                                ) : null}
                        </> : <>
                        <Input
                            id={`filters.${index}.value1`}
                            name={`filters.${index}.value1`}
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.filters[index].value1}
                            invalid={
                                formik.touched?.filters?.[index]?.value1 && formik.errors?.filters?.[index]?.value1 ? true : false
                            }
                            />
                            {formik.touched?.filters?.[index]?.value1 && formik.errors?.filters?.[index]?.value1 ? (
                                    <FormFeedback type="invalid">{formik.errors?.filters?.[index]?.value1}</FormFeedback>
                                ) : null}</>
                        }
                        </Col>
                        {formik.values.filters.length > 1 && <Col md="1">
                            <Button
                                type="button"
                                color="secondary"
                                className="btn"
                                onClick={() => {
                                    let existing = formik.values.filters;
                                    const newFilters = existing.filter((_, filterIndex) => filterIndex !== index);
                                    formik.setFieldValue('filters', newFilters);
                                }}
                            >
                                <i className="mdi mdi-trash-can-outline" />
                            </Button>
                            </Col>}
                           
                        </Row>
                    </React.Fragment>))}
                    </>}
                        <Row>
                            <Col>
                            <Button
                                type="button"
                                color="primary"
                                className="btn"
                                style={{float:'right'}}
                                onClick={() => {
                                    let existing = formik.values.filters;
                                    formik.setFieldValue('filters', [...existing, {field:'',operator:'',value1:'',value2:''}])
                                }}
                            >
                                <i className="mdi mdi-plus" />
                            </Button>
                            </Col>
                        </Row>
            
            <Row>
            <Col className="mt-3">
                <div className="text-end">
                <button type="button" className="btn btn-secondary" onClick={() => onClose()} disabled={isLoading}>{i18n.t("CANCEL")}</button>
                &nbsp;&nbsp;
                <button
                    type="submit"
                    className="btn btn-success"
                    disabled={isLoading}
                >
                    {i18n.t("SAVE")}
                </button>
                
                </div>
            </Col>
            </Row>
        </Form>
        </ModalBody>
    </Modal>
    )
}

export default EntityFilterModal
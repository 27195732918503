import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { GET_AUTHENTICATED_USER, LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";
import { refreshWomsUser } from "../womsuser/actions";

const fireBaseBackend = getFirebaseBackend();
//const REGISTER_URL = process.env.REACT_APP_WOMS_API_URL + "/WomsUser/register"; 
const ME_URL = process.env.REACT_APP_WOMS_API_URL + "/WomsUser/me"; 

const splitName = (name) => {
  const parts = name.trim().split(' ');

  // If there is more than one part, assume the first part is the first name
  // and the last part is the last name
  if (parts.length > 1) {
    return {
      firstName: parts.slice(0, -1).join(' '), // All parts except the last part
      lastName: parts[parts.length - 1] // The last part
    };
  } else {
    // If there is only one part, assume it is the first name
    return {
      firstName: parts[0],
      lastName: '' // No last name can be determined
    };
  }
}


function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      );
      
      localStorage.setItem("authUser", JSON.stringify(response));
      
      
      /*const token = yield call(() => response.getIdToken());

      const womsUserResponse = yield call(fetch, ME_URL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const remoteResponseData = yield womsUserResponse.json();
      
      yield put(refreshWomsUser(remoteResponseData));*/

      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history('/new');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      //const fireBaseBackend = getFirebaseBackend();
      const result = yield call(fireBaseBackend.socialLoginUser, type);

      if (result.user) {
        localStorage.setItem("authUser", JSON.stringify(result.user));
        // 구글이 아니면 다를수 있음
        /*if (result.additionalUserInfo.isNewUser == true) {
          const firebaseUID = result.user.uid;

      
      
          const token = yield call(() => result.user.getIdToken());
    
          const womsUserRegisterResponse = yield call(fetch, REGISTER_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              // Include the data you want to send to your remote API
              email: result.user.email,
              firstName: splitName(result.additionalUserInfo.profile.name).firstName,
              lastName: splitName(result.additionalUserInfo.profile.name).lastName,
              fireBaseUid:firebaseUID
            })
          });
    
          const remoteResponseData = yield womsUserRegisterResponse.json();
          
          yield put(refreshWomsUser(remoteResponseData))

        } else {*/ // registration is handeled by server side
          const token = yield call(() => result.user.getIdToken());
          const womsUserResponse = yield call(fetch, ME_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
    
          const remoteResponseData = yield womsUserResponse.json();
          
          yield put(refreshWomsUser(remoteResponseData));
        //}

        
        //yield put(loginSuccess(result.user));
        history("/new");
      }/* else {
        history("/login");
      }*/
      
    }
    else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    //history("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getAuthenticatedUser(){
  try{
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      //const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.getAuthenticatedUser);
      if(response){
        yield put(getAuthenticatedUser(response));
      }
    }
  } catch (error) {
    yield put(apiError(error));
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(GET_AUTHENTICATED_USER, getAuthenticatedUser);
}

export default authSaga;

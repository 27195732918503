import { Input, Form, FormGroup, Label, Spinner, ModalBody, ModalFooter, ModalHeader, Modal } from 'reactstrap';

import { ErrorMessage, Formik, useFormik } from 'formik';
import i18n from 'i18next';
import { EditCancelButton } from 'components/Common/EditModal/EditCancelButton';
import { EditSaveButton } from 'components/Common/EditModal/EditSaveButton';
import PhoneInput from 'react-phone-input-2';
import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { CLIENT_SYNC_TYPE, EDIT_MODAL_TYPE } from 'services/womsEnum';
import { WomsServiceContext } from 'services/womsService';

const addressSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(255, 'Too Long! Please keep order number under 255 characters.'),
  lastName: Yup.string()
    .max(255, 'Too Long! Please keep order number under 255 characters.'),
  company: Yup.string()
    .max(255, 'Too Long! Please keep order number under 255 characters.'),
  phone: Yup.string()
    .max(255, 'Too Long! Please keep order number under 255 characters.'),
  address1: Yup.string()
    .max(1024, 'Too Long! Please keep order number under 1024 characters.'),
  address2: Yup.string()
    .max(1024, 'Too Long! Please keep order number under 1024 characters.'),
  city: Yup.string()
    .max(255, 'Too Long! Please keep order number under 255 characters.'),
  postalCode: Yup.string()
    .max(255, 'Too Long! Please keep order number under 255 characters.'),
  province: Yup.string()
    .max(255, 'Too Long! Please keep order number under 255 characters.'),
  country: Yup.string()
    .max(255, 'Too Long! Please keep order number under 255 characters.'),
});

export const AddressModal = ({ 
  order,
  setOrder,
  activeModal,
  setActiveModal,
}) => {

  const { womsSvc } = useContext(WomsServiceContext);

  const [addressKey, setAddressKey] = useState("");

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [initialValues, setInitalValues] = useState({
    id: 0,
    firstName: "",
    lastName: "",
    company: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    postalCode: "",
    province: "",
    country: "",
  });

  const [header, setHeader] = useState("");

  const toggle = () => {
    setModalIsOpen(!modalIsOpen);
    setActiveModal(``);
    setAddressKey("");
  };

  useEffect(()=> {
    if(activeModal === EDIT_MODAL_TYPE.ORDER_SHIPPING_ADDRESS) {
      setAddressKey("shippingAddress");
      setHeader(i18n.t("EDIT_SHIPPING_ADDRESS"));
      setModalIsOpen(true); 
    } else if (activeModal === EDIT_MODAL_TYPE.ORDER_BILLING_ADDRESS) {
      setAddressKey("billingAddress");
      setHeader(i18n.t("EDIT_BILLING_ADDRESS"));
      setModalIsOpen(true); 
    }

  },[activeModal]);

  useEffect(()=> {

    // init values
    if(order && order.id && addressKey && order[addressKey]) {
      setInitalValues({
        id: order[addressKey].id ? order[addressKey].id : 0,
        firstName: order[addressKey].firstName,
        lastName: order[addressKey].lastName,
        company: order[addressKey].company,
        phone: order[addressKey].phone,
        address1: order[addressKey].address1,
        address2: order[addressKey].address2,
        city: order[addressKey].city,
        postalCode: order[addressKey].postalCode,
        province: order[addressKey].province,
        country: order[addressKey].country,
      });
    }
  },[order, addressKey]);
 
  return (
    <Modal isOpen={modalIsOpen} toggle={toggle}>

      <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={addressSchema}
      onSubmit={async (values, {setSubmitting}) => {
        setSubmitting(true);  
        
        let updateOrder = {...order, [addressKey]: values};
        updateOrder[addressKey].syncType = updateOrder[addressKey].id
        ? CLIENT_SYNC_TYPE.UPDATED 
        : CLIENT_SYNC_TYPE.CREATED
        ;

        try {

          await womsSvc.updateOrder(updateOrder);
          const { data } = await womsSvc.getOrder(order.id)
          setOrder(data);
          setSubmitting(false);
          toggle();
          
        } catch (error) {
          console.error(error);
          setSubmitting(false);
        }
      }}
      >
        {({ 
          values, 
          setValues,
          handleChange, 
          handleBlur, 
          errors, 
          touched, 
          handleSubmit,
          isSubmitting 
        }) => (
      <>
        <ModalHeader className='text-base font-semibold !bg-gray-50 !rounded-t-3xl ' tag={"h3"} toggle={toggle}>
          {header}
        </ModalHeader>

        <ModalBody>
          <Form 
          className='grid grid-cols-2 gap-2' 
          onSubmit={handleSubmit}
          > 
            <FormGroup className='col-span-2'>
              <Label>
                {i18n.t("FIRST_NAME")}
              </Label>
              <Input
                className='col-span-1 border-gray-200'
                type="text"
                name="firstName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
              />
              <ErrorMessage name="firstName" component="div" className="formik-error-message-color"/>
            </FormGroup>

            <FormGroup className='col-span-2'>
              <Label>
                {i18n.t("LAST_NAME")} </Label>
              <Input
                className='col-span-2 border-gray-200 '
                type="text"
                name="lastName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
              />
              <ErrorMessage name="lastName" component="div" className="formik-error-message-color"/>
            </FormGroup>

            <FormGroup className='col-span-2'>
              <Label>
                {i18n.t("COMPANY_NAME")}
              </Label>
              <Input
                className='col-span-2 border-gray-200 '
                type="text"
                name="company"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.company}
              />
              <ErrorMessage name="company" component="div" className="formik-error-message-color"/>
            </FormGroup>

            <FormGroup className='col-span-2'>
              <Label>
                {i18n.t("PHONE_NO")}
              </Label>
              <PhoneInput
                inputClass='!border-gray-200 '
                country={"us"}
                preferredCountries={["us", "kr", "cn",]}
                enableSearch={true}
                onChange={(phone)=> {
                  setValues(prev => ({...prev, phone}));
                  // setOrder(prev => ({...prev, [addressKey]: { ...prev[addressKey], phone}}))
                }}
                value={values.phone}
              />
              <ErrorMessage name="phone" component="div" className="formik-error-message-color"/>
            </FormGroup>

            <FormGroup className='col-span-2'>
              <Label>
                {i18n.t("ADDRESS_1")}
              </Label>
              <Input
                className='col-span-2 border-gray-200'
                type="text"
                name="address1"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address1} /> 
                <ErrorMessage name="address1" component="div" className="formik-error-message-color"/> 
            </FormGroup>

            <FormGroup className='col-span-2'>
              <Label>
                {i18n.t("ADDRESS_2")}
              </Label>
              <Input
                className='col-span-2 border-gray-200'
                type="text"
                name="address2"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address2}
              />
              <ErrorMessage name="address2" component="div" className="formik-error-message-color"/>
            </FormGroup>

            <FormGroup className='col-span-1 border-gray-200'>
              <Label> 
                {i18n.t("CITY")}
              </Label>

                <Input
                className='col-span-2 border-gray-200'
                type="text"
                name="city"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
              />
              <ErrorMessage name="city" component="div" className="formik-error-message-color"/>
            </FormGroup >

            <FormGroup className='col-span-1 border-gray-200'>
              <Label>
                {i18n.t("POSTALCODE")}
              </Label>
              <Input
                className='col-span-2 border-gray-200'
                type="text"
                name="postalCode"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.postalCode}
              />
              <ErrorMessage name="postalCode" component="div" className="formik-error-message-color"/>
            </FormGroup> 

            <FormGroup className='col-span-1'>
              <Label>
                {i18n.t("STATE")}
              </Label>
              <Input
                className='col-span-2 border-gray-200'
                type="text"
                name="province"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.province}
              />
              <ErrorMessage name="province" component="div" className="formik-error-message-color"/>
            </FormGroup> 

            <FormGroup className='col-span-1'>
              <Label>
                {i18n.t("COUNTRY")}
              </Label>
              <Input
                className='col-span-2 border-gray-200'
                type="text"
                name="country"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.country}
              />
              <ErrorMessage name="country" component="div" className="formik-error-message-color"/>
            </FormGroup> 
              
          </Form>
        </ModalBody>
        <ModalFooter>
            <EditCancelButton onClick={toggle}/>
            <EditSaveButton isLoading={isSubmitting} onClick={handleSubmit}/>
        </ModalFooter>    
      </>
        )}
        </Formik>
    </Modal>
  );
};
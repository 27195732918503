import React, { useContext, useEffect, useState } from "react"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Form,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup";
import i18n from 'i18next';
import { WomsServiceContext } from "services/womsService";

const BrandModal = props => {
  const { show, id, onClose } = props;
  const { womsSvc } = useContext(WomsServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: '',
      name: '',
      description: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required(i18n.t("VALIDATION.REQUIRED"))
    }),

    onSubmit: (values) => {
        if (id) {
            womsSvc.updateBrand(values).then(({ data }) => {
                formik.resetForm();
                onClose(true);
              });
        } else {
            womsSvc.createBrand(values).then(({ data }) => {
                formik.resetForm();
                onClose(true);
              });
        }

    },
  });

  useEffect(() => {
    if (show) {
      if (id) {
        setIsLoading(true);
        womsSvc.getBrand(id).then(({ data }) => {
          formik.setValues(data);
          setIsLoading(false);
        });
      }
    } else {
      formik.resetForm();
    }
  }, [show]);


  return (<>
    <Modal isOpen={show} size="md">
      <ModalHeader tag="h4">
        {!!id ? i18n.t('EDIT_BRAND') : i18n.t('NEW_BRAND')}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <ModalBody>
          <Row>
            {id && <Col md="6">
              <div className="mb-3">
                <Label className="form-label">{i18n.t('ID')}</Label>
                <Input
                  name="id"
                  type="text"
                  readOnly
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.id ? ("#BR" + formik.values.id) : ""}
                  invalid={
                    formik.touched.id && formik.errors.id ? true : false
                  }
                  disabled={true}
                />
                {formik.touched.id && formik.errors.id ? (
                  <FormFeedback type="invalid">{formik.errors.id}</FormFeedback>
                ) : null}
              </div></Col>}
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">{i18n.t('NAME')}</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder={i18n.t('NAME')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name || ""}
                  invalid={
                    formik.touched.storeSku && formik.errors.name ? true : false
                  }
                />
                {formik.touched.name && formik.errors.name ? (
                  <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>
                ) : null}
              </div>
            </Col>
            </Row>
            <Row>
            <Col md="12">
              <div className="mb-3">
                <Label className="form-label">{i18n.t('DESCRIPTION')}</Label>
                <div className="d-flex flex-row"><Input
                  name="description"
                  type="text"
                  placeholder={i18n.t('DESCRIPTION')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description || ""}
                  invalid={
                    formik.touched.description && formik.errors.description ? true : false
                  }
                />
                </div>
                {formik.touched.description && formik.errors.description ? (
                  <FormFeedback type="invalid">{formik.errors.description}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col md="12">
              <div className="text-end mt-2">
                <button type="button" className="btn btn-secondary" onClick={() => onClose()} disabled={isLoading}>{i18n.t("CANCEL")}</button>
                &nbsp;&nbsp;
                <button
                  type="submit"
                  className="btn btn-success save-integration"
                  disabled={isLoading}
                >
                  {i18n.t("SAVE")}
                </button>
              </div>
            </Col>
          </Row>
        </ModalFooter>
      </Form>
    </Modal>
    </>
  )
}
export default BrandModal;
import { Form } from "reactstrap";
import { formatToCurrency } from "../OrderUIHelper";
import { calculateSubtotal, calculateTotalAmount, calculateTotalTax } from "../helpers/orderCalculations";
import { CLIENT_SYNC_TYPE, ORDER_FEE_TYPE, PRICE_MOD_TYPE } from "services/womsEnum";
import { useEffect, useRef, useState } from "react";
import { isNanoId } from "helpers/is_nano_id";
import { AddPriceMod } from "./AddPriceMod";
import { PriceModRow } from "./PriceModRow";
import { usePriceModConfig } from "./usePriceModConfig";
import i18n from "i18next";

const PriceModsSection = ({ 
  orderFeeType = "", 
  priceModType,
  order, 
  setOrder,
  currency,
}) => {

  const { priceModLineItemsKey, pModHeader } = usePriceModConfig(priceModType, orderFeeType);

  const handlePriceModChange = (pModItem, value, changeField) => {
    const priceModItems = order[priceModLineItemsKey].map(item => {
      
      let priceModItem = item.id == pModItem.id 
      ? {... item, [changeField]: value, syncType: isNanoId(item.id) ? item.syncType : CLIENT_SYNC_TYPE.UPDATED} 
      : item;
      return priceModItem;
    });

    setOrder(prev => ({...prev, [priceModLineItemsKey]: priceModItems}));

  };

  const markPriceModAsDeleted = (id) => {
    const priceModItems = order[priceModLineItemsKey].map(pModItem => {
      if (pModItem.id === id) {
        return {
          ...pModItem,
          syncType: pModItem.syncType === CLIENT_SYNC_TYPE.DELETED && isNanoId(id)
            ? CLIENT_SYNC_TYPE.CREATED
            : pModItem.syncType === CLIENT_SYNC_TYPE.DELETED
            ? CLIENT_SYNC_TYPE.UPDATED
            : CLIENT_SYNC_TYPE.DELETED
        };
      }
      return pModItem;
    });
    setOrder(prev => ({...prev, [priceModLineItemsKey]: priceModItems}));

  };

  const handleRowDeleted = (id) => {
    // Filter out the item with the given id
    const updatedPriceModItems = order[priceModLineItemsKey].filter(pModItem => pModItem.id !== id);
  
    // Update the order state with the new array
    setOrder(prev => ({ 
      ...prev, 
      [priceModLineItemsKey]: updatedPriceModItems 
    }));
  };

  const renderPriceModItems = () => {
    return (
      <>
      {
        order[priceModLineItemsKey].length > 0 ? 
        order[priceModLineItemsKey]
        .filter(item => !orderFeeType || item.type === ORDER_FEE_TYPE[orderFeeType])
        .map(pModItem => (
          <PriceModRow
            key={pModItem.id}
            priceModItem={pModItem}
            order={order}
            setOrder={setOrder}
            handlePriceModChange={handlePriceModChange}
            currency={currency}
            markPriceModAsDeleted={markPriceModAsDeleted}
            handleRowDeleted={handleRowDeleted}
          />
        ))
        :
        <span className="text-gray-300 p-2 h-8">{i18n.t("NO_ENTRY")}</span>
      }
      </>
    )
  };
 
  return (
    <>
      {
        priceModLineItemsKey && 
        <div className="d-flex flex-column gap-2 w-100">

          <div className="d-flex align-items-center justify-content-between h-auto border-bottom border-light">
            <h6 className="m-0 text-start fw-bold p-2">{pModHeader}</h6>
            <AddPriceMod
            order={order}
            setOrder={setOrder}
            priceModType={priceModType}
            orderFeeType={orderFeeType}
            />
          </div>
          <div className="d-flex flex-column align-items-end gap-2">
          {
            renderPriceModItems()
          }
          </div>
        </div>
      }
    </>
  );
};

export const PriceMods = ({ 
  order, 
  setOrder,
  deletedItemsSubtotal 
}) => {

  const [priceModsSectionData, setPriceModsSectionData] = useState([]);

  useEffect(()=> {
    if(order) {
      const initializePriceModsSectionData = () => {
        const pModsSectionData = [];

        Object.keys(ORDER_FEE_TYPE).map((key) => {
            let pModHeader = "";

            switch(key) {
              case ORDER_FEE_TYPE.SHIPPING :
                pModHeader = i18n.t("ORDER_SHIPPING");
                break;
              case ORDER_FEE_TYPE.OTHER : 
                pModHeader = i18n.t("ORDER_FEE");
                break;
              case ORDER_FEE_TYPE.UNKNOWN :
                pModHeader = i18n.t("ORDER_UNKNOWN");
                break;
            }
            if(key !== ORDER_FEE_TYPE.UNKNOWN) {
              //temp
              pModsSectionData.push({
                pModHeader,
                priceModType: PRICE_MOD_TYPE.FEE,
                orderFeeType: ORDER_FEE_TYPE[key],
                hasLength: true,
              });
            }
        });

        pModsSectionData.push({
          pModHeader: i18n.t("PRICE_ADDITIONAL_DISCOUNT"),
          priceModType: PRICE_MOD_TYPE.DISCOUNT,
          orderFeeType: "",
          hasLength: order.discountLineItems.length > 0 ? true : false,
        });

        pModsSectionData.push({
          pModHeader: i18n.t("PRICE_TAX"),
          priceModType: PRICE_MOD_TYPE.TAX,
          orderFeeType: "",
          hasLength: order.taxLineItems.length > 0 ? true : false,
        });

        setPriceModsSectionData(pModsSectionData);
      };
      initializePriceModsSectionData();
  
    }
  },[order]);

  return (

    <div className="d-flex flex-column gap-2 h-100 price-mod-section">
      {
      order &&
      priceModsSectionData.map(data => (
        <PriceModsSection
        key={data.pModHeader}
        pModHeader={data.pModHeader}
        orderFeeType={data.orderFeeType} 
        priceModType={data.priceModType}
        order={order}
        setOrder={setOrder}
        currency={order.currency}
        />
      ))
      }
    </div>
  )
};



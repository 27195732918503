import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import womsUser from "./auth/womsuser/reducer";

//E-commerce
import ecommerce from "./e-commerce/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//crypto
import crypto from "./crypto/reducer";

//invoices
import invoices from "./invoices/reducer";

//jobs
import JobReducer from "./jobs/reducer";

//projects
import projects from "./projects/reducer";

//tasks
import tasks from "./tasks/reducer";

//contacts
import contacts from "./contacts/reducer";

//mails
import mails from "./mails/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

//Dasboard crypto
import DashboardCrypto from "./dashboard-crypto/reducer";

//Dasboard blog
import DashboardBlog from "./dashboard-blog/reducer";

//Dasboard job
import DashboardJob from "./dashboard-jobs/reducer";

import organization from "./organization/reducer";

import badge from "./badge/reducer";

import salesWidget from "./woms-dashboard/sales/reducer";
import ordersWidget from "./woms-dashboard/orders/reducer";
import avgPriceWidget from "./woms-dashboard/avg-price/reducer";
import orderStatusWidget from "./woms-dashboard/order-status/reducer";
import topSkusWidget from "./woms-dashboard/top-skus/reducer";
import topStoresWidget from "./woms-dashboard/top-stores/reducer";
import storeStatWidget from "./woms-dashboard/store-stat/reducer";
import batchResult from "./batchResult/reducer";
import salesByStoreWidget from "./report/sales-by-store/reducer";
import salesBySkuWidget from "./report/sales-by-sku/reducer";
import skuTrendWidget from "./report/sku-trend-by-store/reducer";
import profitBySkuWidget from "./report/profit-by-sku/reducer";
import profitByStoreWidget from "./report/profit-by-store/reducer";
import hubconnectionStatus from './hubconnectionStatus/reducer';
import notification from "./notification/reducer";
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  JobReducer,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  DashboardCrypto,
  DashboardBlog,
  DashboardJob,
  organization,
  womsUser,
  badge,
  salesWidget,
  ordersWidget,
  avgPriceWidget,
  topStoresWidget,
  topSkusWidget,
  storeStatWidget,
  orderStatusWidget,
  batchResult,
  salesByStoreWidget,
  salesBySkuWidget,
  skuTrendWidget,
  profitBySkuWidget,
  profitByStoreWidget,
  hubconnectionStatus,
  notification
});

export default rootReducer;

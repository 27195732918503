import { SUBSCRIPTION_PLAN_TYPE } from "services/womsEnum";

  
  export function getSubscriptionCssClasses(status) {
    var type;
    switch(status) {
      case SUBSCRIPTION_PLAN_TYPE.FREE:
        type="secondary"
        break;
        case SUBSCRIPTION_PLAN_TYPE.BASIC:
        type="primary"
        break;
        case SUBSCRIPTION_PLAN_TYPE.PRO:
        type="warning"
        break;
        case SUBSCRIPTION_PLAN_TYPE.ENTERPRISE:
        type="danger"
        break;
        case SUBSCRIPTION_PLAN_TYPE.INSIDER:
        type="success"
        break;
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }

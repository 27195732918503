import React, { useEffect, useState, useMemo, useContext } from "react";
import Moment from 'react-moment';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import AdvancedTableContainer from "components/Common/AdvancedTableContainer";
import SimpleNoticeModal from "components/Common/SimpleNoticeModal";
import { WomsServiceContext } from "services/womsService";
import cf from "../../services/codeFormatter";
import i18n from 'i18next';
import { withTranslation } from "react-i18next";
import { BATCH_REQUEST_TYPE, ENTITY_FILTER_TYPE, PRODUCT_SYNC_TYPE, STORE_TYPE, SYNC_STATUS } from "services/womsEnum";
import { getsSyncStatusCssClasses } from "./StoreListingUIHelper";
import SyncModal from "./SyncModal";
import StoreListingModal from "./StoreListingModal";
import { useDispatch, useSelector } from "react-redux";
import {
  updateNewListings
} from "../../store/actions";
import BrandSelectedModal from "./BrandSelectedModal";

// Column
const Id = (cell) => {
  return cell.getValue() ? "#SL" + cell.getValue() : '';
};
const StoreSKU = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};
const Name = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};
const Product = (cell) => {
  return cell.getValue() ? <a href={"/product/"+cell.getValue().id}>{cell.getValue().sku}</a> : <span className="badge badge-soft-danger">{i18n.t("NEW_LISTING")}</span>;
};
const Store = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};
const Brand = ({brandName}) => {
  return <span>{brandName ? brandName :<span className="badge badge-soft-warning">{i18n.t("NOT_SPECIFIED")}</span> }</span>;
};
const SyncStatus = (cell) => {
  return cell.getValue() ? <><span className={getsSyncStatusCssClasses(cell.getValue())} id={`syncResultPopover-${cell.row.original.id}`}>{cf.format("SyncStatus",cell.getValue())}</span>
  {cell.getValue() == SYNC_STATUS.FAILED ? 
      <><br/><span className="badge badge-soft-danger">{cell.row.original.syncResult}</span></>: ""}
  </> : '';
};

const LastSyncOn = (cell) => {
  return cell.getValue() ? <Moment format='ll'>{cell.getValue()}</Moment> : '';
};
const CreatedOn = (cell) => {
  return cell.getValue() ? <Moment format='ll'>{cell.getValue()}</Moment> : '';
};


function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}) {
  const ref = React.useRef();

  React.useEffect(() => {
    if (indeterminate) {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
    />
  )
}

const StoreListingList = (props) => {

  //meta title
  document.title = i18n.t("LISTINGS")
  const { womsSvc } = useContext(WomsServiceContext);

  const handleEntityClick = arg => {

  };

  // Customber Column
  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <div className="text-center"><IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
              onClick: ()=> {
                table.toggleAllRowsSelected();
              }
            }}
          /></div>
        ),
        cell: ({ row }) => (
          <div className="text-center">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                //disabled: !row.getCanSelect(),
                //indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
                onClick: () => {
                  row.toggleSelected();
                },
              }}
            />
          </div>
        ),
      },
      {
        header: i18n.t("ID"),
        accessorKey: 'id',
        filterable: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Id {...cellProps} />;
        }
      },
      {
        header: i18n.t("STORE_SKU"),
        accessorKey: 'storeSku',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <StoreSKU {...cellProps} />;
          ;
        }
      },
      {
        header: i18n.t('NAME'),
        accessorKey: 'name',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <Name {...cellProps} />;
          ;
        }
      },
      {
        header:i18n.t('PRODUCT'),
        accessorKey: 'product',
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <Product {...cellProps} />;
        }
      },
      {
        header: i18n.t('STORE'),
        accessorKey: 'store.name',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <Store {...cellProps} />;
        }
      },
      {
        header: i18n.t('BRAND'),
        accessorKey: 'brand', 
        enableSorting: true,
        filterable: false,
        cell: ({ cell }) => {
          const brandName = cell.row.original.brand?.name;
          return <Brand brandName={brandName} />;
        }
      },
      {
        header: i18n.t("SYNC_STATUS"),
        accessorKey: 'syncStatus',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <SyncStatus {...cellProps} />;
        }
      },
      {
        header: i18n.t("LAST_SYNCED_ON"),
        accessorKey: 'lastSyncedOn',
        enableSorting: true,
        cell: (cellProps) => {
          return <LastSyncOn {...cellProps} />;
        }
      },
      {
        header: i18n.t('CREATED_ON'),
        accessorKey: 'createdOn',
        enableSorting: true,
        cell: (cellProps) => {
          return <CreatedOn {...cellProps} />;
        }
      },
      {
        header: i18n.t('ACTION'),
        enableSorting: false,
        cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
              <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onMappingClick(entityData);
                  }
                  }
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                  { i18n.t("EDIT")}
                  <UncontrolledTooltip placement="top" target="edittooltip">
                  {i18n.t("EDIT")}
                  </UncontrolledTooltip>
                </DropdownItem>
                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onSyncClick(entityData);
                  }
                  }
                  disabled={cellProps.row.original?.store?.type != STORE_TYPE.SHOPIFY}
                >
                  <i className="mdi mdi-sync font-size-16 text-primary me-1" id="edittooltip"></i>
                  {i18n.t('SYNC')}
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    {i18n.t('SYNC')}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onDeleteClick(entityData);
                  }}>
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                  {i18n.t('DELETE')}
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {i18n.t('DELETE')}
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }
      },
    ],
    [i18n.language]
  );
  //delete 
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteSelectedModal, setDeleteSelectedModal] = useState(false);
  const [brandSelectedModal, setBrandSelectedModal] = useState(false);
  const [entityData, setEntityData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const batchResult = useSelector(state => state.batchResult);
  const [rerender, setRerender] = useState(batchResult.data);
  const [storeId,setStoreId] = useState('');
  const [storeList, setStoreList] = useState([]);
  const [errorModal, setErrorModal] = useState(false);


  const [isEdit, setIsEdit] = useState(false);
  const [modal,setModal] = useState(false);
  const [syncModal, setSyncModal] = useState(false);
  const [listingInfoList, setListingInfoList] = useState([]);
  const dispatch = useDispatch();


  const onDeleteClick = (entityData) => {
    setEntityData(entityData);
    setDeleteModal(true);
  };

  const onSyncClick = (entityData) => {
    if (!entityData.productId ) {
      var request = {
        type:BATCH_REQUEST_TYPE.PRODUCT_SYNC,
        payLoad : JSON.stringify({storeListingInfos: [{id:entityData.id,refNumber:entityData.refNumber,refNumber2:entityData.refNumber2}], syncType: PRODUCT_SYNC_TYPE.CREATE_NEW})
      }
        womsSvc.createBatchRequest(request).then(({ data }) => {
          refreshSidebar();
          setRerender(rerender+1);
        }).catch(err => {
          setErrorMessage(err.response.data);
          setErrorModal(true);
        });
    } else {
      var request = {
        type:BATCH_REQUEST_TYPE.PRODUCT_SYNC,
        payLoad : JSON.stringify({storeListingInfos: [{id:entityData.id,refNumber:entityData.refNumber,refNumber2:entityData.refNumber2}], syncType: PRODUCT_SYNC_TYPE.MERGE})
      }
        womsSvc.createBatchRequest(request).then(({ data }) => {
          refreshSidebar();
          setRerender(rerender+1);
        }).catch(err => {
          setErrorMessage(err.response.data);
          setErrorModal(true);
        });
    }

  };

  useEffect(() => {
    if (batchResult.data > rerender) {
      setRerender(batchResult.data);
    }
    

  }, [batchResult]);

const refreshSidebar = () => {
  womsSvc.getNewStoreListingsCount().then(({data}) => {
    dispatch(updateNewListings(data));
  })
}
  const onMappingClick = (entityData, isEdit) => {
    setEntityData(entityData);
    setErrorMessage("");
    setIsEdit(isEdit);
    setModal(true);
  };
    

  const handleDeleteEntity = () => {
    
    if (entityData && entityData.id) {
    womsSvc.deleteStoreListing(entityData.id).then((data) => {
      setRerender(rerender + 1);
      setDeleteModal(false);
    });
  
  }
  };
  const handleDeleteSelectedEntity = () => {
      if (listingInfoList && listingInfoList.length > 0) {
        womsSvc.deleteStoreListings(listingInfoList).then((data) => {
          setRerender(rerender + 1);
          setDeleteModal(false);
        });
      }
  };
  
  const handleSyncModalClose = (syncDetail) => {
    if (syncDetail) {

      // enqueue sync here
      setRerender(rerender + 1);
      refreshSidebar();
    }
    setSyncModal(false);

  };

  const handleModalClose = (isUpdated) => {
    if(isUpdated) {
      
      setRerender(rerender+1);
      refreshSidebar();
    }
    setModal(false);
  };
  const handleBrandSelectedModalClose = (isUpdated) => {
    if(isUpdated) {
      
      setRerender(rerender+1);
    }
    setBrandSelectedModal(false);
  };
  const handleErrorModalClose = () => {
    setErrorModal(false);
  };
  useEffect(() => {
    womsSvc.searchStore(100,0,'createdOn','desc').then(({data}) => {
      setStoreList(data.value);
    }).finally(() => {
    });
  }, []);

  const selectedPopup = (selectedRows) => {
    return <>
    <Button className="btn btn-sm btn-light" onClick={()=> {
            const firstStoreId = selectedRows[0].original.storeId;
            // Use Array.every() to check if all rows have the same storeId
            const allSameStoreId = selectedRows.every(row => row.original.storeId === firstStoreId);
      
            if (allSameStoreId) {
              setListingInfoList(selectedRows.map(row => ({
                id: row.original.id, 
                refNumber: row.original.refNumber,
                refNumber2: row.original.refNumber2
            })));
              setSyncModal(true);
            } else {
              setErrorMessage(i18n.t("VALIDATION.LISTING_SINGLE_STORE_ONLY"));
              setErrorModal(true);
            }
  }}>{i18n.t("SYNC_SELECTED")}</Button>
  <Button className="btn btn-sm btn-light ms-3" onClick={()=> {

setListingInfoList(selectedRows.map(row => ({
  id: row.original.id, 
  refNumber: row.original.refNumber,
  refNumber2: row.original.refNumber2
})));
setBrandSelectedModal(true);
}}>{i18n.t("ASSIGN_BRAND_SELECTED")}</Button>
      {/*<Button className="btn btn-sm btn-danger ms-3" onClick={()=> {

        setListingInfoList(selectedRows.map(row => ({
          id: row.original.id, 
          refNumber: row.original.refNumber,
          refNumber2: row.original.refNumber2
      })));
        setDeleteSelectedModal(true);
  }}>{i18n.t("DELETE_SELECTED")}</Button>*/}
    </>
};

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEntity}
        errorMessage={errorMessage}
        entityName={i18n.t("LISTINGS")}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteSelectedModal}
        onDeleteClick={handleDeleteSelectedEntity}
        errorMessage={errorMessage}
        entityName={i18n.t("LISTINGS")}
        onCloseClick={() => setDeleteSelectedModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={i18n.t("LISTINGS")} breadcrumbItem={i18n.t("LIST")}
          altTitle={i18n.t("STORE_LISTINGS_LIST") + " : "} button={
            <select
            className="form-select form-select-sm mt-1"
            value={storeId}
            onChange={(e) => {
              setStoreId(e.target.value);
              setRerender(rerender +  1);
            }}
          >
            <option key={''} value={''}>
                {i18n.t("ALL_STORES")}
              </option>
            {storeList.map(store => (
              <option key={store.id} value={store.id}>
                {store.name}
              </option>
            ))}
          </select> 
          } />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AdvancedTableContainer
                    columns={columns}
                    odataFetch={womsSvc.searchStoreListing}
                    //odataExport={womsSvc.exportOrder}
                    fetchCondition={storeId ? "storeId eq "+ storeId : ""}
                    initialSortBy={"createdOn"}
                    isGlobalFilter={true}
                    filterColumns={["name","storeSku"]}
                    //addButtonLabel={"Add New Store"}
                    //isExportButton={true}
                    //onAddClick={handleAddEntity}
                    customPageSize={20}
                    //isCustomFilter={true}
                    //entityType={ENTITY_FILTER_TYPE.STORE}
                    className="table-advanced"
                    rerender={rerender}
                    selectedPopup={selectedPopup}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <SyncModal
        show={syncModal}
        //isEdit={isEdit}
        infos={listingInfoList}
        onClose={handleSyncModalClose}
        errorMessage={errorMessage}
      />
      <StoreListingModal
        show={modal}
        isEdit={isEdit}
        id={entityData?.id}
        onClose={handleModalClose}
        errorMessage={errorMessage}
      />
      <SimpleNoticeModal
        show={errorModal}
        message={errorMessage}
        onCloseClick={handleErrorModalClose}
      />
      <BrandSelectedModal
        show={brandSelectedModal}
        message={errorMessage}
        infos={listingInfoList}
        onClose={handleBrandSelectedModalClose}
        />
    </>
  );
}

export default withTranslation()(StoreListingList);
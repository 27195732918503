import i18n from 'i18next';

const CODE_STORAGE =
{
    'UserStatus'
    : [
        { code: '1', lang: 'en', text: 'Active' },
        { code: '2', lang: 'en', text: "Inactive" },
        { code: '3', lang: 'en', text: "Pending" },
        { code: '4', lang: 'en', text: "Deleted" },

        { code: '1', lang: 'ko', text: '정상' },
        { code: '2', lang: 'ko', text: "비활성" },
        { code: '3', lang: 'ko', text: "가입대기" },
        { code: '4', lang: 'ko', text: "삭제됨" },
    ],
    'IsActive'
    : [
        { code: 'true', lang: 'en', text: 'Active' },
        { code: 'false', lang: 'en', text: 'Inactive' },

        { code: 'true', lang: 'ko', text: '정상' },
        { code: 'false', lang: 'ko', text: '비활성' }
    ],
    'FilterOperatorType'
    : [
        { code: 'EQUAL', lang: 'en', text: 'Equal' },
        { code: 'NOT_EQUAL', lang: 'en', text: 'Not Equal' },
        { code: 'LESS_THAN', lang: 'en', text: 'Less Than' },
        { code: 'GREATER_THAN', lang: 'en', text: 'Greater Than' },
        { code: 'BETWEEN', lang: 'en', text: 'Between' },
        { code: 'CONTAINS', lang: 'en', text: 'Contains' },
        { code: 'NOT_CONTAINS', lang: 'en', text: 'Not Contains' },

        { code: 'EQUAL', lang: 'ko', text: '일치' },
        { code: 'NOT_EQUAL', lang: 'ko', text: '불일치' },
        { code: 'LESS_THAN', lang: 'ko', text: '미만' },
        { code: 'GREATER_THAN', lang: 'ko', text: '이상' },
        { code: 'BETWEEN', lang: 'ko', text: '범위' },
        { code: 'CONTAINS', lang: 'ko', text: '포함' },
        { code: 'NOT_CONTAINS', lang: 'ko', text: '불포함' },
    ],
    'OrderStatus'
    : [
        { code: 'DRAFT', lang: 'en', text: 'Draft' },
        { code: 'SUBMITTED', lang: 'en', text: 'Submitted' },
        { code: 'PENDING_APPROVAL', lang: 'en', text: 'Pending Approval' },
        { code: 'READY_TO_FULFILL', lang: 'en', text: 'Ready To Fulfill' },
        { code: 'PENDING_BILLING', lang: 'en', text: 'Pending Billing' },
        { code: 'COMPLETED', lang: 'en', text: 'Completed' },
        { code: 'CANCELLED', lang: 'en', text: 'Cancelled' },
        { code: 'DELETED', lang: 'en', text: 'Deleted' },

        { code: 'DRAFT', lang: 'ko', text: '초안' },
        { code: 'SUBMITTED', lang: 'ko', text: '신규됨' },
        { code: 'PENDING_APPROVAL', lang: 'ko', text: '승인대기중' },
        { code: 'READY_TO_FULFILL', lang: 'ko', text: '출고대기중' },
        { code: 'PENDING_BILLING', lang: 'ko', text: '청구대기중' },
        { code: 'COMPLETED', lang: 'ko', text: '완료됨' },
        { code: 'CANCELLED', lang: 'ko', text: '취소됨' },
        { code: 'DELETED', lang: 'ko', text: '삭제됨' },
    ]
    ,
    'OrderFulfillmentStatus'
    : [
        { code: 'NONE', lang: 'en', text: 'None' },
        { code: 'INITIATED', lang: 'en', text: 'Initiated' },
        { code: 'SHIPPED', lang: 'en', text: 'Shipped' },
        { code: 'PARTIALLY_SHIPPED', lang: 'en', text: 'Partially Shipped' },
        
        { code: 'NONE', lang: 'ko', text: '정보없음' },
        { code: 'INITIATED', lang: 'ko', text: '요청됨' },
        { code: 'SHIPPED', lang: 'ko', text: '출고완료' },
        { code: 'PARTIALLY_SHIPPED', lang: 'ko', text: '부분출고됨' }
    ]
    ,
    'FulfillmentStatus'
    : [
        { code: 'DRAFT', lang: 'en', text: 'Draft' },
        { code: 'SUBMITTED', lang: 'en', text: 'Submitted' },
        { code: 'PICKED', lang: 'en', text: 'Pending Approval' },
        { code: 'PACKED', lang: 'en', text: 'Ready To Fulfill' },
        { code: 'SHIPPED', lang: 'en', text: 'Shipped' },
        { code: 'PARTIALLY_SHIPPED', lang: 'en', text: 'Partially Shipped' },
        { code: 'CANCELLED', lang: 'en', text: 'Cancelled' },
        { code: 'DELETED', lang: 'en', text: 'Deleted' },

        { code: 'DRAFT', lang: 'ko', text: '초안' },
        { code: 'SUBMITTED', lang: 'ko', text: '신규됨' },
        { code: 'PICKED', lang: 'ko', text: '피킹완료' },
        { code: 'PACKED', lang: 'ko', text: '포장완료' },
        { code: 'SHIPPED', lang: 'ko', text: '출고완료' },
        { code: 'PARTIALLY_SHIPPED', lang: 'ko', text: '부분출고됨' },
        { code: 'CANCELLED', lang: 'ko', text: '취소됨' },
        { code: 'DELETED', lang: 'ko', text: '삭제됨' },
    ]    ,
    'UserRole'
    : [
        { code: 'SYS_ADMIN', lang: 'en', text: 'Sys Admin' },
        { code: 'ORG_ADMIN', lang: 'en', text: 'Org Admin' },
        
        { code: 'SYS_ADMIN', lang: 'ko', text: '시스템 관리자' },
        { code: 'ORG_ADMIN', lang: 'ko', text: '조직 관리자' },
    ],
    "SyncStatus" : [
        { code: 'NONE', lang: 'en', text: 'None' },
        { code: 'REQUESTED', lang: 'en', text: 'Requested' },
        { code: 'PROCESSING', lang: 'en', text: 'Processing' },
        { code: 'COMPLETED', lang: 'en', text: 'Completed' },
        { code: 'COMPLETED_WITH_ISSUE', lang: 'en', text: 'Comleted with issue' },
        { code: 'FAILED', lang: 'en', text: 'Failed' },
        { code: 'OTHER', lang: 'en', text: 'Other' },

        { code: 'NONE', lang: 'ko', text: '상태없음' },
        { code: 'REQUESTED', lang: 'ko', text: '요청됨' },
        { code: 'PROCESSING', lang: 'ko', text: '처리중' },
        { code: 'COMPLETED', lang: 'ko', text: '완료' },
        { code: 'COMPLETED_WITH_ISSUE', lang: 'ko', text: '완료및이슈발생' },
        { code: 'FAILED', lang: 'ko', text: '실패' },
        { code: 'OTHER', lang: 'ko', text: '기타' },
    ],
    "DashboardPeriodType" : [
        { code: 'LAST_DAY', lang: 'en', text: 'Last Day' },
        { code: 'LAST_7_DAYS', lang: 'en', text: 'Last 7 days' },
        { code: 'LAST_30_DAYS', lang: 'en', text: 'Last 30 days' },
        { code: 'LAST_90_DAYS', lang: 'en', text: 'Last 90 days' },
        { code: 'RANGE', lang: 'en', text: 'Range' },

        { code: 'LAST_DAY', lang: 'ko', text: '하루' },
        { code: 'LAST_7_DAYS', lang: 'ko', text: '최근 7일' },
        { code: 'LAST_30_DAYS', lang: 'ko', text: '최근 30일' },
        { code: 'LAST_90_DAYS', lang: 'ko', text: '최근 90일' },
        { code: 'RANGE', lang: 'ko', text: '범위' },
    ],
    "ReportPeriodType" : [
        { code: 'LAST_DAY', lang: 'en', text: 'Last Day' },
        { code: 'LAST_WEEK', lang: 'en', text: 'Last Week' },
        { code: 'THIS_WEEK', lang: 'en', text: 'This Week' },
        { code: 'LAST_MONTH', lang: 'en', text: 'Last Month' },
        { code: 'THIS_MONTH', lang: 'en', text: 'This Month' },
        { code: 'RANGE', lang: 'en', text: 'Range' },

        { code: 'LAST_DAY', lang: 'ko', text: '어제' },
        { code: 'LAST_WEEK', lang: 'ko', text: '지난주' },
        { code: 'THIS_WEEK', lang: 'ko', text: '이번주' },
        { code: 'LAST_MONTH', lang: 'ko', text: '지난달' },
        { code: 'THIS_MONTH', lang: 'ko', text: '이번달' },
        { code: 'RANGE', lang: 'ko', text: '범위' },
    ],
    "StoreType": [
        { code: 'UNKNOWN', lang: 'en', text: 'Unknown'},
        { code: 'AMAZON', lang: 'en', text: 'Amazon'},
        { code: 'SHOPIFY', lang: 'en', 'text': 'Shopify'},
        { code: 'WOOCOMMERCE', lang: 'en', 'text': 'WooCommerce'},
        { code: 'SHOPEE', lang: 'en', 'text': 'Shopee'},
        { code: 'SABANG_NET', lang: 'en', 'text': 'Sabangnet'},
        {code: 'TIKTOK', lang: 'en', 'text': 'Tiktok'},
        {code: 'SHEIN', lang: 'en', 'text': 'Shein'},
        {code: 'EBAY', lang: 'en', 'text': 'Ebay'},
        {code: 'FLIPSHOP', lang: 'en', 'text': 'Flipshop'},

        { code: 'UNKNOWN', lang: 'ko', text: '미정'},
        { code: 'AMAZON', lang: 'ko', text: '아마존'},
        { code: 'SHOPIFY', lang: 'ko', 'text': '쇼피파이'},
        { code: 'WOOCOMMERCE', lang: 'ko', 'text': '우커머스'},
        { code: 'SHOPEE', lang: 'ko', 'text': '쇼피'},
        { code: 'SABANG_NET', lang: 'ko', 'text': '사방넷'},
        {code: 'TIKTOK', lang: 'ko', 'text': '틱톡'},
        {code: 'SHEIN', lang: 'ko', 'text': '쉬인'},
        {code: 'EBAY', lang: 'ko', 'text': '이베이'},
        {code: 'FLIPSHOP', lang: 'ko', 'text': '플립샵'}
    ],
    "StoreSyncType":[
        { code: 'NONE', lang: 'en', text: 'None'},
        { code: 'PASSIVE', lang: 'en', text: 'Passive'},
        { code: 'MASTER', lang: 'en', text: 'Master'},
        { code: 'TWO_WAY', lang: 'en', text: 'Two-way'},

        { code: 'NONE', lang: 'ko', text: '없음'},
        { code: 'PASSIVE', lang: 'ko', text: '패시브'},
        { code: 'MASTER', lang: 'ko', text: '마스터'},
        { code: 'TWO_WAY', lang: 'ko', text: '양방향'},
    ],
    "BundleType":[
        { code: 'STANDARD', lang: 'en', text: 'Standard'},
        { code: 'BUNDLE', lang: 'en', text: 'Bundle'},
        { code: 'KIT', lang: 'en', text: 'Kit'},

        { code: 'STANDARD', lang: 'ko', text: '일반'},
        { code: 'BUNDLE', lang: 'ko', text: '번들'},
        { code: 'KIT', lang: 'ko', text: '키트'},
    ],
    "ProductStatus":[
        { code: 'ACTIVE', lang: 'en', text: 'Active'},
        { code: 'INACTIVE', lang: 'en', text: 'Inactive'},
        { code: 'DELETED', lang: 'en', text: 'Deleted'},

        { code: 'ACTIVE', lang: 'ko', text: '정상'},
        { code: 'INACTIVE', lang: 'ko', text: '비활성'},
        { code: 'DELETED', lang: 'ko', text: '삭제됨'},
    ],
    "TagShowOption":[
        { code: 'ALL', lang: 'en', text: 'All'},
        { code: 'SELECTED', lang: 'en', text: 'Selected'},
        { code: 'NOT_SELECTED', lang: 'en', text: 'Not Selected'},

        { code: 'ALL', lang: 'ko', text: '전체'},
        { code: 'SELECTED', lang: 'ko', text: '선택된 항목만'},
        { code: 'NOT_SELECTED', lang: 'ko', text: '선택되지 않은 항목만'},
    ],
    "EntityFilterUserField":[
        { code: 'STATUS', lang: 'en', text: 'Status'},
        { code: 'CREATED_ON', lang: 'en', text: 'Created On'},
        { code: 'ORDERED_ON', lang: 'en', text: 'Ordered On'},
        { code: 'FIRST_NAME', lang: 'en', text: 'First Name'},
        { code: 'LAST_NAME', lang: 'en', text: 'Last Name'},
        { code: 'TOTAL_AMOUNT', lang: 'en', text: 'Total Amount'},
        { code: 'TOTAL_ITEMS', lang: 'en', text: 'Total Items'},
        { code: 'SKU', lang: 'en', text: 'SKU'},
        { code: 'ITEM_NAME', lang: 'en', text: 'Item Name'},

        { code: 'STATUS', lang: 'ko', text: '상태'},
        { code: 'CREATED_ON', lang: 'ko', text: '생성일'},
        { code: 'ORDERED_ON', lang: 'ko', text: '주문일'},
        { code: 'FIRST_NAME', lang: 'ko', text: '이름'},
        { code: 'LAST_NAME', lang: 'ko', text: '성'},
        { code: 'TOTAL_AMOUNT', lang: 'ko', text: '총 금액'},
        { code: 'TOTAL_ITEMS', lang: 'ko', text: '총 수량'},
        { code: 'SKU', lang: 'ko', text: 'SKU'},
        { code: 'ITEM_NAME', lang: 'ko', text: '품명'},

    ],
    "NotificationType":[
        { code: 'SYSTEM_NOTICE', lang: 'en', text: 'System Notice'},
        { code: 'SYSTEM_ALERT', lang: 'en', text: 'System Alert'},
        { code: 'USER_MESSAGE', lang: 'en', text: 'User Message'},
        { code: 'ORG_NOTICE', lang: 'en', text: 'Organization Notice'},
        
        { code: 'SYSTEM_NOTICE', lang: 'ko', text: '시스템 알림'},
        { code: 'SYSTEM_ALERT', lang: 'ko', text: '시스템 경고'},
        { code: 'USER_MESSAGE', lang: 'ko', text: '사용자 메시지'},
        { code: 'ORG_NOTICE', lang: 'ko', text: '업체 공지'}
    ],
    "NotificationSendToType":[
        { code: 'ALL_USER', lang: 'en', text: 'All Users'},
        { code: 'CURRENT_ORGANIZATION', lang: 'en', text: 'Current Organization'},
        { code: 'SPECIFIC_USER', lang: 'en', text: 'Specific User'},
        
        { code: 'ALL_USER', lang: 'ko', text: '전체 사용자'},
        { code: 'CURRENT_ORGANIZATION', lang: 'ko', text: '현재 조직'},
        { code: 'SPECIFIC_USER', lang: 'ko', text: '특정 사용자'}
    ],
    "FeedbackType":[
        { code: 'UNSPECIFIED', lang: 'en', text: 'Unspecified'},
        { code: 'BUG_REPORT', lang: 'en', text: 'Bug Report'},
        { code: 'FEATURE_SUGGESTION', lang: 'en', text: 'Feature Suggestion'},
        { code: 'INTERNALIZATION', lang: 'en', text: 'Internalization'},
        { code: 'GENERAL_FEEDBACK', lang: 'en', text: 'General Feedback'},

        { code: 'UNSPECIFIED', lang: 'ko', text: '해당 분류 없음'},
        { code: 'BUG_REPORT', lang: 'ko', text: '버그'},
        { code: 'FEATURE_SUGGESTION', lang: 'ko', text: '기능제안'},
        { code: 'INTERNALIZATION', lang: 'ko', text: '번역오류'},
        { code: 'GENERAL_FEEDBACK', lang: 'ko', text: '기타제안'},

    ],
    "ReportSkuType":[
        { code: 'TOP_3', lang: 'en', text: 'Top 3 sales sku'},
        { code: 'TOP_7', lang: 'en', text: 'Top 7 sales sku'},
        { code: 'SELECT', lang: 'en', text: 'Manual Selection'},

        { code: 'TOP_3', lang: 'ko', text: '매출 Top 3 SKU'},
        { code: 'TOP_7', lang: 'ko', text: '매출 Top 7 SKU'},
        { code: 'SELECT', lang: 'ko', text: '직접 선택'},

    ],
    "FulfillmentCostAllocationType": [
        { code: 'BY_QUANTITY', lang: 'en', text: 'By Quantity'},
        { code: 'BY_AMOUNT', lang: 'en', text: 'By Amount'},

        { code: 'BY_QUANTITY', lang: 'ko', text: '수량비례'},
        { code: 'BY_AMOUNT', lang: 'ko', text: '금액비례'},
    ],
    "SubscriptionPlanType":[
        { code: 'FREE', lang: 'en', text: 'Free Plan'},
        { code: 'BASIC', lang: 'en', text: 'Basic Plan'},
        { code: 'PRO', lang: 'en', text: 'Pro Plan'},
        { code: 'ENTERPRISE', lang: 'en', text: 'Enterprise Plan'},
        { code: 'INSIDER', lang: 'en', text: 'Insider Plan'},

        { code: 'FREE', lang: 'ko', text: '무료 플랜'},
        { code: 'BASIC', lang: 'ko', text: '기본 플랜'},
        { code: 'PRO', lang: 'ko', text: '프로 플랜'},
        { code: 'ENTERPRISE', lang: 'ko', text: '엔터프라이즈 플랜'},
        { code: 'INSIDER', lang: 'ko', text: '내부고객'},


    ],
}

const cf = {
    format: function (key,code,lang) {
        lang = lang || i18n.language;
        if (key) {
            try {
                return CODE_STORAGE[key].find(x => x.code === String(code) && x.lang === lang).text;
            } catch (e) {
                return code;
            }
        };
        var formattedObj = Object.assign({}, code);
        Object.keys(formattedObj).forEach(key => {
            var codes = CODE_STORAGE[key];
            if (codes) {
                try {
                    formattedObj[key] = CODE_STORAGE[key].find(x => x.code === String(formattedObj[key]) && x.lang === lang).text;
                } catch (e) { }
            }
        });
        return formattedObj;
    },
    getCodes: function (key) {
        return CODE_STORAGE[key];
    }
}



export default cf

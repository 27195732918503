import { UPDATE_PROFIT_BY_SKU_WIDGET } from "./actionTypes";

const initialState = {
  data: {}  
};

const profitBySkuWidget = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROFIT_BY_SKU_WIDGET:
      const entity = action.payload;
      return { ...state, data: { ...state.data, [entity.id]: entity } };


    default:
      return state;
  }
};

export default profitBySkuWidget;
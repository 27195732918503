import React, { useEffect, useMemo, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    Col,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    Card,
    CardTitle,
    CardBody,
    CardImg,
    CardSubtitle,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    Form
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { WomsServiceContext } from 'services/womsService'
import Dropzone from 'react-dropzone'
import ControlledTypehead from "components/Common/ControlledTypehead"
import defaultImage from "../../../../assets/images/no-img.png"
import '../../product.scss'
import { formatBytes } from 'helpers/woms_utils'
import i18n from 'i18next';
import { withTranslation } from 'react-i18next'
import cf from "../../../../services/codeFormatter";

const productTitle = "ACNE COVER PATCH 40"
const productSKU = "SKACAV1022"
const ProductImageModal = props => {
    const { show, setShow } = props
    const { womsSvc } = useContext(WomsServiceContext)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([]);

    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                //formattedSize: formatBytes(file.size)
            }))

        setSelectedFiles(files)
    }

    return (
        //modal-fullscreen woms-modal-fullscreen
        <Modal isOpen={show} toggle={setShow} className='woms-modal-fullscreen modal-fullscreen' >
            <ModalHeader tag={'h5'} toggle={setShow}>
                <Row>
                    <Col >
                        <div style={{ backgroundColor: '#fcfcfc', border: '0.5px solid #ccc' }}>
                            <img style={{ objectFit: 'contain', aspectRatio: '1', width: '100%' }} src={defaultImage} />
                        </div>
                    </Col>
                    <Col xl={10} xs={10}>
                        <Row>
                            <Col>{i18n.t("EDIT_IMAGES")}</Col>
                        </Row>
                        <Row>
                            <Col>{productTitle}</Col>
                        </Row>
                        <Row>
                            <Col>SKU: {productSKU}</Col>
                        </Row>
                    </Col>
                </Row>

            </ModalHeader>
            <ModalBody>
                <Form >
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Dropzone</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        DropzoneJS is an open source library that provides
                                        drag’n’drop file uploads with image previews.
                                    </CardSubtitle>
                                    <Form>
                                        <Dropzone
                                            onDrop={acceptedFiles => { handleAcceptedFiles(acceptedFiles) }}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone">
                                                <div
                                                    className="dz-message needclick mt-2"
                                                    {...getRootProps()}
                                                >
                                                    <input {...getInputProps()}/>
                                                </div>
                                            </div>
                                            )}
                                        </Dropzone>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Row>
                    <Col>
                        <div className="text-end">
                            <button type="button" className="btn btn-secondary" onClick={setShow} disabled={isLoading}>{i18n.t("CANCEL")}</button>
                            &nbsp;&nbsp;
                            <button
                                type="submit"
                                className="btn btn-success"
                                disabled={isLoading}
                            >
                                {i18n.t("SAVE")}
                            </button>
                        </div>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    )


}

export default withTranslation()(ProductImageModal)

import { FULFILLMENT_STATUS, ORDER_FULFILLMENT_STATUS, ORDER_STATUS, STORE_TYPE, USER_STATUS } from "services/womsEnum";
import shopify from "../../assets/images/brands/shopify-icon.svg";
import amazon from "../../assets/images/brands/amazon-a-logo-icon.svg";
import woocommerce from "../../assets/images/brands/woocommerce-icon.svg";
import shopee from "../../assets/images/brands/shopee-icon.svg";
import sabang_net from "../../assets/images/brands/sabanglogo.png";
import flipshop from "../../assets/images/brands/flipshop_logo.png";
import ebay from "../../assets/images/brands/ebay_logo.png";
import shein from "../../assets/images/brands/shein_logo.png";
import tiktok from "../../assets/images/brands/tiktok_logo.png";

import unknown from "../../assets/images/brands/question-mark-sign-icon.svg";

  
  export function getOrderStatusCssClasses(status) {
    var type;
    switch(status) {
      case ORDER_STATUS.DRAFT:
      type="secondary"
      break;
      case ORDER_STATUS.SUBMITTED:
      type="primary"
      break;
      case ORDER_STATUS.PENDING_APPROVAL:
      type="warning"
      break;
      case ORDER_STATUS.READY_TO_FULFILL:
      type="info"
      break;
      case ORDER_STATUS.PENDING_BILLING:
      type="pink"
      break;
      case ORDER_STATUS.COMPLETED:
      type="success"
      break;
      case ORDER_STATUS.CANCELLED:
      type="danger"
      break;
      case ORDER_STATUS.DELETED:
      type="dark"
      break;
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }
  export function getOrderFulfillmentStatusCssClasses(status) {
    var type;
    switch(status) {
      case ORDER_FULFILLMENT_STATUS.NONE:
      type="secondary"
      break;
      case ORDER_FULFILLMENT_STATUS.INITIATED:
      type="primary"
      break;
      case ORDER_FULFILLMENT_STATUS.PARTIALLY_SHIPPED:
      type="warning"
      break;
      case ORDER_FULFILLMENT_STATUS.SHIPPED:
      type="success"
      break;
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }

  const currencySymbols = {
    AED: 'د.إ', // United Arab Emirates Dirham
    AFN: '؋', // Afghan Afghani
    ALL: 'L', // Albanian Lek
    AMD: '֏', // Armenian Dram
    ANG: 'ƒ', // Netherlands Antillean Guilder
    AOA: 'Kz', // Angolan Kwanza
    ARS: '$', // Argentine Peso
    AUD: 'A$', // Australian Dollar
    AWG: 'ƒ', // Aruban Florin
    AZN: '₼', // Azerbaijani Manat
    BAM: 'KM', // Bosnia-Herzegovina Convertible Mark
    BBD: 'Bds$', // Barbadian Dollar
    BDT: '৳', // Bangladeshi Taka
    BGN: 'лв', // Bulgarian Lev
    BHD: '.د.ب', // Bahraini Dinar
    BIF: 'FBu', // Burundian Franc
    BMD: 'BD$', // Bermudian Dollar
    BND: 'B$', // Brunei Dollar
    BOB: 'Bs.', // Bolivian Boliviano
    BRL: 'R$', // Brazilian Real
    BSD: 'B$', // Bahamian Dollar
    BTN: 'Nu.', // Bhutanese Ngultrum
    BWP: 'P', // Botswana Pula
    BYN: 'Br', // Belarusian Ruble
    BZD: 'BZ$', // Belize Dollar
    CAD: 'C$', // Canadian Dollar
    CDF: 'FC', // Congolese Franc
    CHF: 'CHF', // Swiss Franc
    CLP: '$', // Chilean Peso
    CNY: '¥', // Chinese Yuan
    COP: '$', // Colombian Peso
    CRC: '₡', // Costa Rican Colón
    CUP: '$', // Cuban Peso
    CVE: 'Esc', // Cape Verdean Escudo
    CZK: 'Kč', // Czech Koruna
    DJF: 'Fdj', // Djiboutian Franc
    DKK: 'kr.', // Danish Krone
    DOP: 'RD$', // Dominican Peso
    DZD: 'دج', // Algerian Dinar
    EGP: '£', // Egyptian Pound
    ERN: 'Nfk', // Eritrean Nakfa
    ETB: 'Br', // Ethiopian Birr
    EUR: '€', // Euro
    FJD: 'FJ$', // Fijian Dollar
    FKP: '£', // Falkland Islands Pound
    FOK: 'kr', // Faroese Króna
    GBP: '£', // British Pound Sterling
    GEL: '₾', // Georgian Lari
    GGP: '£', // Guernsey Pound
    GHS: 'GH₵', // Ghanaian Cedi
    GIP: '£', // Gibraltar Pound
    GMD: 'D', // Gambian Dalasi
    GNF: 'FG', // Guinean Franc
    GTQ: 'Q', // Guatemalan Quetzal
    GYD: 'G$', // Guyanaese Dollar
    HKD: 'HK$', // Hong Kong Dollar
    HNL: 'L', // Honduran Lempira
    HRK: 'kn', // Croatian Kuna
    HTG: 'G', // Haitian Gourde
    HUF: 'Ft', // Hungarian Forint
    IDR: 'Rp', // Indonesian Rupiah
    ILS: '₪', // Israeli New Shekel
    IMP: '£', // Isle of Man Pound
    INR: '₹', // Indian Rupee
    IQD: 'ع.د', // Iraqi Dinar
    IRR: '﷼', // Iranian Rial
    ISK: 'kr', // Icelandic Króna
    JEP: '£', // Jersey Pound
    JMD: 'J$', // Jamaican Dollar
    JOD: 'د.ا', // Jordanian Dinar
    JPY: '¥', // Japanese Yen
    KES: 'KSh', // Kenyan Shilling
    KGS: 'с', // Kyrgyzstani Som
    KHR: '៛', // Cambodian Riel
    KID: 'K$', // Kiribati Dollar
    KMF: 'CF', // Comorian Franc
    KRW: '₩', // South Korean Won
    KWD: 'د.ك', // Kuwaiti Dinar
    KYD: 'CI$', // Cayman Islands Dollar
    KZT: '₸', // Kazakhstani Tenge
    LAK: '₭', // Laotian Kip
    LBP: 'ل.ل', // Lebanese Pound
    LKR: 'Rs', // Sri Lankan Rupee
    LRD: 'L$', // Liberian Dollar
    LSL: 'L', // Lesotho Loti
    LYD: 'ل.د', // Libyan Dinar
    MAD: 'د.م.', // Moroccan Dirham
    MDL: 'L', // Moldovan Leu
    MGA: 'Ar', // Malagasy Ariary
    MKD: 'ден', // Macedonian Denar
    MMK: 'K', // Myanma Kyat
    MNT: '₮', // Mongolian Tugrik
    MOP: 'MOP$', // Macanese Pataca
    MRU: 'UM', // Mauritanian Ouguiya
    MUR: '₨', // Mauritian Rupee
    MVR: 'Rf', // Maldivian Rufiyaa
    MWK: 'MK', // Malawian Kwacha
    MXN: 'MX$', // Mexican Peso
    MYR: 'RM', // Malaysian Ringgit
    MZN: 'MT', // Mozambican Metical
    NAD: 'N$', // Namibian Dollar
    NGN: '₦', // Nigerian Naira
    NIO: 'C$', // Nicaraguan Córdoba
    NOK: 'kr', // Norwegian Krone
    NPR: '₨', // Nepalese Rupee
    NZD: 'NZ$', // New Zealand Dollar
    OMR: 'ر.ع.', // Omani Rial
    PAB: 'B/.', // Panamanian Balboa
    PEN: 'S/.', // Peruvian Nuevo Sol
    PGK: 'K', // Papua New Guinean Kina
    PHP: '₱', // Philippine Piso
    PKR: '₨', // Pakistani Rupee
    PLN: 'zł', // Polish Złoty
    PYG: '₲', // Paraguayan Guarani
    QAR: 'ر.ق', // Qatari Riyal
    RON: 'lei', // Romanian Leu
    RSD: 'дин.', // Serbian Dinar
    RUB: '₽', // Russian Ruble
    RWF: 'R₣', // Rwandan Franc
    SAR: 'ر.س', // Saudi Riyal
    SBD: 'SI$', // Solomon Islands Dollar
    SCR: '₨', // Seychellois Rupee
    SDG: 'ج.س.', // Sudanese Pound
    SEK: 'kr', // Swedish Krona
    SGD: 'S$', // Singapore Dollar
    SHP: '£', // Saint Helena Pound
    SLE: 'Le', // Sierra Leonean Leone
    SLL: 'Le', // Sierra Leonean Leone
    SOS: 'Sh', // Somali Shilling
    SRD: '$', // Surinamese Dollar
    SSP: 'SS£', // South Sudanese Pound
    STN: 'Db', // São Tomé and Príncipe Dobra
    SYP: '£S', // Syrian Pound
    SZL: 'E', // Swazi Lilangeni
    THB: '฿', // Thai Baht
    TJS: 'ЅМ', // Tajikistani Somoni
    TMT: 'm', // Turkmenistani Manat
    TND: 'د.ت', // Tunisian Dinar
    TOP: 'T$', // Tongan Paʻanga
    TRY: '₺', // Turkish Lira
    TTD: 'TT$', // Trinidad and Tobago Dollar
    TVD: 'T$', // Tuvalu Dollar
    TWD: 'NT$', // New Taiwan Dollar
    TZS: 'TSh', // Tanzanian Shilling
    UAH: '₴', // Ukrainian Hryvnia
    UGX: 'USh', // Ugandan Shilling
    USD: '$', // US Dollar
    UYU: '$U', // Uruguayan Peso
    UZS: 'soʻm', // Uzbekistani Som
    VES: 'Bs.', // Venezuelan Bolívar Soberano
    VND: '₫', // Vietnamese Đồng
    VUV: 'Vt', // Vanuatu Vatu
    WST: 'WS$', // Samoan Tala
    XAF: 'FCFA', // CFA Franc BEAC
    XCD: 'EC$', // East Caribbean Dollar
    XDR: 'SDR', // Special Drawing Rights
    XOF: 'CFA', // CFA Franc BCEAO
    XPF: 'CFP', // CFP Franc
    YER: '﷼', // Yemeni Rial
    ZAR: 'R', // South African
    ZMW: "ZK", // Zambian Kwacha
    ZWL: "Z$" // Zimbabwean Dollar
  };
  

  
  export function getCurrencySymbol(code) {
    return currencySymbols[code] || '?';
  }
  
  export function formatToCurrency(value, currencyCode) {
    const symbol = getCurrencySymbol(currencyCode);
    const decimals = (currencyCode == 'KRW' || currencyCode == 'JPY') ? 0 : 2;
  
    // Format the number with comma for thousands
    const formattedNumber = parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
  
    // Combine with currency symbol
    const formattedValue = `${symbol} ${formattedNumber}`;
    return formattedValue;
  }
  export function formatToCurrencyRate(value, currencyCode) {
    const symbol = getCurrencySymbol(currencyCode);
    const decimals = 6;
  
    // Format the number with comma for thousands
    const formattedNumber = (currencyCode == 'KRW' || currencyCode == 'JPY') ? parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }):
    parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: decimals });
  
    // Combine with currency symbol
    const formattedValue = `${symbol} ${formattedNumber}`;
    return formattedValue;
  }
  export function getStoreIcon(storeType) {
    var src;
    switch(storeType) {
      case STORE_TYPE.SHOPIFY:
      src=shopify;
      break;
      case STORE_TYPE.AMAZON:
      src=amazon;
      break;
      case STORE_TYPE.WOOCOMMERCE:
      src=woocommerce
      break;
      case STORE_TYPE.SHOPEE:
      src=shopee;
      break;
      case STORE_TYPE.SABANG_NET:
        src=sabang_net;
        break;
      case STORE_TYPE.TIKTOK:
        src=tiktok;
        break;
      case STORE_TYPE.SHEIN:
        src=shein;
        break;
      case STORE_TYPE.EBAY:
        src=ebay;
        break;
      case STORE_TYPE.FLIPSHOP:
        src=flipshop;
        break;
      case STORE_TYPE.UNKNOWN:
      src=unknown
      break;
    }
    return src;
  }
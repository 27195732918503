import React, { useContext, useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap"
import { changeCurrentOrganization } from "store/actions";
import i18n from "i18next"

const OrganizationMenu = props => {
  var womsUser = useSelector(state => state.womsUser, shallowEqual);
  var currentOrganizationId = useSelector(state => state.organization)?.id;
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();

  return (
    <Dropdown
              className="d-none d-lg-block ms-2"
              isOpen={menu}
              toggle={() => setMenu(!menu)}
            >
              {womsUser?.organizations?.length > 1 ? <><DropdownToggle
                className="btn header-item "
                caret
                tag="button"
              >
                {womsUser?.organizations?.find(o=> o.id== currentOrganizationId)?.legalName} <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg">
                <Row className="ps-3">
                  <Col sm={12}>
                        <h5 className="font-size-14 mt-2">
                          {i18n.t("YOUR_ORGANIZATIONS")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          
                        {womsUser?.organizations?.map(o=> <li key={o.id} role="button" onClick={() => {
                            dispatch(changeCurrentOrganization(o));
                            setMenu(false);
                            }}>{o.legalName}</li>)}
                          
                        </ul>
                  </Col>
                  

                </Row>
              </DropdownMenu></> :
              <span className="d-none d-lg-block ms-2 pt-4">{womsUser?.organizations?.find(o=> o.id== currentOrganizationId)?.legalName}</span>}
            </Dropdown>
  )
}
export default OrganizationMenu

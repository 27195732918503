import { UPDATE_UNREAD_COUNT } from "./actionTypes";

const initialState = {
  unread : 0
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_UNREAD_COUNT:
      if (state.unread == action.payload) return state;
      state = { ...state, unread: action.payload};
      break;
    
    default:
      break;
  }
  return state;
};

export default notification;

import React, { useContext, useEffect, useState } from "react"
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
import { WomsServiceContext } from "services/womsService";
import { useDispatch, useSelector } from "react-redux";
import { DASHBOARD_PERIOD_TYPE, DASHBOARD_TYPE } from "services/womsEnum";
import { updateStoreStatWidget } from "store/woms-dashboard/store-stat/actions";
import moment from "moment";

const StoreStatisticsWidget = (props) => {
  const dataColors ='["--bs-primary", "--bs-warning", "--bs-success"]' ;
  const stackedColumnChartColors = getChartColorsArray(dataColors);
  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1
      },
      zoom: {
        enabled: !0
      }
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%"
        // endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: !1
    },
    xaxis: {
      show: true,
      categories: [
      ],
      labels: {
        show: true
      }
    },
    colors: stackedColumnChartColors,
    legend: {
      position: "bottom"
    },
    fill: {
      opacity: 1
    }
  }

  const { params,forceUpdate } = props;
  const { womsSvc } = useContext(WomsServiceContext)
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [status, setStatus] = useState(false);
  const dispatch = useDispatch();
  const widget = useSelector(state => state.storeStatWidget);

  useEffect(() => {
      if (params) {
        womsSvc.requestDashboard( DASHBOARD_TYPE.STORE_STAT,params,false).then(({data}) => {
            dispatch(updateStoreStatWidget(data));
        });
      }
  }, [params]);
  useEffect(() => {
    if (forceUpdate > 0) {
      womsSvc.requestDashboard( DASHBOARD_TYPE.STORE_STAT,params,true).then(({data}) => {
          dispatch(updateStoreStatWidget(data));
      });
    }
}, [forceUpdate]);
  useEffect(() => {
    if (widget && widget.data && widget.data.id) {
          setStatus(widget.data.status)
          if (widget.data.payload) {
            var data = JSON.parse(widget.data.payload)
            // mod data here
            
              setData(data.statByStore);
              setCategory(data.category ? params?.periodType == DASHBOARD_PERIOD_TYPE.LAST_DAY ? data.category.map(entry => moment(entry).startOf('hour').fromNow()) : data.category.map(entry => moment(entry).format('ll')):[]);
          } else {
            setData([]);
          }
    }
}, [widget]);

  return (
    <>
      <ReactApexChart
        options={{
          ...options,
          xaxis: {
            show: true,
            categories: category,
        }}}
        series={[...data]}
        type="bar"
        height="359"
        className="apex-charts"
      />
    </>
  );
}

export default StoreStatisticsWidget;

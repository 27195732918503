import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { CHANGE_ORGANIZATION } from "./actionTypes"

export function* storeOrganizationChange({payload:organization}) {
    var current = undefined;
    try {
        current = JSON.parse(localStorage.getItem("currentOrganization"));
    } catch (err) {}

    if (!current || current.id != organization?.id) {
        localStorage.setItem("currentOrganization", JSON.stringify(organization));
        if (organization) location.reload();
    } else if (current && current.id == organization?.id) {
        // refresh the organization
        localStorage.setItem("currentOrganization", JSON.stringify(organization));
    }
}

export function* watchOrganizationChange() {
    yield takeEvery(CHANGE_ORGANIZATION, storeOrganizationChange)
  }

function* organizationSaga() {
    yield all([fork(watchOrganizationChange)])
}

export default organizationSaga

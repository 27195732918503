import { USER_ROLE, USER_STATUS } from "services/womsEnum";

  
  export function getStatusCssClasses(status) {
    var type;
    switch(status) {
      case USER_STATUS.ACTIVE:
        type="success"
        break;
        case USER_STATUS.INACTIVE:
        type="warning"
        break;
        case USER_STATUS.PENDING:
        type="secondary"
        break;
        case USER_STATUS.DELETED:
        type="danger"
        break;
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }

  export function getUserRoleCssClasses(role) {
    var type;
    switch(role) {
      case USER_ROLE.ORG_ADMIN:
        type="warning"
        break;
        case USER_ROLE.SYS_ADMIN:
        type="danger"
        break;
      default:
        type="primary"
    }

    return `badge badge-soft-${
      type
    }`;
  }
import { Button } from "reactstrap";

import i18n from 'i18next';

export const EditCancelButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>{i18n.t("CANCEL")}</Button>
  );
};
  
  
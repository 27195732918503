export function getIsActiveCssClasses(isActive) {
    var type;
    switch(isActive) {
      case true:
        type="success"
        break;
        case false:
        type="secondary"
        break;
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }
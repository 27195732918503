import React, { useContext, useEffect, useState } from 'react'
import {
    Button,
    Col,
    Row,
    Card,
    CardTitle,
    CardBody,
    Container,
    Modal,
    ModalBody,
    Badge
} from 'reactstrap'
import { withTranslation } from "react-i18next";
import EditButtonIcon from 'components/Common/EditButtonIcon';
import ProductTagModal from './ProductTagModal';
import stc from 'string-to-color';
import i18n from 'i18next';

const ProductTags = (props) => {
    const [show, setShow] = useState(false)
    const { isLoading,tags,productId,tagUpdated } = props;
    function showToggle(data) {
        if (data) tagUpdated(data);
        setShow(!show)
    }
    function onEditClick() {
        setShow(true)
    }

    const handleClose = (tags) => {
    }

    return (
        <>
            <Card className='rounded-4'>
                <CardBody>
                    <CardTitle className='mb-2'>
                        {i18n.t("PRODUCT_TAGS")}
                        <Button
                            disabled={isLoading}
                            type="button"
                            className="btn btn-light btn-sm woms-edit-btn"
                            onClick={onEditClick}
                        >
                            <i className="bx bx-pencil" />
                        </Button>
                    </CardTitle>
                    
                    {tags?.map((item, index) => {
                        return <span key={index} className={"badge badge-soft ms-1 p-1"} style={{"backgroundColor":stc(item.name)}}> {item.name}</span>
                    })}
                </CardBody>
            </Card>
            <ProductTagModal
                productTags={tags}
                productId={productId}
                show={show}
                setShow={showToggle}
                onClose={handleClose}
            />
        </>

    )
}

export default withTranslation()(ProductTags)
import React, { Fragment, useContext, useEffect,useState } from "react";
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
  } from '@tanstack/react-table'
import { Table, Row, Col, Button, Input, CardBody, Dropdown, ButtonDropdown, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown, DropdownItem, Spinner, Card, CardHeader } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import LinearProgress from "./LinearProgress";
import { withTranslation } from 'react-i18next';
import { WomsServiceContext } from "services/womsService";
import EntityFilterModal from "./EntityFilterModal";
import { ENTITY_FILTER_TYPE } from "services/womsEnum";
import i18n from "i18next"


// Define a default UI for filtering
function GlobalFilter({
  globalFilter,
  setGlobalFilter
}) {
  const [value, setValue] = useState(globalFilter);

  function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  const onChange = React.useCallback(debounce(value => {
    setGlobalFilter((value && value.length > 2) ? value : undefined);
  }, 200), []); // needed usecallback to prevent rerendering



  return (
    <>
      <Col xs={4} className="pl-2">
        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block mb-0">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label mb-1">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control form-control-sm"
                placeholder={i18n.t("SEARCH_DEFAULT_OPTION")}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon" style={{lineHeight:"28px"}}></i>
          </div>
        </div>

      </Col>

    </>
  );
}


const AdvancedTableContainer = ({
  columns,
  odataFetch,
  odataExport,
  entityNameForExport,
  odataLineExport,
  initialSortBy,
  isGlobalFilter,
  addButtonLabel,
  isExportButton,
  isLineItemExportCheckbox,
  onAddClick,
  customPageSize,
  className,
  customPageSizeOptions,
  filterColumns,
  fetchCondition,
  isCustomFilter,
  canAddFilter,
  selectedPopup,
  entityType,
  rerender
}) => {
  const [loading, setLoading] = React.useState(false);
  const [exporting, setExporting] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(customPageSize ?? 10);
  const [sorting, setSorting] = React.useState([]);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [filterValue, setFilterValue] = React.useState('');
  const [rowSelection, setRowSelection] = React.useState({});
  const [entityFilters, setEntityFilters] = React.useState([]);
  const [entityFilter, setEntityFilter] = React.useState('');
  const [isLineItemExport, setIsLineItemExport] = React.useState(false);
  const { womsSvc } = useContext(WomsServiceContext);

  const [filterModal,setFilterModal]= React.useState(false);
  //const rerender = React.useReducer(() => ({}), {})[1];


  const refreshTable = () => {
    setLoading(true);
    table.resetRowSelection();
    if (odataFetch) { 
      var filter = filterValue ? makeOdataFilter(filterValue,filterColumns) : "";
      filter = filter ? fetchCondition ? '(' + fetchCondition + ') and ' + filter : filter : fetchCondition;
      if (entityFilter) {
        filter = filter ? entityFilter.filterValue ? '(' + entityFilter.filterValue + ') and ' + filter : filter : entityFilter.filterValue;
      }
      odataFetch(pageSize, pageIndex * pageSize ,sorting[0]?.id ?? initialSortBy, sorting[0] ? (sorting[0].desc ? "desc" : "asc") : "desc" ,filter).then(({data}) => {
        setData(data.value);
        setTotalRows(data['@odata.count']);
        setTotalPage(Math.ceil(data['@odata.count']/pageSize));
        //rerender();


      }).finally(() => {
        setLoading(false);
      });
    }
  }

  const refreshEntityFilter = () => {
    if (isCustomFilter) {
      womsSvc.searchEntityFilter(100,0,"id","asc","entityType eq '" + entityType + "'").then(({data}) => {
        setEntityFilters(data.value);
      })
    }
  }


  useEffect(() => {
    refreshEntityFilter();
  }, [isCustomFilter]);

  useEffect(() => {
    refreshTable();
  }, [pageIndex, pageSize, sorting, filterValue,rerender,fetchCondition]);

  useEffect(() => {
    if (entityFilter) {
      if (pageIndex == 0) {
        refreshTable();
      } else {
        setPageIndex(0);
      }
    }
  }, [entityFilter]);

  useEffect(() => {
    if (filterValue) {
      if (pageIndex == 0) {
        refreshTable();
      } else {
        setPageIndex(0);
      }
    }
  }, [filterValue]);
  
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    enableRowSelection: true, //enable row selection for all rows
    onRowSelectionChange: setRowSelection,
    
    enableMultiRowSelection:true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    //getPaginationRowModel: getPaginationRowModel(),
    manualPagination:true,
  });
// Manage your own state
/*const [state, setState] = React.useState(table.initialState)
 // Override the state managers for the table to your own
 table.setOptions(prev => ({
    ...prev,
    state,
    onStateChange: setState,
  }))*/
  
  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    let value = parseInt(event.target.value);
    if (value < 1) value = 1;
    if (value > totalPage) value = totalPage;
    setPageIndex(value - 1);
  };

  const setServersideFilter = (value) => {
    setFilterValue(value);
  }

  const makeOdataFilter = (value, columns) => {
    // AdvancedTableContainer.js:212 Uncaught TypeError: Cannot read properties of undefined (reading 'map')
    // at makeOdataFilter (AdvancedTableContainer.js:212:1)
    // at refreshTable (AdvancedTableContainer.js:116:1)
    // at AdvancedTableContainer.js:148:1
    // at commitHookEffectListMount (react-dom.development.js:23150:1)
    // at commitPassiveMountOnFiber (react-dom.development.js:24926:1)
    // at commitPassiveMountEffects_complete (react-dom.development.js:24891:1)
    // at commitPassiveMountEffects_begin (react-dom.development.js:24878:1)
    // at commitPassiveMountEffects (react-dom.development.js:24866:1)
    // at flushPassiveEffectsImpl (react-dom.development.js:27039:1)
    // at flushPassiveEffects (react-dom.development.js:26984:1)
    let returnValue = columns
    .map(entry => `contains(${entry}, '${value}')`)
    .join(' or ');

    return returnValue;
  }

  const handleFilterModalClose = (data) => {
    setFilterModal(false);
    if(data) {
      setEntityFilter(data);
      refreshEntityFilter();
    }
  }

  const onExportClick = (isThisPageOnly, selectedRows) => {
    if (odataFetch) { 
      var filter = filterValue ? makeOdataFilter(filterValue,filterColumns) : "";
        filter = filter ? fetchCondition ? '(' + fetchCondition + ') and ' + filter : filter : fetchCondition;
        if (entityFilter) {
          filter = filter ? entityFilter.filterValue ? '(' + entityFilter.filterValue + ') and ' + filter : filter : entityFilter.filterValue;
        }


        if (selectedRows && selectedRows.length > 0) {
          filter = '';
          let filters = [];
          for (var row of selectedRows) {
              if (row && row.original && row.original.id) {
                  filters.push(`id eq ${row.original.id}`);
              }
          }
          filter += filters.join(' or ');
        }
      setExporting(true);
      if (isLineItemExport) {
        odataLineExport(isThisPageOnly ? pageSize : undefined, isThisPageOnly ? (pageIndex * pageSize) : undefined, sorting[0]?.id ?? initialSortBy, sorting[0] ? (sorting[0].desc ? "desc" : "asc") : "desc" ,filter).then((response) => {
          let filename = entityNameForExport + "LineExport_" + new Date().toISOString() + ".csv";
          const url = window.URL.createObjectURL(new Blob([response.data]));
          
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        }).finally(() => {
          setExporting(false);
        });
      } else {
        odataExport(isThisPageOnly ? pageSize : undefined, isThisPageOnly ? (pageIndex * pageSize) : undefined, sorting[0]?.id ?? initialSortBy, sorting[0] ? (sorting[0].desc ? "desc" : "asc") : "desc" ,filter).then((response) => {

          let filename = entityNameForExport + "Export_" + new Date().toISOString() + ".csv";
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        }).finally(() => {
          setExporting(false);
        });
      }

    }
  }

  const bottomPopupStyle = {
    position: 'fixed',
    bottom: table.getIsSomeRowsSelected() || table.getIsAllRowsSelected() ? '0' : '-250px', // Dynamic value based on state
    left:'48%',
    maxWidth:'800px',
    border : '1px solid #aaaaaa',
    borderRadius: '10px',
    transition: 'bottom 0.5s ease-in-out', // Animation effect
    zIndex: '100'
  };

  return (
    <Fragment>
      <Row className="pt-2">
        {isGlobalFilter && (
          <GlobalFilter
            globalFilter={filterValue}
            setGlobalFilter={setServersideFilter}
          />
        )}
        {(isExportButton || addButtonLabel)&& (
          <Col xs="8">
            <div className="text-end">
              
              {isExportButton  && <> <UncontrolledButtonDropdown>
                <DropdownToggle caret
                className={"btn-soft-primary btn-rounded btn-sm"}
                disabled={exporting}>
                  <div style={{display:'flex'}}><div>{i18n.t("EXPORT")}</div>{exporting && <Spinner color="primary" size={'sm'} className='ms-2'/>}</div>
                </DropdownToggle>
                <DropdownMenu>
                {isLineItemExportCheckbox && <div className="ps-2 m-2"><Input
                      name="isLineItemExport"
                      type="checkbox"
                      className="form-check-input"
                      checked={isLineItemExport}
                      onClick={e => {
                        setIsLineItemExport(!isLineItemExport);
                      }}
                    />
                <span className="ps-2">{i18n.t("EXPORT_LINE_ITEM")}</span></div>}
                
                  <DropdownItem onClick={ () => {
                    onExportClick();
                  }}>{i18n.t("EXPORT_ALL")}</DropdownItem>
                  <DropdownItem onClick={ () => {
                    onExportClick(true);
                  }}>{i18n.t("EXPORT_CURRENT_PAGE_ONLY")}</DropdownItem>
                  {<DropdownItem 
                  disabled={!table.getIsSomeRowsSelected()}
                  onClick={ () => {
                  onExportClick(false,table.getSelectedRowModel()?.rows);
                }}>{i18n.t("EXPORT_SELECTED")}</DropdownItem>}
                </DropdownMenu>
              </UncontrolledButtonDropdown>&nbsp;&nbsp;</>}
              {addButtonLabel && (
              <Button
                type="button"
                color="success"
                className="btn-rounded btn-sm ml-2"
                onClick={onAddClick}
              >
                <i className="mdi mdi-plus me-1" />
                {addButtonLabel}
              </Button>
        )}
            </div>
          </Col>
        )}
        
      </Row>
     { isCustomFilter && <Row>
        <Col>
        <div>
        
        <div className="btn-group mb-2">
                      <UncontrolledButtonDropdown>
                        <Button className={entityFilter ? "btn btn-sm btn-light"  : "btn btn-sm btn-secondary"} onClick={() => {
                          
                          setEntityFilter('');
                        }}>
                          {i18n.t("FILTER_ALL")}
                        </Button>
                      </UncontrolledButtonDropdown>
                    </div>
                    {entityFilters.map(filter => {
                      return <div className="btn-group mb-2 px-2" key={filter.id}>
                      <UncontrolledButtonDropdown>
                        <Button className={entityFilter?.id == filter.id ? "btn btn-sm btn-secondary"  : "btn btn-sm btn-light"} onClick={() => {
                          setEntityFilter(filter);
                        }}>
                         {filter.description}
                        </Button>
                        {filter.userId && <> <DropdownToggle caret
                        className={entityFilter?.id == filter.id ? "btn btn-sm btn-secondary"  : "btn btn-sm btn-light"}>
                          <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={ () => {
                              setEntityFilter(filter);
                              setFilterModal(true);
                          }}>{i18n.t("RENAME")}</DropdownItem>
                          <DropdownItem onClick={ () => {
                            womsSvc.deleteEntityFilter(filter.id).then(() => {
                              setEntityFilter('');
                              refreshEntityFilter();
                            });
                          }}>{i18n.t("DELETE")}</DropdownItem>
                        </DropdownMenu></>}
                      </UncontrolledButtonDropdown>
                    </div>
                    })}
                    <div className="btn-group mb-2 px-1">
              {canAddFilter && <Button
                type="button"
                className="btn-light btn-rounded btn-sm"
                onClick={() => {
                  setEntityFilter('');
                  setFilterModal(true);
                }}
              >
                <i className="mdi mdi-plus" />
              </Button>}
              </div>
        </div>
        </Col>
      </Row>}
      {loading && <LinearProgress/>}
      <div className="table-responsive react-table" style={{overflow:"visible"}}>
        <Table bordered hover className={className}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder ? null : (
                  <div
                    {...{
                      className: header.column.getCanSort()
                        ? 'cursor-pointer select-none'
                        : '',
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: <i className="mdi mdi-arrow-up-thick px-1" />,
                      desc:  <i className="mdi mdi-arrow-down-thick px-1" />,
                    }[header.column.getIsSorted()] ?? null}
                  </div>
                )}
              </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        </Table>
      </div>
      <Row className="justify-content-md-end">
      <Col md={customPageSizeOptions ? 3 : 2}>
          <select
            className="form-select form-select-sm"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize} {i18n.t("PER_PAGE")}
              </option>
            ))}
          </select>
        </Col>
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              className="btn-sm"
              onClick={() => setPageIndex(0)}
              disabled={pageIndex == 0}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              className="btn-sm"
              onClick={() => setPageIndex(pageIndex - 1)}
              disabled={pageIndex==0}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          {i18n.t("PAGE")}{" "}
          <strong>
            {/*{pageIndex + 1} of {pageOptions.length}*/}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            className="form-control form-control-sm"
            min={1}
            style={{ width: 70 }}
            max={totalPage}
            value={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" 
            className="btn-sm"
            onClick={() => setPageIndex(pageIndex + 1)} disabled={pageIndex == (totalPage -1)}>
              {">"}
            </Button>
            <Button
              color="primary"
              className="btn-sm"
              onClick={() => setPageIndex(totalPage - 1)}
              disabled={pageIndex == (totalPage -1)}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
      <EntityFilterModal
                show={filterModal}
                entityFilter={entityFilter}
                onClose={handleFilterModalClose}
                entityType={ENTITY_FILTER_TYPE.SALES_ORDER}
      />
      <Row className="justify-content-center">
        <Col>
        <Card style={bottomPopupStyle}>
    <CardBody className="py-3 px-3">{selectedPopup && selectedPopup(table.getSelectedRowModel().rows)}</CardBody>
  </Card>
        </Col>
      </Row>

    </Fragment>
  );
};


export default withTranslation()(AdvancedTableContainer);
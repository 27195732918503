import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Card,
  CardBody,
  CardTitle,
  Table
} from "reactstrap"
import i18n from 'i18next';
import cf from "../../services/codeFormatter";
import { WomsServiceContext } from "services/womsService";
import Moment from 'react-moment';
import { generateTrackingUrl, getFulfillmentStatusCssClasses } from "./FulfillmentUIHelper";
import { formatToCurrency } from "pages/Order/OrderUIHelper";
const FulfillmentDetailModal = props => {
  const { show, isEdit, id, onClose } = props;
  const { womsSvc } = useContext(WomsServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [fulfillment, setFulfillment] = useState({});
  

  useEffect(() => {
    if (show) {
      
      if (id) {
        setIsLoading(true);
        womsSvc.getFulfillment(id).then(({ data }) => {
          setFulfillment(data);
          setIsLoading(false);
        });
      } 
    } else {
      setFulfillment({});
    }
  }, [show]);

  const refreshBrandList = () => {
    setIsLoading(true);
    womsSvc.getBrands().then(({ data }) => {
      setBrandList(data);
      setIsLoading(false);
    });
  }

  const handleBrandModalClose = (isUpdated) => {
    if (isUpdated) {
      refreshBrandList();
    }
    setBrandModal(false);
  }

  return (<>
    <Modal isOpen={show} size="lg">
      <ModalHeader tag="h4">
        {i18n.t("FULFILLMENT_DETAIL")} <small><span className={getFulfillmentStatusCssClasses(fulfillment.status)}>{cf.format("FulfillmentStatus",fulfillment.status)}</span></small>
      </ModalHeader>
      
        <ModalBody>
          <Row>
           <Col>
           
                            <Row className='mb-3'>
                                <Col>
                                    <span className="fw-bold">{i18n.t("REFERENCE_NUMBER")}</span>
                                    <div>{fulfillment.referenceNo}</div>

                                </Col>

                                <Col>
                                    <Row>
                                        <Col>
                                            <span className="fw-bold">{i18n.t("LOCATION")}</span>
                                            <div>{fulfillment?.location?.name}</div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <span className="fw-bold">{i18n.t("TRACKING_NO")}</span>
                                    <div> <a href={generateTrackingUrl(fulfillment.trackingNo)} target="_blank" rel="noopener noreferrer">{fulfillment.trackingNo}</a> </div>
                                </Col>
                                <Col>
                                    <span className="fw-bold">{i18n.t("SHIPPED_ON")}</span>
                                    <div>{fulfillment.shippedOn ? <Moment format='lll'>{fulfillment.shippedOn}</Moment> : '-'}</div>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <span className="fw-bold">{i18n.t("NOTE")}</span>
                                    <div> {fulfillment.note ? fulfillment.note : 'N/A'} </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <Table className="table table-centered table-wrap">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="fw-bold">{i18n.t("SKU")}</th>
                                                        <th scope="col" className="fw-bold">{i18n.t("PRODUCT_NAME")}</th>
                                                        <th scope="col" className="fw-bold">{i18n.t("FULFILLED_QUANTITY")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fulfillment.fulfillmentLineItems?.map(item => (
                                                        <tr key={item.id}>
                                                            <th>
                                                                <div>
                                                                    {item.orderLineItem.storeListing.storeSku}
                                                                </div>
                                                            </th>
                                                            <td>
                                                                <div>
                                                                    {item.orderLineItem.storeListing.name}
                                                                </div>
                                                            </td>
                                                            <td>
                                                            {item.quantity} of {item.orderLineItem.quantity}
                                                            </td>

                                                        </tr>
                                                    ))}

                                                    <tr>
                                                        <td colSpan="1">
                                                            <h6 className="m-0 text-end fw-bold">{i18n.t("FULFILLMENT_COST")}:</h6>
                                                        </td>
                                                        <td colSpan="2">
                                                        {fulfillment.costLineItems?.map(item => (
                                                        <div className="text-end" key={item.id}>
                                                                    {item?.name} {formatToCurrency(item.amount,fulfillment.currency)}

                                                        </div>
                                                    ))}

                                                        </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td colSpan="3">
                                                            <div><h6 className="m-0 text-end fw-bold">{i18n.t("PRICE_TOTAL")}:
                                                            {formatToCurrency(fulfillment.costLineItems?.reduce((accumulator, item) => accumulator + item.amount, 0), fulfillment.currency)}</h6></div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                </Col>
                            </Row>
                        
            </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col md="12">
              <div className="text-end mt-2">
                <button type="button" className="btn btn-secondary" onClick={() => onClose()} disabled={isLoading}>{i18n.t("CLOSE")}</button>
              </div>
            </Col>
          </Row>
        </ModalFooter>
    </Modal>
      </>
  )
}
export default FulfillmentDetailModal;
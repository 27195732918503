import React, { useEffect, useState, useMemo, useContext } from "react"

import i18n from "i18next"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { WomsServiceContext } from "services/womsService"
import { refreshWomsUser } from "store/actions"
import { useNavigate } from "react-router-dom"
import firebase from 'firebase/compat/app';
const NewUser = props => {
  const { womsSvc } = useContext(WomsServiceContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
    // Effect that sets up the auth listener
    useEffect(() => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
        if (user) {
          if (user.emailVerified) {
            womsSvc.me().then(({ data }) => {
              dispatch(refreshWomsUser(data));
              navigate('/dashboard');
            }).catch(error => {
              console.error('Failed to fetch user details:', error);
              // Handle errors e.g., show a notification, etc.
            });
          } else {
            navigate('/emailVerification');
          }
        } else {
          // Handle case when there is no user logged in, if necessary.
          navigate('/login'); // or desired handling when no user
        }
      });
  
      // Cleanup subscription on component unmount
      return () => unsubscribe();
    }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
        </Container>
      </div>
    </>
  )
}

export default NewUser

import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip
} from "reactstrap"
import { useFormik, useFormikContext } from "formik"
import * as Yup from "yup";
import i18n from 'i18next';
import { WomsServiceContext } from "services/womsService";
import { CURRENCY_CODE, STORE_TYPE, STORE_SYNC_TYPE, BATCH_REQUEST_TYPE, PRODUCT_SYNC_TYPE } from "services/womsEnum";

const SyncModal = props => {
  const { show, infos, onClose } = props;
  const { womsSvc } = useContext(WomsServiceContext);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      syncType: PRODUCT_SYNC_TYPE.CREATE_NEW,
    },
    validationSchema: Yup.object({
      syncType: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
    }),

    onSubmit: (values) => {

      var request = {
        type:BATCH_REQUEST_TYPE.PRODUCT_SYNC,
        payLoad : JSON.stringify({storeListingInfos: infos, syncType: values.syncType})
      }
        womsSvc.createBatchRequest(request).then(({ data }) => {
          formik.resetForm();
          onClose(true);
        });
    },
  });
  return (
    <Modal isOpen={show} size="lg">
      <ModalHeader tag="h4">
        {i18n.t("SYNC_REQUEST")}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <ModalBody>
          <Row>
            <Col md="12">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("PRODUCT_SYNC_TYPE")}</Label>

                <Input
                  name="syncType"
                  type="select"
                  className="form-select"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.syncType || ""
                  }
                  invalid={
                    formik.touched.syncType && formik.errors.syncType ? true : false
                  }
                >
                  <option value="">{i18n.t("SELECT_SYNC_TYPE")}</option>
                  <option value={PRODUCT_SYNC_TYPE.CREATE_NEW}>{i18n.t("PRODUCT_SYNC_TYPE.NEW")}</option>
                  {/*<option value={PRODUCT_SYNC_TYPE.REPLACE}>{"Replace : Replace existing product data"}</option>*/}
                  <option value={PRODUCT_SYNC_TYPE.MERGE}>{i18n.t("PRODUCT_SYNC_TYPE.MERGE")}</option>
                </Input>
                {formik.touched.syncType && formik.errors.syncType ? (
                  <FormFeedback type="invalid">{formik.errors.syncType}</FormFeedback>
                ) : null}
              </div>
            </Col>
            
          </Row>
          
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col md="12">
              <div className="text-end mt-2">
                <button type="button" className="btn btn-secondary" onClick={() => onClose()} disabled={formik.isSubmitting}>{i18n.t("CANCEL")}</button>
                &nbsp;&nbsp;
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={formik.isSubmitting}
                >
                  {i18n.t("SUBMIT")}
                </button>
              </div>
            </Col>
          </Row>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
export default SyncModal;
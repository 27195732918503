import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { WomsServiceContext } from "services/womsService"
import { CURRENCY_CODE } from "services/womsEnum"
import Moment from "react-moment"
import { CountryDropdown } from "react-country-region-selector"
import i18n from "i18next";

const OrganizationModal = props => {
  const { show, isEdit, id, onClose, errorMessage } = props
  const { womsSvc } = useContext(WomsServiceContext)
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      status : "ACTIVE",
      legalName: "",
      localizedLegalName: "",
      baseCurrency: "",
      country:"US",
      idNumber: "",
      createdOn: null,
    },
    
    validationSchema: Yup.object({
      legalName: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      baseCurrency: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      baseCurrency: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      country: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
    }),

    onSubmit: values => {
      if (values.country == 'US') values.localizedLegalName = '';

      if (isEdit) {
        womsSvc.updateOrganization(values).then(({ data }) => {
          formik.resetForm()
          onClose(true)
        })
      } else {
        womsSvc.createOrganization(values).then(({ data }) => {
          formik.resetForm()
          onClose(true)
        })
      }
    },
  })

  useEffect(() => {
    if (show) {
      if (id) {
        setIsLoading(true)
        womsSvc.getOrganization(id).then(({ data }) => {
          formik.setValues(data)
        })
        setIsLoading(false)
      }
    } else {
      formik.resetForm();
    }
  }, [show])

  return (
    <Modal isOpen={show} size="lg">
      <ModalHeader tag="h4">
        {isEdit ? i18n.t("EDIT_ORGANIZATION") : i18n.t("ACCOUNT_REGISTRATION")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            formik.handleSubmit()
            return false
          }}
        >
          <Row className="mb-3">
              <Col>
              <div className="text-success"> {i18n.t("WORLDSHOPPE_OMS_WELCOME_MESSAGE")} <br/> {i18n.t("ACCOUNT_REGISTER_INSTRUCTION_MESSAGE")}</div>
              </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("LEGAL_NAME")}</Label>
                <Input
                  name="legalName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.legalName ? formik.values.legalName : ""}
                  invalid={
                    formik.touched.legalName && formik.errors.legalName
                      ? true
                      : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.legalName && formik.errors.legalName ? (
                  <FormFeedback type="invalid">
                    {formik.errors.legalName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md="6">
              {formik.values.country != 'US' && <div className="mb-3">
                <Label className="form-label">{i18n.t("LOCALIZED_LEGAL_NAME")}</Label>
                <Input
                  name="localizedLegalName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.localizedLegalName
                      ? formik.values.localizedLegalName
                      : ""
                  }
                  invalid={
                    formik.touched.localizedLegalName &&
                    formik.errors.localizedLegalName
                      ? true
                      : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.localizedLegalName &&
                formik.errors.localizedLegalName ? (
                  <FormFeedback type="invalid">
                    {formik.errors.localizedLegalName}
                  </FormFeedback>
                ) : null}
              </div>}
            </Col>
          </Row>
          <Row>
          <Col md="4">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("COUNTRY")}</Label>
                <CountryDropdown
                  name="country"
                  value={formik.values.country ? formik.values.country : ""}
                  classes={
                    "select-country form-control" +
                    (formik.touched.country && formik.errors.country
                      ? " is-invalid"
                      : "")
                  }
                  valueType="short"
                  priorityOptions={["US", "KR", "CN"]}
                  onChange={value => {
                    formik.setFieldValue("country", value)
                  }}
                  onBlur={formik.handleBlur}
                  disabled={isLoading}
                  defaultOptionLabel={i18n.t("SELECT_COUNTRY")}
                />
                {formik.touched.country && formik.errors.country ? (
                  <FormFeedback type="invalid">
                    {formik.errors.country}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md="3">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("BASE_CURRENCY")}</Label>
                <Input
                  name="baseCurrency"
                  type="select"
                  className="form-select"
                  onChange={formik.handleChange}
                  disabled={isEdit}
                  onBlur={formik.handleBlur}
                  value={formik.values.baseCurrency || ""}
                  invalid={
                    formik.touched.baseCurrency && formik.errors.baseCurrency
                      ? true
                      : false
                  }
                >
                  <option value={''}>{i18n.t("SELECT_CURRENCY")}</option>
                  <option value={CURRENCY_CODE.USD}>USD</option>
                  <option value={CURRENCY_CODE.KRW}>KRW</option>
                  <option value={CURRENCY_CODE.CNY}>CNY</option>
                </Input>
                {formik.touched.baseCurrency && formik.errors.baseCurrency ? (
                  <FormFeedback type="invalid">
                    {formik.errors.baseCurrency}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md="5">
              <div className="mb-3">
                <Label className="form-label"> {i18n.t("ID_NUMBER")} ({i18n.t("FORM_OPTIONAL")})</Label>
                <Input
                  name="idNumber"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.idNumber ? formik.values.idNumber : ""}
                  invalid={
                    formik.touched.idNumber && formik.errors.idNumber
                      ? true
                      : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.idNumber && formik.errors.idNumber ? (
                  <FormFeedback type="invalid">
                    {formik.errors.idNumber}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            
          </Row>
          <Row>
            <Col md="12">
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => onClose()}
                  disabled={isLoading}
                >
                  {i18n.t("CANCEL")}
                </button>
                &nbsp;&nbsp;
                <button
                  type="submit"
                  className="btn btn-success save-integration"
                  disabled={isLoading}
                >
                  {i18n.t("SAVE")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}
export default OrganizationModal

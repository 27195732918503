import React, { useEffect, useState, useMemo, useContext } from "react";
import Moment from 'react-moment';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import AdvancedTableContainer from "components/Common/AdvancedTableContainer";
import { getStatusCssClasses } from "./BundleUIHelper";
import { WomsServiceContext } from "services/womsService";
import cf from "../../services/codeFormatter";
import i18n from 'i18next';
import { withTranslation } from "react-i18next";
import BundleModal from "./BundleModal";

// Column
const Id = (cell) => {
  return cell.getValue() ? "#BD" + cell.getValue() : '';
};
const Sku = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};
const Name = (cell) => {
  return cell.getValue() ? cell.getValue() : '';
};
const BundleType = (cell) => {
  return cell.getValue() ? cf.format("BundleType", i18n.t(cell.getValue())) : '';
};
const Status = cell => {
  return cell.getValue() ? (
    <span className={getStatusCssClasses(cell.getValue())}>
      {cf.format("BundleStatus", i18n.t(cell.getValue()))}
    </span>
  ) : (
    ""
  )
}
const CreatedOn = (cell) => {
  return cell.getValue() ? <Moment format='ll'>{cell.getValue()}</Moment> : '';
};

const BundleList = (props) => {

  //meta title
  document.title = i18n.t("BUNDLES");
  const { womsSvc } = useContext(WomsServiceContext);

  const handleEntityClick = arg => {

  };

  // Bundle Column
  const columns = useMemo(
    () => [
      {
        header: i18n.t("ID"),
        accessorKey: 'id',
        filterable: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Id {...cellProps} />;
        }
      },
      {
        header: 'SKU',
        accessorKey: 'sku',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <Sku {...cellProps} />;
          ;
        }
      },
      {
        header: i18n.t('NAME'),
        accessorKey: 'name',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <Name {...cellProps} />;
          ;
        }
      },
      {
        header: i18n.t('BUNDLE_TYPE'),
        accessorKey: 'bundleType',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <BundleType {...cellProps} />;
        }
      },
      {
        header: i18n.t('STATUS'),
        accessorKey: 'status',
        enableSorting: true,
        filterable: false,
        cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
      {
        header: i18n.t('CREATED_ON'),
        accessorKey: 'createdOn',
        enableSorting: true,
        cell: (cellProps) => {
          return <CreatedOn {...cellProps} />;
        }
      },
      {
        header: i18n.t('ACTION'),
        enableSorting: false,
        cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onEditClick(entityData);
                  }
                  }
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                  {i18n.t('EDIT')}
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    {i18n.t('EDIT')}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original;
                    onDeleteClick(entityData);
                  }}>
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                  {i18n.t('DELETE')}
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {i18n.t('DELETE')}
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }
      },
    ],
    [i18n.language]
  );
  //delete 
  const [deleteModal, setDeleteModal] = useState(false);
  const [entityData, setEntityData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [rerender, setRerender] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const onDeleteClick = (entityData) => {
    setEntityData(entityData);
    setDeleteModal(true);
  };

  const onEditClick = (entityData) => {
    setEntityData(entityData);
    setErrorMessage("");
    setIsEdit(true);
    setModal(true);
  };

  const handleDeleteEntity = () => {
    if (entityData && entityData.id) {
      womsSvc.deleteBundle(entityData.id).then((data) => {
        setRerender(rerender + 1);
        setDeleteModal(false);
      });
    }
  };

  const handleAddEntity = () => {
    setEntityData(null);
    setErrorMessage("");
    setIsEdit(false);
    setModal(true);
  };

  const handleModalClose = (isUpdated) => {
    if (isUpdated) {
      setRerender(rerender + 1);
    }
    setModal(false);

  };

  useEffect(() => {

  }, []);

  const selectedPopup = () => {
    return <></>
  }

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEntity}
        errorMessage={errorMessage}
        entityName={i18n.t("BUNDLE")}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs altTitle={i18n.t("BUNDLE_LIST")} title={i18n.t("BUNDLE")} breadcrumbItem={i18n.t("LIST")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AdvancedTableContainer
                    columns={columns}
                    odataFetch={womsSvc.searchBundle}
                    //odataExport={womsSvc.exportOrder}
                    //fetchCondition={storeId ? "storeId eq "+ storeId : ""}
                    initialSortBy={"createdOn"}
                    isGlobalFilter={true}
                    //filterColumns={["orderNo","referenceNo","shippingAddress/firstName","shippingAddress/lastName","billingAddress/firstName","billingAddress/lastName"]}
                    addButtonLabel={i18n.t("ADD_NEW_BUNDLE")}
                    //isExportButton={true}
                    onAddClick={handleAddEntity}
                    customPageSize={20}
                    //isCustomFilter={true}
                    //entityType={ENTITY_FILTER_TYPE.SALES_ORDER}
                    className="table-advanced"
                    rerender={rerender}
                    selectedPopup={selectedPopup}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <BundleModal
        show={modal}
        isEdit={isEdit}
        id={entityData?.id}
        onClose={handleModalClose}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default withTranslation()(BundleList);

export function downloadCSV (objArray, fileName) {
  const csvString = convertToCSV(objArray);
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", fileName+".csv");
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}


export function convertToCSV(objArray) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  
  // Extract headers (keys)
  const headers = Object.keys(array[0]).join(',') + '\r\n';
  str += headers;

  // Map over the rows
  array.forEach(item => {
    const row = Object.values(item).join(',');
    str += row + '\r\n';
  });

  return str;
}

import React from 'react';

function LinearProgress() {
    return (
        <div className="linear-progress-container">
            <div className="linear-progress" />
        </div>
    );
}

export default LinearProgress;

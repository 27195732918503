import React, { 
  useCallback,
  useEffect,
  useState, 
} from 'react';

import { 
  Button, 
  Input, 
} from 'reactstrap';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { formatToCurrency } from '../OrderUIHelper';
import ControlledTypehead from 'components/Common/ControlledTypehead';
import CurrencyInput from 'react-currency-input-field';
import { nanoid } from 'nanoid';
import { CLIENT_SYNC_TYPE, ITEM_VALUE_CHANGE_TYPE } from 'services/womsEnum';
import { Menu, MenuItem } from 'react-bootstrap-typeahead';
import i18n from 'i18next';

const ItemThumbnailCell = ({
  item,
  thumbnails
}) => {
  return (
    <th className={`${item.syncType === CLIENT_SYNC_TYPE.DELETED ? "opacity-30" : "opacity-100"} align-middle`} scope="row">
      <div className='d-flex justify-content-center align-items-center border border-4 border-light rounded p-1 h-10 w-10'>
        {thumbnails.length > 0 && thumbnails.find(t=> t.sku ==  item.storeListing.storeSku) ? <img src={thumbnails.find(t=> t.sku ==  item.storeListing.storeSku).thumbnailUrl}/> : <i className="bx bx-image-alt woms-product-placeholder" />}
      </div>
    </th>
  );
};

const ItemTypeaheadCell = ({
  item,
  womsSvc,
  handleItemChange,
  storeListingFilter
}) => {

  const [thumbnails, setThumbnails] = useState([]);
  const [prevResults, setPrevResults] = useState([]);

  const handleThumbnails = async(results) => {
    const newResults = results.filter(result => result.storeSku && !prevResults.find(r => r.storeSku === result.storeSku));

    if (newResults.length > 0) {
      const {data} = await womsSvc.getThumbnails(newResults.map(result => result.storeSku));
      setThumbnails([...thumbnails, ...data.value]);
      setPrevResults(results);
    }
  };

  const renderThumbnail = (result) => {
    if(thumbnails.find(tn => result.storeSku === tn.sku)?.thumbnailUrl) {
      return (
        <div className='' style={{ height: '32px', width: '32px'}}>
          <img className='' style={{ objectFit: "contain", objectPosition: "center", height: '32px', width: '32px'}} src={thumbnails.find(tn => result.storeSku === tn.sku)?.thumbnailUrl}/>

        </div>
      )
    } 
    return <i className="bx bx-image-alt woms-product-placeholder d-flex justify-content-center align-items-center" style={{ objectFit: "contain", objectPosition: "center", height: '32px', width: '32px'}}/>
  };

  
  return (
    <td className={`${item.syncType === CLIENT_SYNC_TYPE.DELETED ? "opacity-25" : "opacity-100"} w-100 align-middle`} colSpan={2} >
      <div className='h-100 w-100 d-flex align-items-center'>
        <ControlledTypehead
          className='w-100 typehead oli-row-element'
          odataFetch={womsSvc.searchStoreListing}
          disabled={item.syncType === CLIENT_SYNC_TYPE.DELETED}
          value={item.storeListing.id}
          filterColumn = {["storeSku", "name"]}
          labelKey= {(option) => `${option["storeSku"]} | ${option["name"]}`}
          valueType={"OBJECT"}
          valueSelected = {(sListingItem) => {
            handleItemChange(item, sListingItem)
          }}
          additionalFilter={" and " + storeListingFilter}
          renderMenu={(results) => {
            handleThumbnails(results);
            return (
              <Menu id='typeahead-menu' className={`max-1000px:w-100  item-typeahead-menu-style d-flex flex-column`} >
                {results.map((result, index) => (
                  <MenuItem
                    key={index}
                    className={`rbt-menu-item ${(!result.label && result.paginationOption) && "border-top"} d-flex p-2 gap-4 align-items-start text-wrap oli-typeahead-menu-item `}
                    option={result}
                    position={index}
                  >
                    {
                      result.id && 
                      <>{renderThumbnail(result)}</>
                    }
                    
                    {
                    result.id 
                    ? <span className='w-fit'>{result.storeSku} | {result.name}</span>
                    : <span>Show More...</span> 
                    }
                  </MenuItem>
                ))}
              </Menu>
            )
          }}
        />
      </div>
    </td>
  );
};

const ItemPriceCell = ({
  item,
  handleValueChange,
  order
}) => {
  return (
    <td className={`${item.syncType === CLIENT_SYNC_TYPE.DELETED ? "opacity-25" : "opacity-100"} h-100 align-middle`}>
      
      <div className='d-flex gap-1 align-items-center  h-100 oli-price-container '>
        {/*formatToCurrency(item.price * item.quantity, order.currency).split(" ")[0]*/}
        
        <CurrencyInput 
        className='p-2 border overflow-x-auto input-border-color text-end rounded-1 oli-row-element'
        disabled={item.syncType === CLIENT_SYNC_TYPE.DELETED} 
        name="price" 
        defaultValue={item.price}
        //decimalSeparator="."
        //groupSeparator=","
        intlConfig={{ locale: i18n.language, currency: order.currency }}
        //decimalsLimit={2}
        //decimalScale={2}
        onValueChange={(_,__, order) => {
          handleValueChange(item, order ? order.float : 0.00, ITEM_VALUE_CHANGE_TYPE.PRICE);
        }}
        />
      </div>
    </td>
  );
};

const ItemXCell = ({
  item,
  itemIsMarkedDelete
}) => {
  return (
    <td className={`${item.syncType === CLIENT_SYNC_TYPE.DELETED ? "opacity-25" : "opacity-100"} align-middle`}>
      <div className='d-flex align-items-center p-2 oli-row-element' >
        <span>x</span>
      </div>
    </td>
  );
};

const ItemQuantityCell = ({
  item,
  handleValueChange,
}) => {
  return (
    <td className={`${item.syncType === CLIENT_SYNC_TYPE.DELETED ? "opacity-25" : "opacity-100"} align-middle quantity-input`} >
      <Input 
      className='p-2 text-end input-border-color rounded oli-row-element ' 
      type='number' pattern="[0-9]{10}" 
      disabled={item.syncType === CLIENT_SYNC_TYPE.DELETED} 
      value={item.quantity} 
      min="0" 
      step="1" 
      onChange={(e)=> handleValueChange(item, Number(e.target.value), ITEM_VALUE_CHANGE_TYPE.QUANTITY)} 
      />
    </td>
  );
};  

const ItemPriceXQuantityCell = ({
  item,
  order
}) => {
  return (
    <td className={`${item.syncType === CLIENT_SYNC_TYPE.DELETED ? "opacity-25" : "opacity-100"} align-middle text-nowrap`}>
      {formatToCurrency(item.price * item.quantity, order.currency)} 
      {/*<Input 
      readOnly
      className='oli-price-x-quantity-input w-auto p-2 text-end border-0 oli-row-element text-wrap' 
      type='text' 
      value={formatToCurrency(item.price * item.quantity, order.currency)} 
      />*/}
    </td>
  );
};

const ItemDeleteButttonCell = ({
  item,
  handleItemDelete,
}) => {

  return (
    <td className='align-middle'>
      <Button 
      className={`${item.syncType === CLIENT_SYNC_TYPE.DELETED ? "border-light-subtle" : "border-light"} border bg-white w-auto text-black d-flex align-items-center justify-content-center rounded-2 oli-row-element`}
      onClick={()=> handleItemDelete(item)}
      >
        {
          item.syncType === CLIENT_SYNC_TYPE.DELETED 
          ?
          <RestoreFromTrashIcon className='text-success' fontSize='inherit'/>
          :
          <DeleteOutlineIcon fontSize='inherit'/>

        }
      </Button>
    </td>
  );
};

export const ItemRow = ({ 
  item, 
  handleItemChange, 
  handleValueChange,
  handleItemDelete, 
  thumbnails, 
  order, 
  womsSvc, 
  storeListingFilter
}) => {

  const defaultOliPriceModItem = {
    id: nanoid(),
    amount: 0.00,
    name: "",
    orderLineItemId: item.id,
  };

  return (

    <tr
    key={item.id}
    className={`align-items-center h-28`}
    >
      <ItemThumbnailCell
      item={item}
      thumbnails={thumbnails}
      />
      <ItemTypeaheadCell
      item={item}
      womsSvc={womsSvc}
      handleItemChange={handleItemChange}
      storeListingFilter={storeListingFilter}
      />

      <ItemPriceCell 
      item={item}
      handleValueChange={handleValueChange}
      order={order}
      />
      
      <ItemXCell 
      item={item}
      />

      <ItemQuantityCell 
      item={item}
      handleValueChange={handleValueChange}
      />

      <ItemPriceXQuantityCell 
      item={item}
      order={order}
      />

      <ItemDeleteButttonCell 
      item={item}
      handleItemDelete={handleItemDelete}
      />

    </tr>
  );
};



import React from "react"

import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"
import { useSelector } from "react-redux"
import Avatar from "react-avatar"
import { getUserRoleCssClasses } from "pages/User/UserUIHelper"
import cf from "../../services/codeFormatter"
import i18n from "i18next"


const WelcomeComp = () => {

  const womsUser = useSelector(state => state.womsUser);
  const organization = useSelector(state => state.organization);
  return (
    <>
      <Card className="">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">{i18n.t("DASHBOARD.WELCOME_BACK")}!</h5>
                <p>{i18n.t("DASHBOARD.WORLDSHOPPE_OMS")}</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-2">
              <Avatar name={womsUser?.firstName + " " + womsUser?.lastName} src={womsUser?.imageUrl} size="60" round="30px" />
              </div>
              <h5 className="font-size-15 text-truncate">{womsUser?.firstName + " " + womsUser?.lastName}</h5>
              {womsUser?.userRoles && womsUser?.userRoles.filter(u => !u.organizationId || u.organizationId== organization.id).length ==0 ? <span className="badge badge-soft-primary">{i18n.t("ROLE.USER")}</span> : womsUser?.userRoles?.filter(u =>  !u.organizationId || u.organizationId== organization.id).map(r => <span className={getUserRoleCssClasses(r.role) + " me-2"} key={r.id}>
                  {cf.format("UserRole",r.role)}
                </span>)}
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">-</h5>
                    <p className="text-muted mb-0">{i18n.t("DASHBOARD.NOTIFICATIONS")}</p>
                  </Col>
                  <Col xs="6" className="text-muted">
                    <h5 className="font-size-15">-</h5>
                    <p className="text-muted mb-0">{i18n.t("DASHBOARD.TASKS")}</p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <button
                    className="btn btn-primary  btn-sm"
                    disabled={true}
                  >
                    {i18n.t("DASHBOARD.VIEW_NOTIFICATIONS")} <i className="mdi mdi-arrow-right ms-1"></i>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
export default WelcomeComp

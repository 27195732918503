import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { WomsServiceContext } from "services/womsService"
import ControlledTypehead from "components/Common/ControlledTypehead"
import { CountryDropdown, RegionDropdown } from "react-country-region-selector"
import i18n from "i18next"
import { withTranslation } from "react-i18next"
import Moment from "react-moment"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/bootstrap.css"
import { useSelector } from "react-redux"

const LocationModal = props => {
  const { show, isEdit, id, onClose, errorMessage } = props
  const { womsSvc } = useContext(WomsServiceContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isDeveloper, setIsDeveloper] = useState(true); //뭐지 이건
  var currentOrganizationId = useSelector(state => state.organization)?.id;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      name: "",
      city: "",
      country: "",
      firstName: "",
      lastName: "",
      organizationId: 0,
      company: "",
      phone: "",
      address1: "",
      address2: "",
      postalCode: "",
      province: "",
      isActive: true,
      isInventory: false,
      isFBA: false,
      isVirtual: false,
      createdOn: null,
    },

    validationSchema: Yup.object({
      name: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      address1: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      city: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      country: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      firstName: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      lastName: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      postalCode: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      phone: Yup.string()
        .required(i18n.t("VALIDATION.REQUIRED"))
        .min(5, i18n.t("VALIDATION.MINIMUM_5_CHARACTERS"))
        .max(50, i18n.t("VALIDATION.MAXIMUM_50_CHARACTERS")),
    }),

    onSubmit: values => {
      if (isEdit) {
        womsSvc.updateLocation(values).then(({ data }) => {
          formik.resetForm()
          onClose(true)
        })
      } else {
        values.organizationId = currentOrganizationId;
        womsSvc.createLocation(values).then(({ data }) => {
          formik.resetForm()
          onClose(true)
        })
      }
    },
  })

  useEffect(() => {
    if (show) {
      if (id) {
        setIsLoading(true)
        womsSvc.getLocation(id).then(({ data }) => {
          formik.setValues(data)
          setIsLoading(false)
        })
      }
    } else {
      formik.resetForm()
    }
  }, [show])

  return (
    <Modal isOpen={show} size="lg">
      <ModalHeader tag="h4">
        {!!isEdit ? i18n.t("EDIT_LOCATION") : i18n.t("ADD_LOCATION")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            formik.handleSubmit()
            return false
          }}
        >
          <h5 className="mb-4">{i18n.t("LOCATION_INFORMATION")}</h5>
          <Row>
            {isEdit && (
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{i18n.t("ID")}</Label>
                  <br />
                  <p className="mt-2">{"#LO" + formik.values.id}</p>
                </div>
              </Col>
            )}
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("LOCATION_NAME")}</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name ? formik.values.name : ""}
                  invalid={
                    formik.touched.name && formik.errors.name ? true : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.name && formik.errors.name ? (
                  <FormFeedback type="invalid">
                    {formik.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("ADDRESS_1")}</Label>
                <Input
                  name="address1"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address1 ? formik.values.address1 : ""}
                  invalid={
                    formik.touched.address1 && formik.errors.address1
                      ? true
                      : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.address1 && formik.errors.address1 ? (
                  <FormFeedback type="invalid">
                    {formik.errors.address1}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">
                  {i18n.t("ADDRESS_2")} ({i18n.t("FORM_OPTIONAL")})
                </Label>
                <Input
                  name="address2"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address2 ? formik.values.address2 : ""}
                  invalid={
                    formik.touched.address2 && formik.errors.address2
                      ? true
                      : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.address2 && formik.errors.address2 ? (
                  <FormFeedback type="invalid">
                    {formik.errors.address2}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("CITY")}</Label>
                <Input
                  name="city"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city ? formik.values.city : ""}
                  invalid={
                    formik.touched.city && formik.errors.city ? true : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.city && formik.errors.city ? (
                  <FormFeedback type="invalid">
                    {formik.errors.city}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("POSTALCODE")}</Label>
                <Input
                  name="postalCode"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.postalCode ? formik.values.postalCode : ""
                  }
                  invalid={
                    formik.touched.postalCode && formik.errors.postalCode
                      ? true
                      : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.postalCode && formik.errors.postalCode ? (
                  <FormFeedback type="invalid">
                    {formik.errors.postalCode}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("COUNTRY")}</Label>
                <CountryDropdown
                  name="country"
                  value={formik.values.country ? formik.values.country : ""}
                  classes={
                    "select-country form-control" +
                    (formik.touched.country && formik.errors.country
                      ? " is-invalid"
                      : "")
                  }
                  valueType="short"
                  priorityOptions={["US", "KR", "CN"]}
                  onChange={value => {
                    formik.setFieldValue("country", value)
                  }}
                  onBlur={formik.handleBlur}
                  disabled={isLoading}
                  defaultOptionLabel={i18n.t("SELECT_COUNTRY")}
                />
                {formik.touched.country && formik.errors.country ? (
                  <FormFeedback type="invalid">
                    {formik.errors.country}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            {formik.values.country && formik.values.country == 'US' ? (
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{i18n.t("STATE")}</Label>
                  <RegionDropdown
                    name="province"
                    country={formik.values.country ? formik.values.country : ""}
                    value={formik.values.province ? formik.values.province : ""}
                    onChange={value => {
                      formik.setFieldValue("province", value)
                    }}
                    onBlur={formik.handleBlur}                 
                    countryValueType="short"
                    classes={
                      "select-region form-control" +
                      (formik.touched.province && formik.errors.province
                        ? " is-invalid"
                        : "")
                    
                    }
                    defaultOptionLabel={i18n.t("SELECT_REGION")}
                    disabled={isLoading}
                    valueType="short"
                    blankOptionLabel="Select country to see options"
                  />
                  {formik.touched.province && formik.errors.province ? (
                    <FormFeedback type="invalid">
                      {formik.errors.province}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            ) : (
              <Col md="6">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("STATE")} ({i18n.t("FORM_OPTIONAL")})</Label>
                <Input
                  name="province"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.province ? formik.values.province : ""
                  }
                  invalid={
                    formik.touched.province && formik.errors.province
                      ? true
                      : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.province && formik.errors.province ? (
                  <FormFeedback type="invalid">
                    {formik.errors.province}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            )
            }
            
          </Row>
          <h5 className="mb-4 mt-3">{i18n.t("REPRESENTATIVE_INFORMATION")}</h5>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("FIRST_NAME")}</Label>
                <Input
                  name="firstName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName ? formik.values.firstName : ""}
                  invalid={
                    formik.touched.firstName && formik.errors.firstName
                      ? true
                      : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <FormFeedback type="invalid">
                    {formik.errors.firstName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("LAST_NAME")}</Label>
                <Input
                  name="lastName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName ? formik.values.lastName : ""}
                  invalid={
                    formik.touched.lastName && formik.errors.lastName
                      ? true
                      : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <FormFeedback type="invalid">
                    {formik.errors.lastName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">
                  {i18n.t("COMPANY_NAME")}
                  ({i18n.t("FORM_OPTIONAL")})
                </Label>
                <Input
                  name="company"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company ? formik.values.company : ""}
                  invalid={
                    formik.touched.company && formik.errors.company
                      ? true
                      : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.company && formik.errors.company ? (
                  <FormFeedback type="invalid">
                    {formik.errors.company}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">
                  {i18n.t("PHONE_NO")}
                </Label>
                <PhoneInput
                  name="phone"
                  country={"us"}
                  preferredCountries={["cn", "kr", "us"]}
                  enableSearch={true}
                  value={formik.values.phone}
                  onChange={(phone) => {
                    formik.setFieldValue(
                      "phone",
                      phone
                    )
                  }}
                  disabled={isLoading}
                  inputClass={
                    formik.touched.phone && formik.errors.phone
                      ? "is-invalid"
                      : ""
                  }
                  searchPlaceholder={i18n.t("SEARCH_DEFAULT_OPTION")}
                />
                <Input
                hidden
                  name="phone"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone ? formik.values.phone : ""}
                  invalid={
                    formik.touched.phone && formik.errors.phone ? true : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <FormFeedback type="invalid">
                    {formik.errors.phone}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            
            {isEdit && (
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{i18n.t("CREATED_ON")}</Label>
                  <br />
                  <p className="mt-2">
                    <Moment format="lll">{formik.values.createdOn}</Moment>
                  </p>
                </div>
              </Col>
            )}
          </Row>
          {isDeveloper && (
            <div>
              <h5 className="mb-4 mt-3">{i18n.t("LOCATION_OPTIONS")}</h5>
              <Row>
                {isEdit && (
                  <Col md="3">
                    <div className="form-check woms-checkbox-lg form-check-left mb-3">
                      <Label className="form-label">
                        {i18n.t("LOCATION_ACTIVE")}
                      </Label>
                      <Input
                        name="isActive"
                        type="checkbox"
                        className="form-check-input"
                        checked={formik.values.isActive ?? false}
                        onClick={e => {
                          formik.setFieldValue(
                            "isActive",
                            !formik.values.isActive
                          )
                        }}
                        onChange={e => {}}
                      />
                    </div>
                  </Col>
                )}
              </Row>
              <Row>
                <Col md="3">
                  <div className="form-check woms-checkbox-lg form-check-left mb-3">
                    <Label className="form-label">
                      {i18n.t("IS_VIRTUAL_LOCATION")}
                    </Label>

                    <Input
                      name="isVirtual"
                      type="checkbox"
                      className="form-check-input"
                      checked={formik.values.isVirtual ?? false}
                      onClick={e => {
                        formik.setFieldValue(
                          "isVirtual",
                          !formik.values.isVirtual
                        )
                      }}
                      onChange={e => {}}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <div className="form-check woms-checkbox-lg form-check-left mb-3">
                    <Label className="form-label">
                      {i18n.t("IS_INVENTORY")}
                    </Label>

                    <Input
                      name="isInventory"
                      type="checkbox"
                      className="form-check-input"
                      checked={formik.values.isInventory ?? false}
                      onClick={e => {
                        formik.setFieldValue(
                          "isInventory",
                          !formik.values.isInventory
                        )
                      }}
                      onChange={e => {}}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <div className="form-check woms-checkbox-lg form-check-left mb-3">
                    <Label className="form-label">{i18n.t("IS_FBA")}</Label>
                    <Input
                      name="isFBA"
                      type="checkbox"
                      className="form-check-input"
                      checked={formik.values.isFBA ?? false}
                      onClick={e => {
                        formik.setFieldValue("isFBA", !formik.values.isFBA)
                      }}
                      onChange={e => {}}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <Row>
            <Col md="12">
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => onClose()}
                  disabled={isLoading}
                >
                  {i18n.t("CANCEL")}
                </button>
                &nbsp;&nbsp;
                <button
                  type="submit"
                  className="btn btn-success save-integration"
                  disabled={isLoading}
                >
                  {i18n.t("SAVE")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(LocationModal)

import React, { useState, useEffect, useContext, Fragment } from 'react';

import { 
  Button, 
  Table, 
  ModalFooter,
  ModalBody,
  Modal,
  ModalHeader,
} from 'reactstrap';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { WomsServiceContext } from 'services/womsService';
import i18n from 'i18next';

import { CLIENT_SYNC_TYPE, EDIT_MODAL_TYPE, ITEM_VALUE_CHANGE_TYPE, PRICE_MOD_KEYS } from 'services/womsEnum';
import { isNanoId } from 'helpers/is_nano_id';
import { nanoid } from 'nanoid';
import { PriceMods } from './PriceMods';
import { ItemRow } from './ItemRow';
import { EditCancelButton } from 'components/Common/EditModal/EditCancelButton';
import { EditSaveButton } from 'components/Common/EditModal/EditSaveButton';
import { ItemPriceMods } from './ItemPriceMods';
import { formatToCurrency } from '../OrderUIHelper';
import { calculateSubtotal, calculateTotalAmount, calculateTotalTax } from '../helpers/orderCalculations';
import { DividerVertical } from 'components/Common/DividerVertical';
import { useOrderLineItemsThumbnails } from 'hooks/useOrderLineItemsThumbnails';
import { JsonHubProtocol } from '@microsoft/signalr';


const ItemsContainer = ({className, children}) => {
  return (
    <div className={className}>
      {children}  
    </div>
  );
};

const SummaryContainer = ({className, children}) => {
  return (
    <div className={className}>
      {children}  
    </div>
  );
};


export const ItemsModal = ({
  values,
  setValues,
  activeModal,
  setActiveModal
}) => {

  const { womsSvc } = useContext(WomsServiceContext);
  const [order, setOrder] = useState({});

  const { thumbnails } = useOrderLineItemsThumbnails({order});

  const [itemsToDelete, setItemsToDelete] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);


  const toggle = () => {
    setModalIsOpen(!modalIsOpen);
    setActiveModal(``);
    setOrder(values);
  };

  useEffect(()=> {
    if(activeModal && activeModal === EDIT_MODAL_TYPE.ORDER_ITEMS) {
      setModalIsOpen(true); 
    }
  },[activeModal])

  useEffect(()=> {
    if(values) {
      setOrder(values);
    }
  },[values])

  

  const [storeListingFilter, setStoreListingFilter] = useState(`StoreId eq ${order.storeId}`);

  const defaultItem = {
    id: nanoid(), //client temp id
    discountLineItems: [],
    feeLineItems: [],
    fulfillmentService: 0,
    fulfillmentStatus: 0,
    grams: 0,
    inverseParentLintItemNavigation: [],
    orderId: order.id,
    price: 0,
    quantity: 1,
    refundLineItems: [],
    sku: "",
    storeListing: {},
    storeListingId: null,
    taxLineItems: [],
    syncType: CLIENT_SYNC_TYPE.CREATED
  };

  useEffect(()=> {
    if(order.id && order.storeId && order.orderLineItems.length > 0) {
      const generateStoreListingFilter = () => {
        let queryString = `StoreId eq ${order.storeId}`
    
        //prevent user from viewing sku or item from search if already in orderlineitem
        //order.orderLineItems.forEach(item => {
        //  queryString += ` and Id ne ${item.storeListingId}`
        //});
    
        setStoreListingFilter(queryString);
      };
      generateStoreListingFilter();
    }
  },[order.storeId, order.orderLineItems]);

  const processPriceModItems = (priceModItems) => {

    return priceModItems.map(pmi => {
      if(pmi.syncType === CLIENT_SYNC_TYPE.DELETED && isNanoId(pmi.id)) {
        return null;
      } else if (pmi.syncType === CLIENT_SYNC_TYPE.CREATED) {
        const { id, ...rest} = pmi;
        return {...rest};
      }
      return pmi;
    })
    .filter(item => !!item && item)
  };

  const processOliDeleteSyncType = (oli) => {

    const oliPriceModKeys = Object.values(PRICE_MOD_KEYS);

    oliPriceModKeys.forEach(prop => {
      oli[prop] = oli[prop]
      .filter(item => item.syncType !== CLIENT_SYNC_TYPE.CREATED) 
      .map(item => ({ ...item, syncType: CLIENT_SYNC_TYPE.DELETED }));
    });

    return { ...oli };
  };

  const processOliUpdateSyncType = (oli) => {

    const oliPriceModKeys = Object.values(PRICE_MOD_KEYS);

    oliPriceModKeys.forEach(prop => {
      oli[prop] = oli[prop]
      .map(item => {
        if(item.syncType === CLIENT_SYNC_TYPE.CREATED) {
          const { id, ...rest } = item;
          return {...rest};
        } else if (item.syncType === CLIENT_SYNC_TYPE.DELETED && isNanoId(item.id)) {
          return null;
        }
        return item;
      })
      .filter(item => item && item);
    });

    return { ...oli };
  };

  const processOliCreateSyncType = (oli) => {

    const oliPriceModKeys = Object.values(PRICE_MOD_KEYS);
    oliPriceModKeys.forEach(prop => {
      oli[prop] = oli[prop]
      .map(item => {
        if(item.syncType === CLIENT_SYNC_TYPE.DELETED) {
          return null;
        } else if(item.syncType === CLIENT_SYNC_TYPE.CREATED) {
          const { id, orderLineItemId, ...rest} = item;
          return {...rest}
        }
      })
      .filter(item => item && item);
    });

    return { ...oli };
  };
  
  const handleSubmit = async () => {

    const orderLineItems = order.orderLineItems
    .filter(item => item.storeListingId)
    .map(item => {
      if(item.syncType === CLIENT_SYNC_TYPE.DELETED) {
        return processOliDeleteSyncType(item);
      } else if (item.syncType === CLIENT_SYNC_TYPE.UPDATED) {
        return processOliUpdateSyncType(item);
      } else if (item.syncType === CLIENT_SYNC_TYPE.CREATED) {
        const { id, ...rest } = processOliCreateSyncType(item);
        return {...rest};
      }
      return item;
    })

    const updatedOrder = {
      ...order,
      orderLineItems,
      feeLineItems: processPriceModItems(order.feeLineItems),
      discountLineItems: processPriceModItems(order.discountLineItems),
      taxLineItems: processPriceModItems(order.taxLineItems)
    };

    try {
      setIsSubmitting(true);
      await womsSvc.updateOrder(updatedOrder);
      const { data } = await womsSvc.getOrder(order.id);
      setIsSubmitting(false);
      setValues(data);
      toggle();

    } catch (error) {
      setIsSubmitting(false);
      console.error(error)
    }
  
  };
 
  const handleValueChange = (item, value, changeType) => {
    const updatedItems = order.orderLineItems.map(oli => {
      if (oli.id === item.id) {

        let editedOli = {
          ...oli,
          syncType:
          !oli.syncType 
          || (oli.syncType === "NONE") 
          || (oli.syncType === CLIENT_SYNC_TYPE.DELETED)
          ? CLIENT_SYNC_TYPE.UPDATED
          : oli.syncType
        };

        if(changeType === ITEM_VALUE_CHANGE_TYPE.PRICE) {
          editedOli.price = value;
        } else if (changeType === ITEM_VALUE_CHANGE_TYPE.QUANTITY) { 
          editedOli.quantity = value;
        }

        return editedOli;
      }
      return oli;
    });

    setOrder(prev => ({...prev, orderLineItems: updatedItems}));
  };

  const handleItemChange = async (item, sListingItem) => {
    try {
      const { data } = await womsSvc.getStoreListing(sListingItem.id);
      const updatedItems = order.orderLineItems.map(oli => {
        if (oli.id === item.id) {
          return {
            ...oli, 
            storeListing: data, 
            storeListingId: data.id, 
            sku: data.storeSku,
            syncType: 
            !oli.syncType 
            || (oli.syncType === "NONE") 
            || (oli.syncType === CLIENT_SYNC_TYPE.DELETED)
            ? CLIENT_SYNC_TYPE.UPDATED
            : oli.syncType
          };
        }
        return oli;
      });
      setOrder(prev => ({...prev, orderLineItems: updatedItems}));
    } catch (error) {
      console.error(error);
    }
    
  };

  const handleItemDelete = (item) => {
    if (isNanoId(item.id)) {
      const orderLineItems = order.orderLineItems.filter(oli => oli.id !== item.id );
      setOrder(prev => ({...prev, orderLineItems}))
    } else {
      const orderLineItems = order.orderLineItems.map(oli => oli.id === item.id 
        ? {...oli, syncType: oli.syncType === CLIENT_SYNC_TYPE.DELETED ? CLIENT_SYNC_TYPE.UPDATED : CLIENT_SYNC_TYPE.DELETED}
        : oli
      );
      setOrder(prev => ({...prev, orderLineItems}))
    }


    
  };

  const deletedItemsSubtotal = () => {
    return itemsToDelete.reduce((total, cur) => {
      return (cur.price * cur.quantity) + total;
    },0)
  };

  const addOrderLineItem = () => {
    const orderLineItems = [...order.orderLineItems, defaultItem];
    setOrder(prev => ({...prev, orderLineItems}));
  };

  const SubTotal = () => {
    return (
      <div className="d-flex flex-column gap-2">
        <div className="p-2 w-100 border-bottom">
          <h6 className="m-0 fw-bold">{i18n.t("PRICE_SUBTOTAL")}</h6>
        </div>
        <div className="w-100 justify-content-end d-flex pr-20">
          <span className="text-nowrap p-2 w-100 text-end ">
            {formatToCurrency(calculateSubtotal(order) - deletedItemsSubtotal(), order.currency)}
            
          </span>
        </div>
      </div>
    );
  };  

  const TotalTax = () => {
    return (
      <>
        {
        calculateTotalTax(order) > 0 &&
        <div className="flex gap-4">
          <div className="">
            <h6 className="m-0 text-end fw-bold w-20">{i18n.t("PRICE_TAX")}</h6>
          </div>
          <span className="whitespace-nowrap">
            {formatToCurrency(calculateTotalTax(order), order.currency)}
          </span>
        </div>
        }
      </>
    );
  };

  const Total = () => {
    
    return (

      <div className="d-flex justify-content-between">
        <div className="p-2 w-100">
          <h6 className="m-0 fw-bold">{i18n.t("PRICE_TOTAL")}</h6>
        </div>
        <div className="w-100 justify-content-end d-flex">
          <span className="text-nowrap p-2 w-100 text-end ">
            {formatToCurrency(calculateTotalAmount(order) - deletedItemsSubtotal(), order.currency)}
          </span>
        </div>
      </div>
    );
  };

  const AddOrderLineItemButton = () => {
    return (
      <div className='w-100 pt-4 bg-white d-flex justify-content-center align-items-center gap-2'>
        <Button className='add-order-item-button w-auto p-2 d-flex align-items-center gap-1 rounded-2 border border-light text-black border-0  '  onClick={()=> addOrderLineItem()}>
          <AddCircleOutlineIcon fontSize='small'/>
          <span>{i18n.t("ADD_ITEM")}</span>
        </Button>
      </div>
    );
  };

  return (
    <Modal className='' isOpen={modalIsOpen} toggle={toggle} size='xl'>
      <ModalHeader className='' tag={"h4"} toggle={toggle}>
      {i18n.t("EDIT_ITEMS")}
      </ModalHeader>
      <ModalBody className='d-flex flex-column flex-xl-row gap-2 position-relative'>
        <ItemsContainer className='p-2 position-relative order-line-items-container'>
          <Table  className=" m-0 woms-lineitem-edit-table">
            <thead>
              <tr>
                <th scope="col" className="fw-bold text-center">{i18n.t("PRODUCT")}</th>
                <th scope="col" colSpan={2} className="fw-bold text-center">{i18n.t("PRODUCT_NAME")}</th>
                <th scope="col" colSpan={1} className="fw-bold text-end">{i18n.t("PRICE")}</th>
                <th scope="col" colSpan={1}/>
                <th scope="col" colSpan={1} className="fw-bold text-end">{i18n.t("QUANTITY")}</th>
              </tr>
            </thead>

            <tbody>
            
              {order.orderLineItems?.map((item, index) => (
              <Fragment key={item.id}>
                <ItemRow
                item={item}
                handleItemChange={handleItemChange}
                handleValueChange={handleValueChange}
                handleItemDelete={handleItemDelete}
                thumbnails={thumbnails}
                order={order}
                womsSvc={womsSvc}
                storeListingFilter={storeListingFilter}
                />

                <ItemPriceMods
                item={item}
                order={order}
                setOrder={setOrder}
                />
              </Fragment>
              ))}
            </tbody>

          </Table>

          <AddOrderLineItemButton />
        </ItemsContainer>

        <DividerVertical/>

        <SummaryContainer className={`d-flex flex-column p-2 h-100 gap-2 flex-grow-1 position-relative `}>
          <SubTotal />
          
          <PriceMods
          order={order} 
          setOrder={setOrder}
          deletedItemsSubtotal={deletedItemsSubtotal} 
          />
          {/* <TotalTax /> */}
          <Total />
        </SummaryContainer>

      </ModalBody>

      <ModalFooter className=' w-100 bg-white'>
        <EditCancelButton onClick={toggle}/>
        <EditSaveButton isLoading={isSubmitting}  onClick={handleSubmit}/>
      </ModalFooter>
    </Modal>
  );
};
import React, { useContext, useEffect, useState} from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import i18n from 'i18next';
import { withTranslation } from "react-i18next";
import SalesByStoreWidget from "./widgets/SalesByStoreWidget";
import SalesBySkuWidget from "./widgets/SalesBySkuWidget";
import SkuTrendWidget from "./widgets/SkuTrendWidget";
import ProfitBySkuWidget from "./widgets/ProfitBySkuWidget";
import { WomsServiceContext } from "services/womsService";
import ProfitByStoreWidget from "./widgets/ProfitByStoreWidget";
const Report = (props) => {
  const { womsSvc } = useContext(WomsServiceContext)
  //meta title
  document.title = i18n.t("REPORT");
  const [storeList, setStoreList] = useState([]);
  //const [brandList, setBrandList] = useState([]);
  useEffect(() => {
    womsSvc.searchStore(100,0,'createdOn','desc').then(({data}) => {
      setStoreList(data.value);
    }).finally(() => {
    });
    //womsSvc.getBrands().then(({data}) => {
    //  setBrandList(data);
    //})
  }, []);


  return (
    <>
      
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs altTitle={i18n.t("REPORT")} title={i18n.t("REPORT")} breadcrumbItem={i18n.t("REPORT")} />
          <Row>
            <Row>
              <Col>
              <h6>{i18n.t("REPORT.STORE_REPORTS")}</h6>
              </Col>
            </Row>
           
            <Col md="4">
              <SalesByStoreWidget storeList={storeList}/>
            </Col>
            <Col md="8">
            <ProfitByStoreWidget storeList={storeList} />
            </Col>
            </Row>
            {/*<Row>
              <Col>
              <h6>{i18n.t("ORDER_REPORT")}</h6>
              </Col>
            </Row>
            <Row>
              <Col>
              <SalesBySkuWidget/>
              </Col>
            </Row>*/}
            <Row>
              <Col>
              <h6>{i18n.t("REPORT.SKU_REPORTS")}</h6>
              </Col>
            </Row>
            <Row>
              <Col md="4">
              <SalesBySkuWidget/>
              </Col>
            <Col md="8">
              <ProfitBySkuWidget storeList={storeList} />
            </Col>
            </Row>

            <Row>
            <Col>
              <SkuTrendWidget storeList={storeList}/>
            </Col>
            </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(Report);
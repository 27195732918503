import React, { useContext, useEffect, useMemo, useState, Fragment } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip
} from "reactstrap"
import { useFormik, FormikProvider, Field, FieldArray, useFormikContext } from "formik"
import * as Yup from "yup";
import i18n from 'i18next';
import Select from "react-select"
import { WomsServiceContext } from "services/womsService";
import { PRODUCT_STATUS, BUNDLE_TYPE } from "services/womsEnum";
import './Bundle.css'

const BundleModal = props => {
  const { show, isEdit, id, onClose } = props;
  const { womsSvc } = useContext(WomsServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [entryProducts, setEntryProducts] = useState([]);
  const [organizationId, setOrganizationId] = useState(2); // TODO:

  const selectStyles = {
    menu: base => ({
      ...base,
      zIndex: 100,
    }),
  }

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      organizationId: '',
      id: '',
      sku: '',
      name: '',
      upc: '',
      modelNo: '',
      bundleType: BUNDLE_TYPE.BUNDLE,
      status: PRODUCT_STATUS.ACTIVE,
      bundleDetailBundleProducts: [
        {
          id: '',
          quantity: 0,
          product: {
            id: '',
            sku: ''
          }
        }
      ]
    },

    validationSchema: Yup.object({
      sku: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      bundleType: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      status: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      bundleDetailBundleProducts: Yup.array().of(Yup.object().shape({
        id: Yup.number(),
        quantity: Yup.number().required(i18n.t("VALIDATION.REQUIRED")).min(1, i18n.t("VALIDATION.MINIMUM_1")),
        product: Yup.object().shape({
          id: Yup.number(),
          sku: Yup.string().required(i18n.t("VALIDATION.REQUIRED"))
        })
      }))
    }).test('bundleCount',
      null,
      (obj) => {
        if (obj.bundleDetailBundleProducts?.length === 0) {
          return new Yup.ValidationError(i18n.t("VALIDATION.AT_LEAST_ONE_PRODUCT"), null, 'bundleCount')
        }
        return true
      }
    ),

    onSubmit: (values) => {
      if (isEdit) {
        womsSvc.updateProduct(values).then(({ data }) => {
          formik.resetForm();
          onClose(true);
        });
      } else {
        if (!values.organizationId){
          values.organizationId = organizationId;
        }
        womsSvc.createProduct(values).then(({ data }) => {
          formik.resetForm();
          onClose(true);
        });
      }
    },
  });

  useEffect(() => {
    if (show) {
      setIsLoading(true);
      womsSvc.searchProductForBundleEntry(organizationId).then(({ data }) => {
        setEntryProducts(data.value);
      }).finally(() => {
        if (id) {
          womsSvc.getProduct(id).then(({ data }) => {
            formik.setValues(data);
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
      })
    } else {
      formik.resetForm();
    }
  }, [show]);

  return (
    <FormikProvider value={formik}>
      <Modal isOpen={show} size="lg">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}

        >
          <ModalHeader tag="h4">
            {!!isEdit ? i18n.t("EDIT_BUNDLE") : i18n.t("ADD_NEW_BUNDLE") }
          </ModalHeader>
          <ModalBody>

            <Row>
              {isEdit && <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{i18n.t("ID")}</Label>
                  <Input
                    name="id"
                    type="text"
                    readOnly
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.id ? ("#BD" + formik.values.id) : ""}
                    invalid={
                      formik.touched.id && formik.errors.id ? true : false
                    }
                    disabled={isLoading}
                  />
                  {formik.touched.id && formik.errors.id ? (
                    <FormFeedback type="invalid">{formik.errors.id}</FormFeedback>
                  ) : null}
                </div></Col>}
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">SKU</Label>
                  <Input
                    name="sku"
                    type="text"
                    placeholder="SKU"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sku || ""}
                    invalid={
                      formik.touched.sku && formik.errors.sku ? true : false
                    }
                    disabled={isLoading}
                  />
                  {formik.touched.sku && formik.errors.sku ? (
                    <FormFeedback type="invalid">{formik.errors.sku}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{i18n.t('BUNDLE_NAME')}</Label>
                  <Input
                    name="name"
                    type="text"
                    placeholder={i18n.t("BUNDLE_NAME")}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name || ""}
                    invalid={
                      formik.touched.name && formik.errors.name ? true : false
                    }
                    disabled={isLoading}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{i18n.t('BUNDLE_TYPE')}</Label>
                  <Input
                    name="bundleType"
                    type="select"
                    className="form-select"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.bundleType || ""
                    }
                    invalid={
                      formik.touched.bundleType && formik.errors.bundleType ? true : false
                    }
                  >
                    <option value="">{i18n.t("SELECT_BUNDLE_TYPE")}</option>
                    <option value={BUNDLE_TYPE.BUNDLE}>{i18n.t("BUNDLE_TYPE_BUNDLE")}</option>
                    <option value={BUNDLE_TYPE.KIT}>{i18n.t("BUNDLE_TYPE_KIT")}</option>
                  </Input>
                  {formik.touched.bundleType && formik.errors.bundleType ? (
                    <FormFeedback type="invalid">{formik.errors.bundleType}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">UPC</Label>
                  <Input
                    name="upc"
                    type="text"
                    placeholder="UPC"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.upc || ""}
                    invalid={
                      formik.touched.upc && formik.errors.upc ? true : false
                    }
                    disabled={isLoading}
                  />
                  {formik.touched.upc && formik.errors.upc ? (
                    <FormFeedback type="invalid">{formik.errors.upc}</FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{i18n.t("BUNDLE_MODEL_NUMBER")}</Label>
                  <Input
                    name="modelNo"
                    type="text"
                    placeholder={i18n.t("BUNDLE_MODEL_NUMBER")}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.modelNo || ""}
                    invalid={
                      formik.touched.modelNo && formik.errors.modelNo ? true : false
                    }
                    disabled={isLoading}
                  />
                  {formik.touched.modelNo && formik.errors.modelNo ? (
                    <FormFeedback type="invalid">{formik.errors.modelNo}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{i18n.t("STATUS")}</Label><br />
                  <Input
                    name="status"
                    type="select"
                    className="form-select"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.status || ""
                    }
                    invalid={
                      formik.touched.status && formik.errors.status ? true : false
                    }
                  >
                    <option value="">{i18n.t("SELECT_BUNDLE_STATUS")}</option>
                    <option value={PRODUCT_STATUS.ACTIVE}>{i18n.t("ACTIVE")}</option>
                    <option value={PRODUCT_STATUS.INACTIVE}>{i18n.t("INACTIVE")}</option>
                    <option value={PRODUCT_STATUS.DELETED}>{i18n.t("DELETED")}</option>
                  </Input>
                  {formik.touched.status && formik.errors.status ? (
                    <FormFeedback type="invalid">{formik.errors.status}</FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            {!isLoading &&

              <FieldArray
                name="bundleDetailBundleProducts"
                render={(arrayHelpers) => (
                  <>
                    <Row>
                      <Col sm='12'>
                          <Label className="form-label">
                            {i18n.t("PRODUCTS")}
                            &nbsp;&nbsp;
                            <i type="button" className="bx bx-plus-circle icon-add" size='50' onClick={() => arrayHelpers.push({
                              id: '',
                              quantity: 0,
                              product: {
                                id: '',
                                sku: ''
                              }
                            })}
                            />
                          </Label>
                        {formik.errors.bundleCount ? (
                          <FormFeedback type="invalid" className='d-block'>{formik.errors.bundleCount}</FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    {formik.values.bundleDetailBundleProducts?.map((row, index) => (
                      <Fragment key={index}>
                        <Row className='array-item'>
                          <Col xl='1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <i type="button" className="bx bx-minus-circle icon-remove" size='50' onClick={() => arrayHelpers.remove(index)}></i>
                          </Col>
                          <Col xl='8'>
                            <Select
                              options={entryProducts?.map(p => ({
                                value: p.id,
                                label: p.sku,
                              }))}
                              isDisabled={isLoading}
                              value={{ value: row.product.id, label: row.product.sku }}
                              onChange={p => {
                                formik.setFieldValue(`bundleDetailBundleProducts.${index}.product.id`, p.value);
                                formik.setFieldValue(`bundleDetailBundleProducts.${index}.product.sku`, p.label);
                              }}
                              styles={selectStyles}
                              placeholder={{ value: '', label: i18n.t("SELECT_PRODUCT") }}
                              className={formik.touched.bundleDetailBundleProducts?.[index]?.product?.sku &&
                                formik.errors.bundleDetailBundleProducts?.[index]?.product?.sku ? "is-invalid" : ""}
                              classNames={{
                                control: () =>
                                formik.touched.bundleDetailBundleProducts?.[index]?.product?.sku &&
                                formik.errors.bundleDetailBundleProducts?.[index]?.product?.sku ? "form-control is-invalid" : ""
                              }}
                              noOptionsMessage={() => i18n.t("SELECT_NO_OPTIONS")}
                            />
                            {/* <Input
                              type='text'
                              placeholder='Sku'
                              name={`bundleDetailBundleProducts.${index}.product.sku`}
                              id={`bundleDetailBundleProducts.${index}.product.sku`}
                              value={row.product.sku}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              invalid={formik.touched.bundleDetailBundleProducts?.[index]?.product?.sku
                                && formik.errors.bundleDetailBundleProducts?.[index]?.product?.sku
                                && true}
                            /> */}
                            {formik.touched.bundleDetailBundleProducts?.[index]?.product?.sku && formik.errors.bundleDetailBundleProducts?.[index]?.product?.sku ? (
                              <FormFeedback type="invalid">{formik.errors.bundleDetailBundleProducts?.[index]?.product?.sku}</FormFeedback>
                            ) : null}
                          </Col>
                          <Col xl='3'>
                            <Input
                              type='text'
                              style={{ textAlign: 'right' }}
                              placeholder={i18n.t("QUANTITY")}
                              autoComplete="off"
                              name={`bundleDetailBundleProducts.${index}.quantity`}
                              id={`bundleDetailBundleProducts.${index}.quantity`}
                              value={row.quantity}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              invalid={formik.touched.bundleDetailBundleProducts?.[index]?.quantity
                                && formik.errors.bundleDetailBundleProducts?.[index]?.quantity
                                && true}
                            />
                            {formik.touched.bundleDetailBundleProducts?.[index]?.quantity && formik.errors.bundleDetailBundleProducts?.[index]?.quantity ? (
                              <FormFeedback type="invalid">{formik.errors.bundleDetailBundleProducts?.[index]?.quantity}</FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </Fragment>
                    ))}
                  </>
                )}
              />
            }
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col md="12">
                <div className="text-end mt-2">
                  <button type="button" className="btn btn-secondary" onClick={() => onClose()} disabled={isLoading}>{i18n.t("CANCEL")}</button>
                  &nbsp;&nbsp;
                  <button
                    type="submit"
                    className="btn btn-success save-integration"
                    disabled={isLoading}
                  >
                    {i18n.t("SAVE")}
                  </button>
                </div>
              </Col>
            </Row>
          </ModalFooter>
        </Form>
      </Modal>
    </FormikProvider>
  )
}
export default BundleModal;
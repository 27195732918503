import React, { useContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import { DASHBOARD_STATUS, DASHBOARD_TYPE } from "services/womsEnum";
import { updateTopStoresWidget } from "store/woms-dashboard/top-stores/actions";
import LinearProgress from "components/Common/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { WomsServiceContext } from "services/womsService";
import { getStoreIcon } from "pages/Order/OrderUIHelper";
import i18n from "i18next";

const TopStoresWidget = (props) => {
  
  const socials = [
    {
      title: "Facebook",
      bgColor: "bg-primary",
      iconClass: "mdi-facebook",
      description: "125",
    },
    {
      title: "Twitter",
      bgColor: "bg-info",
      iconClass: "mdi-twitter",
      description: "112",
    },
    {
      title: "Instagram",
      bgColor: "bg-pink",
      iconClass: "mdi-instagram",
      description: "104",
    },
  ];
  const { params,forceUpdate } = props;
  const { womsSvc } = useContext(WomsServiceContext)
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(false);
  
  const dispatch = useDispatch();
  const widget = useSelector(state => state.topStoresWidget);

  useEffect(() => {
      if (params) {
        womsSvc.requestDashboard( DASHBOARD_TYPE.TOP_STORES,params,false).then(({data}) => {
            dispatch(updateTopStoresWidget(data));
        });
      }
  }, [params]);
  useEffect(() => {
    if (forceUpdate > 0) {
      womsSvc.requestDashboard( DASHBOARD_TYPE.TOP_STORES,params,true).then(({data}) => {
          dispatch(updateTopStoresWidget(data));
      });
    }
}, [forceUpdate]);
  useEffect(() => {
    if (widget && widget.data && widget.data.id) {
          setStatus(widget.data.status)
          if (widget.data.payload) {
              setData(JSON.parse(widget.data.payload));
          } else {
            setData({});
          }
    }
}, [widget]);

  return (
    <>
      <Card>
        <CardBody>
        {(!status || status == DASHBOARD_STATUS.PROCESSING) && <LinearProgress/>}
          <CardTitle className="mb-4">{i18n.t("DASHBOARD.TOP_STORES")} {(status && status == DASHBOARD_STATUS.FAILED) && <i className="bx bx-error-circle text-danger font-size-13"></i>}</CardTitle>
          <div className="text-center">
            <div className="avatar-sm mx-auto mb-4">
              <span className="avatar-title rounded-circle bg-primary bg-soft font-size-24 ">
              {data && data.topStores && data.topStores[0] && <img src={getStoreIcon(data.topStores[0]?.storeType)} height="35px" alt="Store Icon" />}
              </span>
            </div>
            <p className="font-16 text-muted mb-2"></p>
            <h5>
              <Link to="#" className="text-dark">
                {data && data.topStores  && data.topStores[0] && <>
                  {data.topStores[0]?.storeName} - <span className="text-muted font-16">{data.topStores[0]?.count} {i18n.t("DASHBOARD.ORDERS")}</span>
                </>} {" "}
              </Link>
            </h5>
          </div>
          <Row className="mt-4">
            {data && data.topStores && data.topStores.map((entry, index) => ( index !=0 &&
              <Col className="col-4" key={"_li_" + index}>
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3">

                    <span
                      className={
                        "avatar-title rounded-circle font-size-15"
                      }
                    >
                      <img src={getStoreIcon(entry.storeType)} height="16px" alt="Store Icon" />
                    </span>
                  </div>
                  <h5 className="font-size-13">{entry.storeName}</h5>
                  <p className="text-muted mb-0">{entry.count} {i18n.t("DASHBOARD.ORDERS")}</p>
                </div>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default TopStoresWidget

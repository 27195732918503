import React, {useContext, useEffect, useState} from 'react'
import {
    Col,
    Row,
    Modal,
    ModalBody,
    Card,
    CardTitle,
    CardBody,
    ModalFooter,
    ModalHeader,
    Form
} from 'reactstrap'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import { WomsServiceContext } from 'services/womsService'
import { withTranslation } from 'react-i18next'

const ProductBasicInfoModal = props => {
    const { show, showToggle } = props
    const {womsSvc} = useContext(WomsServiceContext)
    const [isLoading, setIsLoading] = useState(false)
    
    return (
        <Modal isOpen={show} toggle={showToggle} className='woms-modal-fullscreen modal-fullscreen'>
            <ModalHeader tag={'h4'} toggle={showToggle}>
                <Row>
                    <Col>
                    Title
                    </Col>
                </Row>
                <Row>
                    <Col>SKU</Col>
                </Row>
            </ModalHeader>
            <ModalBody>

            </ModalBody>

        </Modal>
    )
}

export default withTranslation()(ProductBasicInfoModal)
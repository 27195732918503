import React, { useContext, useEffect, useState } from 'react'
import {
    Col,
    Row,
    Card,
    CardTitle,
    CardText,
    CardImg,
    CardBody,
    Button
} from 'reactstrap'
import { withTranslation } from "react-i18next";
import TestImg from "../../../../assets/images/sample-img-to-be-deleted.jpg"
import EditButtonIcon from 'components/Common/EditButtonIcon';
import '../../product.scss'
import ProductBasicInfoModal from './ProductBasicInfoModal';
import HTMLReactParser from 'html-react-parser';
import cf from "../../../../services/codeFormatter";
import i18n from 'i18next';


const ProductBasicInfo = props => {
    const [show, setShow] = useState(false);

    const { product,isLoading } = props;

    function showToggle() {
        setShow(!show)
    }
    function onEditClick() {
        setShow(true)
    }

    function formatDescription(str) {

        var newStr = str.replace(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi, function () {
                return '<a href="'+arguments[0]+'" target="_blank">' + arguments[0] + '</a>';
            }).replace(/(?:\r\n|\r|\n)/g, '<br />');
        return newStr;
    }
    return (
        <>
            <Card>
                <CardBody>
                    <CardTitle>
                        {i18n.t("BASIC_INFORMATION")}
                        <Button
                            //disabled={isLoading}
                            disabled
                            type="button"
                            className="btn btn-light btn-sm woms-edit-btn"
                            onClick={onEditClick}
                        >
                            <i className="bx bx-pencil" />
                        </Button>
                    </CardTitle>
                    <Row>
                        <Col className='woms-product-basic-info-img-col' xl={4} lg={6} >
                            {product?.imageUrl ? <CardImg className='woms-product-basic-info-img' src={product.imageUrl} /> : <i className="bx bx-image-alt woms-product-placeholder" />}
                        </Col>
                        <Col xl={8} lg={6}>
                            <Row className="mb-1">
                                <Col xl={2}>
                                    <span className="fw-bold">
                                        {i18n.t("PRODUCT_NAME")}
                                    </span>
                                </Col>
                                <Col xl={10}>
                                        {product.name ? product.name : 'N/A'}
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col xl={2}>
                                <span className="fw-bold">
                                {i18n.t("PRODUCT_BRAND")}
                                    </span>
                                </Col>
                                <Col xl={10}>
                                    {product.brand ? product.brand : 'N/A'}
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col xl={2}>
                                    <span className="fw-bold no-wrap">
                                    {i18n.t("PRODUCT_DESCRIPTION")}
                                    </span>
                                </Col>
                                <Col xl={10}>
                                    {product.description ? HTMLReactParser(formatDescription(product.description)) : 'N/A'}
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col xl={2}>
                                    <span className="fw-bold">
                                        SKU
                                    </span>
                                </Col>
                                <Col xl={10}>
                                    {product.sku ? product.sku : 'N/A'}
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col xl={2}>
                                    <span className="fw-bold">
                                        UPC
                                    </span>
                                </Col>
                                <Col xl={10}>
                                    {product.upc ? product.upc : 'N/A'}
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <span className="fw-bold">
                                        {i18n.t("PRODUCT_MODEL_NUMBER")}
                                    </span>
                                </Col>
                                <Col xl={10}>
                                    {product.modelNo ? product.modelNo : 'N/A'}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <ProductBasicInfoModal
                show={show}
                showToggle={showToggle}
            />
        </>
    )
}

export default withTranslation()(ProductBasicInfo)
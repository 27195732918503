import React, { useContext, useEffect, useState } from "react";

import { Row, Col, Card, CardBody, CardTitle, Input, FormFeedback, Label, InputGroup, Form, Spinner } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "components/Common/ChartsDynamicColor";
import { WomsServiceContext } from "services/womsService";
import { useDispatch, useSelector } from "react-redux";
import { updateOrderStatusWidget } from "store/woms-dashboard/order-status/actions";
import { REPORT_PERIOD_TYPE, REPORT_STATUS, REPORT_TYPE, ORDER_STATUS } from "services/womsEnum";
import cf from "../../../services/codeFormatter";
import i18n from 'i18next';
import { useFormik } from "formik";
import Flatpickr from 'react-flatpickr';
import * as Yup from "yup";
import { updateSalesBySkuWidget } from "store/report/sales-by-sku/actions";
import { downloadCSV } from "../ReportUIHelper";
import moment from "moment";

const SalesBySkuWidget = (props) => {
    const { womsSvc } = useContext(WomsServiceContext)
    const [data, setData] = useState({});
    const [status, setStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();
    const widget = useSelector(state => state.salesBySkuWidget);
    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            "periodType": REPORT_PERIOD_TYPE.LAST_MONTH,
            "fromDate": '',
            'toDate': ''
        },
        validationSchema: Yup.object({
            periodType: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
            fromDate: Yup.date().when('periodType', {
                is: periodType => periodType == REPORT_PERIOD_TYPE.RANGE,
                then: Yup.date()
                    .required(i18n.t("VALIDATION.FROM_DATE_REQUIRED")),
                otherwise: Yup.date()
            }),
            toDate: Yup.date().when('periodType', {
                is: periodType => periodType == REPORT_PERIOD_TYPE.RANGE,
                then: Yup.date()
                    .required(i18n.t("VALIDATION.TO_DATE_REQUIRED")),
                otherwise: Yup.date()
            })
        }),

        onSubmit: (values) => {
            setIsLoading(true);
            setErrorMessage("");
            womsSvc.requestReport( REPORT_TYPE.SALES_BY_SKU,values,false).then(({data}) => {
                setData(data);
                setIsLoading(false);
            });
        },
    });
    useEffect(() => {
        if (data && data.id) {
            dispatch(updateSalesBySkuWidget(data));
        }
    }, [data]);
    useEffect(() => {
        if (widget && widget.data) {
            if (widget.data[data.id]) {
                setStatus(widget.data[data.id].status)
                if (widget.data[data.id].payload) {
                    var result = JSON.parse(widget.data[data.id].payload)
                    if (result && result.length > 0) {
                        setErrorMessage("");
                        downloadCSV(result,"ExportSalesBySku_" + moment().format());
                        } else {
                            setErrorMessage("No data found");
                        }
                } 
            }
            
            
        }
    }, [widget]);

    return (
        <>
            <Card>
                <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            formik.handleSubmit();
                            return false;
                        }}
                    >
                <CardBody>
                    <CardTitle className="mb-4">{i18n.t("REPORT.SALES_BY_SKU")}{(status && status == REPORT_STATUS.FAILED) && <i className="bx bx-error-circle text-danger font-size-13"></i>}
                    {((status && status == REPORT_STATUS.PROCESSING) || isLoading) && <Spinner size='sm' animation="border" className="ms-2"/>}
                    {errorMessage && <span className="text-danger ms-2"><small>{errorMessage}</small></span>}
                        <button
                            type="submit"
                            className="btn btn-success save-integration"
                            style={{ "float": "right" }}
                            disabled={((status && status == REPORT_STATUS.PROCESSING) || isLoading)}
                        >
                            {i18n.t("REPORT.GENERATE")}
                        </button>

                    </CardTitle>
                    
                        <Row>
                            <Col md="12">
                                <div className="mb-3">
                                    <Row>
                                        <Col>
                                            <Label className="form-label"> {i18n.t("REPORT.PERIOD_TYPE")}</Label>

                                        </Col>
                                    </Row>
                                    <Input
                                        name="periodType"
                                        type="select"
                                        className="form-select"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={
                                            formik.values.periodType || ""
                                        }
                                        invalid={
                                            formik.touched.periodType && formik.errors.periodType ? true : false
                                        }
                                    >
                                        {
                                            Object.values(REPORT_PERIOD_TYPE).map(val =>
                                                <option value={val} key={val}>{cf.format("ReportPeriodType", val)}</option>
                                            )
                                        }
                                    </Input>
                                    {formik.touched.periodType && formik.errors.periodType ? (
                                        <FormFeedback type="invalid">{formik.errors.periodType}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        {formik.values.periodType == REPORT_PERIOD_TYPE.RANGE && <Row>
                            <Col md="12">
                                <div className="mb-3">
                                    <Label>{i18n.t("REPORT.DATE_RANGE")}</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            className="form-control d-block"
                                            placeholder={i18n.t("REPORT.SELECT_DATE_RANGE")}
                                            options={{
                                                mode: "range",
                                                dateFormat: "Y-m-d"
                                            }}
                                            value={[formik.values.fromDate, formik.values.toDate]}
                                            onChange={([from, to]) => {
                                                formik.setFieldValue('fromDate', from);
                                                formik.setFieldValue('toDate', to);
                                            }}
                                            onBlur={() => {
                                                formik.setFieldTouched('fromDate');
                                                formik.setFieldTouched('toDate');
                                            }}
                                        />
                                    </InputGroup>
                                    {formik.errors.fromDate ? (
                                        <div className="text-danger">
                                            <small>{formik.errors.fromDate}</small>
                                        </div>
                                    ) : null}
                                    {formik.errors.toDate ? (
                                        <div className="text-danger">
                                            <small>{formik.errors.toDate}</small>
                                        </div>
                                    ) : null}

                                </div>

                            </Col>

                        </Row>}
                        <Row>
                            <Col md="12">

                            </Col>
                        </Row>
                    
                </CardBody>
                </Form>
            </Card>
        </>
    );
};

export default SalesBySkuWidget;

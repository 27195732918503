import React, {useEffect, useMemo, useState, useContext} from 'react'
import PropTypes from 'prop-types'
import {
    Button, 
    Col,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    Card,
    CardTitle,
    CardBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    Form
} from 'reactstrap'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import { WomsServiceContext } from 'services/womsService'
import ControlledTypehead from "components/Common/ControlledTypehead"


const ProductAddModal = props => {
    const { show, setShow, onClose } = props
    const { womsSvc } = useContext(WomsServiceContext)
    const [isLoading, setIsLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            id: ''
        },
        validationSchema: Yup.object({
            Name: Yup.string().required("Please enter Name")
        })
    })
    return (  
        //modal-fullscreen woms-modal-fullscreen
        <Modal isOpen={show} toggle={setShow} size='xl' >
            <ModalHeader tag={'h4'} toggle={setShow}>NEW PRODUCT</ModalHeader>
            <ModalBody>
                <Form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <Label className="form-label">Title</Label>
                                <Input 
                                    type="text" 
                                    className="form-control"
                                
                                />
                            </div>
                        </Col>
                    </Row>
                </Form>
                
            </ModalBody>
        </Modal>
    )
        
    
}

export default ProductAddModal

/* case insensitive string comparision */
export function isStringEqualIgnoreCase(str1, str2) {
    return str1.localeCompare(str2, undefined, {sensitivity: 'base'}) === 0;
}


export function getRoles(womsUser, currentOrg) {
    if (womsUser && womsUser.userRoles) {
        return womsUser.userRoles
        .filter(role => !role.organizationId || role.organizationId == currentOrg.id)
        .map(role => role.role);
    }

    return [];
    
}

export function hasRole(womsUser, currentOrg, targetRole) {
    const roles = getRoles(womsUser, currentOrg);
    return roles.includes(targetRole);
}

export function getCountryName(countryCode,lang) {
    const regionNames = new Intl.DisplayNames(
        [lang], {type: 'region'}
    );
    return regionNames.of(countryCode);
}
import { useContext, useEffect, useState } from "react"
import { WomsServiceContext } from 'services/womsService';


export const useOrderLineItemsThumbnails = ({order}) => {

  const { womsSvc } = useContext(WomsServiceContext);
  const [thumbnails, setThumbnails] = useState([]);

  useEffect(()=> {
    if(order.id) {
      const skus = order.orderLineItems.map(l => l.storeListing.storeSku);
      womsSvc.getThumbnails(skus).then(({ data }) => {
        setThumbnails(data?.value);
      });
    }
  },[order, womsSvc])

  return { thumbnails };
};
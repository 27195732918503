import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { layoutTypes } from "./constants/layout";
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
import { initFirebaseBackend, registerDispatchForFirebaseCallback } from "./helpers/firebase_helper";

import fakeBackend from "./helpers/AuthType/fakeBackend";
import WomsService, { WomsServiceContext } from 'services/womsService';
import setupAxios from 'helpers/setup_axios';
import axios from 'axios';
import { refreshWomsUser } from 'store/actions';

// Activating fake backend
// fakeBackend();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

export const ME_URL = process.env.REACT_APP_WOMS_API_URL + "/WomsUser/me";

// init firebase backend
initFirebaseBackend(firebaseConfig);


const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const App = () => {

  setupAxios(axios);

  //registerStore(store)

  const dispatch = useDispatch();
  registerDispatchForFirebaseCallback(dispatch); // store setup 시 firebase를 init하기 때문에 따로 둘수 밖에 없다

  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }),shallowEqual);
  
  const Layout = getLayout(layoutType);

  return (
    <>
      <WomsServiceContext.Provider value={{ womsSvc: new WomsService() }}>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>}
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
      </WomsServiceContext.Provider>
    </>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;
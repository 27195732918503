import usFlag from "../assets/images/flags/us.jpg"
import korea from "../assets/images/flags/korea.png"

const languages = {

  en: {
    label: "English",
    flag: usFlag,
  },
  ko: {
    label: "한글",
    flag: korea,
  },
}

export default languages

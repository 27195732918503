import { useState, useEffect } from 'react';
import i18n from 'i18next';
import { PRICE_MOD_TYPE, ORDER_FEE_TYPE } from 'services/womsEnum';

export const usePriceModConfig = (priceModType, orderFeeType = "") => {

  const [priceModLineItemsKey, setPriceModLineItemsKey] = useState("");
  const [pModHeader, setHeader] = useState("");

  useEffect(() => {
    const priceModConfig = {
      [PRICE_MOD_TYPE.FEE]: {
        key: "feeLineItems",
        pModHeader: i18n.t(orderFeeType === "SHIPPING" ? "PRICE_SHIPPING" : "PRICE_FEE")
      },
      [PRICE_MOD_TYPE.DISCOUNT]: {
        key: "discountLineItems",
        pModHeader: i18n.t("PRICE_DISCOUNT")
      },
      [PRICE_MOD_TYPE.TAX]: {
        key: "taxLineItems",
        pModHeader: i18n.t("PRICE_TAX")
      },
    };

    const config = priceModConfig[priceModType];
    if (config) {
      setPriceModLineItemsKey(config.key);
      setHeader(config.pModHeader);
    }
  }, [priceModType, orderFeeType]);

  return { priceModLineItemsKey, pModHeader };
}

import React from "react";
import { Navigate } from "react-router-dom";

const Authmiddleware = (props) => {
  var authUser = localStorage.getItem("authUser");
  if (!authUser) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  return (<>
    {props.children}
  </>);
};

export default Authmiddleware;

import React, { useEffect, useState, useMemo, useContext } from "react"
import Moment from "react-moment"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import AdvancedTableContainer from "components/Common/AdvancedTableContainer"
import { WomsServiceContext } from "services/womsService"
import cf from "../../services/codeFormatter"
import i18n from "i18next"
import { withTranslation } from "react-i18next"
import OrganizationModal from "./OrganizationModal"
import { ENTITY_FILTER_TYPE } from "services/womsEnum"
import { getCountryName } from "helpers/woms_utils"

// Column
const Id = cell => {
  return cell.getValue() ? "#OR" + cell.getValue() : ""
}

const LegalName = cell => {
  return cell.getValue() ? cell.getValue() : ""
}

const BaseCurrency = cell => {
  return cell.getValue() ? cell.getValue() : ""
}
const Country = cell => {
  return cell.getValue() ? getCountryName(cell.getValue(),i18n.language) : ""
}
const LocalizedLegalName = cell => {
  return cell.getValue() ? cell.getValue() : ""
}

const IdNumber = cell => {
  return cell.getValue() ? cell.getValue() : ""
}

const CreatedOn = cell => {
  return cell.getValue() ? <Moment format="ll">{cell.getValue()}</Moment> : ""
}

const OrganizationList = props => {
  //meta title
  document.title = i18n.t("ORGANIZATIONS");

  const { womsSvc } = useContext(WomsServiceContext);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const columns = useMemo(
    () => [
      {
        header: i18n.t("ID"),
        accessorKey: "id",
        filterable: false,
        enableSorting: true,
        cell: cellProps => {
          return <Id {...cellProps} />
        },
      },
      {
        header: i18n.t("LEGAL_NAME"),
        accessorKey: "legalName",
        enableSorting: false,
        filterable: true,
        cell: cellProps => {
          return <LegalName {...cellProps} />
        },
      },

      {
        header: i18n.t("LOCALIZED_LEGAL_NAME"),
        accessorKey: "localizedLegalName",
        enableSorting: false,
        filterable: true,
        cell: cellProps => {
          return <LocalizedLegalName {...cellProps} />
        },
      },
      {
        header: i18n.t("COUNTRY"),
        accessorKey: "country",
        enableSorting: false,
        filterable: false,
        cell: cellProps => {
          return <Country {...cellProps} />
        },
      },
      {
        header: i18n.t("BASE_CURRENCY"),
        accessorKey: "baseCurrency",
        enableSorting: false,
        filterable: false,
        cell: cellProps => {
          return <BaseCurrency {...cellProps} />
        },
      },
      {
        header: i18n.t("ID_NUMBER"),
        accessorKey: "idNumber",
        enableSorting: false,
        filterable: true,
        cell: cellProps => {
          return <IdNumber {...cellProps} />
        },
      },
      {
        header: i18n.t("CREATED_ON"),
        accessorKey: "createdOn",
        enableSorting: true,
        cell: cellProps => {
          return <CreatedOn {...cellProps} />
        },
      },
      {
        header: i18n.t("ACTION"),
        enableSorting: false,
        cell: cellProps => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  href="#"
                  onClick={() => {
                    const entityData = cellProps.row.original
                    onEditClick(entityData)
                  }}
                >
                  <i
                    className="mdi mdi-pencil font-size-16 text-success me-1"
                    id="edittooltip"
                  ></i>
                  {i18n.t("EDIT")}
                  <UncontrolledTooltip placement="top" target="edittooltip">
                  {i18n.t("EDIT")}
                  </UncontrolledTooltip>
                </DropdownItem>

              </DropdownMenu>
            </UncontrolledDropdown>
          )
        },
      },
    ],
    [i18n.language]
  );
  
  const [deleteModal, setDeleteModal] = useState(false);
  const [entityData, setEntityData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [rerender, setRerender] = useState(0);

  const onEditClick = entityData => {
    setEntityData(entityData)
    setErrorMessage("")
    setIsEdit(true)
    setModal(true)
  }

  const onAddClick = () => {
    setEntityData({})
    setErrorMessage("")
    setIsEdit(false)
    setModal(true)
  }

  const handleModalClose = isUpdated => {
    if (isUpdated) {
      setRerender(rerender + 1)
    }
    setModal(false)
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs altTitle={i18n.t("ORGANIZATION_LIST")} title={i18n.t("ORGANIZATIONS")} breadcrumbItem={i18n.t("LIST")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <AdvancedTableContainer
                    columns={columns}
                    odataFetch={womsSvc.searchOrganization}
                    initialSortBy={"Id"}
                    isGlobalFilter={true}
                    addButtonLabel={i18n.t("ADD_NEW_ORGANIZATION")}
                    filterColumns={["legalName","localizedLegalName"]}
                    onAddClick={onAddClick}
                    customPageSize={20}
                    className="table-advanced"
                    rerender={rerender}
                    isCustomFilter={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <OrganizationModal
        show={modal}
        isEdit={isEdit}
        id={entityData?.id}
        onClose={handleModalClose}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default withTranslation()(OrganizationList)

import React, { useEffect, useState, useMemo, useContext } from "react"
import Moment from "react-moment"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalFooter,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import AdvancedTableContainer from "components/Common/AdvancedTableContainer"
import { WomsServiceContext } from "services/womsService"
import i18n from "i18next"
import { withTranslation } from "react-i18next"
import { ENTITY_FILTER_TYPE } from "services/womsEnum"
import { formatToCurrency, formatToCurrencyRate } from "pages/Order/OrderUIHelper"

// Column
const Id = cell => {
  return cell.getValue() ? "#LO" + cell.getValue() : ""
}
const CurrencyCode = cell => {
  return cell.getValue() ? cell.getValue() : ""
}
const Rate = cell => {
  return <div className="text-end" style={{marginRight:"15px"}}>{cell.getValue() ? cell.getValue() : ''}</div>
}
const RateByBase = cell => {
  return <div className="text-end" style={{marginRight:"15px"}}>{cell.getValue() ? cell.getValue() : ''}</div>
}
const RateOn = cell => {
  return <div className="text-end" style={{marginRight:"15px"}}>{cell.getValue() ? <Moment format="LLL">{cell.getValue()}</Moment> : ""}</div>
}

const CurrencyRateListModal = props => {
  //meta title
  const { womsSvc } = useContext(WomsServiceContext)
  const { show,onClose } = props

  // Customber Column
  const columns = useMemo(
    () => [
      {
        header: i18n.t("CURRENCY_CODE"),
        accessorKey: "targetCurrencyCode",
        enableSorting: true,
        filterable: false,
        cell: cellProps => {
          return <CurrencyCode {...cellProps} />
        },
      },
      {
        id:'rate', // id needed if there is accessorFn and header is not text
        header: <div className="text-end" style={{marginRight:"15px"}}>{i18n.t('RATE')}</div>,
        accessorFn: data => { return formatToCurrencyRate(data.rate,data.targetCurrencyCode); },
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <Rate {...cellProps} />;
        }
      },
      {
        id:'rateByBase', // id needed if there is accessorFn and header is not text
        header: <div className="text-end" style={{marginRight:"15px"}}>{i18n.t('RATE_BASE_CURRENCY')}</div>,
        accessorFn: data => { return formatToCurrencyRate(1 / data.rate,data.baseCurrencyCode); },
        enableSorting: false,
        filterable: false,
        cell: (cellProps) => {
          return <RateByBase {...cellProps} />;
        }
      },
      {
        header: <div className="text-end" style={{marginRight:"15px"}}>{i18n.t('RATE_ON')}</div>,
        accessorKey: "rateOn",
        enableSorting: true,
        cell: cellProps => {
          return <RateOn {...cellProps} />
        },
      }
    ],
    [i18n.language]
  )


  return (
    <>
      <Modal isOpen={show} size="lg">
        <ModalBody>
        <Row>
            <Col>
            <div className="d-flex justify-content-between"><h4>
            {i18n.t("CURRENCY_RATE")}
            </h4>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onClose()}
                >
                  {i18n.t("CLOSE")}
                </button></div>
            </Col>
          </Row>
          <Row>
            <Col>
            <AdvancedTableContainer
                    columns={columns}
                    odataFetch={womsSvc.searchCurrencyRates}
                    odataExport={womsSvc.exportCurrencyRates}
                    entityNameForExport={"CurrencyRate"}
                    isExportButton={true}
                    initialSortBy={"updatedOn"}
                    isGlobalFilter={true}
                    customPageSize={10}
                    className="table-advanced"
                    filterColumns={["targetCurrencyCode"]}
                    isCustomFilter={false}
                  />
            </Col>
          </Row>
          
          </ModalBody>
          </Modal>
    </>
  )
}

export default withTranslation()(CurrencyRateListModal)

import React, { useContext, useEffect, useState } from 'react'
import {
    Button,
    Col,
    Row,
    Label,
    Card,
    CardTitle,
    CardHeader,
    CardImg,
    CardBody,
    Container,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'
import { withTranslation } from "react-i18next";
import ProductTags from "./ProductTags/ProductTags"
import ProductImages from "./ProductImages/ProductImages"
import ProductBasicInfo from './ProductBasicInfo/ProductBasicInfo'
import ProductVariants from './ProductVariants/ProductVariants';
import ActivityLogs from 'pages/ActivityLog/ActivityLogs';
import { useNavigate, useParams } from 'react-router-dom';
import { WomsServiceContext } from 'services/womsService';
import LinearProgress from 'components/Common/LinearProgress';
import { getStatusCssClasses } from '../ProductUIHelper';
import cf from "../../../services/codeFormatter";
import i18n from 'i18next';
import ProductStoreListings from './ProductStoreListings/ProductStoreListings';


const ProductDetails = props => {
    document.title = "Product Detail"
    
    let { id } = useParams();
    let navigate = useNavigate();
    const { womsSvc } = useContext(WomsServiceContext);
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState({});

    useEffect(() => {

        if (id) {
            setIsLoading(true);
            womsSvc.getProduct(id).then(({ data }) => {
                setProduct(data);
                setIsLoading(false);

            });
        }

    }, [id]);

    const handleTagUpdate = (tags) => {
        setProduct({...product, tags: tags});
    }
    return (
        <>
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <div style={{ display: "flex" }}>
                                    <Button
                                        type="button"
                                        className="btn btn-light btn-sm mt-1"
                                        onClick={() => {
                                            navigate('/product');
                                        }}
                                    >
                                        <i className="bx bx-arrow-back" />
                                    </Button>
                                    <h4 className="mb-0 pt-2 ms-2 font-size-18">{"#PD" + id}</h4>
                                    <span className={getStatusCssClasses(product.status) + " mt-3 ms-2"} >{cf.format("ProductStatus", product.status)}</span>
                                    

                                </div>
                                <div className="page-title-right">
                                    
                                    <UncontrolledButtonDropdown>
                                        <Button disabled={isLoading} className="btn btn-secondary btn-sm mt-1 ms-2" onClick={() => {
                                        }}>
                                            {i18n.t("MORE_ACTIONS")}
                                        </Button>
                                        <DropdownToggle caret
                                            className="btn btn-secondary btn-sm mt-1">
                                            <i className="mdi mdi-chevron-down" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem disabled onClick={() => {
                                            }}>{i18n.t("DELETE")}</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {isLoading && <LinearProgress />}
                    <Row>
                        <Col xl={9} lg={12}>
                            <Row>
                                <Col>
                                <ProductBasicInfo product={product} isLoading={isLoading}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <ProductStoreListings productId={product?.id} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <ActivityLogs productId={id} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={3} lg={12}>
                            <Row>
                                <Col>
                                    <ProductVariants productId={product?.id}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ProductImages images={product?.images} isLoading={isLoading}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ProductTags productId={product?.id} tags={product?.tags} isLoading={isLoading} tagUpdated={handleTagUpdate}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default withTranslation()(ProductDetails)

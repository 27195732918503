import React, { useContext, useEffect, useState } from "react";
import {
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Container, Row
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import classNames from "classnames";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import LatestTranaction from "./LatestTranaction";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useSelector, useDispatch } from "react-redux";

import { WomsServiceContext } from "services/womsService";
import { Link, useNavigate } from "react-router-dom";
import StackedColumnChart from "./StackedColumnChart";
import { DASHBOARD_PERIOD_TYPE } from "services/womsEnum";
import DashboardParamsModal from "./DashboardParamsModal";
import OrdersWidget from "./widgets/OrdersWidget";
import SalesWidget from "./widgets/SalesWidget";
import AvgPriceWidget from "./widgets/AvgPriceWidget";
import cf from "../../services/codeFormatter";
import i18n from 'i18next';
import TopSkusWidget from "./widgets/TopSkusWidget";
import TopStoresWidget from "./widgets/TopStoresWidget";
import OrderStatusWidget from "./widgets/OrderStatusWidget";
import StoreStatisticsWidget from "./widgets/StoreStatisticsWidget";

const Dashboard = props => {

  //meta title
  document.title = i18n.t("DASHBOARD") + " | Worldshoppe OMS";
  const womsUser = useSelector(state => state.womsUser);
  const organization = useSelector(state => state.organization);
  const hubConnectionStatus= useSelector(state => state.hubconnectionStatus);
  const { womsSvc } = useContext(WomsServiceContext);
  const [dashboardParams, setDashboardParams] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {

  },[])
  useEffect(() => {
    if (hubConnectionStatus) {
    const param = localStorage.getItem("dashboardParams");
    if (param) {
      // need some version control here

      setDashboardParams(JSON.parse(param));
    } else {
      setDashboardParams({
        "periodType" : DASHBOARD_PERIOD_TYPE.LAST_30_DAYS,
        "fromDate" : '',
        'toDate' : ''
      })
    }
  }
},[hubConnectionStatus])

  const showParamEditModal = () => {
    setShowModal(true);
  }
  const handleModalClose = (params) => {
    if (params) {
      setDashboardParams(params);
    }
    setShowModal(false);
  }
  const invokeForceUpdate = () => {
    setForceUpdate(forceUpdate + 1);
  }
  useEffect(() => {
    if (womsUser && womsUser.organizations?.length == 0) {
      navigate('/newRegistration');
    }
  },[womsUser])
  useEffect(() => {
    if (dashboardParams) { 
    localStorage.setItem("dashboardParams",JSON.stringify(dashboardParams));
    }
  },[dashboardParams])
  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {<Breadcrumbs
            altTitle={props.t("DASHBOARDS")}
            breadcrumbItem={cf.format("DashboardPeriodType",dashboardParams?.periodType)}
            button={<Button
                  type="button"
                  className="btn btn-light btn-sm woms-config-btn"
                  onClick={showParamEditModal}
              >
                  <i className="mdi mdi-wrench" />
              </Button>}
              breadcrumbButton={<Button
                type="button"
                className="btn btn-light btn-sm woms-config-btn"
                onClick={invokeForceUpdate}
            >
                <i className="mdi mdi-refresh" />
            </Button>
            }
          />}
          
        <Row>
            <Col xl="4">
              <WelcomeComp />
              <OrderStatusWidget  params={dashboardParams} forceUpdate={forceUpdate}/>
            </Col>
            <Col xl="8">
              <Row>
                  <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <OrdersWidget params={dashboardParams} forceUpdate={forceUpdate}/>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <SalesWidget params={dashboardParams} forceUpdate={forceUpdate}/>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <AvgPriceWidget params={dashboardParams} forceUpdate={forceUpdate}/>
                      </CardBody>
                    </Card>
                  </Col>
              </Row>

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">{i18n.t("DASHBOARD.STORE_STATISTICS")}</h4>
                    
                  </div>
                  {/* <div className="clearfix"></div> */}
                  <StoreStatisticsWidget params={dashboardParams} forceUpdate={forceUpdate}/>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              <TopStoresWidget  params={dashboardParams} forceUpdate={forceUpdate}/>
            </Col>
            <Col xl="6">
              <TopSkusWidget  params={dashboardParams} forceUpdate={forceUpdate}/>
            </Col>
          </Row>

          </Container>
          </div>
          <DashboardParamsModal show={showModal} onClose={handleModalClose} params={dashboardParams}/>
    </>
  );
};


export default withTranslation()(Dashboard);

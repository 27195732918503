export const USER_STATUS = {
    ACTIVE : 'ACTIVE',
    INACTIVE : 'INACTIVE',
    PENDING : 'PENDING',
    DELETED : 'DELETED'
}

export const USER_ROLE = {
    ORG_ADMIN: "ORG_ADMIN",
    SYS_ADMIN: "SYS_ADMIN"
}

// Product
export const PRODUCT_STATUS = {
    ACTIVE : 'ACTIVE',
    INACTIVE : 'INACTIVE',
    DELETED : 'DELETED'
}

export const ORDER_STATUS = {
    DRAFT : 'DRAFT',
    SUBMITTED : 'SUBMITTED',
    PENDING_APPROVAL : 'PENDING_APPROVAL',
    READY_TO_FULFILL :'READY_TO_FULFILL',
    PENDING_BILLING : 'PENDING_BILLING',
    COMPLETED : 'COMPLETED',
    CANCELLED : 'CANCELLED',
    DELETED : 'DELETED'
}

export const ORDER_FULFILLMENT_STATUS = {
    NONE : 'NONE',
    SHIPPED : 'SHIPPED',
    PARTIALLY_SHIPPED : 'PARTIALLY_SHIPPED'
}

export const FULFILLMENT_STATUS = {
    DRAFT : 'DRAFT',
    SUBMITTED : 'SUBMITTED',
    PICKED : 'PICKED',
    PACKED : 'PACKED',
    SHIPPED : 'SHIPPED',
    PARTIALLY_SHIPPED : 'PARTIALLY_SHIPPED',
    CANCELLED : 'CANCELLED',
    DELETED : 'DELETED'
}

export const ENTITY_FILTER_TYPE = {
    SALES_ORDER : 'SALES_ORDER',
    PRODUCT : 'PRODUCT',
    USER: "USER",
    STORE: 'STORE',
    LOCATION: "LOCATION",
    USER_SUPER_ADMIN: "USER_SUPER_ADMIN"
}

export const CURRENCY_CODE = {
    AED: 'AED', // United Arab Emirates Dirham
    AFN: 'AFN', // Afghan Afghani
    ALL: 'ALL', // Albanian Lek
    AMD: 'AMD', // Armenian Dram
    ANG: 'ANG', // Netherlands Antillean Guilder
    AOA: 'AOA', // Angolan Kwanza
    ARS: 'ARS', // Argentine Peso
    AUD: 'AUD', // Australian Dollar
    AWG: 'AWG', // Aruban Florin
    AZN: 'AZN', // Azerbaijani Manat
    BAM: 'BAM', // Bosnia-Herzegovina Convertible Mark
    BBD: 'BBD', // Barbadian Dollar
    BDT: 'BDT', // Bangladeshi Taka
    BGN: 'BGN', // Bulgarian Lev
    BHD: 'BHD', // Bahraini Dinar
    BIF: 'BIF', // Burundian Franc
    BMD: 'BMD', // Bermudian Dollar
    BND: 'BND', // Brunei Dollar
    BOB: 'BOB', // Bolivian Boliviano
    BRL: 'BRL', // Brazilian Real
    BSD: 'BSD', // Bahamian Dollar
    BTN: 'BTN', // Bhutanese Ngultrum
    BWP: 'BWP', // Botswana Pula
    BYN: 'BYN', // Belarusian Ruble
    BZD: 'BZD', // Belize Dollar
    CAD: 'CAD', // Canadian Dollar
    CDF: 'CDF', // Congolese Franc
    CHF: 'CHF', // Swiss Franc
    CLP: 'CLP', // Chilean Peso
    CNY: 'CNY', // Chinese Yuan
    COP: 'COP', // Colombian Peso
    CRC: 'CRC', // Costa Rican Colón
    CUP: 'CUP', // Cuban Peso
    CVE: 'CVE', // Cape Verdean Escudo
    CZK: 'CZK', // Czech Koruna
    DJF: 'DJF', // Djiboutian Franc
    DKK: 'DKK', // Danish Krone
    DOP: 'DOP', // Dominican Peso
    DZD: 'DZD', // Algerian Dinar
    EGP: 'EGP', // Egyptian Pound
    ERN: 'ERN', // Eritrean Nakfa
    ETB: 'ETB', // Ethiopian Birr
    EUR: 'EUR', // Euro
    FJD: 'FJD', // Fijian Dollar
    FKP: 'FKP', // Falkland Islands Pound
    FOK: 'FOK', // Faroese Króna
    GBP: 'GBP', // British Pound Sterling
    GEL: 'GEL', // Georgian Lari
    GGP: 'GGP', // Guernsey Pound
    GHS: 'GHS', // Ghanaian Cedi
    GIP: 'GIP', // Gibraltar Pound
    GMD: 'GMD', // Gambian Dalasi
    GNF: 'GNF', // Guinean Franc
    GTQ: 'GTQ', // Guatemalan Quetzal
    GYD: 'GYD', // Guyanaese Dollar
    HKD: 'HKD', // Hong Kong Dollar
    HNL: 'HNL', // Honduran Lempira
    HRK: 'HRK', // Croatian Kuna
    HTG: 'HTG', // Haitian Gourde
    HUF: 'HUF', // Hungarian Forint
    IDR: 'IDR', // Indonesian Rupiah
    ILS: 'ILS', // Israeli New Shekel
    IMP: 'IMP', // Isle of Man Pound
    INR: 'INR', // Indian Rupee
    IQD: 'IQD', // Iraqi Dinar
    IRR: 'IRR', // Iranian Rial
    ISK: 'ISK', // Icelandic Króna
    JEP: 'JEP', // Jersey Pound
    JMD: 'JMD', // Jamaican Dollar
    JOD: 'JOD', // Jordanian Dinar
    JPY: 'JPY', // Japanese Yen
    KES: 'KES', // Kenyan Shilling
    KGS: 'KGS', // Kyrgystani Som
    KHR: 'KHR', // Cambodian Riel
    KID: 'KID', // Kiribati Dollar
    KMF: 'KMF', // Comorian Franc
    KRW: 'KRW', // South Korean Won
    KWD: 'KWD', // Kuwaiti Dinar
    KYD: 'KYD', // Cayman Islands Dollar
    KZT: 'KZT', // Kazakhstani Tenge
    LAK: 'LAK', // Laotian Kip
    LBP: 'LBP', // Lebanese Pound
    LKR: 'LKR', // Sri Lankan Rupee
    LRD: 'LRD', // Liberian Dollar
    LSL: 'LSL', // Lesotho Loti
    LYD: 'LYD', // Libyan Dinar
    MAD: 'MAD', // Moroccan Dirham
    MDL: 'MDL', // Moldovan Leu
    MGA: 'MGA', // Malagasy Ariary
    MKD: 'MKD', // Macedonian Denar
    MMK: 'MMK', // Myanma Kyat
    MNT: 'MNT', // Mongolian Tugrik
    MOP: 'MOP', // Macanese Pataca
    MRU: 'MRU', // Mauritanian Ouguiya
    MUR: 'MUR', // Mauritian Rupee
    MVR: 'MVR', // Maldivian Rufiyaa
    MWK: 'MWK', // Malawian Kwacha
    MXN: 'MXN', // Mexican Peso
    MYR: 'MYR', // Malaysian Ringgit
    MZN: 'MZN', // Mozambican Metical
    NAD: 'NAD', // Namibian Dollar
    NGN: 'NGN', // Nigerian Naira
    NIO: 'NIO', // Nicaraguan Córdoba
    NOK: 'NOK', // Norwegian Krone
    NPR: 'NPR', // Nepalese Rupee
    NZD: 'NZD', // New Zealand Dollar
    OMR: 'OMR', // Omani Rial
    PAB: 'PAB', // Panamanian Balboa
    PEN: 'PEN', // Peruvian Nuevo Sol
    PGK: 'PGK', // Papua New Guinean Kina
    PHP: 'PHP', // Philippine Peso
    PKR: 'PKR', // Pakistani Rupee
    PLN: 'PLN', // Polish Złoty
    PYG: 'PYG', // Paraguayan Guarani
    QAR: 'QAR', // Qatari Rial
    RON: 'RON', // Romanian Leu
    RSD: 'RSD', // Serbian Dinar
    RUB: 'RUB', // Russian Ruble
    RWF: 'RWF', // Rwandan Franc
    SAR: 'SAR', // Saudi Riyal
    SBD: 'SBD', // Solomon Islands Dollar
    SCR: 'SCR', // Seychellois Rupee
    SDG: 'SDG', // Sudanese Pound
    SEK: 'SEK', // Swedish Krona
    SGD: 'SGD', // Singapore Dollar
    SHP: 'SHP', // Saint Helena Pound
    SLE: 'SLE', // Sierra Leonean Leone
    SLL: 'SLL', // Sierra Leonean Leone
    SOS: 'SOS', // Somali Shilling
    SRD: 'SRD', // Surinamese Dollar
    SSP: 'SSP', // South Sudanese Pound
    STN: 'STN', // São Tomé and Príncipe Dobra
    SYP: 'SYP', // Syrian Pound
    SZL: 'SZL', // Swazi Lilangeni
    THB: 'THB', // Thai Baht
    TJS: 'TJS', // Tajikistani Somoni
    TMT: 'TMT', // Turkmenistani Manat
    TND: 'TND', // Tunisian Dinar
    TOP: 'TOP', // Tongan Paʻanga
    TRY: 'TRY', // Turkish Lira
    TTD: 'TTD', // Trinidad and Tobago Dollar
    TVD: 'TVD', // Tuvaluan Dollar
    TWD: 'TWD', // New Taiwan Dollar
    TZS: 'TZS', // Tanzanian Shilling
    UAH: 'UAH', // Ukrainian Hryvnia
    UGX: 'UGX', // Ugandan Shilling
    USD: 'USD', // United States Dollar
    UYU: 'UYU', // Uruguayan Peso
    UZS: 'UZS', // Uzbekistani Som
    VES: 'VES', // Venezuelan Bolívar Soberano
    VND: 'VND', // Vietnamese Đồng
    VUV: 'VUV', // Vanuatu Vatu
    WST: 'WST', // Samoan Tala
    XAF: 'XAF', // CFA Franc BEAC
    XCD: 'XCD', // East Caribbean Dollar
    XDR: 'XDR', // Special Drawing Rights
    XOF: 'XOF', // CFA Franc BCEAO
    XPF: 'XPF', // CFP Franc
    YER: 'YER', // Yemeni Rial
    ZAR: 'ZAR', // South African Rand
    ZMW: 'ZMW', // Zambian Kwacha
    ZWL: 'ZWL' // Zimbabwean Dollar
}

export const FILTER_OPERATOR_TYPE = {
    EQUAL : 'EQUAL',
    NOT_EQUAL : 'NOT_EQUAL',
    LESS_THAN : 'LESS_THAN',
    GREATER_THAN : 'GREATER_THAN',
    BETWEEN : 'BETWEEN',
    CONTAINS : 'CONTAINS',
    NOT_CONTAINS : 'NOT_CONTAINS'
}

export const FILTER_VALUE_TYPE = {
    NUMBER : 'NUMBER',
    TEXT : 'TEXT',
    ENUM : 'ENUM',
    DATE : 'DATE'
}

export const STORE_TYPE = {
    UNKNOWN : 'UNKNOWN',
    AMAZON : 'AMAZON',
    SHOPIFY : 'SHOPIFY',
    WOOCOMMERCE : 'WOOCOMMERCE',
    SHOPEE : "SHOPEE",
    SABANG_NET : "SABANG_NET",
    SHEIN : "SHEIN",
    TIKTOK : "TIKTOK",
    EBAY : "EBAY",
    FLIPSHOP : "FLIPSHOP"
}

export const STORE_SYNC_TYPE = {
    NONE : 'NONE',
    PASSIVE : 'PASSIVE',
    MASTER : 'MASTER',
    TWO_WAY : 'TWO_WAY'
}

export const ORDER_FEE_TYPE = {
    UNKNOWN : 'UNKNOWN',
    SHIPPING : 'SHIPPING',
    OTHER : 'OTHER',
}
export const BUNDLE_TYPE = {
    STANDARD: 'STANDARD',
    BUNDLE: 'BUNDLE',
    KIT: 'KIT'
}

export const ACTIVITY_LOG_TYPE = {
    USER_NOTE : 'USER_NOTE',
    ADMIN_LOG : 'ADMIN_LOG',
    SYSTEM_LOG : 'SYSTEM_LOG',
    OTHER : 'OTHER'
}

export const SYNC_STATUS = {
    NONE : 'NONE',
    REQUESTED : 'REQUESTED',
    PROCESSING : 'PROCESSING',
    COMPLETED : 'COMPLETED',    
    COMPLETED_WITH_ISSUE : 'COMPLETED_WITH_ISSUE',
    FAILED : 'FAILED',
    OTHER : 'OTHER'
}
export const BATCH_REQUEST_TYPE = {
    PRODUCT_SYNC : 'PRODUCT_SYNC'
}

export const PRODUCT_SYNC_TYPE = {
    CREATE_NEW : 'CREATE_NEW',
    REPLACE : 'REPLACE',
    MERGE : 'MERGE'
}

export const CLIENT_SYNC_TYPE  = {
    CREATED : "CREATED",
    UPDATED : "UPDATED",
    DELETED : "DELETED"
}

export const TAG_SHOW_OPTION = {
    ALL : "ALL",
    SELECTED : "SELECTED",
    NOT_SELECTED : "NOT_SELECTED"
}

export const DASHBOARD_PERIOD_TYPE = {
    LAST_DAY : 'LAST_DAY',
    LAST_7_DAYS : 'LAST_7_DAYS',
    LAST_30_DAYS : 'LAST_30_DAYS',
    LAST_90_DAYS : 'LAST_90_DAYS',
    RANGE : 'RANGE'
}
export const  DASHBOARD_TYPE = {
    UNKNOWN : 'UNKNOWN',
    ORDERS : 'ORDERS',
    SALES : 'SALES',
    AVG_PRICE : 'AVG_PRICE',
    ORDER_STATUS : 'ORDER_STATUS',
    STORE_STAT : 'STORE_STAT',
    TOP_SKUS : 'TOP_SKUS',
    TOP_STORES : 'TOP_STORES'
}

export const DASHBOARD_STATUS = {
    CREATED : 'CREATED',
    PROCESSING : 'PROCESSING',
    COMPLETED : 'COMPLETED',
    FAILED : 'FAILED'
}

export const ENTITY_FILTER_USER_FIELD = {
    STATUS : 'STATUS',
    ORDERED_ON:'ORDERED_ON',
    CREATED_ON:'CREATED_ON',
    FIRST_NAME:'FIRST_NAME',
    LAST_NAME:"LAST_NAME",
    TOTAL_AMOUNT:'TOTAL_AMOUNT',
    TOTAL_ITEMS:'TOTAL_ITEMS',
    SKU:'SKU',
    ITEM_NAME:'ITEM_NAME'
}

export const REPORT_STATUS = {
    CREATED : 'CREATED',
    PROCESSING : 'PROCESSING',
    COMPLETED : 'COMPLETED',
    FAILED : 'FAILED'
}

export const  REPORT_TYPE = {
    UNKNOWN : 'UNKNOWN',
    SALES_BY_STORE : 'SALES_BY_STORE',
    SALES_BY_SKU : 'SALES_BY_SKU',
    SKU_TREND_BY_STORE : 'SKU_TREND_BY_STORE',
    PROFIT_BY_SKU : 'PROFIT_BY_SKU',
    PROFIT_BY_STORE : 'PROFIT_BY_STORE'
}

export const REPORT_PERIOD_TYPE = {
    LAST_DAY : 'LAST_DAY',
    LAST_WEEK :'LAST_WEEK',
    THIS_WEEK :'THIS_WEEK',
    LAST_MONTH :'LAST_MONTH',
    THIS_MONTH :'THIS_MONTH',
    RANGE : 'RANGE'
}
export const REPORT_SKU_TYPE = {
    TOP_3 : 'TOP_3',
    TOP_7 :'TOP_7',
    SELECT :'SELECT'
}
export const NOTIFICATION_TYPE = {
    SYSTEM_NOTICE : 'SYSTEM_NOTICE',
    SYSTEM_ALERT :'SYSTEM_ALERT',
    USER_MESSAGE :'USER_MESSAGE',
    ORG_NOTICE : 'ORG_NOTICE',
}

export const NOTIFICATION_SEND_TO_TYPE = {
    ALL_USER : 'ALL_USER',
    CURRENT_ORGANIZATION :'CURRENT_ORGANIZATION',
    SPECIFIC_USER :'SPECIFIC_USER'
}

export const FEEDBACK_TYPE = {
    UNSPECIFIED : 'UNSPECIFIED',
    BUG_REPORT :'BUG_REPORT',
    FEATURE_SUGGESTION :'FEATURE_SUGGESTION',
    INTERNALIZATION :'INTERNALIZATION', 
    GENERAL_FEEDBACK :'GENERAL_FEEDBACK'
}

export const FULFILLMENT_COST_ALLOCATION_TYPE = {
    BY_QUANTITY :'BY_QUANTITY',
    BY_AMOUNT :'BY_AMOUNT',
}

export const EDIT_MODAL_TYPE = {
    ORDER_DETAILS: "ORDER_DETAILS",
    ORDER_NOTES: "ORDER_NOTES",
    ORDER_SHIPPING_ADDRESS: "ORDER_SHIPPING_ADDRESS",
    ORDER_BILLING_ADDRESS: "ORDER_BILLING_ADDRESS",
    ORDER_ITEMS: "ORDER_ITEMS",
    ORDER_SUMMARY: "ORDER_SUMMARY"

}

export const MODAL_TITLE = {
    ORDER_DETAILS: "Details",
    ORDER_NOTES: "Notes",
    ORDER_SHIPPING_ADDRESS: "Shipping Address",
    ORDER_BILLING_ADDRESS: "Billing Address",
    ORDER_ITEMS: "Order Items",
}

export const TYPEAHEAD_VALUE_RETURN_TYPE = {
    ID: "ID",
    OBJECT: "OBJECT"
}

export const PRICE_MOD_TYPE = {
    FEE: "FEE",
    DISCOUNT: "DISCOUNT",
    TAX: "TAX",
}

export const PRICE_MOD_KEYS = {
    FEE_LINE_ITEMS: "feeLineItems",
    DISCOUNT_LINE_ITEMS: "discountLineItems",
    TAX_LINE_ITEMS: "taxLineItems",
}

export const PRICE_MOD_CHANGE_TYPE = {
    AMOUNT: "AMOUNT",
    NAME: "NAME"
}

export const ITEM_VALUE_CHANGE_TYPE = {
    QUANTITY: "QUANTITY",
    PRICE: "PRICE"
}

export const PRICE_MOD_ROW_TYPE = {
    DEFAULT: "DEFAULT",
    ITEM: "ITEM"
}

export const SUBSCRIPTION_PLAN_TYPE = {

    FREE : "FREE",
    BASIC : "BASIC",
    PRO : "PRO",
    ENTERPRISE : "ENTERPRISE",
    INSIDER : "INSIDER",
}

export const ENROLLMENT_STATUS = {

    SUBMITTED : "SUBMITTED",
    PAYMENT_PENDING : "PAYMENT_PENDING",
    DOWNGRADE_PENDING : "DOWNGRADE_PENDING",
    COMPLETED : "COMPLETED",
    CANCELLED : "CANCELLED",
}

export const ENROLLMENT_TYPE = {

    UPGRADE : "UPGRADE",
    DOWNGRADE : "DOWNGRADE",
}


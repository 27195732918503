import React, { useContext, useEffect, useState } from "react"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Form,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup";
import i18n from 'i18next';
import { WomsServiceContext } from "services/womsService";
import ControlledTypehead from "components/Common/ControlledTypehead";
import BrandModal from "pages/Brand/BrandModal";

const StoreListingModal = props => {
  const { show, isEdit, id, onClose } = props;
  const { womsSvc } = useContext(WomsServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [brandModal, setBrandModal] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: '',
      storeSku: '',
      name: '',
      productId: '',
      brandId: '',
    },
    validationSchema: Yup.object({
      // productId: Yup.string().required(i18n.t("VALIDATION.REQUIRED"))
    }),

    onSubmit: (values) => {
      womsSvc.updateStoreListing(values).then(({ data }) => {
        formik.resetForm();
        onClose(true);
      });
    },
  });

  useEffect(() => {
    if (show) {
      refreshBrandList();

      if (id) {
        setIsLoading(true);
        womsSvc.getStoreListing(id).then(({ data }) => {
          formik.setValues(data);
          setIsLoading(false);
        });
      }
    } else {
      formik.resetForm();
    }
  }, [show]);

  const refreshBrandList = () => {
    setIsLoading(true);
    womsSvc.getBrands().then(({ data }) => {
      setBrandList(data);
      setIsLoading(false);
    });
  }

  const handleBrandModalClose = (isUpdated) => {
    if (isUpdated) {
      refreshBrandList();
    }
    setBrandModal(false);
  }

  return (
    <>
      <Modal isOpen={show} size="lg">
        <ModalHeader tag="h4">
          {!!isEdit ? i18n.t('EDIT_MAPPING') : i18n.t('NEW_MAPPING')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Row>
              {isEdit && <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{i18n.t('ID')}</Label>
                  <Input
                    name="id"
                    type="text"
                    readOnly
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.id ? ("#SL" + formik.values.id) : ""}
                    invalid={
                      formik.touched.id && formik.errors.id ? true : false
                    }
                    disabled
                  />
                  {formik.touched.id && formik.errors.id ? (
                    <FormFeedback type="invalid">{formik.errors.id}</FormFeedback>
                  ) : null}
                </div>
              </Col>}
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{i18n.t('STORE_SKU')}</Label>
                  <Input
                    name="storeSku"
                    type="text"
                    placeholder={i18n.t('STORE_SKU')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.storeSku || ""}
                    invalid={
                      formik.touched.storeSku && formik.errors.storeSku ? true : false
                    }
                    disabled
                  />
                  {formik.touched.storeSku && formik.errors.storeSku ? (
                    <FormFeedback type="invalid">{formik.errors.storeSku}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{i18n.t('BRAND')}</Label>
                  <div className="d-flex flex-row">
                    <Input
                      name="brandId"
                      type="select"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.brandId || ""}
                      invalid={
                        formik.touched.brandId && formik.errors.brandId ? true : false
                      }
                    >
                      <option key={''} value={''}>
                        {i18n.t('NOT_SPECIFIED')}
                      </option>
                      {brandList.map(el => (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      ))}
                    </Input>
                    &nbsp;
                    <Button outline onClick={() => { setBrandModal(true) }}>
                      {formik.values.brandId ? <i className="mdi mdi-pencil" /> : <i className="mdi mdi-plus" />}
                    </Button>
                  </div>
                  {formik.touched.brandId && formik.errors.brandId ? (
                    <FormFeedback type="invalid">{formik.errors.brandId}</FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label className="form-label">{i18n.t('NAME')}</Label>
                  <Input
                    name="name"
                    type="text"
                    placeholder={i18n.t("STORE_NAME")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name || ""}
                    invalid={
                      formik.touched.name && formik.errors.name ? true : false
                    }
                    disabled
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label className="form-label">{i18n.t('PRODUCT')}</Label>
                  <ControlledTypehead
                    odataFetch={womsSvc.searchProduct}
                    value={formik.values.productId}
                    filterColumn={["name", 'sku']}
                    labelKey={(option) => {
                      return `${option["sku"] + " : " + option["name"]}`
                    }}
                    valueSelected={(id) => {
                      formik.setFieldValue('productId', id);
                    }}
                    invalid={
                      formik.errors.productId ? true : false
                    }
                  />
                  {formik.touched.productId && formik.errors.productId ? (
                    <FormFeedback type="invalid">{formik.errors.productId}</FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

          </ModalBody>
          <ModalFooter>
            <Row>
              <Col md="12">
                <div className="text-end mt-2">
                  <button type="button" className="btn btn-secondary" onClick={() => onClose()} disabled={isLoading}>{i18n.t("CANCEL")}</button>
                  &nbsp;&nbsp;
                  <button
                    type="submit"
                    className="btn btn-success save-integration"
                    disabled={isLoading}
                  >
                    {i18n.t("SAVE")}
                  </button>
                </div>
              </Col>
            </Row>
          </ModalFooter>
        </Form>
      </Modal>
      <BrandModal
        show={brandModal}
        id={formik.values.brandId}
        onClose={handleBrandModalClose}
      />
    </>
  )
}

export default StoreListingModal;
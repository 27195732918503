import React, { useContext, useEffect, useMemo, useState } from "react"
import i18n from 'i18next';
import { WomsServiceContext } from "services/womsService";
import { DASHBOARD_STATUS, DASHBOARD_TYPE } from "services/womsEnum";
import { updateSalesWidget } from "store/woms-dashboard/sales/actions";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "components/Common/LinearProgress";
import { formatToCurrency } from "pages/Order/OrderUIHelper";
import { updateAvgPriceWidget } from "store/woms-dashboard/avg-price/actions";



const AvgPriceWidget = props => {
  const { params,forceUpdate } = props;
  const { womsSvc } = useContext(WomsServiceContext)
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(false);
  
  const dispatch = useDispatch();
  const widget = useSelector(state => state.avgPriceWidget);

  useEffect(() => {
      if (params) {
        womsSvc.requestDashboard( DASHBOARD_TYPE.AVG_PRICE,params,false).then(({data}) => {
            dispatch(updateAvgPriceWidget(data));
        });
      }
  }, [params]);
  useEffect(() => {
    if (forceUpdate > 0) {
      womsSvc.requestDashboard( DASHBOARD_TYPE.AVG_PRICE,params,true).then(({data}) => {
          dispatch(updateAvgPriceWidget(data));
      });
    }
}, [forceUpdate]);
  useEffect(() => {
    if (widget && widget.data && widget.data.id) {
          setStatus(widget.data.status)
          if (widget.data.payload) {
              setData(JSON.parse(widget.data.payload));
          } else {
            setData({});
          }
    }
}, [widget]);

const percentChanges = (current,prev) => {
  if (!current) return 0;
  if (!prev || prev == 0) {
    return current == 0 ? 0 : 100;
  }
  return ((current - prev) / prev) * 100;
};
  return (
    
    <div className="d-flex">
        <div className="flex-grow-1">
        {(!status || status == DASHBOARD_STATUS.PROCESSING) && <LinearProgress/>}
        <p className="text-muted fw-medium">
            {"AvgPrice"}
        </p>
        <h4 className="mb-1">{formatToCurrency(data.avgPriceCurrentPeriod,data.currency)}</h4>
        <p className="text-muted">
          <span className={`text-${percentChanges(data.avgPriceCurrentPeriod,data.avgPricePreviousPeriod) >= 0 ? 'success' : 'danger'} me-2`}>
            {" "}
            {Math.abs(percentChanges(data.avgPriceCurrentPeriod,data.avgPricePreviousPeriod)).toFixed(2)}% <i className={`mdi mdi-arrow-${percentChanges(data.avgPriceCurrentPeriod,data.avgPricePreviousPeriod) >= 0  ? 'up' : 'down'}`}></i>{" "}
          </span>{" "}
          From last period
        </p>
        </div>
        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
        <span className="avatar-title rounded-circle bg-primary">
            <i
            className={
                "bx bx-purchase-tag-alt font-size-24"
            }
            ></i>
        </span>
        </div>
    </div>
  )
}
export default AvgPriceWidget;







import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { UPDATE_NEW_LISTINGS } from "./actionTypes"

export function* storeNewListingChange({payload:count}) {
}

export function* watchNewListingsChange() {
    yield takeEvery(UPDATE_NEW_LISTINGS, storeNewListingChange)
  }

function* badgeSaga() {
    yield all([fork(watchNewListingsChange)])
}

export default badgeSaga

import React, { useContext, useEffect, useState } from "react";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "components/Common/ChartsDynamicColor";
import { WomsServiceContext } from "services/womsService";
import { useDispatch, useSelector } from "react-redux";
import { updateOrderStatusWidget } from "store/woms-dashboard/order-status/actions";
import { DASHBOARD_STATUS, DASHBOARD_TYPE, ORDER_STATUS } from "services/womsEnum";
import cf from "../../../services/codeFormatter";
import i18n from 'i18next';

const OrderStatusWidget = (props) => {
  const { params,forceUpdate } = props;
  const { womsSvc } = useContext(WomsServiceContext)
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(false);
  const [chartValue, setChartValue] = useState(0);
  const dispatch = useDispatch();
  const widget = useSelector(state => state.orderStatusWidget);

  useEffect(() => {
      if (params) {
        womsSvc.requestDashboard( DASHBOARD_TYPE.ORDER_STATUS,params,false).then(({data}) => {
            dispatch(updateOrderStatusWidget(data));
        });
      }
  }, [params]);
  useEffect(() => {
    if (forceUpdate > 0) {
      womsSvc.requestDashboard( DASHBOARD_TYPE.ORDER_STATUS,params,true).then(({data}) => {
          dispatch(updateOrderStatusWidget(data));
      });
    }
}, [forceUpdate]);
  useEffect(() => {
    if (widget && widget.data && widget.data.id) {
          setStatus(widget.data.status)
          if (widget.data.payload) {
            var data = JSON.parse(widget.data.payload)
              setData(data);
              var val = Math.floor(((data.ordersByStatus.find(s=> s.status == ORDER_STATUS.PENDING_BILLING)?.count || 0 +
               data.ordersByStatus.find(s=> s.status == ORDER_STATUS.COMPLETED)?.count || 0) / 
               (data.ordersByStatus.find(s=> s.status == ORDER_STATUS.PENDING_BILLING)?.count || 0 +
               data.ordersByStatus.find(s=> s.status == ORDER_STATUS.COMPLETED)?.count || 0 +
               data.ordersByStatus.find(s=> s.status == ORDER_STATUS.READY_TO_FULFILL)?.count || 0)) * 100);
               if (isNaN(val)) val = 0;
               setChartValue(val);
              
          } else {
            setData({});
          }
    }
}, [widget]);

  const apexRadialChartColors = getChartColorsArray('["--bs-primary"]');
  const options = {
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: "13px",
            color: void 0,
            offsetY: 60,
          },
          value: {
            offsetY: 22,
            fontSize: "16px",
            color: void 0,
            formatter: function (e) {
              return e + "%";
            },
          },
        },
      },
    },
    colors: apexRadialChartColors,
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        shadeIntensity: 0.15,
        inverseColors: !1,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91],
      },
    },
    stroke: {
      dashArray: 4,
    },
    labels: [i18n.t("DASHBOARD.FULFILLED")],
  };
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">{i18n.t("DASHBOARD.ORDER_STATUS")}{(status && status == DASHBOARD_STATUS.FAILED) && <i className="bx bx-error-circle text-danger font-size-13"></i>}</CardTitle>
          <Row>
            <Col sm="6">
              <p className="text-muted">{i18n.t("DASHBOARD.THIS_PERIOD")}</p>
              <div className="d-flex"><h3>{data.ordersByStatus && data.ordersByStatus.find(s=> s.status == ORDER_STATUS.SUBMITTED) ? data.ordersByStatus.find(s=> s.status == ORDER_STATUS.SUBMITTED)?.count : 0}</h3><span className="text-primary mt-2 ms-2">{cf.format('OrderStatus',ORDER_STATUS.SUBMITTED)}</span></div>
              {data.ordersByStatus && data.ordersByStatus.map(st => st.status != ORDER_STATUS.SUBMITTED && <div key={st.status}>
              <p className="text-muted mb-0">
              {st.count}<span className={"text-" + (st.status == ORDER_STATUS.COMPLETED ? "success" : 
              st.status == ORDER_STATUS.READY_TO_FULFILL ? "danger" :
              st.status == ORDER_STATUS.PENDING_APPROVAL ? "warning" :
              st.status == ORDER_STATUS.PENDING_BILLING ? "primary" : "secondary") + " me-2"}>

                  {" "}
                  {cf.format('OrderStatus',st.status)}
                </span>{" "}
                
              </p>
              </div>)}
              <div className="mt-4">
                <button
                  disabled={true}
                  className="btn btn-primary waves-effect waves-light btn-sm"
                >
                  {i18n.t("DASHBOARD.FULFILLMENT")} <i className="mdi mdi-arrow-right ms-1"></i>
                </button>
              </div>
            </Col>
            <Col sm="6">
              <div className="mt-4 mt-sm-0">
              <ReactApexChart
                  options={options}
                  series={[chartValue]}
                  type="radialBar"
                  height="200"
                  className="apex-charts"
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default OrderStatusWidget;

import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { WomsServiceContext } from "services/womsService"
import ControlledTypehead from "components/Common/ControlledTypehead"
import { CountryDropdown, RegionDropdown } from "react-country-region-selector"
import i18n from "i18next"
import { withTranslation } from "react-i18next"
import Moment from "react-moment"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/bootstrap.css"
import { useSelector } from "react-redux"
import { NOTIFICATION_SEND_TO_TYPE, NOTIFICATION_TYPE, USER_ROLE } from "services/womsEnum"
import cf from "../../services/codeFormatter"
import { hasRole } from "helpers/woms_utils"
const NotificationModal = props => {
  const { show, isEdit, id, onClose, errorMessage } = props
  const { womsSvc } = useContext(WomsServiceContext)
  const [isLoading, setIsLoading] = useState(false)
  const womsUser = useSelector(state => state.womsUser);
  const organization = useSelector(state => state.organization);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      type : NOTIFICATION_TYPE.USER_MESSAGE,
      content : "",
      sendToType : NOTIFICATION_SEND_TO_TYPE.ALL_USER,
      sendToId : "",
      sendToOrgId : "",
      
    },
    
    validationSchema: Yup.object({
      content: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      sendToId: Yup.string().when('type', {
        is: type => type == NOTIFICATION_TYPE.USER_MESSAGE,
        then: Yup.string()
          .required(i18n.t("VALIDATION.TO_DATE_REQUIRED")),
        otherwise: Yup.string()
      })
    }),

    onSubmit: values => {
      setIsLoading(true);
        womsSvc.createNotification(values).then(({ data }) => {
          formik.resetForm()
          onClose(true)
          setIsLoading(false)
        })
      
    },
  })

  useEffect(() => {
      formik.resetForm();
  }, [show])

  return (
    <Modal isOpen={show} size="lg">
      <ModalHeader tag="h4">
        {isEdit ? i18n.t("EDIT_NOTIFICATION") : i18n.t("NEW_NOTIFICATION")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            formik.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col md="5">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("TYPE")}</Label>
                <Input
                  name="type"
                  type="select"
                  className="form-select"
                  onChange={formik.handleChange}
                  disabled={isEdit}
                  onBlur={formik.handleBlur}
                  value={formik.values.type || ""}
                  invalid={
                    formik.touched.type && formik.errors.type
                      ? true
                      : false
                  }
                >
                  <option value={NOTIFICATION_TYPE.USER_MESSAGE}>{cf.format('NotificationType',NOTIFICATION_TYPE.USER_MESSAGE)}</option>
                  {hasRole(womsUser,organization,USER_ROLE.SYS_ADMIN) && <option value={NOTIFICATION_TYPE.SYSTEM_ALERT}>{cf.format('NotificationType',NOTIFICATION_TYPE.SYSTEM_ALERT)}</option>}
                  {hasRole(womsUser,organization,USER_ROLE.SYS_ADMIN) && <option value={NOTIFICATION_TYPE.SYSTEM_NOTICE}>{cf.format('NotificationType',NOTIFICATION_TYPE.SYSTEM_NOTICE)}</option>}
                  {hasRole(womsUser,organization,USER_ROLE.ORG_ADMIN) && <option value={NOTIFICATION_TYPE.ORG_NOTICE}>{cf.format('NotificationType',NOTIFICATION_TYPE.ORG_NOTICE)}</option>}
                </Input>
                {formik.touched.type && formik.errors.type ? (
                  <FormFeedback type="invalid">
                    {formik.errors.type}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            {(formik.values.type == NOTIFICATION_TYPE.USER_MESSAGE || ((formik.values.type == NOTIFICATION_TYPE.SYSTEM_ALERT || formik.values.type == NOTIFICATION_TYPE.SYSTEM_NOTICE ) && hasRole(womsUser,organization,USER_ROLE.SYS_ADMIN) && formik.values.sendToType == NOTIFICATION_SEND_TO_TYPE.SPECIFIC_USER)) && <Col md="7">
            <Label className="form-label">{i18n.t("SEND_TO")}</Label>
            <ControlledTypehead
                odataFetch = {womsSvc.searchUser}
                disabled = {isEdit}
                value={formik.values.sendToId}
                filterColumn = {["firstName","lastName"]}
                labelKey= {(option) => `${option["firstName"]} ${option["lastName"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('sendToId',id);
                }}
                invalid={
                    formik.errors.sendToId ? true : false
                }
                />
                {formik.touched.sendToId && formik.errors.sendToId ? (
                  <FormFeedback type="invalid">
                    {formik.errors.sendToId}
                  </FormFeedback>
                ) : null}
            </Col>}
            {formik.values.type == NOTIFICATION_TYPE.ORG_NOTICE && hasRole(womsUser,organization,USER_ROLE.SYS_ADMIN) && <Col md="7">
            <Label className="form-label">{i18n.t("SEND_TO_ORG")}</Label>
            <ControlledTypehead
                odataFetch = {womsSvc.searchOrganization}
                disabled = {isEdit}
                value={formik.values.sendToOrgId}
                filterColumn = {["legalName","localizedLegalName"]}
                labelKey= {(option) => `${option["legalName"]}`}
                valueSelected = {(id) => {
                    formik.setFieldValue('sendToOrgId',id);
                }}
                invalid={
                    formik.errors.sendToOrgId ? true : false
                }
                />
                {formik.touched.sendToORgId && formik.errors.sendToOrgId ? (
                  <FormFeedback type="invalid">
                    {formik.errors.sendToOrgId}
                  </FormFeedback>
                ) : null}
            </Col>}
            </Row>
            <Row>
            {(formik.values.type == NOTIFICATION_TYPE.SYSTEM_ALERT || formik.values.type == NOTIFICATION_TYPE.SYSTEM_NOTICE ) && hasRole(womsUser,organization,USER_ROLE.SYS_ADMIN) &&<><Col md="5">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("SEND_TO_TYPE")}</Label>
                <Input
                  name="sendToType"
                  type="select"
                  className="form-select"
                  onChange={formik.handleChange}
                  disabled={isEdit}
                  onBlur={formik.handleBlur}
                  value={formik.values.sendToType || ""}
                  invalid={
                    formik.touched.sendToType && formik.errors.sendToType
                      ? true
                      : false
                  }
                >
                  <option value={NOTIFICATION_SEND_TO_TYPE.ALL_USER}>{cf.format('NotificationSendToType',NOTIFICATION_SEND_TO_TYPE.ALL_USER)}</option>
                  <option value={NOTIFICATION_SEND_TO_TYPE.CURRENT_ORGANIZATION}>{cf.format('NotificationSendToType',NOTIFICATION_SEND_TO_TYPE.CURRENT_ORGANIZATION)}</option>
                  <option value={NOTIFICATION_SEND_TO_TYPE.SPECIFIC_USER}>{cf.format('NotificationSendToType',NOTIFICATION_SEND_TO_TYPE.SPECIFIC_USER)}</option>
                  
                </Input>
                {formik.touched.sendToType && formik.errors.sendToType ? (
                  <FormFeedback type="invalid">
                    {formik.errors.sendToType}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md="7">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("REFERENCE_ROUTE")}</Label>
                <Input
                  name="referenceRoute"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.referenceRoute ? formik.values.referenceRoute : ""}
                  invalid={
                    formik.touched.referenceRoute && formik.errors.referenceRoute
                      ? true
                      : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.referenceRoute && formik.errors.referenceRoute ? (
                  <FormFeedback type="invalid">
                    {formik.errors.referenceRoute}
                  </FormFeedback>
                ) : null}
              </div>
            </Col></>}
            </Row>
          <Row>
            <Col md="12">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("CONTENT")}</Label>
                <Input
                  name="content"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.content ? formik.values.content : ""}
                  invalid={
                    formik.touched.content && formik.errors.content
                      ? true
                      : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.content && formik.errors.content ? (
                  <FormFeedback type="invalid">
                    {formik.errors.content}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            </Row>
            
            
          <Row>
            <Col md="12">
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => onClose()}
                  disabled={isLoading}
                >
                  {i18n.t("CANCEL")}
                </button>
                &nbsp;&nbsp;
                <button
                  type="submit"
                  className="btn btn-success save-integration"
                  disabled={isLoading}
                >
                  {i18n.t("SAVE")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(NotificationModal)

import React, { useEffect, useRef, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { hasRole } from "helpers/woms_utils";
import { USER_ROLE } from "services/womsEnum";
import { useSelector } from "react-redux";
import { Badge } from "reactstrap";
import BetaFeedbackModal from "pages/Notification/BetaFeedbackModal";

const SidebarContent = props => {
  const ref = useRef();
  const womsUser = useSelector(state => state.womsUser);
  const organization = useSelector(state => state.organization);
  const badge = useSelector(state => state.badge);
  const [feedbackModal, setFeedbackModal] = useState(false);

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function toggleBetaModal() {
      setFeedbackModal(true);
  }

  const handleModalClose = isUpdated => {
    setFeedbackModal(false)
  }

  return (
    <>
    <SimpleBar className="h-100" ref={ref}>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{props.t("HOME")} </li>
          <li>
            <Link to="/dashboard" >
              <i className="bx bx-home-circle"></i>
              <span>{props.t("DASHBOARD")}</span>
            </Link>
          </li>
          <li className="menu-title">{props.t("ORDER")}</li>
          <li>
            <Link to="/salesOrder" >
              <i className="bx bx-archive"></i>
              <span>{props.t("SALES_ORDERS")}</span>
            </Link>
          </li>
          <li className="menu-title">{props.t("PRODUCT")}</li>

          <li>
            <Link to="/product" >
              <i className="bx bx-purchase-tag-alt"></i>
              <span>{props.t("PRODUCTS")}</span>
            </Link>
          </li>
          {/*<li>
            <Link to="/bundle" >
              <i className="bx bx-package"></i>
              <span>{props.t("BUNDLES")}</span>
            </Link>
  </li>*/}
          <li className="menu-title">{props.t("STORE")}</li>
          <li>
            <Link to="/store" >
              <i className="bx bx-store"></i>
              <span>{props.t("STORES")}</span>
            </Link>
          </li>
          <li>
            <Link to="/listing" >
              <i className="bx bxs-book-content"></i>
              <span>{props.t("LISTINGS")}</span>{badge.newListings !=0 && <Badge className={"ms-1"} color="danger" pill>{badge.newListings}</Badge>}
            </Link>
          </li>
          <li className="menu-title">{props.t("REPORT")}</li>
          <li>
            <Link to="/report" >
              <i className="bx bx-bar-chart-alt-2"></i>
              <span>{props.t("REPORT")}</span>
            </Link>
          </li>
          {/*<li className="menu-title">{props.t("INTEGRATION")}</li>
          <li>
            <Link to="/integration" >
              <i className="bx bx-share-alt"></i>
              <span>{props.t("INTEGRATIONS")}</span>
            </Link>
</li>*/}
          <li className="menu-title">{props.t("ORGANIZATION")}</li>
          {/*<li>
            <Link to="/setting" >
              <i className="bx bx-cog"></i>
              <span>{props.t("SETTINGS")}</span>
            </Link>
</li>*/}
          {hasRole(womsUser,organization,USER_ROLE.ORG_ADMIN) && !hasRole(womsUser,organization,USER_ROLE.SYS_ADMIN) &&<li>
            <Link to="/user" >
              <i className="bx bx-user-circle"></i>
              <span>{props.t("USERS")}</span>
            </Link>
          </li>}
          <li>
            <Link to="/location" >
              <i className="bx bx-map"></i>
              <span>{props.t("LOCATIONS")}</span>
            </Link>
          </li>
          {hasRole(womsUser,organization,USER_ROLE.SYS_ADMIN) && <><li className="menu-title">{props.t("SYSTEM_ADMINISTRATION")}</li>
          <li>
            <Link to="/organization" >
              <i className="bx bx-briefcase"></i>
              <span>{props.t("ORGANIZATIONS")}</span>
            </Link>
          </li>
          <li>
            <Link to="/user" >
              <i className="bx bx-user-circle"></i>
              <span>{props.t("USERS")}</span>
            </Link>
          </li></>}
        </ul>
        
      </div>
    </SimpleBar>
    <div className="sticky-bottom" >
    <Badge className="mb-3 ms-4" role="button" color="primary" onClick={toggleBetaModal} pill>{props.t("BETA_FEEDBACK")}</Badge>
      </div>
      <BetaFeedbackModal
        show={feedbackModal}
        onClose={handleModalClose}
      />
  </>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));

import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import withRouter from "components/Common/withRouter";
import {
  changeLayout,
  changeLayoutMode,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  showRightSidebarAction,
  updateNewListings
} from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import RightSidebar from "../CommonForBoth/RightSidebar";

//redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { WomsServiceContext } from "services/womsService";

import { HubConnectionBuilder } from '@microsoft/signalr';

import firebase from 'firebase/compat/app';


// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { Token } from "react-bootstrap-typeahead";
import { DASHBOARD_TYPE, REPORT_TYPE } from "services/womsEnum";
import { updateOrdersWidget } from "store/woms-dashboard/orders/actions";
import { updateAvgPriceWidget } from "store/woms-dashboard/avg-price/actions";
import { updateSalesWidget } from "store/woms-dashboard/sales/actions";
import { updateTopSkusWidget } from "store/woms-dashboard/top-skus/actions";
import { updateTopStoresWidget } from "store/woms-dashboard/top-stores/actions";
import { updateOrderStatusWidget } from "store/woms-dashboard/order-status/actions";
import { updateStoreStatWidget } from "store/woms-dashboard/store-stat/actions";
import { updateBatchResult } from "store/batchResult/actions";
import { updateSalesByStoreWidget } from "store/report/sales-by-store/actions";
import { updateSalesBySkuWidget } from "store/report/sales-by-sku/actions";
import { updateHubConnection } from "store/hubconnectionStatus/actions";
import { updateUnreadCount } from "store/notification/actions";
import { updateSkuTrendWidget } from "store/report/sku-trend-by-store/actions";
import { updateProfitBySkuWidget } from "store/report/profit-by-sku/actions";
import { updateProfitByStoreWidget } from "store/report/profit-by-store/actions";
const Layout = props => {
  const dispatch = useDispatch();
  const organization = useSelector(state => state.organization);
  const {
    isPreloader,
    leftSideBarThemeImage,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    showRightSidebar,
    leftSideBarTheme,
    layoutModeType
} = useSelector(state => ({
    isPreloader: state.Layout.isPreloader,
    layoutModeType: state.Layout.layoutModeType,
    leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    showRightSidebar: state.Layout.showRightSidebar,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
}), shallowEqual);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const { womsSvc } = useContext(WomsServiceContext);
  const womsUser = useSelector(state => state.womsUser);

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile));
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile));
    }
  };

  //hides right sidebar on body click
  const hideRightbar = (event) => {
    var rightbar = document.getElementById("right-bar");
    //if clicked in inside right bar, then do nothing
    if (rightbar && rightbar.contains(event.target)) {
      return;
    } else {
      //if clicked in outside of rightbar then fire action for hide rightbar
      dispatch(showRightSidebarAction(false));
    }
  };

  /*
  layout  settings
  */

  useEffect(() => {
    //init body click event fot toggle rightbar
    document.body.addEventListener("click", hideRightbar, true);


  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (organization && organization.id) {
      womsSvc.getNewStoreListingsCount().then(({data}) => {
        dispatch(updateNewListings(data));
      })
    }

  }, [organization]);

  useEffect(() => {
    dispatch(changeLayout("vertical"));
  }, [dispatch]);

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
  }, [leftSideBarTheme, dispatch]);

  useEffect(() => {
    if (layoutModeType) {
      dispatch(changeLayoutMode(layoutModeType));
    }
  }, [layoutModeType, dispatch]);

  useEffect(() => {
    if (leftSideBarThemeImage) {
      dispatch(changeSidebarThemeImage(leftSideBarThemeImage));
    }
  }, [leftSideBarThemeImage, dispatch]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [layoutWidth, dispatch]);

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
  }, [leftSideBarType, dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [topbarTheme, dispatch]);

  useEffect(() => {
if (womsUser.id ) {
 var hubConnection = new HubConnectionBuilder()
 //.withUrl(process.env.REACT_APP_SERVER_PUSH_URL, { accessTokenFactory: () => storedUser.stsTokenManager.accessToken })
 .withUrl(process.env.REACT_APP_SERVER_PUSH_URL, {
   accessTokenFactory: () => firebase.auth().currentUser.getIdToken()
})
 .build();

 hubConnection.start()
   .then(() => {
     // 
     console.log("Hub connection");
     dispatch(updateHubConnection(true));
     hubConnection.on('SendMessage', (user,message) => {
       console.log(user + ": " + message);
     });
     hubConnection.on('UpdateDashboard', (data) => {
       if (data) {
        switch (data.type) {
          case DASHBOARD_TYPE.ORDERS:
            dispatch(updateOrdersWidget(data));
          break;
          case DASHBOARD_TYPE.SALES:
            dispatch(updateSalesWidget(data));
          break;
          case DASHBOARD_TYPE.AVG_PRICE:
            dispatch(updateAvgPriceWidget(data));
          break;
          case DASHBOARD_TYPE.TOP_SKUS:
            dispatch(updateTopSkusWidget(data));
          break;
          case DASHBOARD_TYPE.TOP_STORES:
            dispatch(updateTopStoresWidget(data));
          break;
          case DASHBOARD_TYPE.ORDER_STATUS:
            dispatch(updateOrderStatusWidget(data));
          break;
          case DASHBOARD_TYPE.STORE_STAT:
            dispatch(updateStoreStatWidget(data));
          break;
        }
       }
     });
     hubConnection.on('UpdateBatchRequest', (id) => {
        dispatch(updateBatchResult());
    });
    hubConnection.on('UpdateNotification', () => {
      dispatch(updateUnreadCount(-1));
  });
    hubConnection.on('UpdateReport', (data) => {
      if (data) {
       switch (data.type) {
         case REPORT_TYPE.SALES_BY_STORE:
           dispatch(updateSalesByStoreWidget(data));
         break;
         case REPORT_TYPE.SALES_BY_SKU:
           dispatch(updateSalesBySkuWidget(data));
         break;
         case REPORT_TYPE.SKU_TREND_BY_STORE:
           dispatch(updateSkuTrendWidget(data));
         break;
         case REPORT_TYPE.PROFIT_BY_SKU:
           dispatch(updateProfitBySkuWidget(data));
         break;
         case REPORT_TYPE.PROFIT_BY_STORE:
          dispatch(updateProfitByStoreWidget(data));
        break;
       }
      }
    });
   })
   .catch(err => 
    { 
      console.log('Error while establishing connection :(')
  });
   
   // 이게 사용되는 시나리오는 서버가 잠깐 죽었다 5초만에 살아나는 케이스밖에 없음
   // heartbeat 으로 죽었을경우 재접속하도록 개선할것 
   hubConnection.onclose(function() {
    dispatch(updateHubConnection(false));
     setTimeout(function() {
         hubConnection.start();
     }, 5000); // Restart connection after 5 seconds.
  });

 
}
  
}, [womsUser]);

  return (
    <>
      {/* <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div> */}

      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
      {showRightSidebar ? <RightSidebar /> : null}
    </>
  );
};

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.any,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

export default withRouter(Layout);

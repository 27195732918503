import LinearProgress from "components/Common/LinearProgress";
import React, { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Progress } from "reactstrap"
import { DASHBOARD_STATUS, DASHBOARD_TYPE } from "services/womsEnum";
import { WomsServiceContext } from "services/womsService";
import { updateTopSkusWidget } from "store/woms-dashboard/top-skus/actions";
import i18n from 'i18next';

const TopSkusWidget = (props) => {

  const { params,forceUpdate } = props;
  const { womsSvc } = useContext(WomsServiceContext)
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(false);
  const [ thumbnails, setThumbnails] = useState([]);
  const dispatch = useDispatch();
  const widget = useSelector(state => state.topSkusWidget);

  useEffect(() => {
      if (params) {
        womsSvc.requestDashboard( DASHBOARD_TYPE.TOP_SKUS,params,false).then(({data}) => {
            dispatch(updateTopSkusWidget(data));
        });
      }
  }, [params]);
  useEffect(() => {
    if (forceUpdate > 0) {
      womsSvc.requestDashboard( DASHBOARD_TYPE.TOP_SKUS,params,true).then(({data}) => {
          dispatch(updateTopSkusWidget(data));
      });
    }
}, [forceUpdate]);
  useEffect(() => {
    if (widget && widget.data && widget.data.id) {
          setStatus(widget.data.status)
          if (widget.data.payload) {
            var data = JSON.parse(widget.data.payload)
              setData(data);

              var skus= data.topSkus?.map(l => l.sku);

                womsSvc.getThumbnails(skus).then(({ data }) => {
                    setThumbnails(data?.value);
                })
          } else {
            setData({});
          }
    }
}, [widget]);

  return (
    <>
      <Card>
        <CardBody>
        {(!status || status == DASHBOARD_STATUS.PROCESSING) && <LinearProgress/>}
          <CardTitle className="mb-4">{i18n.t("DASHBOARD.TOP_SELLING_SKUS")} {(status && status == DASHBOARD_STATUS.FAILED) && <i className="bx bx-error-circle text-danger font-size-13"></i>}</CardTitle>
          <div className="text-center">
            <div className="mb-4">
            {data && data.topSkus && data.topSkus[0] && data.topSkus[0].sku && thumbnails.find(t=> t.sku ==  data.topSkus[0].sku) ? <img src={thumbnails.find(t=> t.sku ==  data.topSkus[0].sku).thumbnailUrl}/> : <i className="bx bx-image-alt woms-product-placeholder" />}
            </div>
            <h3>{data && data.topSkus && data.topSkus[0] && data.topSkus[0].count}</h3>
            <p>{data && data.topSkus && data.topSkus[0] && data.topSkus[0].sku}</p>
          </div>

          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
              {data && data.topSkus && data.topSkus.map((entry,index) => index !=0 && <tr key={entry.sku}>
                  <td style={{ width: "30%" }}>
                  <p className="mb-0">{entry.sku && thumbnails.find(t=> t.sku ==  entry.sku) ? <img width="30px" src={thumbnails.find(t=> t.sku ==  entry.sku).thumbnailUrl}/> : <i className="bx bx-image-alt woms-sku-placeholder"/>}{entry.sku}</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">{entry.count}</h5>
                  </td>
                  <td>
                    <Progress
                      value={(entry.count/data.topSkus[0].count)*100}
                      color={index == 1 ? "success" : index == 2 ? "warning" : "primary" }
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>)}

                
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default TopSkusWidget

import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import moment from 'moment';
//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";
import i18n from "i18n";
import { updateUnreadCount } from "store/notification/actions";
import { useDispatch, useSelector } from "react-redux";
import { WomsServiceContext } from "services/womsService";
import cf from "../../../services/codeFormatter";
import { NOTIFICATION_TYPE } from "services/womsEnum";
import Avatar from "react-avatar";

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const { womsSvc } = useContext(WomsServiceContext);
  const navigate = useNavigate();
  const unreadCount = useSelector(state => state.notification.unread);
  const dispatch = useDispatch();
  const organization = useSelector(state => state.organization);
  const [notifications, setNotifications] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (organization && organization.id) {
      womsSvc.getUnreadCount().then(({ data }) => {
        dispatch(updateUnreadCount(data));
      })
      womsSvc.searchNotifications(5,0,"createdOn","desc").then(({ data }) => {
        setNotifications(data.value);
        setTotalCount(data['@odata.count']);

      })
    }

  }, [organization]);

  useEffect(() => {
    if (menu == true) {
        womsSvc.markAsRead(notifications.filter(each=> each.userNotifications[0].isRead == false).map(each=> each.id)).then(({ data }) => {
          womsSvc.getUnreadCount().then(({ data }) => {
            dispatch(updateUnreadCount(data));
          })
        });
    }
  }, [menu]);
  useEffect(() => {
    if (unreadCount == -1) {
        womsSvc.getUnreadCount().then(({ data }) => {
          dispatch(updateUnreadCount(data));
        })
        womsSvc.searchNotifications(5,0,"createdOn","desc").then(({ data }) => {
          setNotifications(data.value);
          setTotalCount(data['@odata.count']);
  
        })
      }
  }, [unreadCount]);
  const loadMore= () => {
    womsSvc.searchNotifications(5,notifications.length,"createdOn","desc").then(({ data }) => {
      setNotifications(notifications.concat(data.value));
      setTotalCount(data['@odata.count']);
      womsSvc.markAsRead(data.value.filter(each=>each.userNotifications[0].isRead == false).map(each=> each.id)).then(({ data }) => {
        womsSvc.getUnreadCount().then(({ data }) => {
          dispatch(updateUnreadCount(data));
        })
      });
    })
  }

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          {unreadCount > 0 && <span className="badge bg-danger rounded-pill">{unreadCount}</span>}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("NOTIFICATIONS")} </h6>
              </Col>
              <div className="col-auto">
                <a href="/notification" className="small">
                  {" "}
                 {props.t("NOTIFICATIONS.VIEW_ALL")}
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: notifications.length > 1 ? "230px" : "80px"}}>
            {notifications.length == 0 && <>
              <div className="text-center">
              <div className="font-size-12 text-muted">
                    <h6 className="m-3">{props.t("NOTIFICATIONS.NO_MESSAGES")}</h6>
                    </div>
              </div>
            </>}
        {
    notifications.map(n => (
        <div key={n.id}>
            <div className="text-reset notification-item" key={n.id}>
              <div className="d-flex">
                <div className="avatar-xs me-3">
                {n.type === NOTIFICATION_TYPE.SYSTEM_NOTICE ? 
                <span className="avatar-title bg-success rounded-circle font-size-16">
                    
                <i className="bx bx-message" />
              </span> : n.type === NOTIFICATION_TYPE.SYSTEM_ALERT ?
              <span className="avatar-title bg-danger rounded-circle font-size-16">
                    
              <i className="bx bx-error" />
            </span> : n.type === NOTIFICATION_TYPE.USER_MESSAGE ? 
            <Avatar name={n.sentBy?.firstName + " " + n.sentBy?.lastName} src={n.sentBy?.imageUrl} size="34" round="17px" /> 
            : n.type === NOTIFICATION_TYPE.ORG_NOTICE ? 
            <span className="avatar-title bg-primary rounded-circle font-size-16">
                    
              <i className="bx bx-error" />
            </span> :
            <></>}  
                </div>
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">
                  {n.type !== NOTIFICATION_TYPE.USER_MESSAGE ? cf.format("NotificationType",n.type) : (n.sentBy?.firstName + " " + n.sentBy?.lastName)}
                  {!n.userNotifications[0].isRead && <span className="badge badge-soft-warning ms-2">New</span>}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                    {n.content}
                    </p>
                    {n.referenceRoute && <a href={n.referenceRoute}>Details</a>}
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />{" "}
                      {moment(n.createdOn).fromNow()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>
    ))
}
            
            
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            {notifications && notifications.length < totalCount && <Link className="btn btn-sm btn-link font-size-14 text-center" onClick={() => loadMore()}>
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("NOTIFICATIONS.VIEW_MORE")}</span>
            </Link>}
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};
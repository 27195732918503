export function getStripeStatusCssClasses(status) {
  var type;
  switch (status) {
    case 'draft':
      type = "primary"
      break;
    case 'open':
      type = "warning"
      break;
    case 'paid':
      type = "success"
      break;
    case 'uncollectible':
      type = "danger"
      break;
    case 'void':
      type = "secondary"
      break;
    case 'upcomming':
      type = "primary"
      break;
    default:
      type = ""
  }

  return `badge badge-soft-${type
    }`;
}
import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap"
import { WomsServiceContext } from "services/womsService"
import i18n from "i18next"
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import cf from "../../services/codeFormatter";
import CardPricing from "./CardPricing"
import { SUBSCRIPTION_PLAN_TYPE } from "services/womsEnum"

const SubscriptionPlanModal = props => {
  const { show, onClose } = props
  const { womsSvc } = useContext(WomsServiceContext)
  const [isLoading, setIsLoading] = useState(false)
  const organization = useSelector(state => state.organization);
  const womsUser = useSelector(state => state.womsUser);
  const dispatch = useDispatch();

  const handleFreePlan = () => {
    setIsLoading(true);
    womsSvc.createEnrollment(SUBSCRIPTION_PLAN_TYPE.FREE).then(({data}) => {
      if (data.sessionUrl) {
        window.location.href = data.sessionUrl;
      } else {
        window.location.href = '/';
      }
    });
  }

  const handleBasicPlan = () => {
    setIsLoading(true);
    womsSvc.createEnrollment(SUBSCRIPTION_PLAN_TYPE.BASIC).then(({data}) => {
      if (data.sessionUrl) {
        window.location.href = data.sessionUrl;
      } else {
        window.location.href = '/';
      }
    });
  }
  const handleProPlan = () => {
    setIsLoading(true);
    womsSvc.createEnrollment(SUBSCRIPTION_PLAN_TYPE.PRO).then(({data}) => {
      if (data.sessionUrl) {
        window.location.href = data.sessionUrl;
      } else {
        window.location.href = '/';
      }
    });
  }
  const pricings = [
    {
      id: 1,
      title: i18n.t("PLAN_FREE"),
      description: i18n.t("PLAN_FREE_DESC"),
      icon: "",
      price: "0",
      duration: i18n.t("PER_MONTH"),
      subscriptionPlan :SUBSCRIPTION_PLAN_TYPE.FREE,
      callback : handleFreePlan,
      features: [
        { title: i18n.t("FREE_FEATURE_1") },
        { title: i18n.t("FREE_FEATURE_2") },
        { title: i18n.t("FREE_FEATURE_3") }
      ],
    },
    {
      id: 1,
      title: i18n.t("PLAN_BASIC"),
      description: i18n.t("PLAN_BASIC_DESC"),
      icon: "bx-walk",
      price: "50",
      duration: i18n.t("PER_MONTH"),
      //link: "https://buy.stripe.com/test_fZe28d0kd6VWaGc3cc",
      callback : handleBasicPlan,
      subscriptionPlan :SUBSCRIPTION_PLAN_TYPE.BASIC,
      features: [
        { title: i18n.t("BASIC_FEATURE_1") },
        { title: i18n.t("BASIC_FEATURE_2") },
        { title: i18n.t("BASIC_FEATURE_3") },
        { title: i18n.t("BASIC_FEATURE_4") }
      ],
    },
    {
      id: 2,
      title: i18n.t("PLAN_PRO"),
      description: i18n.t("PLAN_PRO_DESC"),
      icon: "bx-run",
      price: "200",
      duration: i18n.t("PER_MONTH"),
      //link: "https://buy.stripe.com/test_28o3chaYR6VW9C8fYZ",
      callback : handleProPlan,
      subscriptionPlan :SUBSCRIPTION_PLAN_TYPE.PRO,
      features: [
        { title: i18n.t("PRO_FEATURE_1") },
        { title: i18n.t("PRO_FEATURE_2") },
        { title: i18n.t("PRO_FEATURE_3") },
        { title: i18n.t("PRO_FEATURE_4") }
      ],
    },
    {
      id: 3,
      title: i18n.t("PLAN_ENTERPRISE"),
      description: i18n.t("PLAN_ENTERPRISE_DESC"),
      icon: "bx-cycling",
      price: "",
      subscriptionPlan :SUBSCRIPTION_PLAN_TYPE.ENTERPRISE,
      duration: i18n.t("CONTACT_US"),
      link: "mailto:support@codizsoft.com",
      features: [
        { title: i18n.t("ENTERPRISE_FEATURE_1") },
        { title: i18n.t("ENTERPRISE_FEATURE_2") },
        { title: i18n.t("ENTERPRISE_FEATURE_3") }
      ],
    },
  ]
  useEffect(() => {

  }, [show])

  return (
    <Modal isOpen={show} size="xl">
        <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              onClose();
            }}
          ></ModalHeader>
      <ModalBody>
      <Container fluid>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center mb-5">
                <h4>{i18n.t("PLAN_MODAL_TITLE")}</h4>
                <p className="text-muted">
                {i18n.t("PLAN_MODAL_DESC")}
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            {pricings.map((pricing, key) => (
              <CardPricing pricing={pricing} key={"_pricing_" + key} organization={organization} isLoading={isLoading}/>
            ))}
          </Row>
          <Row>
            <Col className="w-100 text-end">
            <small>{i18n.t("PLAN_UPGRADE_DESC")}</small>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  )
}
export default withTranslation() (SubscriptionPlanModal)

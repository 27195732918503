import { takeLatest, takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeRegister,
  postJwtRegister,
} from "../../../helpers/fakebackend_helper"
import { refreshWomsUser } from "../womsuser/actions"

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user,history } }) {

  console.log("using the following url for registration: ")
  try {
    console.log("Trying to register user (within try block)")
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.registerUser,
        user.email,
        user.password,
        user.firstName,
        user.lastName,
      )

      localStorage.setItem("authUser", JSON.stringify(response));
      /*const firebaseUID = response.uid;
      const REGISTER_URL = process.env.REACT_APP_WOMS_API_URL + "/WomsUser/register"; // Replace with your actual API URL
      
      
      const token = yield call(() => response.getIdToken());

      const womsUserRegisterResponse = yield call(fetch, REGISTER_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          // Include the data you want to send to your remote API
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          fireBaseUid:firebaseUID
        })
      });

      const remoteResponseData = yield womsUserRegisterResponse.json();
      
      yield put(refreshWomsUser(remoteResponseData))
*/
      yield put(registerUserSuccessful(response))
      history("/new");
      
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtRegister, "/post-jwt-register", user)
      yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeRegister, user)
      yield put(registerUserSuccessful(response))
    }
  } catch (error) {
    console.log("There was an error registering: ", error)
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga

import React, { useEffect, useState, useContext, useRef } from 'react'
import {
    Button,
    Col,
    FormFeedback,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Form,s
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { WomsServiceContext } from 'services/womsService'
import i18n from 'i18next';
import { withTranslation } from 'react-i18next'
import cf from "../../../../services/codeFormatter";
import '../../product.scss'
import { useDebounceSearch } from 'helpers/woms_hooks'
import { isStringEqualIgnoreCase } from 'helpers/woms_utils'
import { CLIENT_SYNC_TYPE, TAG_SHOW_OPTION } from 'services/womsEnum'


const ProductTagModal = props => {
    const { show, setShow, productId, productTags } = props
    const { womsSvc } = useContext(WomsServiceContext)
    const searchRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false)
    const [showOption, setShowOption] = useState(TAG_SHOW_OPTION.ALL)
    const [tags, setTags] = useState([])
    const [searchValue, setSearchValue] = useDebounceSearch()
    const showOptions= [ TAG_SHOW_OPTION.ALL, TAG_SHOW_OPTION.SELECTED, TAG_SHOW_OPTION.NOT_SELECTED];

    function handleCheckboxClick(e) {
        const timeout= setTimeout(() => {
            //setNewlyAddedTags(newlyAddedTags.filter(each => each.name !== e.target.dataset.name))   
            var updatedTags = [...tags];
            var target = updatedTags.find(each => each.id == e.target.value);
            target.isSelected = !target.isSelected;
            if (target.isSelected) {
                target.syncType == CLIENT_SYNC_TYPE.DELETED ? target.syncType = undefined : target.syncType = CLIENT_SYNC_TYPE.CREATED;
            } else {
                target.syncType == CLIENT_SYNC_TYPE.CREATED ? target.syncType = undefined : target.syncType = CLIENT_SYNC_TYPE.DELETED;
            }    
            setTags(updatedTags);
        }, 200)
        return () => clearTimeout(timeout)
    }


    function handleAddClick() {
        womsSvc.createTag({id:0, name: searchValue}).then(({data}) =>{
            data.isNew = true;
            data.isSelected = true;
            data.syncType = CLIENT_SYNC_TYPE.CREATED;
            setTags([...tags, data])
            setSearchValue('')
            searchRef.current.value = ''
        });
        
    }

    const changeShowOption = showOption => {
        setShowOption(showOption)
    }

    function handleSaveClick() {
        var payload = tags.filter(each => each.syncType == CLIENT_SYNC_TYPE.CREATED || each.syncType == CLIENT_SYNC_TYPE.DELETED);
        if (payload.length >0) {
            womsSvc.updateTags(productId,payload).then(({data}) =>{
                // data is updated product
                setShow(data);
            });
        }
    }

    useEffect(() => {
        if (show) {
            setShowOption(TAG_SHOW_OPTION.ALL)
            setSearchValue('')
            setIsLoading(true);
            womsSvc.getAllTags().then(({ data }) => { 
                data.map(each => productTags.some( e=> e.id == each.id) ? each.isSelected = true : each);
                setTags(data);
                setIsLoading(false);

            });
           
        }
    }, [show])


    return (
        <Modal isOpen={show} toggle={()=>setShow()}>
            <ModalHeader tag={'h5'} toggle={()=>setShow()}>
                <Row>
                    <Col>
                        {i18n.t("EDIT_TAG")}
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody className='pb-0'>
                <div>
                    <Row>
                        <Col>
                            <div className="search-box my-xxl-0 d-inline-block mb-0">
                                <div className="position-relative">
                                    <label htmlFor="search-bar-0" className="search-label mb-1">
                                        <input
                                            onChange={(e) => setSearchValue(e.target.value)}
                                            id="search-bar-0"
                                            type="text"
                                            className="form-control"
                                            placeholder={i18n.t("SEARCH_DEFAULT_OPTION")}
                                            ref={searchRef}
                                            
                                        />
                                    </label>
                                    <i className="bx bx-search-alt search-icon" style={{ lineHeight: "36px" }}></i>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                <UncontrolledDropdown size='sm'>
                                    <DropdownToggle className='btn-light my-2 rounded-pill'>
                                        <span className='fw-bold'>{i18n.t("SHOW")}: </span>{cf.format("TagShowOption", showOption)}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {showOptions.map(each =>
                                            <DropdownItem key={each} className={`${showOption === each ? 'active' : 'none'}`} onClick={() => changeShowOption(each)}>{cf.format("TagShowOption", each)}</DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </Col>
                    </Row>
                    {(searchValue && !tags.some(each => isStringEqualIgnoreCase(each.name, searchValue))) &&
                        <Row>
                            <Col>
                                <Button
                                    type="button"
                                    className="btn-light btn-rounded btn-sm ml-2"
                                    onClick={handleAddClick}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    {i18n.t("TAG_ADD")} <span className='fw-bold'>{searchValue}</span>
                                </Button>
                            </Col>
                        </Row>
                    }
                </div>
            </ModalBody>
            <ModalBody className='pe-0 ps-0 pb-0'>
                <div className='woms-scrollable-box-shadow'>
                    <div className='ps-4'>
                        {tags.some(el=> el.isNew) &&
                            <Row style={{ width: '100%' }}>
                                <Col sm={12}>
                                    <h6 className='fw-bold mb-3'>{i18n.t("TAG_NEWLY_ADDED")}</h6>
                                </Col>
                                <Col sm={12} className='ps-4'>
                                    {tags.filter(each => each.isNew && (!searchValue || (each.name.toLowerCase().includes(searchValue.toLowerCase())))).map((item, index) => {
                                        return (
                                            <Row key={index} className='mb-3'>
                                                <Col>
                                                    <div className="form-check woms-checkbox-lg">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value={item.id}
                                                            data-name={item.name}
                                                            id={`checkbox-new-${index}`}
                                                            onChange={handleCheckboxClick}
                                                            checked={item.isSelected}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor={`checkbox-new-${index}`}
                                                        >
                                                            {item.name}
                                                        </label>

                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </Col>
                            </Row>
                        }
                        {(showOption !== TAG_SHOW_OPTION.NOT_SELECTED) &&
                            <Row style={{ width: '100%' }}>
                                <Col sm={12}>
                                    <h6 className='fw-bold mb-3'>{i18n.t("TAG_SELECTED")}</h6>
                                </Col>
                                <Col sm={12} className='ps-4'>
                                    {tags.some(el => el.isSelected) &&
                                        tags.filter(each => each.isSelected && (!searchValue || each.name.toLowerCase().includes(searchValue.toLowerCase()))).map(each => {
                                            return (
                                                <Row key={each.id} className='mb-3'>
                                                    <Col>
                                                        <div className="form-check woms-checkbox-lg">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value={each.id}
                                                                id={`checkbox-${each.id}`}
                                                                data-name={each.name}
                                                                onChange={handleCheckboxClick}
                                                                checked={each.isSelected}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`checkbox-${each.id}`}
                                                            >
                                                                {each.name}
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>)
                                        })
                                    }
                                </Col>
                            </Row>
                        }
                        {(showOption !== TAG_SHOW_OPTION.SELECTED) &&
                            <Row style={{ width: '100%' }}>
                                <Col sm={12}>
                                    <h6 className='fw-bold mb-3'>{i18n.t("TAG_NOT_SELECTED")}</h6>
                                </Col>
                                <Col sm={12} className='ps-4'>

                                    {tags.length > 0 &&
                                        tags.filter(each => !each.isSelected && (!searchValue || each.name.toLowerCase().includes(searchValue.toLowerCase()))).map(each => {

                                            return (
                                                <Row key={each.id} className='mb-3'>
                                                    <Col>
                                                        <div className="form-check woms-checkbox-lg">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value={each.id}
                                                                id={`checkbox-${each.id}`}
                                                                data-name={each.name}
                                                                onChange={handleCheckboxClick}
                                                                checked={each.isSelected}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`checkbox-${each.id}`}
                                                            >
                                                                {each.name}
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>)
                                        })
                                    }
                                </Col>
                            </Row>
                        }
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Row>
                    <Col>
                        <div className="text-end">
                            <button type="button" className="btn btn-secondary" onClick={()=>setShow()} disabled={isLoading}>{i18n.t("CANCEL")}</button>
                            &nbsp;&nbsp;
                            <button
                                type="submit"
                                className="btn btn-success"
                                disabled={isLoading}
                                onClick={handleSaveClick}
                            >
                                {i18n.t("SAVE")}
                            </button>
                        </div>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    )
}

export default withTranslation()(ProductTagModal)
import React, {useContext, useEffect, useState} from 'react'
import {
    Card,
    CardTitle,
    CardBody,
    Button
} from 'reactstrap'
import EditButtonIcon from 'components/Common/EditButtonIcon'
import { withTranslation } from 'react-i18next'
import { WomsServiceContext } from 'services/womsService'
import i18n from 'i18next';


const ProductVariants = (props) => {

    const { productId } = props;
    const { womsSvc } = useContext(WomsServiceContext);
    const [isLoading, setIsLoading] = useState(false);
    const [variants, setVariants] = useState({});

    function onEditClick() {
    }

    return (
        <>
            <Card>
                <CardBody>
                <CardTitle className='mb-2'>
                        {i18n.t("PRODUCT_VARIANTS")}
                        <Button
                            //disabled={isLoading}
                            disabled
                            type="button"
                            className="btn btn-light btn-sm woms-edit-btn"
                            onClick={onEditClick}
                        >
                            <i className="bx bx-pencil" />
                        </Button>
                    </CardTitle>
                    
                </CardBody>
            </Card>
        </>
    )
}

export default withTranslation()(ProductVariants)